import { IPopupOptions } from './types';
import { STATIC_STORAGE_URL } from '@nexxt/common/constants';

export const firstBucketOptionsDefault = [
    {
        id: 1,
        text: 'browsed the automaker’s website'
    },
    {
        id: 2,
        text: 'browsed 3rd party auto sites'
    },
    {
        id: 3,
        text: 'read reviews online'
    },
    {
        id: 4,
        text: 'compared prices online'
    },
    {
        id: 5,
        text: 'searched for vehicle info online'
    },
    {
        id: 6,
        text: 'researched financing options'
    },
    {
        id: 7,
        text: 'talked to friends/family'
    },
    {
        id: 8,
        text: 'visited a showroom/dealer'
    },
    {
        id: 9,
        text: 'watched video online'
    },
    {
        id: 10,
        text: 'searched content from influencers/experts online'
    }
];

export const secondBucketOptionsDefault = [
    {
        id: 11,
        text: 'saw an ad online'
    },
    {
        id: 12,
        text: 'saw an ad offline'
    },
    {
        id: 13,
        text: 'saw content from influencers/experts online'
    },
    {
        id: 14,
        text: 'saw a vehicle in street'
    },
    {
        id: 15,
        text: 'heard people talking about it'
    },
    {
        id: 16,
        text: 'received a newsletter/email'
    },
    {
        id: 17,
        text: 'received something in mail'
    },
    { id: 18, text: 'received a promotion' },
    { id: 19, text: 'saw a pop-up/event' }
];

export const emojiListDefault = [
    {
        id: '1',
        texts: [
            {
                language: 'en',
                text: 'Very difficult'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'Very difficult'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/usr/4dae543b-15df-4658-a4cf-c5ae90f221b8_Terrible.png`,
        value: 1,
        label: 'Very difficult',
        tags: []
    },
    {
        id: '2',
        texts: [
            {
                language: 'en',
                text: 'Quite difficult'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'Quite difficult'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/usr/439e7627-7512-42f7-a98e-1ffac48eefa8_Bad.png`,
        value: 2,
        label: 'Quite difficult',
        tags: []
    },
    {
        id: '3',
        texts: [
            {
                language: 'en',
                text: 'Neutral'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'Neutral'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/usr/4303f6d6-a27d-481d-aee6-10ea85808f60_Neutral.png`,
        value: 3,
        label: 'Neutral',
        tags: []
    },
    {
        id: '4',
        texts: [
            {
                language: 'en',
                text: 'Quite easy'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'Quite easy'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/usr/a7d921ee-9089-4141-966a-9cca99fbc5c9_Good.png`,
        value: 4,
        label: 'Quite easy',
        tags: []
    },
    {
        id: '5',
        texts: [
            {
                language: 'en',
                text: 'Very easy'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'Very easy'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/usr/5c75232f-5989-4d2f-ba67-61b94dd6f804_Great.png`,
        value: 5,
        label: 'Very easy',
        tags: []
    }
];

export const emoji_10_ListDefault = [
    {
        id: '1',
        texts: [
            {
                language: 'en',
                text: 'Excited'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'Excited'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/usr/ce1adc62-b752-4463-8f31-52ad29cbbc5a_excited.png`,
        value: 1,
        label: 'Excited',
        tags: []
    },
    {
        id: '2',
        texts: [
            {
                language: 'en',
                text: 'Anxious'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'Anxious'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/usr/dc9a2faf-c60e-412f-82f7-7dd88127a2ce_anxious.png`,
        value: 2,
        label: 'Anxious',
        tags: []
    },
    {
        id: '3',
        texts: [
            {
                language: 'en',
                text: 'Confused'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'Confused'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/usr/a82b897d-2f74-4af4-bcf9-0eb0438d6e18_confused.png`,
        value: 3,
        label: 'Confused',
        tags: []
    },
    {
        id: '4',
        texts: [
            {
                language: 'en',
                text: 'Nothing'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'Nothing'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/usr/225f3544-e52c-49cb-a0b0-f42e2d440099_nothing.png`,
        value: 4,
        label: 'Nothing',
        tags: []
    },
    {
        id: '5',
        texts: [
            {
                language: 'en',
                text: 'Bored'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'Bored'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/usr/834943f8-63ac-4cd5-a0c9-d9966c7959de_bored.png`,
        value: 5,
        label: 'Bored',
        tags: []
    },
    {
        id: '6',
        texts: [
            {
                language: 'en',
                text: 'Curious'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'Curious'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/usr/41397c46-267d-4ec9-9a50-95fca06fba87_curious.png`,
        value: 6,
        label: 'Curious',
        tags: []
    },
    {
        id: '7',
        texts: [
            {
                language: 'en',
                text: 'Happy'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'Happy'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/usr/b6e57661-4adb-4e99-ba4b-288dee10ae03_happy.png`,
        value: 7,
        label: 'Happy',
        tags: []
    },
    {
        id: '8',
        texts: [
            {
                language: 'en',
                text: 'Frustrated'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'Frustrated'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/usr/2dfc01d9-4a01-4353-8343-24cb178c9b22_frustrated.png`,
        value: 8,
        label: 'Frustrated',
        tags: []
    },
    {
        id: '9',
        texts: [
            {
                language: 'en',
                text: 'Mad'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'Mad'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/usr/5563131f-9039-433c-8d2f-038667a03b67_mad.png`,
        value: 9,
        label: 'Mad',
        tags: []
    },
    {
        id: '10',
        texts: [
            {
                language: 'en',
                text: 'Inspired'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'Inspired'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/usr/c4860f68-f8e3-41bf-b71b-1e8ec093fe0f_inspired.png`,
        value: 10,
        label: 'Inspired',
        tags: []
    }
];

export const getBankListDefault = (IS_ENGLISH: boolean) =>
    [
        {
            id: '1',
            texts: [
                {
                    language: 'en',
                    text: IS_ENGLISH ? 'BMO' : '蒙特利尔银行'
                }
            ],
            altTexts: [
                {
                    language: 'en',
                    imgAltText: 'BMO'
                }
            ],
            imgUrl: `${STATIC_STORAGE_URL}/usr/d39e46d2-92b0-4e32-bffb-41f91614c1c3_6346f6ca-9227-4ff2-aed9-b3142537c45f_BMO%20Logo.png`,
            value: 1,
            label: 'Label1',
            tags: []
        },
        {
            id: '7',
            texts: [
                {
                    language: 'en',
                    text: IS_ENGLISH ? 'CIBC' : '帝国商业银行'
                }
            ],
            altTexts: [
                {
                    language: 'en',
                    imgAltText: 'CIBC'
                }
            ],
            imgUrl: 'https://upload.wikimedia.org/wikipedia/en/c/cf/CIBC_logo.svg',
            value: 7,
            label: 'Label2',
            tags: []
        },
        {
            id: '2',
            texts: [
                {
                    language: 'en',
                    text: IS_ENGLISH ? 'Scotiabank' : '丰业银行'
                }
            ],
            altTexts: [
                {
                    language: 'en',
                    imgAltText: 'Scotiabank'
                }
            ],
            imgUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdaetcegrQA-4k2arVu1j7dK5_p7sprSFoUGrCIZz6-YiRSye0Bw',
            value: 2,
            label: 'Label3',
            tags: []
        },
        {
            id: '5',
            texts: [
                {
                    language: 'en',
                    text: IS_ENGLISH ? 'TD' : '道明信托'
                }
            ],
            altTexts: [
                {
                    language: 'en',
                    imgAltText: 'TD'
                }
            ],
            imgUrl: 'https://is5-ssl.mzstatic.com/image/thumb/Purple113/v4/cd/f5/94/cdf594c8-d625-4fba-e18e-2eb9242b5569/AppIcon-0-1x_U007emarketing-0-0-85-220-0-8.png/246x0w.jpg',
            value: 5,
            label: 'Label4',
            tags: []
        },
        {
            id: '3',
            texts: [
                {
                    language: 'en',
                    text: IS_ENGLISH ? 'RBC' : '皇家银行'
                }
            ],
            altTexts: [
                {
                    language: 'en',
                    imgAltText: 'RBC'
                }
            ],
            imgUrl: `${STATIC_STORAGE_URL}/usr/433eb1e8-bd91-4387-982f-125b90fb1231_d680e551-1cb7-42a0-b698-4ed0b84ef295_RBC%20logo%20updated.png`,
            value: 3,
            label: 'Label5',
            tags: []
        },
        {
            id: '6',
            texts: [
                {
                    language: 'en',
                    text: 'Tangerine'
                }
            ],
            altTexts: [
                {
                    language: 'en',
                    imgAltText: 'Tangerine'
                }
            ],
            imgUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfSks6ikOzcNuJQEl3ddyIOKiC6dk9WEjVNlS5ReLmmroRwbZlMA',
            value: 6,
            label: 'Label6',
            tags: []
        }
        // {
        //     id: '4',
        //     texts: [
        //         {
        //             language: 'en',
        //             text: IS_ENGLISH ? 'All Banks' : '所有的加拿大银行'
        //         }
        //     ],
        //     altTexts: [
        //         {
        //             language: 'en',
        //             imgAltText: 'All Banks'
        //         }
        //     ],
        //     imgUrl: `${STATIC_STORAGE_URL}/usr/462c41b1-9a91-4197-9896-7a82f0b71be8_77071cbf-5b8b-4579-a9da-945a56943145_all_banks_v3.png`,
        //     value: 4,
        //     label: 'Label4',
        //     tags: []
        // },
        // {
        //     id: '8',
        //     texts: [
        //         {
        //             language: 'en',
        //             text: IS_ENGLISH ? 'Other/Unsure' : '其它/不确定'
        //         }
        //     ],
        //     altTexts: [
        //         {
        //             language: 'en',
        //             imgAltText: 'Other'
        //         }
        //     ],
        //     imgUrl: `${STATIC_STORAGE_URL}/usr/8808e5f0-06c3-4da5-87e7-a5e135832aa3_2ea8de7f-f2cd-4e75-90d3-545221b3755c_otherbank.png`,
        //     value: 8,
        //     label: 'Label8',
        //     tags: []
        // }
    ] as IPopupOptions[];

export const vehicleBrandList = [
    {
        id: '1',
        texts: [
            {
                language: 'en',
                text: 'Toyota'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'Toyota'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/usr/0eaa0ffe-51af-498d-8897-d3f76d1e257e_toyota.png`,
        value: 1,
        label: 'Toyota',
        tags: []
    },
    {
        id: '2',
        texts: [
            {
                language: 'en',
                text: 'Honda'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'Honda'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/usr/1011c4e8-b102-475d-933d-b6bcc9efdf6c_honda.png`,
        value: 2,
        label: 'Honda',
        tags: []
    },
    {
        id: '3',
        texts: [
            {
                language: 'en',
                text: 'Tesla'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'Tesla'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/usr/61128a02-41e8-4b91-a07c-2a2423dd2c46_tesla.png`,
        value: 3,
        label: 'Tesla',
        tags: []
    },
    {
        id: '4',
        texts: [
            {
                language: 'en',
                text: 'Nissan'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'Nissan'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/usr/204716a9-c257-4fc1-b289-c9f8b555acef_Nissan.png`,
        value: 4,
        label: 'Nissan',
        tags: []
    },
    {
        id: '5',
        texts: [
            {
                language: 'en',
                text: 'Hyundai'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'Hyundai'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/usr/84fcff58-c278-47a7-9f2a-ac823ab3ff39_Hyundai.png`,
        value: 5,
        label: 'Hyundai',
        tags: []
    },
    {
        id: '6',
        texts: [
            {
                language: 'en',
                text: 'Kia'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'Kia'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/usr/b5dd717a-f3ae-4850-911e-9e6e12be04e5_kia.png`,
        value: 6,
        label: 'Kia',
        tags: []
    },
    {
        id: '7',
        texts: [
            {
                language: 'en',
                text: 'Chery'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'Chery'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/usr/980af04f-0823-4d94-93a7-3da892dddaf7_Chevrolet.png`,
        value: 7,
        label: 'Chery',
        tags: []
    },
    {
        id: '8',
        texts: [
            {
                language: 'en',
                text: 'Jeep'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'Jeep'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/usr/c7a1de61-a5e0-4cc1-a8d0-b773c7ce130a_Jeep.png`,
        value: 8,
        label: 'Jeep',
        tags: []
    },
    {
        id: '9',
        texts: [
            {
                language: 'en',
                text: 'Ford'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'Ford'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/usr/6bbd5bf9-4eda-4746-a6b7-20621784bc55_ford.png`,
        value: 9,
        label: 'Ford',
        tags: []
    }
];
