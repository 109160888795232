import * as React from 'react';
import * as PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { OptionData, OPTION_TYPES } from '@nexxt/common/types';
import { getStringFromMultimediaTexts } from '@nexxt/common/services/TextService';
import ReactTooltip from 'react-tooltip';
import { dataTestid } from '../constants/dataTestid';

/* Todo: Separate Folder */
export interface IPopupButtonsProps {
    hasIcon: boolean;
    onClick: () => void;
    buttonText: string;
    buttonSet: Partial<OptionData>;
    value: string;
    selected: boolean;
    disableButtons?: boolean;
    buttonType?: string;
    onKeyDown: (e) => void;
    width?: number;
    size?: string;
    numOptions: number;
}

const LONG_TEXT_LENGTH = 15;

/* Use Flex design */
export const PopupButtons: React.FunctionComponent<IPopupButtonsProps> = (
    props
) => {
    const isLongText = (props.buttonText || '').length > LONG_TEXT_LENGTH;
    const imageUrl = props.buttonSet['mediaURL'] || props.buttonSet.pictureURL;
    let isTextEnabled;
    let isImageEnabled;

    if (props.buttonType) {
        isTextEnabled =
            props.buttonType === OPTION_TYPES.TEXT_ONLY ||
            props.buttonType === OPTION_TYPES.IMAGE_AND_TEXT;
        isImageEnabled =
            props.buttonType === OPTION_TYPES.IMAGE_ONLY ||
            props.buttonType === OPTION_TYPES.IMAGE_AND_TEXT;
    } else {
        isTextEnabled = true;
        isImageEnabled = true;
    }
    const buttonTextStyle = props.buttonSet.color
        ? { color: props.buttonSet.color }
        : {};
    const tooltip =
        props.disableButtons && props.buttonSet.disableTooltip ? (
            <ReactTooltip
                id={`popup-button-${props.buttonSet.id}`}
                aria-haspopup="true"
                effect="solid"
                place="top"
                arrowColor="transparent"
            >
                {props.buttonSet.disableTooltip || ''}
            </ReactTooltip>
        ) : null;
    if (props.hasIcon /* && props.buttonSet.iconURL */) {
        return (
            <>
                {tooltip}
                <div
                    className={`popup-button--container ${
                        props.size ? `--popup-button--${props.size}` : ''
                    } ${props.numOptions <= 5 ? '--with-less-options' : ''}`}
                    style={props.width && { width: `${props.width}%` }}
                >
                    <div
                        className={`popup-button ${
                            props.value ? '' : ' popup '
                        } ${
                            props.selected
                                ? ' popup active '
                                : props.value || props.disableButtons
                                ? ' disabled '
                                : ''
                        }`}
                        onClick={() => !props.disableButtons && props.onClick()}
                        onKeyDown={(e) => {
                            if (e.repeat || props.disableButtons) return;
                            props.onKeyDown(e);
                        }}
                        tabIndex={0}
                        data-tip
                        data-for={`popup-button-${props.buttonSet.id}`}
                        data-tip-disable={false}
                    >
                        <span className="checkmark">
                            <span className="checkmark_stem"></span>
                            <span className="checkmark_kick"></span>
                        </span>
                        {isImageEnabled && (
                            <span className="p-icon">
                                <img src={`${imageUrl}`} className="img" />
                            </span>
                        )}
                        {/* <span className="p-icon"><img src="https://scontent.fktm8-1.fna.fbcdn.net/v/t1.15752-9/60662984_451034098803590_1065550054351175680_n.png?_nc_cat=108&amp;_nc_ht=scontent.fktm8-1.fna&amp;oh=67b6b3e30d508aded6a596d1b18fd925&amp;oe=5D6500EB" alt="" className="img" /></span> */}
                        {isTextEnabled && (
                            <span
                                data-testid={dataTestid.POPUPBTN}
                                className={`p-text ${
                                    isLongText || props.size == 'mobile'
                                        ? 'text-small'
                                        : ''
                                }`}
                                style={buttonTextStyle}
                            >
                                {props.buttonText ? props.buttonText : null}
                            </span>
                        )}
                    </div>
                </div>
            </>
        );
    }
    return (
        <>
            {tooltip}
            <div className="popup-button--container">
                <div
                    className="popup-button"
                    onClick={() => !props.disableButtons && props.onClick()}
                    onKeyDown={(e) => {
                        if (e.repeat || props.disableButtons) return;
                        props.onKeyDown(e);
                    }}
                    tabIndex={0}
                    data-tip="custom show"
                    data-for={`popup-button-${props.buttonSet.id}`}
                >
                    <span className="p-text" style={buttonTextStyle}>
                        {props.buttonText}
                    </span>
                </div>
            </div>
        </>
    );
};

PopupButtons.propTypes = {
    hasIcon: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonSet: PropTypes.any,
    value: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    disableButtons: PropTypes.bool,
    onKeyDown: PropTypes.func.isRequired,
    buttonType: PropTypes.string
};

export interface IMultiPopupButtonsProps {
    langId: string;
    options: OptionData;
    onClick: () => void;
    onKeyDown: (e) => void;
    selected: boolean;
    value: boolean;
}

export const MultiPopupButtons: React.FunctionComponent<IMultiPopupButtonsProps> =
    (props) => {
        const { langId, onClick, onKeyDown, options, selected, value } = props;
        const { texts, pictureURL, label, disabled } = options;
        const translatedText = getStringFromMultimediaTexts(
            texts,
            langId,
            label
        );
        const isLongText = (translatedText || '').length > LONG_TEXT_LENGTH;

        if (pictureURL /* && props.buttonSet.iconURL */) {
            return (
                <div
                    className={`popup-button ${value ? '' : 'popup'} ${
                        selected ? ' popup active ' : value ? ' disabled ' : ''
                    }`}
                    onClick={() => !disabled && onClick()}
                    onKeyDown={(e) => {
                        if (e.repeat || disabled) return;
                        onKeyDown(e);
                    }}
                    tabIndex={0}
                >
                    <span className="checkmark">
                        <span className="checkmark_stem"></span>
                        <span className="checkmark_kick"></span>
                    </span>
                    <span className="p-icon">
                        <img src={`${pictureURL}`} className="img" />
                    </span>
                    {/* <span className="p-icon"><img src="https://scontent.fktm8-1.fna.fbcdn.net/v/t1.15752-9/60662984_451034098803590_1065550054351175680_n.png?_nc_cat=108&amp;_nc_ht=scontent.fktm8-1.fna&amp;oh=67b6b3e30d508aded6a596d1b18fd925&amp;oe=5D6500EB" alt="" className="img" /></span> */}
                    <span
                        className={`p-text ${isLongText ? 'text-small' : ''}`}
                    >
                        {translatedText}
                    </span>
                </div>
            );
        }
        return (
            <div
                className={`popup-button ${value ? '' : 'popup'} ${
                    selected ? ' popup active ' : value ? ' disabled ' : ''
                }`}
                onClick={() => !disabled && onClick()}
                onKeyDown={(e) => {
                    if (e.repeat || disabled) return;
                    onKeyDown(e);
                }}
                tabIndex={0}
            >
                <span className="p-text">{translatedText}</span>
            </div>
        );
    };

MultiPopupButtons.propTypes = {
    langId: PropTypes.string,
    options: PropTypes.any,
    onClick: PropTypes.func,
    onKeyDown: PropTypes.func,
    selected: PropTypes.bool,
    value: PropTypes.bool
};

interface PopupWithTextProps {
    backgroundURL: string;
    text: string;
}

export const PopupWithText: React.FunctionComponent<PopupWithTextProps> = (
    props
) => {
    const style: React.CSSProperties = {};
    if (props.backgroundURL) {
        style.backgroundImage = props.backgroundURL;
    }
    return (
        <div className="text-container" style={style}>
            <div>ProgressBar</div>
            {props.text}
        </div>
    );
};

PopupWithText.propTypes = {
    backgroundURL: PropTypes.string,
    text: PropTypes.string
};

interface PortalButtonContainerProps {
    el: string;
}

export class PortalButtonContainer extends React.Component<
    PortalButtonContainer | any
> {
    el = null;
    constructor(props: any) {
        super(props);
        this.el = document.getElementById(props.el);
        this.el.classList.add('popup-container-wrapper');
        this.el.classList.remove('close');
        // this.el.classList.add('close');
    }

    render() {
        if (this.el) {
            return createPortal(this.props.children, this.el);
        } else {
            return null;
        }
    }
}
