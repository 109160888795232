import * as React from 'react';
import { Image as KImage } from 'react-konva';

interface CanvasImageProps {
    url: string;
    width?: number;
    height?: number;
    maxWidth?: number;
    maxHeight?: number;
    showOriginalSize?: boolean;
    onSelectImage: (
        imageProps: HTMLImageElement,
        originalWidth?: number,
        ratio?: number,
        newWidth?: number,
        newHeight?: number
    ) => void;
    handleSlowLoading?: () => void;
}

interface CanvasImageState {
    image: HTMLImageElement | null;
}

export class CanvasImage extends React.Component<
    CanvasImageProps,
    CanvasImageState
> {
    state = {
        image: null
    };
    handleImage = (image, originalWidth, ratio = 1, width, height) => {
        this.props.onSelectImage(image, originalWidth, ratio, width, height);
    };

    UNSAFE_componentWillMount(): void {
        const image = new Image();
        // CORS needed on prod server to call toDataURL() later
        if (process.env.NODE_ENV === 'production') {
            image.setAttribute('crossOrigin', 'anonymous');
        } else {
            image.crossOrigin = 'Anonymous';
        }
        this.props.handleSlowLoading();

        image.src = this.props.url;
        image.onload = () => {
            const ratio = image.height / image.width;
            let renderRatio = 1;
            const originalImageWidth = image.width;
            if (this.props.height && this.props.width) {
                this.fixImageSize(image, this.props.width, this.props.height);
            } else if (
                this.props.maxWidth &&
                this.props.maxWidth < innerWidth
            ) {
                this.imageSizeConvert(
                    image,
                    this.props.maxWidth,
                    this.props.maxWidth * ratio,
                    ratio
                );
            } else if (!this.props.showOriginalSize) {
                this.imageSizeConvert(
                    image,
                    innerWidth * 0.98,
                    innerWidth * 0.98 * ratio,
                    ratio
                );
            }
            renderRatio = image.width / originalImageWidth;
            // setState will redraw layer
            // because "image" property is changed
            this.setState({
                image: image
            });
            this.handleImage(
                image,
                originalImageWidth,
                renderRatio,
                image.width,
                image.height
            );
        };
    }

    fixImageSize = (image: HTMLImageElement, width: number, height: number) => {
        image.width = width;
        image.height = height;
    };

    imageSizeConvert = (image: HTMLImageElement, width, height, ratio) => {
        if (
            !(
                height >
                (this.props.maxHeight && this.props.maxHeight < innerHeight
                    ? this.props.maxHeight
                    : innerHeight)
            )
        ) {
            image.width = width;
            image.height = height;
        } else {
            this.imageSizeConvert(image, width * 0.98, height * 0.98, ratio);
        }
    };

    render() {
        return <KImage image={this.state.image} preventDefault={false} />;
    }
}
