import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { slideInLeft } from 'react-animations';
import { get } from 'lodash';
import { delay } from '@nexxt/common/utils/index';

import '../style/RapidChoice.scss';
import { BaseProps, BaseInput } from './_BaseInput';
import { delayLoop } from '../utils/questions';
import { MessageLoader } from '../components/MessageLoader';
import { getStringFromMultimediaTexts } from '@nexxt/common/services/TextService';
const { translate } = require('react-i18next');

const RAPID_CHOICE_EXPOSURE_IN_SECONDS = 1;
const RAPID_CHOICE_TRANSITION_IN_SECONDS = 3;
const NUM_PRACTICE_ATTEMPTS = 2;
const instructionalGif =
    'https://nexxt-inca-storage.s3.us-east-2.amazonaws.com/img/rapid_choice_example_en.gif';

const practiceQuestion = (
    t: (text: string, options?: Record<string, number>) => string
) => {
    return {
        image: 'https://nexxt-inca-storage.s3.us-east-2.amazonaws.com/img/training-elephant.jpg',
        options: [
            t('RAPID_CHOICE.OPTIONS.BIG'),
            t('RAPID_CHOICE.OPTIONS.ELEPHANT'),
            t('RAPID_CHOICE.OPTIONS.STRONG'),
            t('RAPID_CHOICE.OPTIONS.TRUNK'),
            t('RAPID_CHOICE.OPTIONS.DOG'),
            t('RAPID_CHOICE.OPTIONS.TINY')
        ]
    };
};

const slideInAnimation = keyframes`${slideInLeft}`;
const SlideInDiv = styled.div`
    animation: 1s ${slideInAnimation};
`;
const CHECK_TEXT = <FontAwesomeIcon icon={faCheck} />;

interface Props extends BaseProps {
    handleAnswer: (o: number[]) => void;
    isEmbedded: boolean;
}

interface State {
    currentStep: number;
    disableButtons: boolean;
    fadeOut: boolean;
    selectedOptions: any;
    practiceQuestionAttempts: number;
    imageStatus: boolean;
}
@translate('translation')
export class RapidChoice extends BaseInput<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            // Set current step to 1 to include the instructional GIF
            currentStep: 2,
            disableButtons: true,
            fadeOut: false,
            selectedOptions: {},
            practiceQuestionAttempts: 1,
            imageStatus: false
        };
    }
    stickyFooter: HTMLDivElement;
    popupWrapper: HTMLDivElement;

    dayone_question_id = ['56199'];

    messageQuestions = [
        '45630',
        '45433',
        '45510',
        '45501',
        '45505',
        '45508',
        '46180',
        '41195'
    ];
    skipQuestions = [
        '45433',
        '45510',
        '45501',
        '45505',
        '45508',
        '46180',
        '41195',
        '46625',
        '41835'
    ];

    slowQuestions = [
        '46616',
        '46625',
        '61021',
        '61020',
        '61019',
        '61018',
        '61013',
        '61012',
        '61011',
        '61010',
        '61008',
        '61007',
        '61006',
        '61005',
        '61002',
        '61001',
        '60999',
        '60998',
        '60995',
        '60994',
        '60993',
        '60992',
        '60991',
        '60990',
        '60989',
        '60988',
        '60987',
        '60811',
        '60810',
        '60809',
        '60808',
        '60807',
        '60806',
        '60805',
        '60804',
        '60802',
        '60801',
        '60800',
        '60799',
        '60798',
        '60797',
        '60796',
        '60795',
        '60794',
        '60793',
        '60792',
        '60791',
        '60790',
        '60789',
        '60788',
        '60787',
        '60786',
        '60785',
        '59633',
        '46616',
        '46625',
        '69577',
        '69515'
    ];
    slowQuestion = this.slowQuestions.includes(String(this.props.question.id));

    // TODO: update use of isVisible to skipPractice after updating stored procedures
    skipPractice =
        this.skipQuestions.includes(String(this.props.question.id)) ||
        this.props.question.isVisible;
    optionDuration = !this.slowQuestion ? RAPID_CHOICE_EXPOSURE_IN_SECONDS : 2;

    handleTranslation = (path: string): string => {
        const overrideValue = get(
            this.props.question.systemTextsOverride.mappingByLangId?.[
                this.props.langId
            ],
            path
        );
        return overrideValue ?? this.props.t(path);
    };

    getRapidChoiceWordings = (string, id) => {
        let wordings;

        switch (String(id)) {
            // displays message instead of logo text
            case '45630':
            case '45433':
            case '45510':
            case '45501':
            case '45505':
            case '45508':
            case '46180':
            case '41195':
                wordings = {
                    'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD7':
                        this.handleTranslation(
                            'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD7M'
                        ),
                    'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD8':
                        this.handleTranslation(
                            'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD8'
                        ),
                    'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD9':
                        this.handleTranslation(
                            'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD9M'
                        )
                };
                break;
            // custom text for dayone project
            case '56199':
                wordings = {
                    'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD7':
                        this.handleTranslation(
                            'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_DAYONE7'
                        ),
                    'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD8':
                        this.handleTranslation(
                            'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_DAYONE8'
                        ),
                    'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD9': ''
                };
                break;
            // custom text for hummingbird project
            case '56877':
                wordings = {
                    'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD7':
                        this.handleTranslation(
                            'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD7HB'
                        ),
                    'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD8':
                        this.handleTranslation(
                            'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD8HB'
                        ),
                    'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD9':
                        this.handleTranslation(
                            'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD9HB'
                        )
                };
                break;
            default:
                wordings = {
                    'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD7':
                        this.handleTranslation(
                            'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD7'
                        ),
                    'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD8':
                        this.handleTranslation(
                            'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD8'
                        ),
                    'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD9':
                        this.handleTranslation(
                            'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD9'
                        )
                };
                break;
        }
        return wordings[string];
    };

    // Taken from PopupCard
    async componentDidMount() {
        if (this.skipPractice) {
            this.setState({ currentStep: 4 });
        }
        // reserve space at bottom for sticky footer
        const STICKY_FOOTER_HEIGHT = this.stickyFooter.clientHeight + 20;
        !this.props.isEmbedded &&
            this.props.setBottomOffset(STICKY_FOOTER_HEIGHT);

        // wait one second for animation to load
        // before calling scrollToBottom
        this.updateDisabled();
        await delay(1000);
        this.props.scrollToBottom();
    }
    updateDisabled = () => {
        setTimeout(() => {
            this.setState({ disableButtons: false });
        }, 1000);
    };

    practiceText = () => (
        <>
            <div className="header-warning">
                {this.handleTranslation(
                    'LABEL.INSTRUCTIONS.RAPID_CHOICE.PRACTICE'
                )}
            </div>
            <div className="header-warning sub-head">
                {this.handleTranslation(
                    'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD1'
                )}{' '}
                <span className="underlined">
                    {this.handleTranslation(
                        'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD2'
                    )}
                </span>{' '}
                {this.handleTranslation(
                    'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD3'
                )}{' '}
                <span className="underlined">
                    {this.handleTranslation(
                        'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD4'
                    )}
                </span>{' '}
                {this.handleTranslation(
                    'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD5'
                )}
            </div>
        </>
    );
    realText = () => (
        <>
            {!this.skipPractice && (
                <div className="header-warning">
                    {this.handleTranslation(
                        'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD6'
                    )}
                </div>
            )}
            <div className="header-warning sub-head">
                {this.getRapidChoiceWordings(
                    'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD7',
                    this.props.question.id
                )}{' '}
                <span className="red-text">
                    {this.getRapidChoiceWordings(
                        'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD8',
                        this.props.question.id
                    )}{' '}
                </span>{' '}
                {this.getRapidChoiceWordings(
                    'LABEL.INSTRUCTIONS.RAPID_CHOICE.SUB_HEAD9',
                    this.props.question.id
                )}
            </div>
        </>
    );

    handleSteps = () => {
        const { currentStep } = this.state;
        const options = this.props.question.options.map((opt) => {
            return getStringFromMultimediaTexts(
                opt.texts,
                this.props.langId,
                opt.label
            );
        });

        const AnimationClass = this.state.fadeOut ? 'fadeOut' : '';
        switch (currentStep) {
            case 1:
                //Step 1: Instructional GIF
                return (
                    <>
                        <div className="header-warning">
                            {this.handleTranslation(
                                'LABEL.INSTRUCTIONS.RAPID_CHOICE.FOLLOW'
                            )}
                        </div>
                        <div className="image-wrapper">
                            {this.onHandleImage(
                                instructionalGif,
                                'instructional Gif'
                            )}
                        </div>
                        <div className="rapid-choice-option-container">
                            <div
                                className="rapid-choice-option-wrapper"
                                ref={(e) => (this.stickyFooter = e)}
                            >
                                {this.state.imageStatus ? (
                                    <div
                                        className="rapid-choice-option"
                                        onClick={() => this.onHandleNextStep()}
                                    >
                                        {this.handleTranslation(
                                            'LABEL.INSTRUCTIONS.RAPID_CHOICE.START'
                                        )}
                                    </div>
                                ) : (
                                    <MessageLoader />
                                )}
                            </div>
                        </div>
                    </>
                );

            case 2:
                // Step 2: Get respondant ready for practice question
                return (
                    <>
                        {this.practiceText()}
                        <div className="rapid-choice-option-container">
                            <div
                                className="rapid-choice-option-wrapper"
                                ref={(e) => (this.stickyFooter = e)}
                            >
                                <div
                                    className="rapid-choice-option"
                                    onClick={() => this.onHandleNextStep()}
                                >
                                    {this.handleTranslation(
                                        'LABEL.INSTRUCTIONS.RAPID_CHOICE.START'
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                );
            case 3:
                // Step 3: Practise Question (Will loop if user chooses Dog or Tiny || if user hasnt taken the maximum number of practice attempts)
                return this.onDisplayQuestion(options, AnimationClass);
            case 4:
                // Step 4: Confirmation to move to real question
                return (
                    <>
                        {this.realText()}
                        <div className="rapid-choice-option-container">
                            <div
                                className="rapid-choice-option-wrapper flx"
                                ref={(e) => (this.stickyFooter = e)}
                            >
                                {!this.skipPractice && (
                                    <div
                                        className="rapid-choice-option mar-b"
                                        onClick={() =>
                                            this.onRetryPracticeQuestion()
                                        }
                                    >
                                        {this.handleTranslation(
                                            'LABEL.INSTRUCTIONS.RAPID_CHOICE.RETRY'
                                        )}
                                    </div>
                                )}
                                <div
                                    className="rapid-choice-option"
                                    onClick={() => this.onHandleNextStep()}
                                >
                                    {this.handleTranslation(
                                        'LABEL.INSTRUCTIONS.RAPID_CHOICE.GO'
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                );
            default:
                // Step 5: Real Question
                return this.onDisplayQuestion(options, AnimationClass);
        }
    };

    onDisplayQuestion = (options: string[], AnimationClass: string) => {
        const isPracticeQuestion = this.state.currentStep === 3;
        return (
            <>
                {isPracticeQuestion ? this.practiceText() : this.realText()}
                <SlideInDiv
                    className={`image-wrapper ${AnimationClass}`}
                    key={this.props.question.value}
                >
                    {this.onHandleImage(
                        isPracticeQuestion
                            ? practiceQuestion(this.handleTranslation).image
                            : this.props.question.mediaURL,
                        isPracticeQuestion ? 'Elephant' : 'Client Picture'
                    )}
                </SlideInDiv>
                <div className="rapid-choice-option-container">
                    <div
                        className="rapid-choice-option-wrapper"
                        ref={(e) => (this.stickyFooter = e)}
                    >
                        {this.state.imageStatus ? (
                            isPracticeQuestion ? (
                                <HandleOptions
                                    options={
                                        practiceQuestion(this.handleTranslation)
                                            .options
                                    }
                                    onSelectOptions={this.onCheckPracticeAnswer}
                                    practiceQuestionAttempts={
                                        this.state.practiceQuestionAttempts
                                    }
                                    optionDuration={this.optionDuration}
                                    t={this.handleTranslation}
                                />
                            ) : (
                                <HandleOptions
                                    options={options}
                                    onSelectOptions={this.onSelectOptions}
                                    optionDuration={this.optionDuration}
                                    t={this.handleTranslation}
                                />
                            )
                        ) : (
                            <MessageLoader />
                        )}
                    </div>
                </div>
            </>
        );
    };
    /**
     * Submits an array of chosen option ids to props.handleAnswer
     */
    onSelectOptions = (options): void => {
        const optionIDs = [];
        this.props.question.options.forEach((opt, index) => {
            if (options[index]) {
                optionIDs.push(opt.id);
            }
        });
        this.props.handleAnswer(optionIDs);
    };

    /**
     * Checks if user chooses the correct answer NOT Dog && NOT Tiny || if user took the
     * maximum number of practice attempts
     *      -> true, will proceed to real question
     *      -> false, will loop until they choose correctly
     */
    onCheckPracticeAnswer = (options): void => {
        const keys = Object.keys(options);
        if (
            (!keys.includes('4') && !keys.includes('5') && keys.length > 0) ||
            this.state.practiceQuestionAttempts === NUM_PRACTICE_ATTEMPTS
        ) {
            this.onHandleNextStep();
        } else {
            this.setState(
                {
                    practiceQuestionAttempts:
                        this.state.practiceQuestionAttempts + 1
                },
                () => {
                    this.props.scrollToBottom();
                }
            );
        }
    };

    onHandleNextStep = (): void => {
        this.setState(
            {
                currentStep: this.state.currentStep + 1,
                imageStatus: false
            },
            () => {
                this.props.scrollToBottom();
            }
        );
    };

    onRetryPracticeQuestion = (): void => {
        this.setState(
            {
                currentStep: 2,
                imageStatus: false,
                practiceQuestionAttempts: 1
            },
            () => {
                this.props.scrollToBottom();
            }
        );
    };

    onHandleImage = (URL: string, alt: string): JSX.Element => {
        return (
            <div
                className="image-contents"
                style={this.state.imageStatus ? {} : { display: 'none' }}
            >
                <img
                    src={URL}
                    alt={alt}
                    onLoad={() => {
                        this.setState(
                            {
                                imageStatus: true
                            },
                            () => {
                                // When image loads scroll to bottom
                                this.props.scrollToBottom();
                            }
                        );
                    }}
                />
            </div>
        );
    };
    render() {
        return (
            <div
                className="content from-bot bot-width"
                ref={(e) => (this.popupWrapper = e)}
            >
                <div className="plain-text rapidchoice-wrapper">
                    {this.handleSteps()}
                </div>
            </div>
        );
    }
}

interface HandleOptionsProps {
    options: string[];
    practiceQuestionAttempts?: number;
    onSelectOptions: (options: any) => void;
    t?: (text: string, options?: Record<string, number>) => string;
    optionDuration?: number;
}

const HandleOptions = ({
    options,
    practiceQuestionAttempts,
    onSelectOptions,
    t,
    optionDuration = RAPID_CHOICE_EXPOSURE_IN_SECONDS
}: HandleOptionsProps) => {
    const [currentOption, setCurrentOption] = React.useState(-1);
    const [selectedOptions, setSelectedOptions] = React.useState({});
    const [showMsg, setShowMsg] = React.useState(true);
    const [msg, setMsg] = React.useState(
        t('LABEL.INSTRUCTIONS.RAPID_CHOICE.MSG1')
    );
    const [isDone, setIsDone] = React.useState(false);
    const [isRetry, setIsRetry] = React.useState(false);
    const ref = React.useRef(true);

    // Will be called if practiceQuestionAttempts updates in rapidchoice component
    React.useEffect(() => {
        if (ref.current) {
            ref.current = false;
            options.forEach(
                delayLoop(display, RAPID_CHOICE_TRANSITION_IN_SECONDS * 1000)
            );
        } else {
            setMsg(t('LABEL.INSTRUCTIONS.RAPID_CHOICE.MSG_ERROR'));
            setIsRetry(true);
        }
    }, [practiceQuestionAttempts]);

    const onRetry = (options) => {
        setMsg(t('LABEL.INSTRUCTIONS.RAPID_CHOICE.MSG1'));
        setIsRetry(false);
        options.forEach(
            delayLoop(display, RAPID_CHOICE_TRANSITION_IN_SECONDS * 1000)
        );
    };

    /**
     * After 3 seconds display() will be called, the next option btn will be rendered.
     * After 1.7s, option btn will disappear and if i !== options.length - 1 then the next
     * msg will be rendered
     **/
    const display = (s, i) => {
        setShowMsg(false);
        setMsg(t('LABEL.INSTRUCTIONS.RAPID_CHOICE.MSG2'));
        setCurrentOption(i);
        setTimeout(() => {
            i === options.length - 1 ? setIsDone(true) : setShowMsg(true);
        }, optionDuration * 1000);
    };

    const selectOption = () => {
        setSelectedOptions({
            ...selectedOptions,
            [currentOption]: true
        });
    };
    const clearState = () => {
        setSelectedOptions({});
        setIsDone(false);
        setShowMsg(true);
        setCurrentOption(-1);
        setIsRetry(false);
    };

    if (isDone) {
        onSelectOptions(selectedOptions);
        clearState();
        return <div>{t('LABEL.INSTRUCTIONS.RAPID_CHOICE.DONE')}</div>;
    } else {
        return showMsg ? (
            isRetry ? (
                <>
                    <div className="header-warning">{msg}</div>
                    <div
                        className="rapid-choice-option"
                        style={{
                            width: '20%',
                            margin: 'auto'
                        }}
                        onClick={() => {
                            onRetry(options);
                        }}
                    >
                        {t('LABEL.INSTRUCTIONS.RAPID_CHOICE.TRY_AGAIN')}
                    </div>
                </>
            ) : (
                <>{msg}</>
            )
        ) : (
            <>
                {selectedOptions[currentOption] && (
                    <div className="selected-option">{CHECK_TEXT}</div>
                )}
                <div
                    className={`rapid-choice-option ${
                        selectedOptions[currentOption] && 'selected'
                    }`}
                    key={options[currentOption]}
                    onClick={selectOption}
                >
                    {options[currentOption]}
                </div>
            </>
        );
    }
};
