import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import '../style/LightBoxImage.scss';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

interface ImageExpandButtonProps {
    openLightBox: () => void;
}
export const ImageExpandButton = (props: ImageExpandButtonProps) => {
    return (
        <button
            className="image-expand--button"
            onClick={() => props.openLightBox()}
        >
            <FontAwesomeIcon icon={faExpand} />
        </button>
    );
};

interface ImageLightBoxProps {
    imageURL: string;
    closeLightBox: () => void;
}

export const ReactLightBox = (props: ImageLightBoxProps) => {
    return (
        <Lightbox
            mainSrc={props.imageURL}
            onCloseRequest={() => props.closeLightBox()}
        />
    );
};
