import { QTYPES } from '@nexxt/common/types';
import * as React from 'react';
import Modal from '../../../components/Modal';
import { UilTimes } from '@iconscout/react-unicons';
import { FileIcon, VideoIcon } from '../../../components/FileIcon';

interface JourneyModalProps {
    openModal: boolean;
    followUpAnswers: any[];
    followUpSettings: any[];
    toggleModal: (toggle: boolean) => void;
}

const IMAGE_EXTS = ['png', 'jpg', 'jpeg', 'gif'];
const DOC_EXTS = ['pdf', 'doc', 'psd'];
const VIDEO_EXTS = ['mp4', 'mov', 'flv', 'webm'];
const AUDIO_EXTS = ['mp3', 'wav'];

const JourneyModal: React.FC<JourneyModalProps> = (
    props: JourneyModalProps
) => {
    const renderBody = () => {
        return props.followUpAnswers.map((ans, index) => {
            const currentSetting = props.followUpSettings?.[index];
            return (
                <div className="journey--answers--container" key={index}>
                    <div className="journey--title">
                        Q: {currentSetting?.texts}
                    </div>
                    {currentSetting?.type === QTYPES.OPEN ? (
                        <div className="journey--answers text">
                            <p>{ans.value}</p>
                            {ans?.url &&
                                ans?.url.map((o) => {
                                    const fileExtension = o.thumb.substring(
                                        o.thumb.lastIndexOf('.') + 1
                                    );
                                    if (IMAGE_EXTS.includes(fileExtension)) {
                                        return (
                                            <div className="media-image">
                                                <img src={o.thumb} />
                                            </div>
                                        );
                                    } else if (
                                        DOC_EXTS.includes(fileExtension)
                                    ) {
                                        return (
                                            <div className="media-file">
                                                <FileIcon
                                                    type={fileExtension}
                                                />
                                            </div>
                                        );
                                    } else if (
                                        VIDEO_EXTS.includes(fileExtension)
                                    ) {
                                        return (
                                            <div className="media-video">
                                                <VideoIcon />
                                            </div>
                                        );
                                    } else if (
                                        AUDIO_EXTS.includes(fileExtension)
                                    ) {
                                        return (
                                            <div className="media-audio">
                                                <VideoIcon />
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                        </div>
                    ) : (
                        <div className="journey--answers">
                            {ans.map((a) => (
                                <div className="journey--answer" key={a.id}>
                                    <img src={a.imgUrl} />
                                    <span>{a.label}</span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            );
        });
    };
    return (
        <Modal>
            <div className="modal--blur"></div>
            <div className="journey--modal">
                <div
                    className="close--button"
                    onClick={() => props.toggleModal(false)}
                >
                    <UilTimes size={20} />
                </div>
                <div className="journey--modal--wrapper">{renderBody()}</div>
            </div>
        </Modal>
    );
};

export default JourneyModal;
