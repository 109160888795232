import { IMAGE_OPTION_SIZING, OptionData, Question } from '@nexxt/common/types';

export const PERFECT = 0;

export function pickStatus(numPicks: number, min: number, max: number): number {
    if (numPicks < min) {
        return min - numPicks;
    } else if (numPicks > max) {
        return max - numPicks;
    } else if (numPicks === max) {
        return 100;
    } else {
        return PERFECT;
    }
}

export function addOtherToOptions(question: Question): OptionData[] {
    try {
        let options = question.options || [];
        if (question?.optionSettings?.showOther) {
            options = options.concat(question?.optionSettings?.otherOption);
        }
        return options;
    } catch (err) {
        return question.options;
    }
}
/**
 * Loop based timer, used with Array based loops ie; forEach Map ect.
 * x = Item passed in loop
 * i = Index of item in loop
 * @param fn Function that would be called after delay
 * @param delay Time(milliseconds) delayed before fn called
 */

export const delayLoop = (fn, delay) => {
    return (x, i) => {
        setTimeout(() => {
            fn(x, i);
        }, (i + 1) * delay);
    };
};

/**
 * Used when there is a custom option size and custom options per row selected (IMAGE BASED QUESTIONS).
 * This will return a a new max-width.
 * Choice(Single/Multiple), Rank Order
 * @param numColumns Number of Options per row
 * @param optSizeKey Custom option size [SM,MD,LG,XL]
 * @param additionalStyles Padding, border ect any additional styles that could impact the result
 */
export const calculateNewMaxWidth = (
    numColumns: number,
    optSizeKey: string,
    additionalStyles?: number
) => {
    const maxNumOfColumns = {
        SM: 13,
        MD: 6,
        LG: 3,
        XL: 2,
        FULL: 1
    };
    const optionSize = IMAGE_OPTION_SIZING[optSizeKey];
    return maxNumOfColumns[optSizeKey] >= numColumns
        ? numColumns * optionSize + additionalStyles
        : maxNumOfColumns[optSizeKey] * optionSize + additionalStyles;
};
