export interface ITranslateCore {
    translateX?: any;
    translateY?: any;
}

import { Color } from 'csstype';

export interface JourneyMidpointData {
    type: JOURNEY_ELEMENT_TYPES.MIDPOINT;
    imgUrl: string;
    text: string;
}

export type IJourneyBank = IPopupOptions;

export type IJourneySmiley = IPopupOptions;

export interface IPopupOptions {
    id: string;
    texts: IPopupOptionsTexts[];
    altTexts: IPopupOptionsAltTexts[];
    imgUrl?: string;
    value: any; // mainly number
    label: string;
    tags: any[];
}

export interface IPopupOptionsTexts {
    language: string; // language Id
    text: string;
}
export interface IPopupOptionsAltTexts {
    language: string; // language Id
    imgAltText: string;
}

export enum JOURNEY_ELEMENT_TYPES {
    OPTION = 'in.nexxt/journey/etype/option',
    STARTPOINT = 'in.nexxt/journey/etype/startpoint',
    MIDPOINT = 'in.nexxt/journey/etype/midpoint',
    ENDPOINT = 'in.nexxt/journey/etype/endpoint',
    DROPSPOT = 'in.nexxt/journey/etype/dropspot'
}
export interface JourneyOptionData {
    id: string;
    type: JOURNEY_ELEMENT_TYPES.OPTION;
    isSelected: boolean;
    color: Color;
    text: string;
    listType: string; // "Actively Looking for" | "Happened To";
    bank: IJourneyBank[]; // image, text, id
    textResponse: string; // user's response to text q
    smiley: IJourneySmiley | null; // image, text, id
}

export interface IJourneyBuilderAdd {
    type: JOURNEY_ELEMENT_TYPES.DROPSPOT;
}

export type JourneyElementData =
    | JourneyOptionData
    | JourneyMidpointData
    | IJourneyBuilderAdd;
