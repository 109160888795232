export function dataURLtoBlob(dataURL: string): Blob {
    const arrURL = dataURL.split(','),
        mime = arrURL[0].match(/:(.*?);/)[1],
        bstr = atob(arrURL[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}

/*
 * Takes in a ref to svg element, and returns a dataURL
 * of the svg's current state.
 * Adapted from https://stackoverflow.com/a/57473981
 */
export const svgToDataURL = (svgElement): string => {
    const svgString = new XMLSerializer().serializeToString(svgElement);
    const decoded = unescape(encodeURIComponent(svgString));
    const base64 = btoa(decoded);
    const dataUrl = `data:image/svg+xml;base64,${base64}`;
    return dataUrl;
};
