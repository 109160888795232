import * as React from 'react';
import {
    InputWithOptionImages,
    ImageLoadState,
    PropsWithOptionQ
} from './_InputWithOptionImages';
import 'purecss/build/grids.css';
import '../style/MultiOptions.scss';
import * as cx from 'classnames';
import NotApplicableButton from '../components/NotApplicableButton';
import { getStringFromMultimediaTexts } from '@nexxt/common/services/TextService';
import {
    KEY_NAME,
    NA_OPTION_VALUE,
    OTHER_OPTION_VALUE,
    RESUME_OVALUE
} from '@nexxt/common/constants';
import { getAutoOptionsPerRow } from '@nexxt/common/utils/question';
import { isBrowser } from 'react-device-detect';
import {
    IMAGE_OPTION_SIZING,
    OPTION_TYPES,
    QTYPES,
    SingleOptionAnswer
} from '@nexxt/common/types';
import { addOtherToOptions, calculateNewMaxWidth } from '../utils/questions';
import SpecifyEditor from '../components/SpecifyEditor';
import ContinueButton from '../components/ContinueButton';
import { isMobile } from 'react-device-detect';
import OptionText from '../components/OptionText';

const { translate } = require('react-i18next');

interface Props extends PropsWithOptionQ {
    handleAnswer: (selectedOptionValue: string, specify?: string) => void;
}

interface State extends ImageLoadState {
    showSpecify: boolean;
    specifyText: string;
    submitting: boolean;
    showError: boolean;
}
@translate('translation')
export class SingleChoice extends InputWithOptionImages<Props, State> {
    state: State = {
        showSpecify: false,
        specifyText: '',
        submitting: false,
        imageLoadCount: 0,
        showError: false
    };

    componentDidMount(): void {
        if (this.props.previousAnswer) {
            const selectedAnswer = (
                this.props.previousAnswer as SingleOptionAnswer
            ).option;

            if (
                selectedAnswer.value === OTHER_OPTION_VALUE &&
                selectedAnswer.specify
            ) {
                this.setState({
                    showSpecify: true,
                    showError: true,
                    specifyText: selectedAnswer.specify
                });
            }
        }
    }

    handleSubmitAnswer = (value: string, other?: string) => {
        if (
            value === OTHER_OPTION_VALUE &&
            this.state.showSpecify &&
            !this.state.specifyText.length
        ) {
            //show warning
            this.setState({ showError: true });
            return;
        }
        if (this.state.submitting) return;
        this.setState({ submitting: true });

        if (
            value === OTHER_OPTION_VALUE &&
            this.props.question.optionSettings?.showSpecify &&
            !other
        ) {
            this.setState({ showSpecify: true, submitting: false });
            setTimeout(() => {
                this.props.scrollToBottom();
            }, 1000);
            return;
        }
        this.props.handleAnswer(value, other);
        this.setState({ submitting: false });
    };

    render() {
        const { langId, question, t } = this.props;
        const { showSpecify } = this.state;
        const {
            showNotApplicableOption,
            notApplicableTexts,
            triangleLayout,
            imageDisplay,
            selectedOptionType,
            optionSettings
        } = question;

        const notApplicableText = getStringFromMultimediaTexts(
            notApplicableTexts,
            langId
        );
        let options = addOtherToOptions(question);
        options = options.map((option) => {
            return {
                ...option,
                text: getStringFromMultimediaTexts(
                    option.texts,
                    langId,
                    option.label
                )
            };
        });

        const isCircle = imageDisplay === 'circle' ? true : false;
        /** @PROJECT Forcome */
        const big = [2709, 2710, 5524, 5646, 5670, 5733, 18537, 19166].includes(
            question.id as number
        );

        if (!question) {
            return <div>Loading...</div>;
        } else {
            /** @PROJECT Forcome */
            let columns;
            let columnsFromOptionSettings;
            if (
                isBrowser &&
                [5524, 5646, 5670, 5733].includes(question.id as number)
            ) {
                columns = 3;
            } else {
                const isAnyMobile =
                    isMobile ||
                    window.innerWidth < 700 ||
                    document.getElementById('bot-preview')?.scrollWidth < 700;

                columnsFromOptionSettings =
                    isAnyMobile || window.innerWidth < 700
                        ? optionSettings?.numColumnsMobile
                        : optionSettings?.numColumns;

                columns =
                    columnsFromOptionSettings >= 1
                        ? columnsFromOptionSettings
                        : getAutoOptionsPerRow(
                              optionSettings?.size,
                              selectedOptionType,
                              isAnyMobile || window.innerWidth < 700
                          );
            }

            // check if options are text-only
            const textOnly =
                selectedOptionType === OPTION_TYPES.TEXT_ONLY ||
                question.type === QTYPES.LANGUAGE;
            const hideText = selectedOptionType === OPTION_TYPES.IMAGE_ONLY;

            // Checks if Resume/Restart screen is shown
            const isResumeQuestion = question.type === QTYPES.RESUME;
            // Check if custom size is selected
            const isCustomSizing = IMAGE_OPTION_SIZING[
                question.optionSettings?.size
            ]
                ? isResumeQuestion
                    ? false
                    : true
                : false;
            // Checks if custom option size & custom number of columns
            const isFullCustomSizing =
                isCustomSizing && columnsFromOptionSettings >= 1;
            return (
                <div className={triangleLayout ? 'triangle' : ''}>
                    <ul
                        className={cx(`multi-options-container`, {
                            [`pure-columns-${columns}`]:
                                !textOnly && !isCustomSizing,
                            'two-columns': columns === 2,
                            'multiple-options': !triangleLayout,
                            big: big,
                            textOnly,
                            'custom-size': isCustomSizing
                        })}
                        style={
                            isFullCustomSizing
                                ? {
                                      maxWidth: calculateNewMaxWidth(
                                          columnsFromOptionSettings,
                                          question.optionSettings?.size,
                                          // Border-> 4px, Padding outer options -> 4px
                                          8
                                      ),
                                      alignItems: !textOnly ? 'center' : 'unset'
                                  }
                                : { alignItems: !textOnly ? 'center' : 'unset' }
                        }
                    >
                        {triangleLayout ? (
                            <React.Fragment>
                                <span>
                                    <li
                                        onClick={() =>
                                            this.handleSubmitAnswer(
                                                options[0].value
                                            )
                                        }
                                        className={cx(`multi-options`, {
                                            textOnly
                                        })}
                                    >
                                        {options[0].pictureURL && (
                                            <img
                                                src={options[0].pictureURL}
                                                alt={getStringFromMultimediaTexts(
                                                    options[0].altTexts,
                                                    langId
                                                )}
                                            />
                                        )}
                                        {options[0].texts && (
                                            <p>
                                                {getStringFromMultimediaTexts(
                                                    options[0].texts,
                                                    langId
                                                )}
                                            </p>
                                        )}
                                    </li>
                                </span>
                                <span>
                                    <li
                                        onClick={() =>
                                            this.handleSubmitAnswer(
                                                options[1].value
                                            )
                                        }
                                        className={cx(`multi-options`, {
                                            textOnly
                                        })}
                                    >
                                        {options[1].pictureURL && (
                                            <img
                                                src={options[1].pictureURL}
                                                alt={getStringFromMultimediaTexts(
                                                    options[1].altTexts,
                                                    langId
                                                )}
                                            />
                                        )}
                                        {options[1].texts && (
                                            <p>
                                                {getStringFromMultimediaTexts(
                                                    options[1].texts,
                                                    langId
                                                )}
                                            </p>
                                        )}
                                    </li>
                                    <li
                                        onClick={() =>
                                            this.handleSubmitAnswer(
                                                options[2].value
                                            )
                                        }
                                        className={cx(`multi-options`, {
                                            textOnly
                                        })}
                                    >
                                        {options[2].pictureURL && (
                                            <img
                                                src={options[2].pictureURL}
                                                alt={getStringFromMultimediaTexts(
                                                    options[2].altTexts,
                                                    langId
                                                )}
                                            />
                                        )}
                                        {options[2].texts && (
                                            <p>
                                                {getStringFromMultimediaTexts(
                                                    options[2].texts,
                                                    langId
                                                )}
                                            </p>
                                        )}
                                    </li>
                                </span>
                            </React.Fragment>
                        ) : (
                            <>
                                {options.map((option) => {
                                    const optionDisabled =
                                        option.disabled || false;
                                    return (
                                        <li
                                            key={option.value}
                                            onClick={() =>
                                                !optionDisabled &&
                                                this.handleSubmitAnswer(
                                                    option.value
                                                )
                                            }
                                            onKeyDown={(e) => {
                                                if (
                                                    e.key === KEY_NAME.SPACE ||
                                                    e.key === KEY_NAME.ENTER
                                                ) {
                                                    !optionDisabled &&
                                                        this.handleSubmitAnswer(
                                                            option.value
                                                        );
                                                }
                                            }}
                                            className={cx(
                                                `multiple-option single-option ${
                                                    isCustomSizing
                                                        ? ` custom-sizing custom-sizing--${question.optionSettings?.size}`
                                                        : `pure-u-1-${columns}`
                                                }`,
                                                {
                                                    'text-image': !textOnly,
                                                    textOnly,
                                                    disabled: optionDisabled,
                                                    selected:
                                                        this.state
                                                            .showSpecify &&
                                                        option.value ===
                                                            OTHER_OPTION_VALUE
                                                }
                                            )}
                                            tabIndex={0}
                                            role={'button'}
                                        >
                                            <div className={'option'}>
                                                {!textOnly && (
                                                    <span
                                                        className={cx(
                                                            'option-img',
                                                            {
                                                                circle: isCircle
                                                            }
                                                        )}
                                                    >
                                                        <div className="img-container">
                                                            <img
                                                                src={
                                                                    option.pictureURL
                                                                }
                                                                alt={getStringFromMultimediaTexts(
                                                                    option.altTexts,
                                                                    langId,
                                                                    option.label
                                                                )}
                                                            />
                                                        </div>
                                                    </span>
                                                )}
                                                {
                                                    // Custom single choice input for TDA
                                                    question.id == 975 ||
                                                    question.id == 976 ? (
                                                        hideText ? (
                                                            ''
                                                        ) : (
                                                            option.texts && (
                                                                <p
                                                                    className={cx(
                                                                        'option-text tda'
                                                                    )}
                                                                >
                                                                    {getStringFromMultimediaTexts(
                                                                        option.texts,
                                                                        langId,
                                                                        option.label
                                                                    )}
                                                                </p>
                                                            )
                                                        )
                                                    ) : hideText ? (
                                                        ''
                                                    ) : option.texts ? (
                                                        <OptionText
                                                            isRichText={
                                                                question.isRichText
                                                            }
                                                            className={cx(
                                                                'option-text'
                                                            )}
                                                            option={option}
                                                            langId={langId}
                                                        />
                                                    ) : (
                                                        <p className="option-text">
                                                            {t(
                                                                'PLACEHOLDER.PLACEHOLDER_TEXT'
                                                            )}
                                                        </p>
                                                    )
                                                }
                                            </div>
                                        </li>
                                    );
                                })}
                            </>
                        )}
                    </ul>
                    {showSpecify ? (
                        <SpecifyEditor
                            onSubmit={() =>
                                this.handleSubmitAnswer(
                                    OTHER_OPTION_VALUE,
                                    this.state.specifyText
                                )
                            }
                            showError={this.state.showError}
                            specifyText={this.state.specifyText}
                            handleChangeSpecifyText={(specifyText) =>
                                this.setState({ specifyText })
                            }
                            t={this.props.t}
                        />
                    ) : null}
                    <div className="bottom-select-links bot-width">
                        {showNotApplicableOption && (
                            <NotApplicableButton
                                onClick={() =>
                                    this.props.handleAnswer(NA_OPTION_VALUE)
                                }
                                text={notApplicableText}
                            />
                        )}
                        {showSpecify && (
                            <ContinueButton
                                onClick={() => {
                                    this.handleSubmitAnswer(
                                        OTHER_OPTION_VALUE,
                                        this.state.specifyText
                                    );
                                }}
                                disabled={!this.state.specifyText}
                                text={this.props.t('BUTTON.CONTINUE')}
                            />
                        )}
                    </div>
                </div>
            );
        }
    }
}
