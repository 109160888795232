import * as React from 'react';
import { DropTargetMonitor } from 'react-dnd';
import * as PropTypes from 'prop-types';
import JourneyElement from './JourneyElement';
import JourneyElementDropspot, {
    JourneyElementDropspotProps
} from './JourneyElementDropspot';
import { ELEMENTS_PER_ROW } from '../constant';
import { JOURNEY_ELEMENT_TYPES, JOURNEY_STEPS } from '../types';

const leftToRight = (
    y: any,
    isFirstRow: boolean,
    index: number,
    firstRowOffset: number,
    extraX: number
) => {
    const offsetX = isFirstRow ? firstRowOffset : 0;
    const coordinates: any = {
        0: {
            x: 75 + offsetX + extraX,
            y,
            add: { x: 150 + offsetX, y }
        },
        1: {
            x: 225 + offsetX + extraX,
            y,
            add: { x: 300 + offsetX, y }
        },
        2: {
            x: 375 + offsetX + extraX,
            y,
            add: { x: 450 + offsetX, y }
        },
        3: {
            x: 525 + offsetX + extraX,
            y,
            add: { x: 600 + offsetX, y }
        },
        4: {
            x: 675 + offsetX + extraX,
            y,
            add: { x: 775 + offsetX, y: +y + 70 } // 920 70
        },
        5: {
            x: 675 + offsetX,
            y: y + 140,
            add: { x: 600 + offsetX, y: +y + 70 + 55 }
        }
    };
    if (isFirstRow) return coordinates[index];
    return coordinates[index + 1];
};

/* never first row! */
const rightToLeft = (y: any, index: number, extraX: number) => {
    const coordinates: any = {
        0: { x: 700 - extraX, y, add: { x: '600', y } },
        1: { x: 525 - extraX, y, add: { x: '450', y } },
        2: { x: 375 - extraX, y, add: { x: '300', y } },
        3: { x: 225 - extraX, y, add: { x: '127', y: +y + 70 } },
        4: { x: 300, y: +y + 140, add: { x: '145', y: +y + 70 + 50 } }
    };
    return coordinates[index];
};

export const getCoordinates = (
    direction: 'right' | 'left',
    isFirstRow: boolean,
    y: any,
    index: number,
    firstRowOffset: number = 0,
    extraX: number = 0
) => {
    if (direction === 'right') {
        return leftToRight(y, isFirstRow, index, firstRowOffset, extraX);
    } else {
        return rightToLeft(y, index, extraX);
    }
};

export interface JourneyElementContainerProps {
    chunkList: any[][];
    // bank: string;
    currentState: JOURNEY_STEPS;
    hightLightCurrent: boolean;
    currentIndex: number;
    currentType: 'TEXT' | 'EMOJI' | 'BANK';
    showAddButton: boolean;
    followUpSettings: any[];
    handleDrop: (
        props: JourneyElementDropspotProps,
        monitor: DropTargetMonitor
    ) => void;
    handleDrag: (
        initialIndex: number,
        finalIndex: number,
        type: JOURNEY_ELEMENT_TYPES
    ) => void;
    handleStepRemove: (index: number) => void;
    startpointText: string;
    startpointImageUrl: string;
    endpointText: string;
    endpointImageUrl: string;
    showStartpoint: boolean;
    showEndpoint: boolean;
    firstRowOffset: number;
    totalSegmentLength: number;
    startpointImageType: 'CIRCLE' | 'SQUARE';
}

export const JourneyElementContainer: React.SFC<JourneyElementContainerProps> =
    (props) => {
        const {
            startpointImageUrl,
            startpointText,
            showStartpoint,
            showEndpoint,
            firstRowOffset,
            totalSegmentLength,
            startpointImageType
        } = props;
        const SVGs: any[] = [];
        let y = 0;
        let lastChunkIndex = 0;
        let lastElemIndex = 0;
        const showAddButton =
            props.showAddButton ||
            props.currentState === JOURNEY_STEPS.ADD_ITEMS_EXTRA;
        /* STARTPOINT */
        if (showStartpoint)
            SVGs.push(
                <JourneyElement
                    type={JOURNEY_ELEMENT_TYPES.STARTPOINT}
                    imgUrl={startpointImageUrl}
                    index={-2}
                    direction={'left'}
                    text={startpointText}
                    translateX={15}
                    translateY={0}
                    startpointImageType={startpointImageType}
                />
            );

        /* STARTING ADD BUTTON */
        if (showAddButton) {
            SVGs.push(
                <JourneyElementDropspot
                    text={''}
                    // index={'plus-initial'}
                    translateX={firstRowOffset}
                    translateY={0}
                    handleDrop={props.handleDrop}
                    moveCard={props.handleDrag}
                    currentIndex={0}
                    showAddIndicator={!totalSegmentLength}
                />
            );
        }

        /* POPULATE ELEMENTS ON ONE LINE PER EACH CHUNK */
        let stepIndex = 0;
        props.chunkList.map((chunk, chunkIndex) => {
            lastChunkIndex = chunkIndex;
            let type: 'left' | 'right' = 'left';
            if (chunkIndex % 2 === 0) {
                type = 'right';
            }
            chunk.map((elem, elemIndex) => {
                lastElemIndex = elemIndex;
                let hightLightComponent = false;
                if (
                    props.hightLightCurrent &&
                    props.currentIndex === stepIndex
                ) {
                    hightLightComponent = true;
                }

                if (elem.type === JOURNEY_ELEMENT_TYPES.OPTION) {
                    stepIndex += 1;
                }

                const coordinates = getCoordinates(
                    type,
                    chunkIndex === 0,
                    y,
                    elemIndex,
                    firstRowOffset
                );

                /* JOURNEY ELEMENTS (TEXT/MIDPOINT/ETC.) */
                SVGs.push(
                    <JourneyElement
                        type={elem.type}
                        text={elem.text}
                        isSelected={elem.isSelected}
                        index={chunkIndex * ELEMENTS_PER_ROW + elemIndex}
                        translateX={coordinates.x}
                        translateY={coordinates.y}
                        bgcolor={elem?.bgcolor}
                        color={elem.color}
                        banks={elem.bank}
                        smiley={elem.smiley}
                        hightLightComponent={hightLightComponent}
                        currentType={props.currentType}
                        hightLightCurrent={props.hightLightCurrent}
                        moveCard={props.handleDrag}
                        handleStepRemove={props.handleStepRemove}
                        currentState={props.currentState}
                        imgUrl={elem.imgUrl}
                        id={elem.id}
                        followUpAnswers={elem.followUpAnswers}
                        followUpSettings={props.followUpSettings}
                    />
                );

                /* SHOW DROPSPOT AFTER EACH ELEMENT */
                if (showAddButton) {
                    SVGs.push(
                        <JourneyElementDropspot
                            type={elem.type}
                            text={''}
                            isSelected={elem.isSelected}
                            // index={
                            //     'plus-' + index * 5 + i + (index > 0 ? 1 : 0)
                            // }
                            translateX={coordinates.add.x}
                            translateY={coordinates.add.y}
                            bgcolor={elem.wrapperColorTheme}
                            handleDrop={props.handleDrop}
                            moveCard={props.handleDrag}
                            currentIndex={
                                chunkIndex * ELEMENTS_PER_ROW + elemIndex + 1
                            }
                            showAddIndicator={!totalSegmentLength}
                        />
                    );
                }
                // }
            });

            // go to next line on UI
            y += 150;
        });

        // compute coordinates for ENDPOINT
        let coordinates;
        let type: 'left' | 'right' = 'left';
        const endpointOffset = 50;
        if (lastChunkIndex === 0) {
            // firstRow
            if (lastElemIndex + 1 === ELEMENTS_PER_ROW) {
                // last (corner) element of first row
                type = 'left';
                coordinates = getCoordinates(type, false, y, 0, endpointOffset);
            } else {
                // other element of first row
                type = 'right';
                coordinates = getCoordinates(
                    type,
                    true,
                    y - 150,
                    lastElemIndex + 1,
                    stepIndex ? firstRowOffset : 30,
                    endpointOffset
                );
            }
        } else {
            // not firstRow
            if (lastChunkIndex % 2 === 0) {
                type = 'right';
            }

            if (lastElemIndex === 4) {
                // last element (corner) of row: put endpoint as though it's first element of next row
                type = type === 'left' ? 'right' : 'left';
                coordinates = getCoordinates(
                    type,
                    false,
                    y,
                    0,
                    0,
                    endpointOffset
                );
            } else {
                // other elements of row
                coordinates = getCoordinates(
                    type,
                    false,
                    y - 150,
                    lastElemIndex + 1,
                    0,
                    endpointOffset
                );
            }
        }
        /* ENDPOINT */
        if (showEndpoint)
            SVGs.push(
                <JourneyElement
                    type={JOURNEY_ELEMENT_TYPES.ENDPOINT}
                    // firstBank={props.bank || 'DEFAULT'}
                    imgUrl={props.endpointImageUrl}
                    index={
                        lastChunkIndex * ELEMENTS_PER_ROW + lastElemIndex + 2
                    }
                    direction={type}
                    text={props.endpointText}
                    translateX={coordinates.x}
                    translateY={coordinates.y}
                    startpointImageType={startpointImageType}
                />
            );

        return <g transform="translate(24,80)">{SVGs}</g>;
    };

JourneyElementContainer.propTypes = {
    chunkList: PropTypes.any.isRequired,
    currentState: PropTypes.any.isRequired,
    hightLightCurrent: PropTypes.bool.isRequired,
    currentIndex: PropTypes.number.isRequired,
    currentType: PropTypes.any.isRequired,
    showAddButton: PropTypes.bool.isRequired,
    handleDrop: PropTypes.func.isRequired,
    handleDrag: PropTypes.func.isRequired,
    handleStepRemove: PropTypes.func.isRequired,
    endpointImageUrl: PropTypes.string.isRequired,
    endpointText: PropTypes.string.isRequired,
    startpointImageUrl: PropTypes.string.isRequired,
    startpointText: PropTypes.string.isRequired,
    showStartpoint: PropTypes.bool.isRequired,
    firstRowOffset: PropTypes.number.isRequired
};
