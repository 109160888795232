import {
    faCamera,
    faMicrophone,
    faPlay,
    faVideo,
    faStop,
    faRotateRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import styled from 'styled-components';
import { LiveTranscriptionEvent, createClient } from '@deepgram/sdk';
import { LiveTranscriptionEvents, LiveConnectionState } from '@deepgram/sdk';
import { USER_INPUT_TYPE } from '@nexxt/common/types';
import { isIOS, isSafari } from 'react-device-detect';

const supportedTranscriptionLanguages = [
    'es', // Spanish
    'en', // English
    'hi', // Hindi
    'ja', // Japanese
    'ru', // Russian
    'uk', // Ukrainian
    'sv', // Swedish
    'zh', // Chinese
    'pt', // Portuguese
    'nl', // Dutch
    'tr', // Turkish
    'fr', // French
    'de', // German
    'id', // Indonesian
    'ko', // Korean
    'it' // Italian
];

// Function to normalize language codes
function normalizeLanguageCode(languageCode) {
    const languageMap = {
        zh_CN: 'zh',
        zh_TW: 'zh'
    };

    return languageMap[languageCode] || languageCode;
}

interface VoicePromptProps {
    userText: string;
    setUserText: (text: string) => void;
    isText?: boolean;
    isAudio?: boolean;
    isVideo?: boolean;
    isCamera?: boolean;
    isDualCamera?: boolean;
    onPermissionChange?: (type: string, hasPermission: boolean) => void;
    audioPermission?: boolean;
    videoPermission?: boolean;
    setLoading: (value: boolean) => void;
    uploading: boolean;
    uploadFiles: (
        file: unknown[],
        uploadType: USER_INPUT_TYPE,
        thumbnail?: any
    ) => void; // temp file type string
    addTranscriptText: (text: string) => void;
    startImmediateAudioRecording?: boolean;
    currentPreviewFile: string;
    exitPreviewMode: () => void;
    error: boolean;
    clearError: () => void;
    langId: string;
}

interface liveVideoProps {
    isBackCamera?: boolean;
}

const VideoContainer = styled.div`
    position: fixed;
    right: 20;
    top: 20;
    z-index: 100;

    .live-player {
        height: 100%;
        width: 100%;
        object-fit: cover;
        ${(props: liveVideoProps) =>
            props.isBackCamera
                ? ''
                : '-webkit-transform: scaleX(-1); transform: scaleX(-1);'}
    }
`;

export function VoicePrompt({
    userText,
    setUserText,
    isText,
    isAudio = false,
    isVideo = false,
    isCamera = false,
    isDualCamera = false,
    onPermissionChange,
    audioPermission = false,
    videoPermission = false,
    setLoading,
    uploading,
    uploadFiles,
    addTranscriptText,
    startImmediateAudioRecording,
    currentPreviewFile,
    exitPreviewMode,
    error,
    clearError,
    langId
}: VoicePromptProps) {
    const videoMediaRecorder = React.useRef(null);
    const audioMediaRecorder = React.useRef(null);
    const liveVideoFeed = React.useRef<any>(null);
    const [isRecording, setIsRecording] = React.useState(false);
    const [chunks, setChunks] = React.useState([]);
    const [savedMedia, setSavedMedia] = React.useState(null);
    /* const mimeType = isVideo
            ? `video/${isIOS || isSafari ? 'mp4' : 'webm'}`
            : `audio/${isIOS || isSafari ? 'mp4' : 'webm'}`;
    */

    const mimeType = isVideo ? 'video/mp4' : 'audio/mp4';

    const [isPlaying, setIsPlaying] = React.useState(false);
    const [timer, setTimer] = React.useState(0);
    const [mediaDuration, setMediaDuration] = React.useState(0);
    const imageCanvas = React.useRef(null);
    const [capturedImage, setCapturedImage] = React.useState(null);
    const [showCapturedImaged, setShowCapturedImage] = React.useState(false);
    const [defaultWidth, setDefaultWidth] = React.useState(150);
    const [defaultHeight, setDefaultHeight] = React.useState(150);
    const [mediaWidth, setMediaWidth] = React.useState(defaultWidth);
    const [mediaHeight, setMediaHeight] = React.useState(defaultHeight);
    const [cameraDirection, setCameraDirection] = React.useState('environment');
    const [facingModes, setFacingModes] = React.useState([]);
    const [isTranscriptionLoading, setIsTranscriptionLoading] =
        React.useState(false);
    const [transcriptionResultsEvents, setTranscriptionResultsEvents] =
        React.useState([]);
    const [
        processedTranscriptionResultsIdxs,
        setProcessedTranscriptionResultsIdxs
    ] = React.useState([]);
    const [deepgramConnection, setDeepgramConnection] = React.useState(null);
    const [deepgramStatus, setDeepgramStatus] =
        React.useState<LiveConnectionState>(LiveConnectionState.CLOSED);
    const deepgramKeepAlive = React.useRef<any>();
    const [isError, setIsError] = React.useState(false);
    const [isFileAvailable, setIsFileAvailable] = React.useState(false);
    const [hasRecorded, setHasRecorded] = React.useState(false);
    const [isVideoReady, setIsVideoReady] = React.useState(true);
    const [useGcpTranscription, setUseGcpTranscription] = React.useState(false);
    const [googleGcpTranscription, setgoogleGcpTranscription] =
        React.useState('');

    const normalizedLanguageCode = normalizeLanguageCode(langId);
    // Check if the detected language is supported, otherwise default to English
    const languageToUse = supportedTranscriptionLanguages.includes(
        normalizedLanguageCode
    )
        ? normalizedLanguageCode
        : 'en';

    const connectToDeepgram = async () => {
        try {
            const deepgram = createClient(process.env.DEEPGRAM_API_KEY);

            const deepgramLive = deepgram.listen.live({
                model: 'nova-2',
                smart_format: true,
                language: languageToUse
            });

            deepgramLive.addListener(LiveTranscriptionEvents.Open, () => {
                console.log('deepgram connection open');
                setDeepgramStatus(LiveConnectionState.OPEN);

                deepgramLive.addListener(LiveTranscriptionEvents.Close, () => {
                    console.log('deepgram connection close event detected!');
                    setDeepgramStatus(LiveConnectionState.CLOSED);
                    setDeepgramConnection(null);
                });
                deepgramLive.addListener(
                    LiveTranscriptionEvents.Transcript,
                    onTranscription
                );
            });
            deepgramLive.addListener(LiveTranscriptionEvents.Error, (error) => {
                console.log({ error });
                setIsError(true);
            });

            setDeepgramConnection(deepgramLive);
        } catch (error) {
            console.log(`Error connecting to deepgram: ${error}`);
        }
    };

    React.useEffect(() => {
        const newTranscriptionResultsEventIdxTuples = Object.entries(
            transcriptionResultsEvents
        ).filter(
            (idxEventTuple) =>
                !processedTranscriptionResultsIdxs.includes(idxEventTuple[0])
        );
        const newTranscriptionResultsEventIdxs =
            newTranscriptionResultsEventIdxTuples.map((tuple) => tuple[0]);
        const newTranscriptionResultsEvents =
            newTranscriptionResultsEventIdxTuples.map((tuple) => tuple[1]);
        setProcessedTranscriptionResultsIdxs((prevIdxs) => [
            ...prevIdxs,
            ...newTranscriptionResultsEventIdxs
        ]);

        const appendedResponseText = newTranscriptionResultsEvents
            .map((event) => event.channel.alternatives[0].transcript)
            .filter((transcript) => transcript?.length)
            .join('\n');
        if (appendedResponseText.length) {
            const newUserText = userText + ' ' + appendedResponseText;
            setUserText(newUserText.trim());
            addTranscriptText(appendedResponseText);
        }
        setIsTranscriptionLoading(false);
    }, [transcriptionResultsEvents]);

    React.useEffect(() => {
        if (isTranscriptionLoading || isRecording || uploading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [isTranscriptionLoading, isRecording, uploading]);

    const isReady =
        isCamera || isDualCamera
            ? videoPermission
            : isAudio
            ? audioPermission
            : true;

    const allowRecord =
        !isPlaying &&
        !uploading &&
        (((isVideo || isAudio) && deepgramStatus == LiveConnectionState.OPEN) ||
            isCamera);

    // add deepgram transcript listener
    const onTranscription = (data: LiveTranscriptionEvent) => {
        if (data.channel.alternatives[0].transcript !== '') {
            setIsFileAvailable(true);
            setTranscriptionResultsEvents((prevEvents) => [
                ...prevEvents,
                data
            ]);
        } else {
            setIsTranscriptionLoading(false);
        }
    };

    React.useEffect(() => {
        if (isFileAvailable && !isRecording) {
            let thumbnailData;
            if (isVideo) {
                const JPEG_MIMETYPE = 'image/jpeg';
                thumbnailData = imageCanvas.current.toDataURL(JPEG_MIMETYPE);
            }
            setTimeout(() => {
                uploadFiles(
                    [new File(chunks, 'test.mp4')],
                    isVideo ? USER_INPUT_TYPE.VIDEO : USER_INPUT_TYPE.AUDIO,
                    thumbnailData
                );
            }, 500);
        }
    }, [isFileAvailable, isRecording]);

    const stopMediaTracks = () => {
        videoMediaRecorder.current?.stream
            ?.getTracks()
            .forEach((track) => track.stop());

        audioMediaRecorder.current?.stream
            ?.getTracks()
            .forEach((track) => track.stop());
    };

    React.useEffect(() => {
        return () => {
            stopMediaTracks();
        };
    }, []);

    React.useEffect(() => {
        if (currentPreviewFile) {
            setSavedMedia(currentPreviewFile);
        } else {
            setSavedMedia(null);
        }

        setCapturedImage(null);
        setIsRecording(false);
        setIsPlaying(false);
        setIsError(false);
        stopMediaTracks();
        setHasRecorded(false);

        if (liveVideoFeed?.current) {
            liveVideoFeed.current.srcObject = null;
        }
        if (!videoPermission && isVideo) {
            requestVideoPermission();
        } else if (isCamera || isDualCamera) {
            requestCameraPermission();
        } else if (!audioPermission && isAudio) {
            requestAudioPermission();
        }
    }, [isAudio, isCamera, isDualCamera, isVideo, isText, currentPreviewFile]);

    React.useEffect(() => {
        if (!deepgramConnection) {
            connectToDeepgram();
        }
    }, [deepgramConnection]);

    React.useEffect(() => {
        if (!startImmediateAudioRecording || hasRecorded) return;

        if (isAudio && deepgramConnection) {
            startRecording().catch(() => {
                onPermissionChange('audio', false);
            });
        }
    }, [deepgramConnection, isAudio]);

    React.useEffect(() => {
        if (!deepgramConnection) return;

        if (deepgramStatus === LiveConnectionState.OPEN) {
            deepgramConnection.keepAlive();
            deepgramKeepAlive.current = setInterval(() => {
                deepgramConnection.keepAlive();
            }, 6000);
        } else {
            clearInterval(deepgramKeepAlive.current);
            console.log('clear keepAlive interval');
        }

        return () => {
            clearInterval(deepgramKeepAlive.current);
            console.log('cleanup keepAlive interval');
        };
    }, [deepgramStatus]);

    const requestAudioPermission = async () => {
        if ('MediaRecorder' in window) {
            try {
                await navigator.mediaDevices.getUserMedia({
                    audio: true
                });
                onPermissionChange('audio', true);
            } catch (err) {
                onPermissionChange('audio', false);
                setIsError(true);
                console.log(err.message);
            }
        } else {
            alert('The MediaRecorder is not supported in your browser.');
        }
    };

    const requestCameraPermission = async () => {
        if ('MediaRecorder' in window) {
            setLoading(true);
            const modes = [];
            const userStream = await navigator.mediaDevices
                .getUserMedia({
                    video: { facingMode: { exact: 'user' } }
                })
                .catch(() => null);
            if (userStream) {
                modes.push('user');
                userStream.getTracks().forEach((track) => track.stop());
            }

            const envStream = await navigator.mediaDevices
                .getUserMedia({
                    video: { facingMode: { exact: 'environment' } }
                })
                .catch(() => null);

            if (envStream) {
                modes.push('environment');
                envStream.getTracks().forEach((track) => track.stop());
            }
            setFacingModes(modes);

            startCamera();
        } else {
            alert('The MediaRecorder API is not supported in your browser.');
        }
    };

    const startCamera = async () => {
        try {
            stopMediaTracks();

            const videoStream = await navigator.mediaDevices.getUserMedia({
                video: { facingMode: cameraDirection }
            });

            videoMediaRecorder.current = new MediaRecorder(videoStream);
            liveVideoFeed.current.srcObject = videoStream;
            liveVideoFeed.current.play();
            onPermissionChange('video', true);
        } catch (err) {
            onPermissionChange('video', false);
            console.log('Error please set permissions', err);
            setIsError(true);
        }
        setLoading(false);
    };

    const requestVideoPermission = async () => {
        if ('MediaRecorder' in window) {
            const cameraPermission = await navigator.permissions.query({
                name: 'camera' as PermissionName
            });
            const audioPerm = await navigator.permissions.query({
                name: 'microphone' as PermissionName
            });
            if (
                cameraPermission.state === 'denied' ||
                audioPerm.state === 'denied'
            ) {
                setIsVideoReady(false);
            } else {
                setIsVideoReady(true);
            }
        } else {
            alert('The MediaRecorder API is not supported in your browser.');
        }
    };

    const dataURItoBlob = (dataURI) => {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        const byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to an ArrayBuffer
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    const captureImage = () => {
        const context = imageCanvas.current.getContext('2d');

        context.drawImage(liveVideoFeed.current, 0, 0, mediaWidth, mediaHeight);

        const JPEG_MIMETYPE = 'image/jpeg';
        const data = imageCanvas.current.toDataURL(JPEG_MIMETYPE);
        const blob = dataURItoBlob(data);
        setCapturedImage(data);
        uploadFiles(
            [new File([blob], 'test.jpeg', { type: JPEG_MIMETYPE })],
            USER_INPUT_TYPE.CAMERA
        );
        setShowCapturedImage(true);
        setTimeout(() => {
            setShowCapturedImage(false);
        }, 2000);
    };

    const switchCameras = () => {
        setCameraDirection((prev) =>
            prev === 'user' ? 'environment' : 'user'
        );
    };

    React.useEffect(() => {
        if (isCamera || isDualCamera) {
            startCamera();
        }
    }, [cameraDirection]);

    const startRecording = async () => {
        try {
            // reset the state variables for recording
            exitPreviewMode();
            setIsFileAvailable(false);
            setSavedMedia(null);
            setMediaDuration(0);
            setIsError(false);
            const localAudioChunks = [];
            setChunks(localAudioChunks);
            setHasRecorded(true);

            const stream = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: isVideo
            });
            const audioStream = new MediaStream([...stream.getAudioTracks()]);
            audioMediaRecorder.current = new MediaRecorder(audioStream);
            // get seperate audio stream to send to deepgram (sending video + audio streams makes it unreliable)
            audioMediaRecorder.current.ondataavailable = (event) => {
                if (event.data && event.data.size > 0) {
                    setIsTranscriptionLoading(true);
                    deepgramConnection.send(event.data);
                    if (isAudio) {
                        localAudioChunks.push(event.data);
                    }
                }
            };
            onPermissionChange('audio', true);

            if (isVideo) {
                const videoStream = new MediaStream([
                    ...stream.getVideoTracks()
                ]);
                liveVideoFeed.current.srcObject = videoStream;
                videoMediaRecorder.current = new MediaRecorder(stream);
                onPermissionChange('video', true);
                setIsVideoReady(true);
                videoMediaRecorder.current.ondataavailable = (event) => {
                    if (event.data && event.data.size > 0) {
                        localAudioChunks.push(event.data);
                    }
                };
                videoMediaRecorder.current.start(3000);
                setTimeout(() => {
                    const context = imageCanvas.current.getContext('2d');
                    context.drawImage(
                        liveVideoFeed.current,
                        0,
                        0,
                        liveVideoFeed.current.videoWidth,
                        liveVideoFeed.current.videoHeight
                    );
                }, 1000);
            }

            audioMediaRecorder.current.start(3000);
            setIsRecording(true);
        } catch (error) {
            setIsVideoReady(false);
            console.log(error);
        }
    };

    const stopRecording = () => {
        liveVideoFeed.current.srcObject = null;
        audioMediaRecorder.current?.stop();
        videoMediaRecorder.current?.stop();

        const mediaRecorder = isVideo
            ? videoMediaRecorder.current
            : audioMediaRecorder.current;

        mediaRecorder.onstop = () => {
            //creates a blob file from the audiochunks data
            const mediaBlob = new Blob(chunks, { type: mimeType });
            //creates a playable URL from the blob file.
            const blobUrl = URL.createObjectURL(mediaBlob);
            setSavedMedia(blobUrl);
            setMediaDuration(timer);
            setIsRecording(false);
            deepgramConnection.finish();
        };

        stopMediaTracks();
    };

    const handleRecordStart = () => {
        if (isCamera || isDualCamera) {
            captureImage();
        } else if (!isRecording) {
            startRecording();
        } else {
            stopRecording();
        }
    };

    const handlePlay = () => {
        setIsPlaying((prevState) => !prevState);
    };

    React.useEffect(() => {
        let interval;

        if (isRecording || isPlaying) {
            interval = setInterval(() => {
                setTimer((prevTime) => prevTime + 1);
            }, 1000);
        } else {
            clearInterval(interval);
            setTimer(0);
        }

        return () => clearInterval(interval);
    }, [isRecording, isPlaying]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes < 10 ? '0' : ''}${minutes}:${
            remainingSeconds < 10 ? '0' : ''
        }${remainingSeconds}`;
    };

    const handleOnProgressBarClick = (e) => {
        const clickedTime = Math.round(
            (e.nativeEvent.offsetX / e.currentTarget.offsetWidth) *
                mediaDuration
        );
        const mediaRecorder = isVideo
            ? videoMediaRecorder.current
            : audioMediaRecorder.current;

        if (mediaRecorder.current) {
            mediaRecorder.currentTime = clickedTime;
            setTimer(clickedTime);
        }
    };

    const updateDimensions = () => {
        setMediaHeight(liveVideoFeed.current.videoHeight || defaultHeight);
        setMediaWidth(liveVideoFeed.current.videoWidth || defaultWidth);
    };

    const displayWidth = mediaWidth * (defaultHeight / mediaHeight);

    React.useEffect(() => {
        let resizeTimer;

        const handleResize = () => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                const newWidth = window.innerWidth >= 768 ? 150 : 110;
                const newHeight = window.innerWidth >= 768 ? 150 : 110;
                setDefaultWidth(newWidth);
                setDefaultHeight(newHeight);
            }, 200);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (isText) return <></>;

    return (
        <>
            <VideoContainer
                style={{
                    width: displayWidth,
                    height: defaultHeight
                }}
                isBackCamera={
                    (isCamera || isDualCamera) &&
                    cameraDirection === 'environment'
                }
            >
                <video
                    ref={liveVideoFeed}
                    autoPlay
                    className="live-player"
                    onLoadedMetadata={updateDimensions}
                    playsInline
                />
            </VideoContainer>
            {isPlaying && isVideo ? (
                <VideoContainer
                    style={{
                        width: displayWidth,
                        height: defaultHeight
                    }}
                >
                    <video
                        className="live-player"
                        autoPlay
                        onEnded={handlePlay}
                        ref={videoMediaRecorder}
                        playsInline
                    >
                        <source src={savedMedia} type="video/mp4" />
                    </video>
                </VideoContainer>
            ) : null}

            {showCapturedImaged && (
                <VideoContainer
                    style={{
                        width: displayWidth,
                        height: defaultHeight
                    }}
                >
                    <img className="live-player" src={capturedImage} />
                </VideoContainer>
            )}
            <canvas
                id="canvas"
                ref={imageCanvas}
                width={mediaWidth}
                height={mediaHeight}
                style={{ display: 'none' }}
            />
            {isReady ? (
                <div className="record-wrap">
                    {/* TODO: temp to visualize latest userText and to later be editable on complete recording */}
                    {/* {userText} */}
                    <div className="icons">
                        <div
                            className={`icons record-icon ${
                                (allowRecord && !isVideo) ||
                                (isVideoReady && isVideo)
                                    ? ''
                                    : 'disabled'
                            }`}
                            onClick={
                                (allowRecord && !isVideo) ||
                                (isVideoReady && isVideo)
                                    ? handleRecordStart
                                    : undefined
                            }
                        >
                            {isRecording ? (
                                <span>{formatTime(timer)}</span>
                            ) : (
                                <FontAwesomeIcon
                                    icon={
                                        isVideo
                                            ? faVideo
                                            : isCamera || isDualCamera
                                            ? faCamera
                                            : faMicrophone
                                    }
                                />
                            )}
                        </div>
                        {savedMedia && (
                            <>
                                <div
                                    className={`icons record-icon ${
                                        isRecording ? 'disabled' : ''
                                    }`}
                                    onClick={
                                        !isRecording ? handlePlay : undefined
                                    }
                                >
                                    {isPlaying ? (
                                        <FontAwesomeIcon icon={faStop} />
                                    ) : (
                                        <FontAwesomeIcon icon={faPlay} />
                                    )}
                                </div>
                                <div
                                    style={{
                                        width: defaultWidth,
                                        background: 'none'
                                    }}
                                >
                                    {isPlaying && !isVideo && (
                                        <audio
                                            autoPlay
                                            onEnded={handlePlay}
                                            ref={audioMediaRecorder}
                                            src={savedMedia}
                                        />
                                    )}
                                    <progress
                                        id="progress"
                                        value={timer}
                                        max={mediaDuration}
                                        ref={audioMediaRecorder}
                                        style={{
                                            width: '100%',
                                            cursor: 'pointer'
                                        }}
                                        onClick={handleOnProgressBarClick}
                                    />
                                </div>
                                <p>{formatTime(timer)}</p>
                            </>
                        )}
                        {isCamera && facingModes.length > 1 && (
                            <div
                                className={`icons record-icon ${
                                    isPlaying ? 'disabled' : ''
                                }`}
                                onClick={switchCameras}
                            >
                                <FontAwesomeIcon icon={faRotateRight} />
                            </div>
                        )}
                    </div>
                    {/* {isRecording
                    ? getTranslation(t, 'PLACEHOLDER.PROMPT.RECORD_STOP')
                : getTranslation(t, 'PLACEHOLDER.PROMPT.RECORD_START')} */}
                    {/* TODO: update the nested ternaries and error handling, difficult to understand */}
                    {!savedMedia && !isError ? (
                        <span className="helper-text">
                            {isCamera || isDualCamera
                                ? 'Tap camera icon to take a photo'
                                : isRecording
                                ? 'Tap above to stop recording'
                                : !isVideoReady
                                ? 'Your browser needs microphone and camera permissions to continue.'
                                : !(isAudio && startImmediateAudioRecording)
                                ? 'Tap above to start recording'
                                : null}
                        </span>
                    ) : (
                        <span
                            className={`helper-text ${
                                isTranscriptionLoading
                                    ? 'loading'
                                    : isError
                                    ? 'danger'
                                    : ''
                            }`}
                        >
                            {/* TODO: better error handling for loading states as userText now has values sooner */}
                            {/* Camera text not required because it is not saved as savedMedia */}
                            {isTranscriptionLoading || uploading
                                ? 'Please wait while audio processes before sending.'
                                : isError || error
                                ? 'Sorry, there was an error with processing your recording! Please try again.'
                                : isAudio
                                ? `Tap on the microphone to add another recording`
                                : isVideo
                                ? 'Tap on the camera to add another recording'
                                : null}
                        </span>
                    )}
                </div>
            ) : (
                <div className="permission-request">
                    <p>
                        {`Your browser needs ${
                            isAudio
                                ? 'microphone'
                                : isVideo
                                ? 'microphone and camera'
                                : 'camera'
                        } permissions to continue.`}
                    </p>
                    <p>
                        Please check your browser settings and enable the
                        necessary permissions.
                    </p>
                    {/* <button
                        onClick={(e) => {
                            e.preventDefault();
                            if (isVideo) {
                                requestVideoPermission();
                            } else if (isCamera || isDualCamera) {
                                requestCameraPermission();
                            } else {
                                requestAudioPermission();
                            }
                        }}
                    >
                        Request Permission
                    </button> */}
                </div>
            )}
        </>
    );
}

export default VoicePrompt;
