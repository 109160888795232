import * as React from 'react';
import {
    DropTarget,
    ConnectDropTarget,
    DropTargetConnector,
    DropTargetMonitor
} from 'react-dnd';
import ItemTypes from '../constant';
import { findDOMNode } from 'react-dom';
import { XYCoord } from 'dnd-core';
import { Color } from 'csstype';
import { JourneyElementDropspotSVG } from './SVGComponents';

class JourneyElementDropspot extends React.Component<JourneyElementDropspotProps> {
    render() {
        const {
            connectDropTarget,
            isOver,
            canDrop,
            text,
            translateX,
            translateY
        } = this.props;
        return (
            <JourneyElementDropspotSVG
                connectDropTarget={connectDropTarget as any}
                translateX={translateX}
                translateY={translateY}
                canDrop={canDrop}
                isDragging={false}
                {...this.props}
            />
        );
    }
}

interface CardTargetCollectedProps {
    connectDropTarget: ConnectDropTarget;
}

const cardTarget = {
    hover(
        props: JourneyElementDropspotProps,
        monitor: DropTargetMonitor,
        component: React.Component | null
    ) {
        if (!component) {
            return null;
        }
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Determine rectangle on screen
        //eslint-disable-next-line react/no-find-dom-node
        const hoverBoundingRect = (
            findDOMNode(component) as Element
        ).getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY =
            (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY =
            (clientOffset as XYCoord).y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
        }

        // Time to actually perform the action
        // props.moveCard(dragIndex, hoverIndex)

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        // monitor.getItem().index = hoverIndex;
        return {
            id: props.id,
            index: props.index,
            text: props.text
        };
    },
    drop(
        props: JourneyElementDropspotProps,
        monitor: DropTargetMonitor,
        component: JourneyElementDropspot | null
    ) {
        if (monitor.getItemType() === ItemTypes.CARD) {
            console.log(
                'Dropped',
                props,
                monitor.getItem(),
                monitor.getItemType(),
                component
            );
            props.onDrop({
                props,
                monitor,
                component
            });
        } else if (monitor.getItemType() === ItemTypes.DIALOG) {
            const dragIndex = monitor.getItem().index;
            const hoverIndex = props.currentIndex;
            props.moveCard(dragIndex, hoverIndex, props.type as any);
            console.log(
                'Dropped',
                dragIndex,
                hoverIndex,
                props,
                monitor.getItem(),
                monitor.getItemType(),
                component
            );
        }
    }
};

export interface DroppableProps {
    canDrop: boolean;
    isOver: boolean;
    connectDropTarget: ConnectDropTarget;
}

export interface JourneyElementDropspotProps extends DroppableProps {
    translateX: string;
    translateY?: string;
    bgcolor?: Color;
    currentObj: any;
    currentIndex: number;

    // addButton?: boolean;
    type?: 'ADDNEW';
    /* extend from base props for journey */
    id: any;
    text: string;
    index: number;
    isBeforeMidpoint: boolean;

    moveCard: (
        dragIndex: number,
        hoverIndex: number,
        type: 'ADDBUTTON' | 'MIDPOINT' | 'TEXT'
    ) => void;
    onDrop: (parameter: any) => void;
}

export default DropTarget<
    JourneyElementDropspotProps,
    CardTargetCollectedProps
>(
    [ItemTypes.CARD, ItemTypes.DIALOG],
    cardTarget,
    (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
    })
)(JourneyElementDropspot as any) as any;
