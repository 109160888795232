import * as React from 'react';
import * as _ from 'lodash';
import { BaseInput, BaseProps } from './_BaseInput';
import {
    getStringFromMultimediaTexts,
    getTranslation
} from '@nexxt/common/services/TextService';
import videojs from 'video.js';
import '../style/Videojs.css'; // Default video js skin css
import '../style/Annotation.scss';
import ContinueButton from '../components/ContinueButton';
import {
    KEY_NAME,
    DEFAULT_VIDEOJS_OPTIONS,
    ANNOTATION_BUTTONS_WITH_TEXT
} from '@nexxt/common/constants';
import { Annotation, MultimediaText, OptionData } from '@nexxt/common/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import {
    UilPen,
    UilTrashAlt,
    UilTimes,
    UilCheck
} from '@iconscout/react-unicons';
import {
    getAnnotationColor,
    getAnnotationColorByButton
} from '@nexxt/common/utils/question';
import { isMobile } from 'react-device-detect';
import { dataTestid } from '../constants/dataTestid';
import VideoPlayer from '../components/VideoPlayer';

const { translate } = require('react-i18next');
interface Props extends BaseProps {
    handleAnswer: (o: any) => void;
}
interface State {
    hasProblem: boolean;
    annotations: Annotation[];
    showComment: boolean;
    showContinue: boolean;
    editing: boolean;
    currentAnnotation: number;
    currentReaction: OptionData;
    currentComment: string;
    currentTime: number;
    // numComments is only updated when needed, i.e. may not always be accurate
    numComments: number;
    isVideoSet: boolean;
}

@translate('translation')
export class VideoAnnotation extends BaseInput<Props, State> {
    state: State = {
        hasProblem: false,
        annotations: [],
        showComment: false,
        showContinue: false,
        currentAnnotation: null,
        currentReaction: null,
        currentTime: null,
        currentComment: '',
        editing: false,
        numComments: 0,
        isVideoSet: false
    };
    private player?: videojs.Player;

    setVideoPlayer = (player: videojs.Player) => {
        if (!this.state.isVideoSet) {
            this.player = player;
            this.player?.on('timeupdate', () => {
                this.setState({
                    currentTime: Number(this.player.currentTime().toFixed(0))
                });
            });

            this.setState({ isVideoSet: true });
        }
    };
    trySubmitAnnotations = () => {
        if (this.state.annotations.length >= this.props.question.min) {
            this.props.handleAnswer(this.state.annotations);
        } else {
            this.setState({
                hasProblem: true,
                numComments: this.state.annotations.length
            });
        }
    };
    handleReaction = (button: OptionData) => {
        this.player?.pause();
        this.setState({
            showComment: true,
            editing: false,
            currentReaction: button,
            currentComment: '',
            currentAnnotation: null
        });
    };
    updateCurrentTime = (value: number) => {
        this.player.currentTime(value);
        this.setState({ currentTime: value });
    };
    addReaction = () => {
        const annotation: Annotation = {
            emoji: this.state.currentReaction as OptionData,
            second: this.state.currentTime,
            comments: [this.state.currentComment]
        };
        const newAnnotations = [...this.state.annotations, annotation];
        this.setState({ annotations: newAnnotations });
        this.clearReaction();
    };
    clearReaction = () => {
        this.setState({
            editing: false,
            showComment: false,
            currentReaction: null,
            currentComment: '',
            currentAnnotation: null
        });
    };

    isValid = () => {
        if (this.state.currentComment.trim() !== '') {
            return true;
        }
    };

    isFullyWatched = () => {
        if (this.player.remainingTime() === 0) {
            return true;
        }
    };

    updateAnnotation = (index) => {
        const newAnnotations = this.state.annotations.map((annotation, i) => {
            if (i === index) {
                annotation.comments = [this.state.currentComment];
            }
            return annotation;
        });
        this.setState({ annotations: newAnnotations });
        this.clearReaction();
    };

    removeAnnotation = (index) => {
        const newAnnotations = this.state.annotations.filter(
            (annotation, i) => i !== index
        );
        this.setState({ annotations: newAnnotations });
    };
    onEnded = () => {
        this.setState({ showContinue: true });
    };
    render() {
        const { langId, question, t } = this.props;
        const { continueTexts } = question;
        const buttons = question.buttons?.length
            ? question.buttons
            : ANNOTATION_BUTTONS_WITH_TEXT(t, langId);

        const { showComment } = this.state;
        const continueText = getStringFromMultimediaTexts(
            continueTexts,
            langId
        );
        const commentsLeft = question.min - this.state.numComments; // only updated when needed

        const formatTime = (time) => {
            const minutes = Math.floor(time / 60);
            const seconds = time % 60;

            return `${minutes}:${seconds
                .toFixed(0)
                .toString()
                .padStart(2, '0')}`;
        };
        const autoplay = _.hasIn(this.props.question.optionSettings, 'autoplay')
            ? this.props.question?.optionSettings?.autoplay
            : DEFAULT_VIDEOJS_OPTIONS.autoplay;
        return (
            <div className="video-annotation--container">
                <VideoPlayer
                    videoURL={this.props.question.videoURL}
                    mediaOptions={{ autoplay }}
                    maxHeight={50}
                    setVideoPlayer={this.setVideoPlayer}
                    onEnded={this.onEnded}
                />
                <div className="video-annotation--reactions--container">
                    {!showComment ? (
                        <div className="video-annotation--reactions">
                            <div className="video-annotation--reactions--header">
                                {t('LABEL.INSTRUCTIONS.ANNOTATION.REACTION')}
                            </div>
                            <div className="video-annotation--reactions--options">
                                {buttons.map((button, i) => {
                                    const text = getStringFromMultimediaTexts(
                                        button.texts as MultimediaText[],
                                        langId
                                    );
                                    return (
                                        <div
                                            className="video-annotation--reactions--options--button"
                                            key={i}
                                            onClick={() =>
                                                this.handleReaction(
                                                    button as OptionData
                                                )
                                            }
                                        >
                                            <img
                                                className="video-annotation--reactions--options--button--img"
                                                src={button.mediaURL}
                                            />
                                            <span className="video-annotation--reactions--options--button--label">
                                                {text}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : (
                        <div className="video-annotation--reactions">
                            <div className="video-annotation--reactions--header">
                                <span>
                                    {t('LABEL.INSTRUCTIONS.ANNOTATION.COMMENT')}{' '}
                                </span>
                                <img
                                    className="video-annotation--reactions--options--button--img--small"
                                    src={this.state.currentReaction.mediaURL}
                                />
                                <span>
                                    {' '}
                                    {t('GRAMMAR.AT')}{' '}
                                    {formatTime(this.state.currentTime)}?
                                </span>
                                <div className="video-annotation--reactions--header--options">
                                    <div
                                        onClick={() =>
                                            this.updateCurrentTime(
                                                this.state.currentTime - 1
                                            )
                                        }
                                    >
                                        -1 {t('GRAMMAR.SEC')}
                                    </div>
                                    <div
                                        onClick={() =>
                                            this.updateCurrentTime(
                                                this.state.currentTime + 1
                                            )
                                        }
                                    >
                                        +1 {t('GRAMMAR.SEC')}
                                    </div>
                                </div>
                            </div>
                            <div className="video-annotation--reactions--options">
                                <div className="video-annotation--reactions--options--text">
                                    <textarea
                                        autoFocus
                                        value={this.state.currentComment}
                                        onChange={(e) =>
                                            this.setState({
                                                currentComment: e.target.value
                                            })
                                        }
                                        onKeyPress={(e) => {
                                            if (
                                                e.key === KEY_NAME.ENTER &&
                                                !e.shiftKey
                                            ) {
                                                this.isValid() &&
                                                    this.addReaction();
                                            }
                                        }}
                                        onPaste={(event) => {
                                            event.preventDefault();
                                        }}
                                    />
                                    <div className="video-annotation--reactions--options--text--actions">
                                        <div
                                            className="video-annotation--reactions--options--text--actions--item"
                                            onClick={this.clearReaction}
                                        >
                                            <UilTimes />
                                        </div>
                                        <div
                                            className="video-annotation--reactions--options--text--actions--item"
                                            onClick={
                                                this.isValid() &&
                                                this.addReaction
                                            }
                                            style={{ paddingRight: 2 }}
                                            tabIndex={0}
                                            data-testid={dataTestid.FABUTTON}
                                        >
                                            <FontAwesomeIcon
                                                icon={faPaperPlane}
                                                className="send"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="video-annotation--comments">
                    <div className="video-annotation--comments--header">
                        {t('LABEL.INSTRUCTIONS.ANNOTATION.REACTION_HEADER')}
                    </div>
                    <div className="video-annotation--comments--list">
                        {this.state.annotations.length > 0
                            ? this.state.annotations.map((annotation, i) => (
                                  <div
                                      className="video-annotation--comment"
                                      key={i}
                                  >
                                      <div
                                          className="video-annotation--comment--reaction"
                                          style={{
                                              backgroundColor:
                                                  typeof annotation.emoji !==
                                                      'string' &&
                                                  getAnnotationColorByButton(
                                                      annotation.emoji,
                                                      true
                                                  )
                                          }}
                                      ></div>
                                      <div
                                          className="video-annotation--comment--body"
                                          onClick={() => {
                                              this.updateCurrentTime(
                                                  annotation.second
                                              );
                                          }}
                                      >
                                          <b className="video-annotation--comment--body--time">
                                              {formatTime(annotation.second)}
                                          </b>
                                          {` `}
                                          {this.state.editing &&
                                          this.state.currentAnnotation === i ? (
                                              <input
                                                  autoFocus
                                                  value={
                                                      this.state.currentComment
                                                  }
                                                  onChange={(e) => {
                                                      this.setState({
                                                          currentComment:
                                                              e.target.value
                                                      });
                                                  }}
                                                  onKeyPress={(e) => {
                                                      if (
                                                          e.key ===
                                                              KEY_NAME.ENTER &&
                                                          !e.shiftKey
                                                      ) {
                                                          this.updateAnnotation(
                                                              i
                                                          );
                                                      }
                                                  }}
                                              />
                                          ) : (
                                              annotation.comments[0]
                                          )}
                                      </div>
                                      {!this.state.editing ? (
                                          <div className="video-annotation--comment--actions">
                                              <UilPen
                                                  size={18}
                                                  onClick={() =>
                                                      this.setState({
                                                          editing: true,
                                                          currentAnnotation: i,
                                                          currentComment:
                                                              annotation
                                                                  .comments[0],
                                                          showComment: false
                                                      })
                                                  }
                                              />
                                              <UilTrashAlt
                                                  size={18}
                                                  onClick={() =>
                                                      this.removeAnnotation(i)
                                                  }
                                              />
                                          </div>
                                      ) : this.state.currentAnnotation === i ? (
                                          <div className="video-annotation--comment--actions">
                                              <UilCheck
                                                  size={18}
                                                  onClick={() =>
                                                      this.updateAnnotation(i)
                                                  }
                                              />
                                              <UilTimes
                                                  size={18}
                                                  onClick={this.clearReaction}
                                              />
                                          </div>
                                      ) : null}
                                  </div>
                              ))
                            : t('LABEL.INSTRUCTIONS.ANNOTATION.REACTION_EMPTY')}
                    </div>
                </div>
                <div className="video-annotation--continue">
                    {this.props.unitTest || this.state.showContinue ? (
                        <>
                            <div className="multi-choice-help">
                                <p>
                                    {this.state.hasProblem
                                        ? getTranslation(
                                              t,
                                              'LABEL.VALIDATE.MORE_COMMENTS',
                                              {
                                                  comment_num: commentsLeft
                                              }
                                          )
                                        : ''}
                                </p>
                            </div>
                            <ContinueButton
                                onClick={this.trySubmitAnnotations}
                                text={continueText}
                            />
                        </>
                    ) : (
                        <div className="multi-choice-help">
                            <p>{t('LABEL.VALIDATE.WAITING_CONTINUE')}</p>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
