import * as React from 'react';
import {
    InputWithOptionImages,
    ImageLoadState,
    PropsWithOptionQ
} from './_InputWithOptionImages';
import {
    OptionData,
    OPTION_TYPES,
    MultipleOptionsAnswer,
    IMAGE_OPTION_SIZING,
    QTYPES
} from '@nexxt/common/types';
import {
    getStringFromMultimediaTexts,
    getTranslation
} from '@nexxt/common/services/TextService';
import * as cx from 'classnames';
import NotApplicableButton from '../components/NotApplicableButton';
import ContinueButton from '../components/ContinueButton';
import '../style/grid-col.scss';
import '../style/MultiOptions.scss';
import { SVGTick } from '../style/SVG';
import {
    KEY_NAME,
    NA_OPTION_VALUE,
    OTHER_OPTION_VALUE,
    RESUME_OVALUE
} from '@nexxt/common/constants';
import {
    addOtherToOptions,
    calculateNewMaxWidth,
    PERFECT,
    pickStatus
} from '../utils/questions';
import SpecifyEditor from '../components/SpecifyEditor';
import { isMobile } from 'react-device-detect';
import { getAutoOptionsPerRow } from '@nexxt/common/utils/question';
import OptionText from '../components/OptionText';

const { translate } = require('react-i18next');

interface Selections {
    [value: string]: true;
}

interface Props extends PropsWithOptionQ {
    handleAnswer: (s: string[], other?: string) => void;
}

interface State extends ImageLoadState {
    selections: Selections;
    options: OptionData[];
    showSpecify: boolean;
    specifyText: string;
    submitting: boolean;
    showError: boolean;
}

@translate('translation')
export class MultipleChoice extends InputWithOptionImages<Props, State> {
    state: State = {
        selections: {},
        imageLoadCount: 0,
        options: [],
        specifyText: '',
        showSpecify: false,
        submitting: false,
        showError: false
    };

    componentDidMount() {
        if (this.props.previousAnswer) {
            let showSpecify = false,
                specifyText = '';

            const selections = (
                this.props.previousAnswer as MultipleOptionsAnswer
            ).values.reduce((agg, opt) => {
                if (opt.value !== NA_OPTION_VALUE) agg[opt.optionKey] = true;
                if (opt.value === OTHER_OPTION_VALUE) {
                    showSpecify = true;
                    specifyText = opt.specify;
                }
                return agg;
            }, {});
            this.setState({
                selections,
                showSpecify,
                specifyText,
                showError: true
            });
        }
    }

    toggleChoice = (optionKey: string) => {
        const isCurrOther =
            optionKey === `${this.props.question.id}_${OTHER_OPTION_VALUE}`;
        const showSpecify =
            isCurrOther && this.props.question.optionSettings?.showSpecify
                ? !this.state.showSpecify
                : this.state.showSpecify;

        if (this.state.selections[optionKey]) {
            const s = Object.assign({}, this.state.selections);
            delete s[optionKey];
            this.setState((prevState) => ({
                selections: s,
                showSpecify
            }));
        } else {
            this.setState((prevState) => ({
                selections: Object.assign({}, this.state.selections, {
                    [optionKey]: true as const
                }),
                showSpecify
            }));
        }
    };

    validatorText = (max: number, min: number) => {
        const { t } = this.props;
        const selected = Object.keys(this.state.selections).length;
        if (selected === 0) {
            return (
                <div className="multi-choice-help">
                    <p>{getTranslation(t, 'LABEL.VALIDATE.SELECT')}</p>
                </div>
            );
        } else if (selected > max) {
            return (
                <div className="multi-choice-help warning">
                    <p>
                        {getTranslation(t, 'LABEL.VALIDATE.SELECT_MIN_MAX', {
                            min,
                            max
                        })}
                    </p>
                </div>
            );
        } else if (selected < min) {
            return (
                <div className="multi-choice-help">
                    <p>
                        {getTranslation(t, 'LABEL.VALIDATE.MORE', {
                            more: min - selected
                        })}
                    </p>
                </div>
            );
        } else if (selected === max) {
            return (
                <div className="multi-choice-help">
                    <p>{getTranslation(t, 'LABEL.VALIDATE.SATISFIED')}</p>
                </div>
            );
        } else {
            if (this.props.question.type === QTYPES.JOURNEY) {
                return (
                    <div className="multi-choice-help">
                        {getTranslation(
                            t,
                            'LABEL.VALIDATE.CONTINUE_OR_MORE_JOURNEY'
                        )}
                    </div>
                );
            }
            return (
                <div className="multi-choice-help">
                    <p>
                        {getTranslation(t, 'LABEL.VALIDATE.CONTINUE_OR_MORE')}
                    </p>
                </div>
            );
        }
    };

    handleSubmitAnswer = (selectedOptionKeys: string[], other?: string) => {
        const { showSpecify, specifyText, submitting } = this.state;
        if (
            showSpecify &&
            !specifyText &&
            !selectedOptionKeys?.includes(
                `${this.props.question.id}_${NA_OPTION_VALUE}`
            )
        ) {
            this.setState({ showError: true });
            return;
        }

        if (submitting) return;
        this.setState({ submitting: true });
        this.props.handleAnswer(selectedOptionKeys, other);
        this.setState({ submitting: false });
    };
    render() {
        const { langId, question, previousAnswer, t } = this.props;
        const {
            showNotApplicableOption,
            notApplicableTexts,
            continueTexts,
            min,
            max,
            triangleLayout,
            imageDisplay,
            selectedOptionType,
            optionSettings
        } = question;
        const { showSpecify, specifyText } = this.state;
        const editing = !!previousAnswer;
        const notApplicableText = getStringFromMultimediaTexts(
            notApplicableTexts,
            langId,
            getTranslation(this.props.t, 'BUTTON.NOT_APPLICABLE')
        );

        const continueText = getStringFromMultimediaTexts(
            continueTexts,
            langId,
            getTranslation(this.props.t, 'BUTTON.CONTINUE')
        );

        const isCircle = imageDisplay === 'circle' ? true : false;

        let options = addOtherToOptions(question);
        options = options.map((option) => {
            return {
                ...option,
                text: getStringFromMultimediaTexts(
                    option.texts,
                    langId,
                    option.label
                )
            };
        });

        const s = pickStatus(
            Object.getOwnPropertyNames(this.state.selections).length,
            min,
            max || options.length
        );

        if (!this.props.question) {
            return <div>{t('PLACEHOLDER.LOADING')}</div>;
        } else {
            const isAnyMobile =
                isMobile ||
                window.innerWidth < 700 ||
                document.getElementById('bot-preview')?.scrollWidth < 700;

            const columnsFromOptionSettings = isAnyMobile
                ? optionSettings?.numColumnsMobile
                : optionSettings?.numColumns;

            const columns =
                columnsFromOptionSettings >= 1
                    ? columnsFromOptionSettings
                    : getAutoOptionsPerRow(
                          optionSettings?.size,
                          selectedOptionType,
                          isAnyMobile
                      );

            let disabledButton = true;
            if (s === 100 || s === PERFECT) {
                disabledButton = false;
            }

            // check if options are text-only
            const textOnly = selectedOptionType === OPTION_TYPES.TEXT_ONLY;
            const hideText = selectedOptionType === OPTION_TYPES.IMAGE_ONLY;

            // Check if custom size is selected
            const isCustomSizing = IMAGE_OPTION_SIZING[
                question.optionSettings?.size
            ]
                ? true
                : false;
            // Checks if custom option size & custom number of columns
            const isFullCustomSizing =
                isCustomSizing && columnsFromOptionSettings >= 1;
            return (
                <div className={triangleLayout ? 'triangle' : ''}>
                    <ul
                        className={cx(`multi-options-container`, {
                            [`grid-container grid-columns-${columns}`]:
                                textOnly && !triangleLayout,
                            'two-columns': columns === 2,
                            [`pure-columns-${columns}`]:
                                !textOnly && !isCustomSizing,
                            // [`with-image`]: !textOnly,
                            'multiple-options': !triangleLayout,
                            'custom-size': isCustomSizing
                        })}
                        style={
                            isFullCustomSizing
                                ? {
                                      maxWidth: calculateNewMaxWidth(
                                          columnsFromOptionSettings,
                                          question.optionSettings?.size,
                                          // Border-> 4px, Padding outer options -> 4px
                                          8
                                      )
                                  }
                                : {}
                        }
                    >
                        {triangleLayout ? (
                            <React.Fragment>
                                <span>
                                    <li
                                        onClick={() =>
                                            this.toggleChoice(
                                                options[0].optionKey
                                            )
                                        }
                                        className={cx(`multi-options`, {
                                            textOnly,
                                            selected:
                                                this.state.selections[
                                                    options[0].optionKey
                                                ]
                                        })}
                                    >
                                        {options[0].pictureURL && (
                                            <img
                                                src={options[0].pictureURL}
                                                alt={getStringFromMultimediaTexts(
                                                    options[0].altTexts,
                                                    langId
                                                )}
                                            />
                                        )}

                                        {hideText
                                            ? ''
                                            : options[0].texts && (
                                                  <p>
                                                      {getStringFromMultimediaTexts(
                                                          options[0].texts,
                                                          langId
                                                      )}
                                                  </p>
                                              )}
                                    </li>
                                </span>

                                <span>
                                    <li
                                        onClick={() =>
                                            this.toggleChoice(
                                                options[1].optionKey
                                            )
                                        }
                                        className={cx(`multi-options`, {
                                            textOnly,
                                            selected:
                                                this.state.selections[
                                                    options[1].optionKey
                                                ]
                                        })}
                                    >
                                        {options[1].pictureURL && (
                                            <img
                                                src={options[1].pictureURL}
                                                alt={getStringFromMultimediaTexts(
                                                    options[1].altTexts,
                                                    langId
                                                )}
                                            />
                                        )}

                                        {hideText
                                            ? ''
                                            : options[1].texts && (
                                                  <p>
                                                      {getStringFromMultimediaTexts(
                                                          options[1].texts,
                                                          langId
                                                      )}
                                                  </p>
                                              )}
                                    </li>
                                    <li
                                        onClick={() =>
                                            this.toggleChoice(
                                                options[2].optionKey
                                            )
                                        }
                                        key={options[2].optionKey}
                                        className={cx(`multi-options`, {
                                            textOnly,
                                            selected:
                                                this.state.selections[
                                                    options[2].optionKey
                                                ]
                                        })}
                                    >
                                        {options[2].pictureURL && (
                                            <img
                                                src={options[2].pictureURL}
                                                alt={getStringFromMultimediaTexts(
                                                    options[2].altTexts,
                                                    langId
                                                )}
                                            />
                                        )}

                                        {hideText
                                            ? ''
                                            : options[2].texts && (
                                                  <p>
                                                      {getStringFromMultimediaTexts(
                                                          options[2].texts,
                                                          langId
                                                      )}
                                                  </p>
                                              )}
                                    </li>
                                </span>
                            </React.Fragment>
                        ) : (
                            options.map((option) => {
                                const selected =
                                    this.state.selections[option.optionKey];
                                const optionDisabled = option.disabled || false;

                                return (
                                    <li
                                        onClick={() =>
                                            !optionDisabled &&
                                            this.toggleChoice(option.optionKey)
                                        }
                                        onKeyDown={(e) => {
                                            if (
                                                e.key === KEY_NAME.SPACE ||
                                                e.key === KEY_NAME.ENTER
                                            ) {
                                                this.toggleChoice(
                                                    option.optionKey
                                                );
                                            }
                                        }}
                                        key={option.optionKey}
                                        className={cx(
                                            /* `flex-u flex-u-1-${columns} multi-options`, */
                                            {
                                                [`${
                                                    isCustomSizing
                                                        ? ` custom-sizing custom-sizing--${question.optionSettings?.size}`
                                                        : `pure-u-1-${columns}`
                                                } multiple-option text-image`]:
                                                    !textOnly,
                                                [`grid-u grid-u-1-${columns} multi-options`]:
                                                    textOnly,
                                                textOnly,
                                                selected,
                                                disabled: optionDisabled
                                            }
                                        )}
                                        tabIndex={0}
                                        role={'button'}
                                    >
                                        <div className="option">
                                            {!textOnly && (
                                                <React.Fragment>
                                                    <span
                                                        className={cx(
                                                            'option-img',
                                                            {
                                                                circle: isCircle
                                                            }
                                                        )}
                                                    >
                                                        <span className="position-relative multiple-option-tick">
                                                            <SVGTick
                                                                checked={
                                                                    !!selected
                                                                }
                                                                fill={'#0CC7B7'}
                                                            />
                                                        </span>
                                                        <div className="img-container">
                                                            <img
                                                                src={
                                                                    option.pictureURL
                                                                }
                                                                alt={getStringFromMultimediaTexts(
                                                                    option.altTexts,
                                                                    langId,
                                                                    option.label
                                                                )}
                                                            />
                                                        </div>
                                                    </span>
                                                </React.Fragment>
                                            )}

                                            {hideText ? (
                                                ''
                                            ) : option.texts ? (
                                                <OptionText
                                                    isRichText={
                                                        question.isRichText
                                                    }
                                                    className={cx(
                                                        'option-text'
                                                    )}
                                                    option={option}
                                                    langId={langId}
                                                />
                                            ) : (
                                                <p className="option-text">
                                                    {t(
                                                        'PLACEHOLDER.PLACEHOLDER_TEXT'
                                                    )}
                                                </p>
                                            )}
                                        </div>
                                    </li>
                                );
                            })
                        )}
                    </ul>
                    {showSpecify ? (
                        <SpecifyEditor
                            onSubmit={() =>
                                this.handleSubmitAnswer(
                                    Object.keys(this.state.selections),
                                    specifyText
                                )
                            }
                            specifyText={specifyText}
                            handleChangeSpecifyText={(specifyText) =>
                                this.setState({ specifyText })
                            }
                            showError={this.state.showError}
                            t={this.props.t}
                        />
                    ) : null}

                    <div className="bottom-select-links bot-width">
                        {this.validatorText(max, min)}
                        {showNotApplicableOption && (
                            <NotApplicableButton
                                onClick={() =>
                                    this.handleSubmitAnswer([
                                        `${question.id}_${NA_OPTION_VALUE}`
                                    ])
                                }
                                text={notApplicableText}
                            />
                        )}

                        <ContinueButton
                            onClick={() => {
                                this.handleSubmitAnswer(
                                    Object.keys(this.state.selections),
                                    specifyText
                                );
                            }}
                            disabled={disabledButton}
                            text={continueText}
                        />
                    </div>
                </div>
            );
        }
    }
}
