import * as React from 'react';
import * as classnames from 'classnames';
import * as _ from 'lodash';
import { BaseInput, BaseProps } from './_BaseInput';
import ContinueButton from '../components/ContinueButton';
import { getStringFromMultimediaTexts } from '@nexxt/common/services/TextService';
import VideoPlayer from '../components/VideoPlayer';
import {
    UilExpandArrowsAlt,
    UilCompressArrows
} from '@iconscout/react-unicons';

interface Props extends BaseProps {
    onAck: () => void;
    confirm: boolean;
}

interface State {
    allowContinue: boolean;
    soundCheck: boolean;
    isFullscreen: boolean;
}

export class Multimedia extends BaseInput<Props, State> {
    state: State = {
        // if video, wait for it to finish before continuing
        allowContinue: !this.props.question.playVideo,
        soundCheck: this.props.question.title === 'sound check' ? true : false,
        isFullscreen: false
    };

    componentDidMount() {
        // auto respond for multimedia without video nor confirmation
        if (
            !(
                this.props.question.showContinueButton ||
                this.props.question.playVideo
            )
        ) {
            this.props.onAck();
            return;
        }
        // allow continue if we're not waiting for video to finish
        if (!this.props.question.playVideo || this.state.soundCheck) {
            this.setState({ allowContinue: true });
        }
    }

    handleVideoComplete = () => {
        // enable continue button; or else auto-complete
        if (this.props.question.showContinueButton) {
            this.setState({ allowContinue: true });
        } else {
            this.props.onAck();
        }
    };

    render() {
        // Show continue button, if enabled — otherwise just
        const baseYoutubeURL =
            'https://www.youtube-nocookie.com/embed/5fIkNzorfOs';
        const youtubeParamString = '?controls=0&autoplay=1';
        const youtubeURL = baseYoutubeURL + youtubeParamString;
        const { langId, onAck, question } = this.props;
        const { continueTexts } = question;
        const continueText = getStringFromMultimediaTexts(
            continueTexts,
            langId
        );

        const mediaOptions = _.hasIn(
            this.props.question.optionSettings,
            'autoplay'
        )
            ? { autoplay: this.props.question?.optionSettings?.autoplay }
            : null;
        return (
            <React.Fragment>
                {false && ( // YOUTUBE PLAYER
                    // TODO: replace with `react-youtube` or `react-player` ??
                    // TODO: get to work with this.handleVideoComplete
                    <iframe
                        className="player"
                        style={{ marginBottom: '20px' }}
                        id="ik_player_iframe"
                        width="100%"
                        height="100%"
                        src={youtubeURL}
                        frameBorder="0"
                        allow="autoplay; encrypted-media;"
                    ></iframe>
                )}
                {this.props.question.playVideo && (
                    <VideoPlayer
                        videoURL={this.props.question.videoURL}
                        onEnded={this.handleVideoComplete}
                        mediaOptions={mediaOptions}
                    />
                )}
                {this.props.question?.multimediaSettings?.showEmbeddedURL && (
                    <div
                        className={classnames('embedded--browser', {
                            fullscreen: this.state.isFullscreen
                        })}
                    >
                        <span
                            className="embedded--browser--fullscreen-button"
                            onClick={() =>
                                this.setState((prevState) => ({
                                    isFullscreen: !prevState.isFullscreen
                                }))
                            }
                        >
                            {this.state.isFullscreen ? (
                                <UilCompressArrows size={30} />
                            ) : (
                                <UilExpandArrowsAlt size={30} />
                            )}
                        </span>
                        <iframe
                            className="player"
                            style={{ marginBottom: '20px' }}
                            id="ik_player_iframe"
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allow="autoplay; encrypted-media;"
                            src={this.props.question.embeddedURL}
                        ></iframe>
                    </div>
                )}
                {this.props.question.showContinueButton && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: '20px 0'
                        }}
                    >
                        <ContinueButton
                            disabled={!this.state.allowContinue}
                            onClick={onAck}
                            text={continueText}
                        />
                    </div>
                )}
            </React.Fragment>
        );

        // TODO** add video/youtube handling here??

        // if (this.props.question.text) {
        //   const texts = this.props.question.text.split("\n");
        //   const last = texts[texts.length - 1];
        //   if (last.indexOf("/video ") === 0) {
        //     const url = last.slice("/video ".length);
        //     return (
        //       <div className="video">
        //         <video width="536" controls
        //           controlsList="nodownload"
        //           autoPlay={true}
        //           onEnded={() => {
        //             this.props.onAck()  // "ack" designates confirmation of receipt (..?)
        //           }}
        //         >
        //           <source src={url} />
        //         </video>
        //         {this.props.confirm && confirm_button}
        //       </div>
        //     );
        //   } else if (last.indexOf("/youtube ") === 0) {
        //     const url = last.slice("/youtube ".length);
        //     return <div>
        //       <iframe id="ik_player_iframe" className="player" width="531px" height="299px"
        //         frameBorder="0"
        //         src={url}
        //         onEnded={() => {
        //           this.props.onAck()  // "ack" designates confirmation of receipt (..?)
        //         }}
        //       />
        //       {this.props.confirm && confirm_button}
        //     </div>
        //   } else if (last.indexOf("/image ") === 0) { // QTYPES.IMAGE
        //     const url = last.slice("/image ".length);
        //     return <div>
        //       <img className="image"
        //         src={url}
        //         onEnded={() => {
        //           this.props.onAck()  // "ack" designates confirmation of receipt (..?)
        //         }}
        //       />
        //       {this.props.confirm && confirm_button}
        //     </div>
        //   }
        // }
    }
}
