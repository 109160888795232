import * as React from 'react';
import * as classnames from 'classnames';
interface SpecifyEditorProps {
    specifyText: string;
    showError: boolean;
    handleChangeSpecifyText: (value: string) => void;
    onSubmit: () => void;
    t?: (key: string) => string;
}

const SpecifyEditor: React.FC<SpecifyEditorProps> = ({
    specifyText,
    showError,
    t,
    handleChangeSpecifyText,
    onSubmit
}: SpecifyEditorProps) => {
    return (
        <form
            className={classnames('specify--container', {
                error: showError && !specifyText.length
            })}
            onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
                return false;
            }}
        >
            <input
                type="text"
                placeholder={t('PLACEHOLDER.SPECIFY')}
                autoFocus
                onChange={(e) => handleChangeSpecifyText(e.target.value)}
                value={specifyText}
            />
        </form>
    );
};

export default SpecifyEditor;
