import * as React from 'react';
import { ImageLoadState, PropsWithOptionQ } from './_InputWithOptionImages';
import 'purecss/build/grids.css';
import '../style/MultiOptions.scss';
import * as cx from 'classnames';
import { MULTIMEDIA_TYPES, OptionData } from '@nexxt/common/types';
import { BaseInput } from './_BaseInput';
import {
    NPS_OVALUE,
    CUSTOM_OPTION_IDENTIFIER,
    KEY_NAME
} from '@nexxt/common/constants';
import { getStringFromMultimediaTexts } from '@nexxt/common/services/TextService';
const { translate } = require('react-i18next');

interface Props extends PropsWithOptionQ {
    handleAnswer: (val: OptionData) => void;
}

type State = {
    options: OptionData[];
};

@translate('translation')
export class NPS extends BaseInput<Props, State> {
    state = {
        options: []
    };
    componentDidMount() {
        const options: OptionData[] = [];
        for (let i = 0; i < 11; i++) {
            options.push({
                id: CUSTOM_OPTION_IDENTIFIER,
                value: `${NPS_OVALUE}/${i.toString()}`,
                label: i.toString(),
                texts: [
                    {
                        language: this.props.langId,
                        contents: [
                            { type: MULTIMEDIA_TYPES.TEXT, text: i.toString() }
                        ]
                    }
                ],
                optionKey: `${
                    this.props.question.id
                }_${NPS_OVALUE}/${i.toString()}`
            });
        }
        this.setState({
            options
        });
    }

    render() {
        const { question, t } = this.props;
        const { options } = this.state;

        if (!question) {
            return <div>{t('PLACEHOLDER.LOADING')}</div>;
        } else {
            const columns = 12;

            return (
                <div>
                    <ul className="multi-options-container multiple-options textOnly">
                        {options.map((o) => {
                            const text = getStringFromMultimediaTexts(
                                o.texts,
                                this.props.langId
                            );
                            return (
                                <li
                                    key={o.value}
                                    onClick={() => this.props.handleAnswer(o)}
                                    onKeyDown={(e) => {
                                        if (
                                            e.key === KEY_NAME.SPACE ||
                                            e.key === KEY_NAME.ENTER
                                        ) {
                                            this.props.handleAnswer(o);
                                        }
                                    }}
                                    className={`pure-u-1-${columns} multiple-option single-option textOnly`}
                                    tabIndex={0}
                                    role={'button'}
                                >
                                    <div className={'option'}>
                                        <p className={cx('option-text')}>
                                            {text}
                                        </p>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            );
        }
    }
}
