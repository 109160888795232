import * as React from 'react';
import { BaseInput, BaseProps } from './_BaseInput';

interface Props extends BaseProps {
    handleAnswer: () => void;
}

export class VirtualQuestion extends BaseInput<Props, unknown> {
    componentDidMount() {
        // auto respond for virtual question without answering the question
        this.props.handleAnswer();
    }

    render() {
        return null;
    }
}
