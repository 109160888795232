import * as React from 'react';
import styled from 'styled-components';
import { BASE_THEME } from '../style/BotThemes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { dataTestid } from '../constants/dataTestid';

const Button = styled.button`
    display: flex;
    background: grey;
    align-items: center;
    border: 1px solid grey;
    border-radius: ${BASE_THEME.borderRadius};
    color: white;
    padding: 0.5em;
    font-size: 14px;

    &:hover:not([disabled]) {
        background: ${(p) => p.theme.buttonBgHover || BASE_THEME.teal};
        color: ${BASE_THEME.white};
    }
    &:disabled {
        cursor: not-allowed;
    }
    svg {
        margin-right: 0.35em;
    }
    span {
        margin: auto;
    }
`;

interface Props {
    buttonClass?: string;
    disabled?: boolean;
    onClick: () => void;
    text: string;
    selected?: boolean;
}

const NotApplicableButton: React.SFC<Props> = (props) => {
    const { buttonClass, selected, disabled, onClick } = props;
    return (
        <Button
            className={
                buttonClass
                    ? buttonClass
                    : '' + ' ' + selected
                    ? 'selected'
                    : ''
            }
            disabled={disabled}
            onClick={onClick}
            data-testid={dataTestid.NABTN}
        >
            {/* uncomment to enable button
         *
         <FontAwesomeIcon icon={faBan} />
         */}
            <span>{props.text}</span>
        </Button>
    );
};

export default NotApplicableButton;
