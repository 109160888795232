import { STATIC_STORAGE_URL } from '@nexxt/common/constants';
import { QTYPES } from '@nexxt/common/types';
import { firstBucketOptionsDefault, secondBucketOptionsDefault } from './data';

export default {
    CARD: 'CARD',
    BOX: 'BANK',
    SMILEY: 'SMILEY',
    DIALOG: 'DIALOG'
};
export const INITIAL_JOURNEY_QUESTION = 'EMOJI';
export const ELEMENTS_PER_ROW = 4;

export const YDiff = 150;

export const DEFAULT_JOURNEY_SETTINGS = {
    // This should be handled by custom logic
    startpointImageUrl: '',
    startpointText: ' ',
    startpointImageType: 'SQUARE',
    // 'https://nexxt-inca-storage.s3.us-east-2.amazonaws.com/usr/7a0c9a76-6f77-4d4d-8274-c6f4a2a568bb_start.png',
    endpointImageUrl: `${STATIC_STORAGE_URL}/usr/0eaa0ffe-51af-498d-8897-d3f76d1e257e_toyota.png`,
    // 'https://nexxt-inca-storage.s3.us-east-2.amazonaws.com/usr/0534cc9d-3183-4a5a-acaa-75c7ce943832_end.png',
    midpoints: [],
    buckets: [
        {
            label: 'What you did',
            questionTexts: [
                'What did you do before you made the purchase? Please select all that apply.'
            ],
            options: firstBucketOptionsDefault,
            bgcolor: '#2D67CB',
            color: '#FFFFFF',
            allowNewOptions: true,
            persistOptions: true,
            instructions: [
                {
                    text: 'Drag and drop events onto the line. You may drag them more than once if they occurred multiple times',
                    style: {}
                }
            ],
            validationType: 'ALL'
        },
        {
            label: 'What occurred to you',
            questionTexts: [
                'Did you happen to see/hear something? Again, please select all that apply.'
            ],
            options: secondBucketOptionsDefault,
            // bgcolor: '#D89C23',
            // color: '#FFFFFF',
            bgcolor: '#FFD05A',
            color: '#000000',
            allowNewOptions: true,
            persistOptions: true,
            instructions: [
                {
                    text: 'Drag and drop events onto the line. You may drag them more than once if they occurred multiple times',
                    style: {}
                }
            ],
            validationType: 'ALL'
        }
    ],
    minSteps: 0,
    maxSteps: 16,
    followUpSettings: [
        {
            type: QTYPES.MULTIPLE_CHOICE,
            random: true,
            texts: 'What brands were involved?',
            options: [],
            preset: 'VEHICLE'
        },
        {
            type: QTYPES.MULTIPLE_CHOICE,
            random: true,
            texts: 'What emojis below best describe how you felt?',
            options: [],
            preset: 'EMOJI_10'
        },
        {
            type: QTYPES.OPEN,
            texts: 'Why did feel that way? What specifically at that step made you feel that way?'
        }
    ],
    journeyInstructionalTexts: [
        'Now let’s do an exercise. Could you drag all the things that you did/occurred to you before you bought the Toyota IN ORDER?',
        'Just think about it as the journey you went through before you made the purchase.'
        // 'This is how it works:'
    ],
    journeyConfirmTexts: [
        'Anything else to add? You may add other events by clicking “Other, specify”.',
        'Or, please click “Continue” to proceed and you will not be able to make any further changes after that.'
        // 'Was there anything else that you recall you can add? You may write down new items by clicking “Other, specify”.',
        // 'You will not be able to make any more changes after continuing.'
    ],
    instructionalMediaURL:
        'https://nexxt-inca-storage.s3.us-east-2.amazonaws.com/usr/04209b93-36ff-4374-893b-b5a48edf00a0_dnd-gif.gif'
};
