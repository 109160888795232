import * as React from 'react';
import {
    InputWithOptionImages,
    ImageLoadState,
    PropsWithOptionQ
} from './_InputWithOptionImages';
import ContinueButton from '../components/ContinueButton';
import styled from 'styled-components';
import {
    getStringFromMultimediaTexts,
    getTranslation
} from '@nexxt/common/services/TextService';
const { translate } = require('react-i18next');
import * as cx from 'classnames';

import { CanvasImage } from '../components/CanvasImage';
import { Stage, Layer, Text } from 'react-konva';

const AdContainer = styled.div`
    margin-left: -145px; // offsets the 290px bot margin
`;
const RankOption = styled.span`
    position: absolute;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    width: 25px;
    height: 25px;
    background: ${(p) => p.theme.userBubble};
    display: block;
    text-align: center;
    transform: translate(-50%, -100%);
    -webkit-transform: translate(-50%, -100%);
    -moz-transform: translate(-50%, -100%);
    border-radius: 50%;
    border: 1px solid #fff;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
`;

type Selections = string[];

interface Props extends PropsWithOptionQ {
    handleAnswer: (s: Selections, dataURL: string) => void;
}

interface State extends ImageLoadState {
    selections: Selections;
    image: HTMLImageElement | null;
    selectedTooMany: boolean;
}

@translate('translation')
export class AdBuilder extends InputWithOptionImages<Props, State> {
    state: State = {
        selections: [],
        imageLoadCount: 0,
        image: null,
        selectedTooMany: false
    };

    private stageRef;

    handleImage = (imageProps: HTMLImageElement) => {
        this.setState({
            image: imageProps
        });
    };

    toggleChoice = (numWords, val: string) => {
        const { langId, question } = this.props;
        const { options, max } = question;
        const index = this.state.selections.indexOf(val);

        this.setState({ selectedTooMany: false });

        if (index > -1) {
            // remove from selection
            const s = this.state.selections.slice();
            s.splice(index, 1);
            this.setState({ selections: s });
        } else {
            const selectedOptions = options.find(
                (option) => option.value == val
            );
            // only allow selection if max hasn't been reached
            const newText = getStringFromMultimediaTexts(
                selectedOptions.texts,
                langId
            );

            const newWords = newText.split(' ').length;

            if (numWords + newWords <= max) {
                const selections = this.state.selections.slice();
                selections.push(val);
                this.setState({ selections });
            } else {
                this.setState({ selectedTooMany: true });
            }
        }
    };

    render() {
        const { langId, t, question } = this.props;
        const AD_WIDTH = 1280;
        const AD_HEIGHT = 800;
        const TEXT_OFFSET_X = AD_WIDTH * 0.2;
        const TEXT_OFFSET_Y = AD_HEIGHT * 0.725;
        const TEXT_SIZE = 45;
        const TEXT_COLOUR = 'white';
        const TEXT_FONT = 'Arial Rounded MT Bold';
        const {
            options,
            optionsLayout: columns,
            continueTexts,
            min,
            max,
            mediaURL
        } = question;

        const continueText = getStringFromMultimediaTexts(
            continueTexts,
            langId
        );

        // TODO: what is happening here..?
        // Validation needs to be refactored!!
        const selectedText = this.state.selections
            .map((selection) => {
                const selectedOptions = options.find(
                    (option) => option.value == selection
                );
                return getStringFromMultimediaTexts(
                    selectedOptions.texts,
                    langId
                );
            })
            .join(' | ');
        const numWords = selectedText.replace(/ \|/g, '').split(' ').length;
        const s = pickStatus(numWords, min, max || options.length);
        const disabled = s !== PERFECT;

        // check if options are text-only
        const textOnly = options.every((o) => {
            return !o.pictureURL;
        });

        return (
            <React.Fragment>
                <AdContainer>
                    <Stage
                        width={AD_WIDTH}
                        height={AD_HEIGHT}
                        ref={(node) => {
                            this.stageRef = node;
                        }}
                    >
                        <Layer>
                            <CanvasImage
                                url={mediaURL}
                                onSelectImage={this.handleImage}
                                width={AD_WIDTH}
                                height={AD_HEIGHT}
                            />
                        </Layer>
                        <Layer>
                            <Text
                                x={TEXT_OFFSET_X}
                                y={TEXT_OFFSET_Y}
                                text={selectedText}
                                fontSize={TEXT_SIZE}
                                fontFamily={TEXT_FONT}
                                fill={TEXT_COLOUR}
                            />
                        </Layer>
                    </Stage>
                </AdContainer>

                <ul
                    className={cx(
                        `multi-options-container multiple-options ranking-order`,
                        {
                            'two-columns': textOnly && columns === 2,
                            [`pure-columns-${Math.min(columns, 6)}`]:
                                columns && !textOnly,
                            [`with-image`]: !textOnly
                        }
                    )}
                >
                    {options.map((option) => {
                        const index = this.state.selections.indexOf(
                            option.value
                        );
                        const selected = index > -1;
                        return (
                            <li
                                onClick={() =>
                                    this.toggleChoice(numWords, option.value)
                                }
                                key={option.id}
                                className={cx(`multiple-option`, {
                                    [`pure-u-1-${columns}`]: textOnly,
                                    'text-image': !textOnly,
                                    [`with-image--${
                                        question.optionSettings?.size || 'md'
                                    }`]: !textOnly,
                                    textOnly,
                                    'selected acitve': selected
                                })}
                            >
                                <div className="option">
                                    {selected && (
                                        <RankOption>{index + 1}</RankOption>
                                    )}
                                    <p className="option-text">
                                        {getStringFromMultimediaTexts(
                                            option.texts,
                                            langId
                                        )}
                                    </p>
                                </div>
                            </li>
                        );
                    })}
                </ul>

                <Validator
                    n={numWords}
                    min={min}
                    max={max || options.length}
                    tooMany={this.state.selectedTooMany}
                    t={t}
                />

                <div className="bottom-select-links ranking-order-btn bot-width">
                    <ContinueButton
                        onClick={() => {
                            const dataURL = this.stageRef
                                .getStage()
                                .toDataURL();
                            this.props.handleAnswer(
                                this.state.selections,
                                dataURL
                            );
                        }}
                        disabled={disabled}
                        text={continueText}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const PERFECT = 0;

function Validator({
    n,
    min,
    max,
    tooMany,
    t
}: {
    n: number;
    min: number;
    max: number;
    tooMany: boolean;
    t: (text: string, options?: Record<string, number>) => string;
}) {
    if (tooMany) {
        return (
            <div className="multi-choice-help warning">
                {getTranslation(t, 'LABEL.VALIDATE.SELECTED_TOO_MANY_WORDS')}
            </div>
        );
    }

    if (n === 0) {
        if (min === max) {
            return (
                <div className="multi-choice-help">
                    {getTranslation(t, 'LABEL.VALIDATE.SELECT_EXACTLY_WORDS', {
                        max
                    })}
                </div>
            );
        } else {
            return (
                <div className="multi-choice-help">
                    {getTranslation(t, 'LABEL.VALIDATE.SELECT_MIN_MAX_WORDS', {
                        min,
                        max
                    })}
                </div>
            );
        }
    } else if (min <= n) {
        if (n === max) {
            return (
                <div className="multi-choice-help">
                    {getTranslation(t, 'LABEL.VALIDATE.SATISFIED_WORDS')}
                </div>
            );
        } else {
            return (
                <div className="multi-choice-help">
                    {getTranslation(t, 'LABEL.VALIDATE.CONTINUE_OR_MORE_WORDS')}
                </div>
            );
        }
    } else {
        return (
            <div className="multi-choice-help">
                {getTranslation(t, 'LABEL.VALIDATE.MORE_WORDS', {
                    word_num: min - n
                })}
            </div>
        );
    }
}

function pickStatus(numPicks: number, min: number, max): number {
    if (numPicks < min) {
        return min - numPicks;
    } else if (numPicks > max) {
        return max - numPicks;
    } else {
        return PERFECT;
    }
}
