import * as React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw } from 'draft-js';
import {
    BMTYPES,
    UMTYPES,
    QTYPES,
    UserMessage,
    Message,
    UserTextMessageResponse,
    UserJourneySelectResponse,
    QuestionID,
    ReconnectionMessage,
    OPTION_TYPES
} from '@nexxt/common/types';
import * as cx from 'classnames';
import FireworksWrapper from './FireworksWrapper';
import * as _ from 'lodash';
import ReactLoading from 'react-loading';
import styled from 'styled-components';
import { BASE_THEME } from '../style/BotThemes';
import '../style/MessageBubble.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPencilAlt,
    faRedoAlt,
    faCheck,
    faTimes,
    faLaugh,
    faTired
} from '@fortawesome/free-solid-svg-icons';
import { FileIcon, VideoIcon } from './FileIcon';
import { getUniqueAnnotations } from '@nexxt/common/utils/question';
import {
    getCombinedMultimediaTextByLanguage,
    getPureStringFromHTML,
    getStringFromMultimediaTexts,
    getTranslation
} from '@nexxt/common/services/TextService';
import { MessageLoader } from './MessageLoader';
import { ReactLightBox } from './LightBoxImage';
import ReactTooltip from 'react-tooltip';
import { KEY_NAME, NA_OPTION_VALUE } from '@nexxt/common/constants';
import VideoPlayer from './VideoPlayer';

const { translate } = require('react-i18next');

const EDIT_TEXT = <FontAwesomeIcon icon={faPencilAlt} />;
const REDO_TEXT = <FontAwesomeIcon icon={faRedoAlt} />;
const CHECK_TEXT = <FontAwesomeIcon icon={faCheck} />;
const CANCEL_TEXT = <FontAwesomeIcon icon={faTimes} />;
const SUCCESS_TEXT = <FontAwesomeIcon icon={faLaugh} />;
const FAIL_TEXT = <FontAwesomeIcon icon={faTired} />;
const enum EDIT_MODES {
    EDITED = 'EDITED',
    EDITING = 'EDITING',
    NONE = 'NONE',
    DISABLED = 'DISABLED'
}

// acceptable file extensions
const IMAGE_EXTS = ['png', 'jpg', 'jpeg', 'gif'];
const DOC_EXTS = ['pdf', 'doc', 'psd'];
const VIDEO_EXTS = ['mp4', 'mov', 'flv', 'webm'];
const AUDIO_EXTS = ['mp3', 'wav'];

const SMALL_IMG_QIDS = [
    5800, 5807, 5812, 6596, 17361, 17362, 17363, 17364, 17366, 17367, 17368,
    17369, 17371, 17372, 17373, 17374, 17376, 17377, 17378, 17379, 17253, 17258,
    17274, 17380, 17381, 17382, 17383, 17384, 26707, 26711
];
const BIG_IMG_QIDS = [
    17282, 17360, 17365, 17370, 17375, 5481, 5584, 5601, 5625, 5642, 5655, 5674,
    5690, 5713, 5729
];

const MsgBubble = styled.div`
    word-break: break-word;
    display: flex;
    align-items: flex-start;
    padding-bottom: 1.5em;
    animation: animationFrames 2s;
    animation-iteration-count: 1;
    overflow-x: hidden;
    .content-wrapper {
        display: flex;
        align-items: center;
        &.user {
            margin-left: auto;
            margin-right: 10px;
            text-align: right;
            flex-flow: row wrap;
            justify-content: flex-end;
            .button-wrapper {
                display: flex;
                align-items: center;
                text-align: center;
                width: 25px;
                height: 25px;
                background-color: rgba(255, 255, 255, 0.4);
                border-radius: 50%;
                justify-content: center;
                margin-right: 10px;
                cursor: pointer;
                &.disabled {
                    opacity: 0.7;
                    pointer-events: none;
                    cursor: not-allowed;
                }
                button {
                    background: transparent;
                    border: none;
                    padding: 0;
                    color: ${(p) => p.theme.userEdit};
                    &:disabled {
                        cursor: not-allowed;
                    }
                }
                &:hover {
                    background-color: rgba(229, 229, 229, 0.9);
                    button {
                        color: ${(p) => p.theme.userEditHover};
                    }
                }
                &.type--cancel {
                    background-color: rgba(229, 229, 229, 0.9);
                    button {
                        color: #c54e76;
                    }
                    &:hover {
                        background-color: rgba(200, 200, 200, 0.9);
                    }
                }
                &.type--confirm {
                    background-color: rgba(229, 229, 229, 0.9);
                    &.disabled {
                        opacity: 0.7;
                        cursor: not-allowed;
                    }
                    button {
                        color: #349972;
                    }
                    &:hover {
                        background-color: rgba(200, 200, 200, 0.9);
                    }
                }
            }
            .content-wrapper--breaker {
                width: 100%;
                height: 0;
            }
            .button-tooltip {
                background-color: #4f4f4f;
                border-radius: 2px;
                color: #fff;
                font-weight: 700;
                height: 30px;
                font-size: 14px;
                padding: 5px 15px;
                opacity: 0.8;
            }
            .content-edit-message {
                font-weight: bold;
                font-size: 10px;
                margin-bottom: 0;
                color: ${(p) => p.theme.multiOptionText};
            }
        }
        &.bot {
            white-space: pre-line;
            margin-left: 60px;
            margin-right: 10px;
        }
    }
    .content {
        line-height: 1.5;
        box-shadow: ${(p) => p.theme.bubbleShadow};
        min-height: 25px;
        max-width: 750px;
        padding: 8px 20px;
        &.clickable {
            cursor: pointer;
        }
        &.selected {
            box-shadow: 0px 1px 1px 0px #ffa333;
        }
        img {
            max-height: 320px;
            max-width: 100%;
        }
        .video iframe {
            border: 0;
        }
        audio,
        video {
            max-width: 100%;
        }
        audio + h1,
        video + h1 {
            margin-top: 11px;
        }
    }
    .from-user {
        background-color: ${(p) => p.theme.userBubble};
        color: white;
        letter-spacing: 0.1px;
        display: flex;
        animation: slideFromRight 0.5s;
        border-radius: 25px 25px 0 25px;

        /* flex-wrap: wrap; */
        &.editing {
            padding: 5px;
            textarea {
                background-color: ${(p) => p.theme.userBubbleEditing};
                border-radius: 25px 25px 0px;
                border: none;
                color: white;
                padding: 20px 15px;
                width: 100%;
            }
        }
        .format-plain {
            &.display-flex {
                display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
                display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
                display: -ms-flexbox; /* TWEENER - IE 10 */
                display: -webkit-flex; /* NEW - Chrome */
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                flex-direction: row;
            }

            &.open-end {
                &.display-flex {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
            .format-plain-icon {
                width: 35px;
                margin-right: 10px;
                img {
                    width: 100%;
                    border-radius: 100%;
                    background-color: white;
                }
            }

            &.multiple {
                margin-bottom: -10px;
                justify-content: left;
                .format-plain-single {
                    margin-right: 10px;
                    margin-bottom: 10px;
                }
            }
            .format-plain-single {
                &.display-flex {
                    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
                    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
                    display: -ms-flexbox; /* TWEENER - IE 10 */
                    display: -webkit-flex; /* NEW - Chrome */
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
    .from-bot {
        border-radius: 25px 25px 25px 0;
        background-color: white;
        color: #111;
        white-space: pre-line;
        margin-right: 10px;
        animation: ${(p: { type }) =>
            p.type === BMTYPES.TYPING ? 'slideFromLeft 0.5s' : ''};
        .connection-status {
            display: inline-grid;
            margin-left: 5px;
            &.success {
                color: #349a71;
            }
            &.fail {
                color: #c54e76;
            }
        }
        &.system {
            background-color: #d9d9d9;
        }
    }
    .avatar--wrapper {
        border-radius: 50%;
        background: ${(p) => p.theme.avatarBgColor};
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        animation: ${(p: { type }) =>
            p.type === BMTYPES.TYPING ? 'slideFromLeft 0.5s' : ''};
        margin-left: 5px;
    }
    .avatar {
        max-width: 40px;
        height: auto;
    }
    .download-link {
        display: flex;
        a {
            font-size: 20px;
            margin: 0 10px 0 0;
            color: #fff;
        }
        em {
            font-style: normal;
        }
    }

    @keyframes slideFromRight {
        0% {
            transform: translateX(100%);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes slideFromLeft {
        0% {
            transform: translateX(-100%);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }
`;

const BigImg = styled.img`
    margin: 0 auto;
    display: block;
    border-radius: ${BASE_THEME.borderRadius};
    width: auto;
    max-width: 60%;
    padding: 10px 0;
    cursor: pointer;
    max-height: 70vh;
    object-fit: contain;
    &.media-size--sm {
        width: 200px;
    }
    &.media-size--md {
        width: 300px;
    }
    &.media-size--lg {
        width: 600px;
    }
    &.media-size--full {
        max-width: 100%;
        width: 100%;
    }
`;

const CustomSmallImg = styled.img`
    margin: 0 auto;
    display: block;
    border-radius: ${BASE_THEME.borderRadius};
    width: auto;
    max-width: 30%;
    padding: 10px 0;
    cursor: pointer;
`;

const CustomGiantImg = styled.img`
    margin: 0 auto;
    display: ${(p: { show: boolean }) => (p.show ? 'block' : 'none')};
    border-radius: ${BASE_THEME.borderRadius};
    width: auto;
    max-width: 100%;
    padding: 10px 0;
    cursor: pointer;
`;
const CroppedImage = styled.img`
    margin: 0 auto;
    display: block;
    border-radius: ${BASE_THEME.borderRadius};
    width: 60%;
    paading: 10px 0;
    position: absolute;
`;

interface MessageBubbleProps {
    data: Message;
    avatar: string;
    showEdit: boolean;
    disableEdit?: boolean;
    langId: string;
    currentQuestionID: QuestionID;
    onRedoRequest: (questionValue: string, questionID: QuestionID) => void;
    editTextAnswer: (userMessage, newText: string) => Promise<void>;
    t?: (text: string, options?: Record<string, number>) => string; // translate function
    isMsgBlocked: boolean;
}

interface MessageBubbleState {
    openLightBox: boolean;
    editMode: EDIT_MODES;
    answerText: string;
    submitTextAnswerDisabled: boolean;
    hideContent: boolean;
}
@translate('translation')
export default class MessageBubble extends React.Component<
    MessageBubbleProps,
    MessageBubbleState
> {
    state: MessageBubbleState = {
        openLightBox: false,
        editMode: EDIT_MODES.NONE,
        answerText: '',
        submitTextAnswerDisabled: false,
        hideContent: false
    };
    messageBubbleContainer: HTMLDivElement;
    componentDidMount() {
        if (this.props.data.type === UMTYPES.TEXT) {
            this.setState({
                answerText: this.props.data.text
            });
        }
    }
    // BMTYPES.IMAGE - toggle for showing image light box
    toggleLightBox = () => {
        this.setState({
            openLightBox: !this.state.openLightBox
        });
    };

    // UMTYPES.TEXT - onChange answerText state as respondent changes on the textarea field
    // Disable submit button when textArea is empty or nothing has editted.
    changeTextAnswer = (e) => {
        const { answerText } = this.state;
        const newAnswer = e.target.value;
        const disable = !newAnswer.trim().length || answerText === newAnswer;
        this.setState({
            answerText: e.target.value,
            submitTextAnswerDisabled: disable
        });
    };

    // UMTYPES.TEXT - allow a respondent to submit/cancel their editing with keyboard
    // ENTER - submit, ESCAPE - cancel editing
    handleKeyPressTextArea = (e) => {
        switch (e.key) {
            case KEY_NAME.ENTER:
                !this.state.submitTextAnswerDisabled && this.submitTextAnswer();
                break;
            case KEY_NAME.ESCAPE:
                this.cancelEditTextAnswer();
                break;
        }
    };

    // UMTYPES.TEXT - onClick cancel button to edit the previous text answer
    // Chagne edit mode to NONE and reset the textarea to original answer text
    cancelEditTextAnswer = () => {
        this.setState({
            answerText: (this.props.data as UserTextMessageResponse).text,
            editMode: EDIT_MODES.NONE
        });
    };

    // UMTYPES.TEXT - onClick the edit(pencil) button to enter the editing mode.
    // Change edit mode to EDITING
    enableEditingTextAnswer = () => {
        this.setState({
            editMode: EDIT_MODES.EDITING
        });
    };

    // UMTYPES.TEXT - onClick the submit(checkmark) button to override the original answer
    // Change edit mode to EDITED
    submitTextAnswer = () => {
        const { data: message } = this.props;
        this.props.editTextAnswer(
            message as UserMessage,
            this.state.answerText
        );

        this.setState({
            editMode: EDIT_MODES.EDITED
        });
    };

    render() {
        const { editMode, answerText, submitTextAnswerDisabled } = this.state;
        const { t, data, showEdit, disableEdit, langId, isMsgBlocked } =
            this.props;
        const message = data as any;
        const correctedMsgType = isMsgBlocked ? BMTYPES.TYPING : message.type;
        const isOpenEnded = message.type === UMTYPES.TEXT;

        let core: JSX.Element;
        switch (correctedMsgType) {
            case BMTYPES.RICH_TEXT:
                core = (
                    <div>
                        <span className="format-plain">
                            <div className="readonly-editor">
                                <Editor
                                    editorState={EditorState.createWithContent(
                                        convertFromRaw(message.text)
                                    )}
                                    toolbar={{ options: [], inline: [] }}
                                    readOnly={true}
                                />
                            </div>
                        </span>
                    </div>
                );
                break;
            case BMTYPES.TEXT:
            case BMTYPES.SYSTEM:
                core = (
                    <div>
                        <span className="format-plain">
                            <span>{message.text}</span>
                        </span>
                    </div>
                );
                break;

            case BMTYPES.RECONNECTION:
                const connectionStauts = (message as ReconnectionMessage)
                    .status;
                let status;
                switch (connectionStauts) {
                    case 'reconnecting':
                        status = (
                            <ReactLoading
                                type="spinningBubbles"
                                color="#2d67cb"
                                width={20}
                                height={20}
                            />
                        );
                        break;
                    case 'success':
                        status = SUCCESS_TEXT;
                        break;
                    case 'fail':
                        status = FAIL_TEXT;
                        break;
                }

                core = (
                    <div>
                        <span className="format-plain">
                            <span>
                                {message.text}
                                <span
                                    className={`connection-status ${connectionStauts}`}
                                >
                                    {status}
                                </span>
                            </span>
                        </span>
                    </div>
                );
                break;
            case BMTYPES.PRELOADER:
                // show invisible imgs for media preloader
                return message.urls.map((url, i) => (
                    <img key={i} style={{ display: 'none' }} src={url} />
                ));
                break;
            case BMTYPES.AUDIO:
                return (
                    <div
                        className="audio-wrapper"
                        style={{ position: 'relative' }}
                    >
                        <audio controls autoPlay>
                            <source src={message.url} />
                        </audio>
                    </div>
                );
                break;
            case BMTYPES.VIDEO:
                return (
                    <VideoPlayer
                        videoURL={message.url}
                        mediaOptions={message?.mediaOptions}
                    />
                );
                break;
            case BMTYPES.IMAGE:
                //for hotspot question
                const size = message?.mediaOptions?.size?.toLowerCase();
                if (message.coordinates) {
                    // For Hotspot's followup questions, image width will be calculated based on the width of the message wrapper
                    const imageWidth =
                        this.messageBubbleContainer &&
                        this.messageBubbleContainer.offsetWidth * 0.6;

                    // Calculate the ratio between the original image and rendered image size to find the right coordinates;
                    const ratio =
                        imageWidth && imageWidth / message.question.width;

                    const width = message.coordinates.width * ratio;
                    const height = message.coordinates.height * ratio;
                    const x = message.coordinates.x * ratio;
                    const y = message.coordinates.y * ratio;
                    return (
                        <div
                            className="wc-message-wrapper"
                            style={{
                                height: `${height}px`,
                                position: 'relative',
                                overflow: 'hidden'
                            }}
                            ref={(ref) => (this.messageBubbleContainer = ref)}
                        >
                            <div
                                className="wrapper"
                                style={{
                                    position: 'relative',
                                    height: '100%',
                                    transform: 'translateX(50%)',
                                    left: `calc(-${width}px / 2)`
                                }}
                            >
                                <CroppedImage
                                    className={'bot-cropped-image'}
                                    src={message.url}
                                    alt={message.altText}
                                    style={{
                                        clip: `rect(${y}px ${width + x}px ${
                                            height + y
                                        }px ${x}px)`,
                                        top: `-${y}px`,
                                        left: `-${x}px`
                                    }}
                                />
                            </div>
                        </div>
                    );
                }

                const followUpImages =
                    message?.question?.value.includes('followUpTextsLike') ||
                    message?.question?.value.includes('followUpTextsDislike');
                return !this.state.hideContent ? (
                    <div
                        className="wc-message-wrapper"
                        style={{ position: 'relative' }}
                    >
                        <span className="format-plain">
                            {this.state.openLightBox && (
                                <ReactLightBox
                                    imageURL={message.url}
                                    closeLightBox={this.toggleLightBox}
                                />
                            )}
                            <BigImg
                                className={cx(
                                    message.altText == 'greeting'
                                        ? 'bot-big-image greeting'
                                        : 'bot-big-image',
                                    {
                                        [`media-size--${size}`]: !!size
                                    }
                                )}
                                title={t('LABEL.INSTRUCTIONS.IMAGE_EXPAND')}
                                onClick={this.toggleLightBox}
                                src={message.url}
                                alt={message.altText}
                                onLoad={() => {
                                    if (
                                        message?.question?.multimediaSettings
                                            ?.disappearingMedia
                                    ) {
                                        setTimeout(() => {
                                            this.setState({
                                                hideContent: true
                                            });
                                        }, message?.question?.multimediaSettings?.disappearingOffsetMilliseconds);
                                    }
                                }}
                            />
                        </span>
                    </div>
                ) : null;
            case BMTYPES.LANGUAGE_PROMPT:
            case BMTYPES.RESUME_PROMPT:
            case BMTYPES.QUESTION: {
                return null;
                //Do nothing for now because the prompt will handle them
            }
            case BMTYPES.TYPING:
                core = <MessageLoader />;
                break;
            case UMTYPES.LANGUAGE_SELECTION:
                core = (
                    <FireworksWrapper text={message.selectedLanguageLabel} />
                );
                break;
            case UMTYPES.RESUME_SELECTION:
                core = (
                    <FireworksWrapper
                        text={
                            message.resume
                                ? getTranslation(t, 'BUTTON.SESSION.RESUME')
                                : getTranslation(t, 'BUTTON.SESSION.RESTART')
                        }
                    />
                );
                break;
            case UMTYPES.SINGLE_SELECTION:
                if (
                    message.questionType === QTYPES.TREEMAN &&
                    message.dataURL
                ) {
                    core = <img src={message.dataURL} />;
                    break;
                }
                if (message.selectedOption.value === NA_OPTION_VALUE) {
                    core = (
                        <FireworksWrapper
                            text={getPureStringFromHTML(
                                getStringFromMultimediaTexts(
                                    message.selectedOption.texts,
                                    langId,
                                    message.selectedOption.label
                                )
                            )}
                        />
                    );
                } else {
                    core = (
                        <FireworksWrapper
                            text={
                                message.selectedOption?.specify ||
                                getPureStringFromHTML(
                                    getStringFromMultimediaTexts(
                                        message.selectedOption.texts,
                                        langId,
                                        message.selectedOption.label
                                    )
                                )
                            }
                            selectedOptionType={message.selectedOptionType}
                            pictureURL={message.selectedOption.pictureURL}
                        />
                    );
                }
                break;
            //case UMTYPES.MULTIPLE_POPUP:
            case UMTYPES.MULTI_SELECTION:
                // The answer message bubble for QTYPES.VIRTUAL won't show up
                if (message.questionType === QTYPES.VIRTUAL) {
                    return null;
                }
                if (message.questionType === QTYPES.HOTSPOT) {
                    core = <img src={message.dataURL} />;
                    break;
                }
                if (message.questionType === QTYPES.TEXT_HIGHLIGHTER) {
                    const uniqueAnnotations = getUniqueAnnotations(
                        message.selectedOptions
                    );

                    const annotationsStr = t('QUESTION.ANSWER.ANNOTATIONS', {
                        annotation_num: uniqueAnnotations.length
                    });
                    core = <FireworksWrapper text={annotationsStr} />;
                    break;
                }
                if (message.questionType === QTYPES.TEXT_HIGHLIGHTER_READONLY) {
                    core = <FireworksWrapper text="Continue" />;
                    break;
                }
                // Check if rapid choice use fireworks wrapper
                if (message.questionType === QTYPES.RAPID_CHOICE) {
                    const isEmpty =
                        message.selectedOptions[0].value.split('/')[2] ===
                        'NOTAPPLICABLE';
                    core = isEmpty ? (
                        <FireworksWrapper text="None selected" />
                    ) : (
                        <FireworksWrapper
                            useOptions={true}
                            options={message.selectedOptions}
                            selectedOptionType={message.selectedOptionType}
                            langId={langId}
                            text={''}
                        />
                    );
                    break;
                }
                core = (
                    <FireworksWrapper
                        useOptions={true}
                        options={message.selectedOptions}
                        selectedOptionType={message.selectedOptionType}
                        langId={langId}
                        text={''}
                    />
                );
                break;
            case UMTYPES.TEXT:
                if (editMode === EDIT_MODES.EDITING) {
                    core = (
                        <textarea
                            rows={3}
                            defaultValue={message.text}
                            value={answerText}
                            onChange={this.changeTextAnswer}
                            onKeyDown={this.handleKeyPressTextArea}
                        />
                    );
                    break;
                }

                core = (
                    <div className="format-plain open-end display-flex flex-column">
                        <div className="media-list">
                            {message.uploadURLs.map((o, ind) => {
                                const fileExtension = o.thumb.substring(
                                    o.thumb.lastIndexOf('.') + 1
                                );
                                if (IMAGE_EXTS.includes(fileExtension)) {
                                    return (
                                        <div key={ind} className="media-image">
                                            <img src={o.thumb} />
                                        </div>
                                    );
                                } else if (DOC_EXTS.includes(fileExtension)) {
                                    return (
                                        <div key={ind} className="media-file">
                                            <FileIcon type={fileExtension} />
                                        </div>
                                    );
                                } else if (VIDEO_EXTS.includes(fileExtension)) {
                                    return (
                                        <div key={ind} className="media-video">
                                            <VideoIcon />
                                        </div>
                                    );
                                } else if (AUDIO_EXTS.includes(fileExtension)) {
                                    return (
                                        <div key={ind} className="media-audio">
                                            <VideoIcon />
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </div>
                        <span className="open-end-text">
                            <FireworksWrapper text={answerText} />
                        </span>
                    </div>
                );
                break;
            case UMTYPES.MAP:
                core = <FireworksWrapper text="🗺️" fontSize={30} />;
                break;
            case UMTYPES.VECTOR_MAP:
                core = (
                    <FireworksWrapper
                        text={getStringFromMultimediaTexts(
                            message.region.texts,
                            this.props.langId,
                            message.region?.value
                        )}
                    />
                );
                break;
            case UMTYPES.DEPARTING:
            case UMTYPES.ACK:
            case UMTYPES.POPUP:
            case UMTYPES.MULTIPLE_POPUP:
                return null;
            case UMTYPES.JOURNEY_SELECT:
                const selectedStepText =
                    (message as UserJourneySelectResponse).journeySteps.find(
                        (s) => s.isSelected
                    )?.text || t('QUESTION.ANSWER.NA_JOURNEY_SELECT');
                core = <FireworksWrapper text={selectedStepText} />;
                //TODO: text should be dynamic
                break;
            //   core = <FireworksWrapper text={message.value} />;
            //   break;
            case UMTYPES.NUMERIC:
                core = <FireworksWrapper text={message.formattedValue} />;
                break;
            case UMTYPES.ICONS_RATING:
                core = (
                    <FireworksWrapper
                        text={
                            // TODO: handle all icon types, not just hearts
                            _.isNumber(message.answer.label)
                                ? _.repeat('❤', message.answer.label)
                                : message.answer.label
                        }
                    />
                );
                break;
            case UMTYPES.TRADE_OFF:
                const favourite = message && message.value;
                core = (
                    <FireworksWrapper
                        text={
                            favourite.value === NA_OPTION_VALUE
                                ? t('QUESTION.ANSWER.NA_TRADE_OFF')
                                : message.optionType === OPTION_TYPES.TEXT_ONLY
                                ? getCombinedMultimediaTextByLanguage(
                                      message.value.texts,
                                      this.props.langId
                                  )
                                : ''
                        }
                        pictureURL={(favourite && favourite.pictureURL) || ''}
                        selectedOptionType={message?.optionType}
                    />
                );
                break;
            case UMTYPES.AD_BUILDER:
            case UMTYPES.RATE_IMAGE:
                core = <img src={message.dataURL} />;
                break;
            case UMTYPES.JOURNEY:
                core = (
                    <FireworksWrapper
                        text={`${message.journeySteps.length} steps`}
                    />
                    // <img src={message.dataURL} width="300px" height="57px" />
                );
                break;
            case UMTYPES.VIDEO_ANNOTATION:
                core = (
                    <FireworksWrapper
                        text={`${String(message.annotations.length)} comments`}
                    />
                );
                break;
            case BMTYPES.TERMINATE:
                return null;
            default:
                console.error(message);
                throw 'unsupported type:: ' + JSON.stringify(message);
        }
        const userMessage = message as UserMessage;

        return (
            <>
                <MsgBubble type={message.type}>
                    {message.from === 'bot' && (
                        <div className="avatar--wrapper">
                            <img
                                className="avatar"
                                src={this.props.avatar}
                                // altText="Bot avatar "
                            />
                        </div>
                    )}
                    <div
                        className={cx(
                            'content-wrapper',
                            {
                                user: 'user',
                                bot: 'bot'
                            }[message.from]
                        )}
                    >
                        {editMode === EDIT_MODES.EDITED && (
                            <span className="content-edit-message">
                                ({t('BUTTON.EDITED')})
                            </span>
                        )}
                        {showEdit && editMode === EDIT_MODES.NONE && (
                            <>
                                <div
                                    className={cx('button-wrapper', {
                                        disabled: disableEdit
                                    })}
                                    data-tip
                                    data-for={`message-${message.uid}`}
                                    onClick={(val) => {
                                        this.props.onRedoRequest(
                                            userMessage.questionValue,
                                            userMessage.questionId
                                        );
                                        this.setState({
                                            editMode: EDIT_MODES.DISABLED
                                        });
                                    }}
                                >
                                    <button
                                        title="redo the previous question..."
                                        disabled={disableEdit}
                                    >
                                        {REDO_TEXT}
                                    </button>
                                </div>
                                <ReactTooltip
                                    id={`message-${message.uid}`}
                                    className="button-tooltip"
                                    aria-haspopup="true"
                                    effect="solid"
                                    place="left"
                                    arrowColor="transparent"
                                >
                                    {t('BUTTON.UNDO')}
                                </ReactTooltip>
                            </>
                        )}
                        {/*
                        // Temporarily disable oe editing 
                         {!showEdit &&
                            isOpenEnded &&
                            ![EDIT_MODES.EDITING, EDIT_MODES.DISABLED].includes(
                                editMode
                            ) && (
                                <>
                                    <div
                                        className="button-wrapper"
                                        onClick={this.enableEditingTextAnswer}
                                    >
                                        <button title="Edit your answer...">
                                            {EDIT_TEXT}
                                        </button>
                                    </div>
                                    <ReactTooltip
                                        id={`message-${message.uid}`}
                                        className="button-tooltip"
                                        aria-haspopup="true"
                                        effect="solid"
                                        place="left"
                                        arrowColor="transparent"
                                    >
                                        {t('BUTTON.EDIT')}
                                    </ReactTooltip>
                                </>
                            )} */}
                        {editMode === EDIT_MODES.EDITING && (
                            <>
                                <div
                                    className="button-wrapper type--cancel"
                                    onClick={this.cancelEditTextAnswer}
                                >
                                    <button title="Edit your answer...">
                                        {CANCEL_TEXT}
                                    </button>
                                </div>
                                <div
                                    className={cx(
                                        'button-wrapper type--confirm',
                                        {
                                            disabled: submitTextAnswerDisabled
                                        }
                                    )}
                                    onClick={this.submitTextAnswer}
                                >
                                    <button
                                        title="Edit your answer..."
                                        disabled={submitTextAnswerDisabled}
                                    >
                                        {CHECK_TEXT}
                                    </button>
                                </div>
                            </>
                        )}
                        <div
                            className={cx(
                                'content',
                                {
                                    user: 'from-user',
                                    bot: 'from-bot'
                                }[message.from],
                                {
                                    editing: editMode === EDIT_MODES.EDITING
                                },
                                {
                                    system: message.type === BMTYPES.SYSTEM
                                }
                            )}
                            tabIndex={0}
                            aria-live={'polite'}
                        >
                            {core}
                        </div>
                        {editMode === EDIT_MODES.EDITING && (
                            <>
                                <div className="content-wrapper--breaker"></div>
                                <p className="content-edit-message">
                                    (
                                    {t(
                                        'LABEL.INSTRUCTIONS.EDITING_INSTRUCTION'
                                    )}
                                    )
                                </p>
                            </>
                        )}
                    </div>
                </MsgBubble>
            </>
        );
    }
}
