// Core react imports
import * as React from 'react';
import * as ReactDOM from 'react-dom';

// i18n
const { translate } = require('react-i18next');

// Styling imports
import './style/normalize.css';
import './style/styles.css';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles, BOT_THEMES } from './style/BotThemes';
import CloudTheme from './components/themes/CloudTheme';
import DotsAndTrianglesTheme from './components/themes/DotsAndTrianglesTheme';
import FloatingSquaresTheme from './components/themes/FloatingSquaresTheme';
import PurpleSkyTheme from './components/themes/PurpleSkyTheme';
import DigitalAvatar from './components/DigitalAvatar';
import 'draft-js/dist/Draft.css';

// Various utils
import { v4 as uuid } from 'uuid';
import { deviceType, isAndroid } from 'react-device-detect';
import * as _ from 'lodash';
import axios from 'axios';
// React Progress
import { Progress } from 'react-sweet-progress';
import 'react-sweet-progress/lib/style.css';

// React Typist
import Typist from 'react-typist';

// Pageloader
import { MessageLoader } from './components/MessageLoader';

// Nexxt constants, types, services, and utils
import RollbarHOC from './components/HOC/Rollbar';
import {
    BMTYPES,
    UMTYPES,
    TTYPES,
    EDITABLE_UMTYPES,
    QUOTA_STATUS,
    BOT_MODES,
    SPECIAL_QIDS,
    BotMessage,
    Message,
    UserMessage,
    QuestionMessage,
    TextMessage,
    Task,
    ProjectInfo,
    QuestionTask,
    BOT_TERMINATE_TYPES,
    Answer,
    SOCKET_EVENTS,
    WEB_SOCKET_CONNECTION_TYPES,
    ReconnectionMessage,
    BOT_CONNECTION_MODE,
    QTYPES,
    INVALID_ACCESS_TYPES,
    Panel,
    CONN_STATUS,
    SecurityMode,
    SvcEventData
} from '@nexxt/common/types';
import {
    SESSION_ID_KEY,
    STATIC_STORAGE_URL,
    ENGLISH,
    MIN_PAGELOADER_DURATION,
    KEY_NAME,
    INCA_LOGO_URL,
    SECOND_IN_MILLISECOND,
    DEFAULT_REDIRECT_MSG,
    SVC_NAMESPACE_TYPE,
    SVC_EVENT_TYPE,
    SVC_EVENT_NAME
} from '@nexxt/common/constants';
import { findLastIndex, sleep, isTrue } from '@nexxt/common/utils/index';
import * as textService from '@nexxt/common/services/TextService';
import * as messageService from './services/MessageService';
import { BotConnection, SocketConnect } from './services/BotConnector';
import { i18n } from './services/i18n';
import Tracker from '@openreplay/tracker';

// Bot components
import QuestionSwitch from './QuestionSwitch';
import MessageBubble from './components/MessageBubble';
import BlockModal from './components/BlockModal';
import PageNotSupported from './components/PageNotSupported';
import PageNotFound from './components/PageNotFound';
import Banner from './components/Banner';
import { AudioVisualizer } from './components/AudioVisualizer';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Component styles
const FOOTER_HEIGHT = 40;
const BotContainer = styled.div`
    position: relative;
    overflow: hidden;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    min-height: calc(100vh - 110px);
    @media (max-height: 400px) {
        min-height: 100%;
    }
`;

const ProgressContainer = styled.div`
    .react-sweet-progress-line,
    .react-sweet-progress-line-inner {
        min-height: 6px;
        border-radius: 0;
    }
    .react-sweet-progress {
        position: absolute;
        top: 0;
        right: 15px;
        .react-sweet-progress-symbol {
            display: none;
        }
    }
    @media (max-width: 1320px) {
        .react-sweet-progress-circle-outer {
            display: none;
        }
    }
    @media (min-width: 1320px) {
        .react-sweet-progress-circle-outer {
            position: absolute;
            right: 2.5em;
            bottom: 2.5em;
        }
        .react-sweet-progress {
            display: none;
        }
    }
    .react-sweet-progress-symbol {
        color: ${(p) => p.theme.progressText};
        font-weight: bold;
        font-size: 90%;
    }
`;
const BotFooter = styled.div`
    position: absolute;
    height: ${FOOTER_HEIGHT}px;
    display: flex;
    align-items: center;
    font-weight: 500;
    margin-bottom: -0.5em;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 85%;
    padding: 0.5em;
    .links {
        margin-top: 0.8em;
        left: 1.5em

        a:first-of-type {
            padding: 0 1em 0 0.5em;
            border-right: 1px solid grey;
        }
        a {
            color: ${(p) => p.theme.botLinks};
            margin-right: 1em;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    @media (max-width: 500px) {
        font-size: 75%;
        padding: 0.25em 1%;
    }
`;
const TypistContainer = styled.div`
    color: ${(p) => p.theme.sliderText};
    text-align: center;
    transition: margin 0.5s, font-size 0.5s;
    &.TypistActive {
        margin: 30% auto;
        font-size: 40px;
    }
    &.TypistDone {
        margin: 25px auto;
        font-size: 24px;
        .Cursor {
            display: none;
        }
    }
`;
const QuestionContainer = styled.div``;
const MessagesContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 10px 0 0 0;
    transition: transform 0.2s cubic-bezier(0, 0, 0.5, 1);
    scroll-behavior: 'smooth';
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    @media (max-width: 1100px) {
        > div:nth-of-type(n + 3):nth-of-type(-n + 5) {
            max-width: 70%;
        }
    }
    @media (max-width: 768px) {
        padding: 120px 0 0 0;
        > div:nth-of-type(n + 3):nth-of-type(-n + 5) {
            max-width: 100%;
        }
    }
`;
const DigitalAvatarContainer = styled.div`
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 10;
    @media (max-width: 768px) {
        width: 110px;
        height: 110px;
    }
`;
const EmbedDigitalAvatarContainer = styled.div`
    position: sticky;
    width: 150px;
    height: 150px;
    right: 20px;
    top: 20px;
    float: right;
    background: #4a4a4a;
    z-index: 10;
`;

interface Props {
    t?: (msg: string) => string;
    jumpQuestionID?: number;
    isEmbedded?: boolean;
    package?: number; // optional packageID when used as React component
    disableSession?: boolean; // prevent session id being used or saved
    disableTypist?: boolean; // ignore BMTYPES.TYPIST messages
    projectInfo?: ProjectInfo; // this overrides the state var
    waiting?: boolean; // show pageloader while this is true
    testMode?: boolean;
    quotaGroup?: string;
    rollbar?: any;
    openReplayTracker?: Tracker;
    match?: any; // react router props
    handleTerminate?: () => Promise<void>; // pass signals to parent component
}

interface State {
    connStatus: CONN_STATUS | Error;
    messageLog: Message[]; // all messages
    currentTask: Task; // controls which components get rendered
    sending: boolean; // only true when user is sending their answer to server
    requesting: boolean; // only true when user is requesting to edit/jump/etc
    waiting: boolean; // only true when user is waiting for bot message
    currentQuestionValue: string | number; // current question value
    projectInfo: ProjectInfo; // loaded during syncProjectInfo()
    packageID: number;
    theme: any; // @TODO typing from styled
    langId: string; // i18n
    progress: number; // proportion
    bottomOffset: number; // controls how far <MessagesContainer> extends to bottom
    synced: boolean; // only true once the project has been synced - after bot sends respondentid
    typistText: string;
    typistActive: boolean;
    bottomOfPage: boolean; // whether the current client browser is bottom of the page
    respondentId: number;
    testMode: boolean;
    msgQueue: UserMessage[]; // if users send message while socket is disconnected, this will be temporary storage
    currentBotTheme: string;
    showBanner: boolean; // preview/testmode banner
    bannerText: string;
    selectedPanel: Panel;
    autoReconnect: boolean;
    messageBubbleBlock: number;
    responseInputDelay: boolean; // delay showing the response/question input
}
const initialState: State = {
    connStatus: CONN_STATUS.CONNECTING,
    messageLog: [],
    currentTask: null,
    sending: false,
    waiting: false,
    projectInfo: {
        id: 0,
        name: 'Loading...',
        pageTitle: 'Loading...',
        avatarImage: INCA_LOGO_URL,
        faviconImage: 'TODO',
        botTheme: 'white',
        botSpeed: 'normal',
        sessionTimeout: 2,
        devices: ['browser', 'mobile', 'tablet'],
        showProgress: window.BOT_MODE === BOT_MODES.CHAT ? true : false,
        redirectURLs: {
            [QUOTA_STATUS.COMPLETE]: 'https://nexxt.in',
            [QUOTA_STATUS.TERMINATE]: 'https://nexxt.in',
            [QUOTA_STATUS.QUOTA_FULL]: 'https://nexxt.in'
        }
    },
    packageID: 0,
    theme: BOT_THEMES['white'],
    currentQuestionValue: '',
    langId: ENGLISH, // english by default
    progress: 0,
    requesting: false,
    bottomOffset: 0,
    synced: false,
    typistText: '',
    typistActive: false,
    bottomOfPage: true,
    respondentId: null,
    testMode: false,
    msgQueue: [],
    currentBotTheme: 'white',
    showBanner: false,
    bannerText: '',
    selectedPanel: null,
    autoReconnect: true, // will set to false once respondent faces the departing message - for no redirect link
    messageBubbleBlock: -1,
    responseInputDelay: false
};

// Extract URL Query Parameters depending on source (PanelGroup)
const urlParams: { [key: string]: string } = _.fromPairs([
    ...new URLSearchParams(window.location.search).entries()
]);

const SOURCE: string = urlParams['SRC'] || urlParams['src'] || '';
const TERMINATE_OVERRIDE = urlParams['TERMINATE'];
const DEBUG_SOCKET = urlParams['debugsocket'];
const QUOTA_GROUP = urlParams['quotaGroup'] || '';
const TEST_MODE = urlParams['testMode'] || false;

const registeredQueryParams = [
    'SRC',
    'src',
    'TERMINATE',
    'quotaGroup',
    'testMode',
    'nodelay',
    'debugconditions',
    'debugquotas'
];
const METADATA = _.omit(urlParams, registeredQueryParams);

const RECONNECTION_THRESHOLD = 5;
let RECONNECTION_TRIES = 0;
let CONNECTION_CHECK_INTERVAL;
const CONNECTION_CHECK_IN_SECOND = 10;
const fixedQTypes = [QTYPES.OPEN, QTYPES.PII, QTYPES.SCALE];

/*
 * BotApp class handles the client-side rendering of the
 * chatbot web interface. The socket connection handles the
 * back-and-forth sending-and-receiving of messages to-and-from
 * the server. Sending and receiving are handled asynchronously
 * by submitAnswer() and componentDidMount(), respectively.
 */
@translate('translation')
class BotApp extends React.Component<Props, State> {
    state: State = { ...initialState, packageID: this.props.package || 0 };
    conn: BotConnection;
    messagesContainer: HTMLDivElement; // container for message bubble pane
    questionContainer: HTMLDivElement; // container for QuestionSwitch
    timeout: any = null;
    socketStatusInterval;

    async componentWillUnmount() {
        console.log('Bot unmounting: closing socket connection.');
        this.socketStatusInterval && clearInterval(this.socketStatusInterval);
        this.conn && (await this.conn.close());
        CONNECTION_CHECK_INTERVAL && clearInterval(CONNECTION_CHECK_INTERVAL);
    }

    async componentDidUpdate(prevProps, prevState) {
        // update projectInfo if needed
        if (
            this.props.projectInfo &&
            prevState.projectInfo !== this.props.projectInfo
        ) {
            this.setState({
                projectInfo: this.props.projectInfo,
                currentBotTheme: this.props.projectInfo.botTheme,
                autoReconnect: true
            });
        }

        // send ready signal to start survey proper
        if (prevState.typistActive && !this.state.typistActive) {
            // @TODO: state var should reflect that the client
            // is ready to begin the survey, not just that typist
            // is (no longer) active
            this.submitAnswerQueue({
                uid: uuid(),
                type: UMTYPES.READY_SIGNAL,
                from: 'user',
                questionValue: '',
                questionId: -1,
                timestamps: { clientSend: new Date().toJSON() }
            });
        }

        // send signal for question request, when updated
        const { jumpQuestionID } = this.props;
        if (jumpQuestionID && jumpQuestionID !== prevProps.jumpQuestionID) {
            this.requestJump(jumpQuestionID);
        }

        // reinit session if package has changed
        if (prevProps.package && prevProps.package !== this.props.package) {
            console.log('Package changed, reinitializing session!');
            await this.initSession();
        }

        // render new theme if it has changed
        if (this.state.currentBotTheme !== prevState.currentBotTheme) {
            this.setState({ theme: BOT_THEMES[this.state.currentBotTheme] });
            // Render background animations
            const parent =
                document.getElementById('bot-container').parentElement;
            const bgContainer = document.createElement('div');

            bgContainer.id = 'bg-container';
            parent.appendChild(bgContainer);

            let themeBackground = null;

            switch (this.state.currentBotTheme) {
                case 'purpleSky':
                case 'purple_sky':
                case QTYPES.GUIDED_FANTASY:
                    themeBackground = <PurpleSkyTheme />;
                    break;
                case 'orangeClouds':
                    themeBackground = <CloudTheme />;
                    break;
                case 'floatingSquares':
                    themeBackground = <FloatingSquaresTheme />;
                    break;
                case 'dotsAndTriangles':
                    themeBackground = <DotsAndTrianglesTheme />;
                    break;
                default:
                    break;
            }

            ReactDOM.render(themeBackground, bgContainer);
        }

        if (
            this.state.respondentId !== prevState.respondentId &&
            !this.props.isEmbedded
        ) {
            this.props.openReplayTracker?.setUserID(
                String(this.state.respondentId)
            );
            this.props.openReplayTracker?.setMetadata(
                'Domain',
                process.env.DOMAIN_NAME
            );
        }

        // update PROGRESS and LANGUAGE if they have changed
        const progress = messageService.getLatestProgress(
            this.state.messageLog
        );
        if (this.state.progress !== progress) {
            this.setState({ progress });
        }
    }

    async sendEvent(data: SvcEventData): Promise<void> {
        try {
            const SERVICE_URL = `${process.env.INCA_SERVER_URL}/data/event/publish-event`;
            const body = {
                data
            };
            await axios.post(SERVICE_URL, body);
        } catch (err) {
            console.log('Error with sending service: ', err);
        }
    }

    /*
     * Setup event listeners and init session.
     */
    async componentDidMount() {
        if (this.props.isEmbedded) {
            this.setState({ packageID: this.props.package });
        } else {
            const codeName = this.props.match?.params?.codeName;
            // const codename = /\/p\/(.+)/.exec(window.location.pathname)?.[1];
            const resp = await axios.get(
                `${process.env.INCA_SERVER_URL}/p/${codeName}`
            );
            const packageID = resp.data.packageID;
            this.setState({ packageID });
        }
        // add event listener for tabbing
        window.addEventListener('keydown', this.handleFirstTab);

        // Special handler for Android devices when keyboard pops up
        // it changes viewport of the device so this code is for detecting automatic viewport resizing and makes it scoll to bottom
        if (isAndroid) {
            window.addEventListener('resize', () => {
                this.scrollToBottom();
            });
        }

        // setup socket connection and get going
        await this.initSession();
    }

    /*
     * Check socket connection status and try to reconnect if it's down.
     * @TODO: perhaps rename this function? It is doing more than just checking status.
     */
    handleReconnection = async () => {
        if (this.conn) {
            const status = this.conn.getStatus();
            // If the number of tries reached the threshold, clear the current interval and show reconnection toast message

            // If the socket connection is disconnected, initiate new connection
            if (
                [
                    WEB_SOCKET_CONNECTION_TYPES.CLOSED,
                    WEB_SOCKET_CONNECTION_TYPES.CLOSING
                ].includes(status) &&
                this.state.connStatus !== CONN_STATUS.RECONNECTING
            ) {
                ++RECONNECTION_TRIES;
                this.setState({ connStatus: CONN_STATUS.RECONNECTING });
                await this.handleDisconnect(
                    `Reconnection attempts:: ${RECONNECTION_TRIES}`,
                    true
                );
            }

            // Send reconnection message if num of tries hits threshold
            if (RECONNECTION_THRESHOLD === RECONNECTION_TRIES) {
                const reconnectMsgIndex = this.state.messageLog.findIndex(
                    (msg) => msg.type === BMTYPES.RECONNECTION
                );
                if (reconnectMsgIndex === -1) {
                    !this.props.isEmbedded &&
                        console.log(JSON.stringify(this.state));
                    !this.props.isEmbedded &&
                        this.props.rollbar.log(
                            `Show reconnecting indicator...(${this.state.respondentId})`
                        );

                    this.setState((prevState) => ({
                        messageLog: [
                            ...prevState.messageLog,
                            {
                                from: 'bot',
                                text: this.props.t('MSG.SESSION.RECONNECTING'),
                                type: BMTYPES.RECONNECTION,
                                status: 'reconnecting'
                            } as ReconnectionMessage
                        ]
                    }));
                    this.scrollToBottom();
                }

                return;
            }

            // Handle reconnection
            if (
                status === WEB_SOCKET_CONNECTION_TYPES.OPEN &&
                RECONNECTION_TRIES
            ) {
                !this.props.isEmbedded &&
                    console.log(
                        'successfully reconnected. ATTEMPTS: ',
                        RECONNECTION_TRIES
                    );
                RECONNECTION_TRIES = 0;
                this.handleReconnectionMessage(
                    WEB_SOCKET_CONNECTION_TYPES.OPEN
                );
            }

            // Send every message in msgQueue
            if (
                status === WEB_SOCKET_CONNECTION_TYPES.OPEN &&
                this.state.msgQueue.length
            ) {
                while (this.state.msgQueue.length) {
                    const msg = this.state.msgQueue.shift();
                    this.conn.send(msg);
                }
            }
        } else {
            !this.props.isEmbedded && console.log(JSON.stringify(this.state));
            !this.props.isEmbedded &&
                this.props.rollbar.error(
                    `Connection is missing.. ${this.state.respondentId}`
                );
        }
    };

    /**
     * Waiting for socket connection to be established.
     * return the final socket status(OPEN, CLOSED, CLOSING)
     */
    socketConnectionAwaiter = async () => {
        return new Promise<WEB_SOCKET_CONNECTION_TYPES>((resolve, reject) => {
            const interval = setInterval(() => {
                if (
                    [
                        WEB_SOCKET_CONNECTION_TYPES.OPEN,
                        WEB_SOCKET_CONNECTION_TYPES.CLOSED,
                        WEB_SOCKET_CONNECTION_TYPES.CLOSING
                    ].includes(this.conn.getStatus())
                ) {
                    !this.props.isEmbedded &&
                        console.log(
                            'Clear connection awaiter interval.. Last status:',
                            this.conn.getStatus()
                        );
                    clearInterval(interval);
                    return resolve(
                        this.conn.getStatus() as WEB_SOCKET_CONNECTION_TYPES
                    );
                }
            }, 1000);
        });
    };

    /*
     * Initialize the chatbot socket connection
     * and define message listeners to facilitate
     * reception of messages from server.
     */
    initSession = async (connectionMode = BOT_CONNECTION_MODE.NEW_PAGE) => {
        // combine packageID with generic session id key to load/save from local storage
        const packageId = this.state.packageID || this.props.package;
        this.conn && this.conn.close();
        const testMode = isTrue(this.props.testMode || TEST_MODE);
        try {
            if (typeof Storage !== 'undefined') {
            } else {
                console.log('invalid browser');
                this.setState({
                    synced: true,
                    connStatus: CONN_STATUS.INVALID_BROWSER
                });
                return;
            }

            /*
             * Show pageloader while waiting for socket connection.
             */
            const initConnection = new SocketConnect();
            await sleep(MIN_PAGELOADER_DURATION); // this is how long the pageloader shows
            this.conn = initConnection;

            /**
             * waiting socket connection while it's connecting.
             */
            if (this.conn.getStatus() !== WEB_SOCKET_CONNECTION_TYPES.OPEN) {
                const currSocketStatus = await this.socketConnectionAwaiter();

                // currSocketStatus should be either of 'OPEN', 'CLOSED' or 'CLOSING'
                // if connection is CLOSED or CLOSING due to another auto reconnect initiation, kill the current process
                if (currSocketStatus !== WEB_SOCKET_CONNECTION_TYPES.OPEN) {
                    !this.props.isEmbedded &&
                        console.log('currentStatus: ', currSocketStatus);
                    this.setState({
                        connStatus: CONN_STATUS.CONNECTION_FAILED
                    });
                    return;
                }
            }

            this.setState({
                connStatus: CONN_STATUS.CONNECTED,
                testMode
            });
            /*
             * Initialize session.
             */
            if (window.BOT_MODE === BOT_MODES.TESTBED) {
                // TESTBED MODE
                this.setState({ synced: true });
                this.conn.testbed(window.BOT_TESTBED_MESSAGE_ID || null);
            } else if (window.BOT_MODE === BOT_MODES.MONITOR) {
                // MONITOR MODE
                this.setState({ synced: true });
                this.conn.monitor();
            } else {
                let ipAddress;

                if (window.location.hostname === 'localhost') {
                    ipAddress = 'localhost';
                } else {
                    const res = await axios.get(
                        `${process.env.INCA_SERVER_URL}/ip-address`
                    );
                    ipAddress = res.data.ip;
                }
                // CHAT MODE
                const testModeSessionKey = testMode ? '_testMode' : '';
                // retrieve session key from localStorage (if available)
                const packageSessionKey = `${SESSION_ID_KEY}/${this.state.packageID}${testModeSessionKey}`;
                const currSessionId = this.props.disableSession
                    ? null
                    : localStorage.getItem(packageSessionKey);
                const currentDateTime = new Date();

                // initialize chat
                this.conn.initChat(
                    packageId,
                    ipAddress,
                    window.navigator.userAgent,
                    deviceType,
                    connectionMode,
                    currSessionId,
                    currentDateTime.getHours(),
                    QUOTA_GROUP || this.props.quotaGroup,
                    testMode,
                    {
                        rawUrlParams: urlParams,
                        pathAndQueryStr:
                            window.location.pathname + window.location.search,
                        src: SOURCE,
                        trackerId: '',
                        metadata: METADATA,
                        pid: ''
                        // PANEL_PID
                    },
                    TERMINATE_OVERRIDE,
                    this.props.isEmbedded
                );

                // get session id
                this.conn.getSessionID((res) => {
                    this.handleSession(res);
                });
            }

            /*
             * Disconnect listener
             * Gracefully inform user and then reboot
             */
            this.conn.addDisconnectListener((reason) => {
                this.handleDisconnect(reason);
            });

            /*
             * Sync listener which loads projectInfo data upon initial
             * connection with the server-side socket.
             */
            this.conn.addSyncListener((data) => {
                const projectInfo = data.project;
                const isPreview = projectInfo?.previewCode !== 0;
                let bannerText = '';

                if (isPreview) bannerText = 'PREVIEW MODE';
                else if (testMode) bannerText = 'TEST MODE';

                const panels = projectInfo.panelSettings?.panels;
                let selectedPanel = null;
                if (panels?.length) {
                    if (SOURCE) {
                        selectedPanel = panels.find(
                            (panel) => panel.key === SOURCE
                        );
                    } else {
                        // index 0 is always "General" panel
                        selectedPanel = panels[0];
                    }
                }

                this.setState({
                    projectInfo: projectInfo,
                    theme: BOT_THEMES[projectInfo.botTheme],
                    synced: true,
                    respondentId: data.respondentId,
                    currentBotTheme: projectInfo.botTheme,
                    bannerText,
                    showBanner: isPreview || testMode,
                    selectedPanel
                });

                try {
                    const attributes = {
                        namespace: SVC_NAMESPACE_TYPE.BOT_CLIENT,
                        stage: process.env.NODE_ENV || '',
                        name: SVC_EVENT_NAME.INIT_CHATBOT_SOCKET,
                        type: SVC_EVENT_TYPE.TRANSACTION,
                        clientId: String(this.state?.projectInfo?.clientID),
                        projectId: String(this.state?.projectInfo?.id),
                        packageId: String(this.state?.packageID),
                        respondentId: String(this.state?.respondentId),
                        context: {
                            isTestMode: this.state?.testMode,
                            projectName: this.state?.projectInfo?.name
                        }
                    };

                    this.sendEvent({ attributes });
                } catch (err) {
                    console.log('Error with sending event');
                }

                if (!this.props.isEmbedded) {
                    document.title = projectInfo.pageTitle;
                }

                this.props.openReplayTracker?.setMetadata(
                    'respondentID',
                    String(data.respondentId)
                );
                this.props.openReplayTracker?.setMetadata(
                    'packageID',
                    String(window.PROJECT_PACKAGE_ID)
                );
                this.props.openReplayTracker?.setMetadata(
                    'metadata',
                    JSON.stringify({
                        ip: window.IP,
                        userAgent: window.USER_AGENT,
                        deviceType,
                        testMode,
                        src: SOURCE,
                        trackerId: '',
                        metadata: METADATA
                    })
                );
            });

            this.conn.addInvalidAccessListener((type) =>
                this.handleInvalidAccess(type)
            );
            /*
             * Message listener -- receives msgs from server
             * always be ready to switch task type.
             */
            this.conn.addMessageListener((m: any, a) => {
                this.handleBotMessage(m, a);
            });
            if (
                !this.socketStatusInterval &&
                this.conn.getStatus() === WEB_SOCKET_CONNECTION_TYPES.OPEN
            ) {
                this.socketStatusInterval = setInterval(
                    this.handleReconnection,
                    3 * SECOND_IN_MILLISECOND // set reconnection interval to 3 seconds
                );
            }

            if (!CONNECTION_CHECK_INTERVAL) {
                /*
                 * By default, Cloudflare has 100 seconds `proxy_read_timeout` connection timeout if there is no activity during that time.
                 * Therefore, To maintain socket connection stability, a connection check from client-side is required.
                 * Here, client sends a connection check message every `CONNECTION_CHECK_IN_SECOND` seconds to ensure the connection does not timeout.
                 * Note that the current reverse proxy setup (`reverse_proxy/nginx.conf`) has `proxy_read_timeout` and `keepalive_timeout` set to `100` seconds.
                 * Before you change anything related to this, you should consider ECS / NGINX / Cloudflare configs for Websocket, and update everything accordingly.
                 *
                 * ref. https://support.cloudflare.com/hc/en-us/articles/115003011431-Error-524-A-timeout-occurred#524error
                 */

                CONNECTION_CHECK_INTERVAL = setInterval(
                    () => this.conn.sendConnectionCheck(),
                    CONNECTION_CHECK_IN_SECOND * SECOND_IN_MILLISECOND
                );
            }
        } catch (err) {
            console.error(err);
            this.setState({ connStatus: new Error(err) });
            const attributes = {
                namespace: SVC_NAMESPACE_TYPE.BOT_CLIENT,
                stage: process.env.NODE_ENV || '',
                name: SVC_EVENT_NAME.INIT_CHATBOT_SOCKET,
                type: SVC_EVENT_TYPE.ALERT,
                clientId: String(this.state?.projectInfo?.clientID),
                projectId: String(this.state?.projectInfo?.id),
                packageId: String(this.state?.packageID),
                respondentId: String(this.state?.respondentId),
                context: {
                    isTestMode: this.state?.testMode,
                    projectName: this.state?.projectInfo?.name
                }
            };
            const message = {
                error: true,
                reason: err
            };
            this.sendEvent({ attributes, message });
        }
    };

    handleFirstTab = (e) => {
        if (e.key === KEY_NAME.TAB) {
            document.body.classList.add('keyboardNavigation');
            window.removeEventListener('keydown', this.handleFirstTab);
            window.addEventListener('mousedown', this.handleMouseDownOnce);
        }
    };
    handleMouseDownOnce = () => {
        document.body.classList.remove('keyboardNavigation');
        window.removeEventListener('mousedown', this.handleMouseDownOnce);
        window.addEventListener('keydown', this.handleFirstTab);
    };

    setBottomOffset = (
        bottomOffset: number = FOOTER_HEIGHT,
        forceScroll?: boolean
    ) => {
        bottomOffset =
            this.props.isEmbedded && bottomOffset === FOOTER_HEIGHT
                ? 10
                : bottomOffset;
        this.setState({ bottomOffset }, () => {
            if (forceScroll) {
                this.scrollToBottom();
            }
        });
    };

    /*
     * Scrolling handler: forces screen to always show most recent message.
     * This is primarily used in componentDidUpdate, but also as a callback
     * function for whenever a subcomponent fully loads; and additionally whenever
     * a new bot message is received.
     * If `recurseDelay` is set, then the scroll will be called again
     * after a delay of `recurseDelay` milliseconds. This delayed recurse helps
     * to repeat the scroll action in case of something not rendering immediately.
     */
    scrollToBottom = async (recurseDepth = 5) => {
        // trigger recursive scroll to account for component mount latency

        try {
            // calculate position
            const { scrollHeight, scrollTop, clientHeight } =
                this.messagesContainer;
            const scrollBuffer = 8;

            // check if currently not at the bottom of page
            // with a 5px buffer
            if (clientHeight <= scrollHeight - scrollTop - scrollBuffer) {
                // scroll to bottom of message container
                this.messagesContainer &&
                    this.messagesContainer.scrollTo({
                        top: this.messagesContainer.scrollHeight,
                        behavior: 'smooth'
                    });

                // prevent future scrollToBottom calls in setTimeout below if successful
                return;
            }
        } catch (err) {
            // silently fail and recurse when scroll is not possible (e.g. during load)
        }
        // set timer to continue scrollToBottom attempts if there is component mount latency
        if (recurseDepth) {
            this.timeout = setTimeout(() => {
                this.scrollToBottom(recurseDepth - 1);
            }, 100);
        }
    };

    isDigitalAvatarEnabled = () => {
        return (
            this.state.projectInfo?.inputSettings?.digitalAvatar?.enabled &&
            !this.props.isEmbedded
        );
    };

    setMessageBubbleBlock = (blockIdx: number) => {
        this.setState({ messageBubbleBlock: blockIdx });
    };

    /*
     *  Delay showing the response/question input until Digital Avatar finishes speaking
     */
    setResponseInputDelay = (isDelayed: boolean) => {
        this.setState({ responseInputDelay: isDelayed });
    };

    /*
     * Shows or hides newMessageNotification depending on @enable
     */
    toggleNewMessageNotification = (enable = false) => {
        // let className = '';
        // //oe - 159, default -40, scale - 200, poopup -180
        // switch (true) {
        //     case this.state.bottomOffset <= 40:
        //         className = 'bottom-offset-default';
        //         break;
        //     case this.state.bottomOffset <= 160:
        //         className = 'bottom-offset-text-prompt';
        //         break;
        //     case this.state.bottomOffset <= 180:
        //         className = 'bottom-offset-popup';
        //         break;
        //     case this.state.bottomOffset <= 200:
        //         className = 'bottom-offset-fixed-footer';
        //         break;
        // }
        // if (enable) {
        //     toast('↓ new messages below', {
        //         onClick: (e) => this.scrollToBottom(undefined, true),
        //         className: className,
        //         toastId: 'custom'
        //     });
        // } else {
        //     toast.dismiss();
        // }
    };

    disableConnection() {
        this.conn && this.conn.close();
        this.setState({ autoReconnect: false });
        CONNECTION_CHECK_INTERVAL && clearInterval(CONNECTION_CHECK_INTERVAL);
        this.socketStatusInterval && clearInterval(this.socketStatusInterval);
    }
    /*
     * Disconnect handler.
     * Sends text message to user explaining problem.
     * Reloads page after a delay.
     * TODO: standardize somewhere?
     */
    handleDisconnect = async (reason: string, reconnect?: boolean) => {
        const { currentTask, autoReconnect } = this.state;
        let connectionMode;
        const currentQuestionId = (currentTask as QuestionTask)?.question?.id;
        // close the current connection
        this.conn && this.conn.close();

        switch (true) {
            case currentQuestionId === SPECIAL_QIDS.RESUME:
                connectionMode = BOT_CONNECTION_MODE.ON_RESUME;
                break;
            case currentQuestionId === SPECIAL_QIDS.LANGUAGE:
                connectionMode = BOT_CONNECTION_MODE.ON_LANGUAGE;
                break;
            case currentQuestionId && (currentQuestionId as number) > 0:
                connectionMode = BOT_CONNECTION_MODE.ON_QUESTION;
                break;
            default:
                connectionMode = BOT_CONNECTION_MODE.SOCKET_RECONNECT;
        }
        if (!this.props.isEmbedded) {
            console.log("disconnected. init'ing session again...");
            console.log('disconnected message:: ', reason);
            console.log('connectionMode:', connectionMode);
        }

        if (autoReconnect && reconnect) await this.initSession(connectionMode);
        return;
    };

    /*
     * Store new session ID to localstorage
     */
    handleSession = (l: { sessionId: string }) => {
        // save session key to localStorage
        if (!this.props.disableSession) {
            const testMode = this.state.testMode ? '_testMode' : '';
            const packageSessionKey = `${SESSION_ID_KEY}/${this.state.packageID}${testMode}`;
            console.info(
                `Storing session id ${packageSessionKey} := ${l.sessionId}`
            );
            this.props.openReplayTracker?.setMetadata('sessionID', l.sessionId);
            localStorage.setItem(packageSessionKey, l.sessionId);
        }
    };

    /**
     * Provides special handler for each of the INVALID_ACCESS_TYPES.
     */
    handleInvalidAccess(type: INVALID_ACCESS_TYPES) {
        console.log('invalid type === ', type);
        switch (type) {
            case INVALID_ACCESS_TYPES.FLOW_NOT_VALID:
                this.setState((prevState) => ({
                    messageLog: prevState.messageLog.concat({
                        from: 'bot',
                        text: this.props.t('MSG.SESSION.REFRESH_PAGE'),
                        type: BMTYPES.TEXT
                    } as TextMessage)
                }));

                setTimeout(() => {
                    window.location.reload();
                }, 5000);
                break;
            case INVALID_ACCESS_TYPES.MULTIPLE_ACCESS:
                this.setState({
                    connStatus: CONN_STATUS.BLOCK_MULTI_ACCESS
                });
                break;
            case INVALID_ACCESS_TYPES.SURVEY_CLOSE:
                this.setState({
                    connStatus: CONN_STATUS.SURVEY_CLOSE
                });
                break;
            case INVALID_ACCESS_TYPES.QUOTA_OVERFLOW:
                this.setState({
                    connStatus: CONN_STATUS.QUOTA_OVERFLOW
                });
                break;
            case INVALID_ACCESS_TYPES.ID_NOT_FOUND:
            default:
                this.setState({
                    connStatus: CONN_STATUS.ID_NOT_FOUND
                });
                break;
        }
    }
    /*
     * Handler for receiving bot messages, and updating current task accordingly.
     * This combines various state updates to enforce the rules which determine
     * which client components are rendered at any given time. For example, the
     * submitting indicators for text and dropdowns are used to ensure that those
     * components can disappear as soon as the answer is submitted, even if it
     * takes some time to receive the answer.
     * TODO: explain more?
     */
    handleBotMessage = (m: BotMessage, a: Answer = null) => {
        this.setState({
            waiting: true
        });
        const { currentTask, messageLog: currentLog } = this.state;
        let newTask: Task;

        // ignore duplicate question messages
        // this is a very hacky way to resolve a socket-related bug
        // where the same question gets sent many times sequentially
        if (
            currentTask &&
            currentTask.type === TTYPES.QUESTION &&
            m.type === BMTYPES.QUESTION &&
            (currentTask as QuestionTask).question.id ===
                (m as QuestionMessage).question.id
        )
            return;

        // update current flow ID
        if (m && m.type === BMTYPES.QUESTION) {
            this.setState({
                currentQuestionValue: m.question.value
            });
        }

        // MESSAGE TYPE SWITCH BOARD
        switch (m.type) {
            // TYPIST: just show the typist text
            case BMTYPES.TYPIST_TEXT:
                !this.props.disableTypist &&
                    this.setState({
                        typistText: m.text,
                        typistActive: true
                    });
                break;
            // DIRECT MESSAGES: no task switch
            case BMTYPES.TEXT:
            case BMTYPES.RICH_TEXT:
            case BMTYPES.IMAGE:
            case BMTYPES.VIDEO:
            case BMTYPES.AUDIO:
            case BMTYPES.TYPING:
            case BMTYPES.PRELOADER:
            case BMTYPES.SYSTEM:
                // reset bottom offset [because no question loaded]
                if (m.type !== BMTYPES.PRELOADER) {
                    this.setBottomOffset();
                }
                // update messageLog and apply new task
                newTask = {
                    type: TTYPES.MESSAGE,
                    messageLog: currentTask
                        ? messageService.appendToLog(currentTask.messageLog, m)
                        : [m]
                };
                break;
            // QUESTION: switch to question type; handle automatic responses
            case BMTYPES.QUESTION:
                newTask = {
                    type: TTYPES.QUESTION,
                    question: m.question,
                    messageLog: [m],
                    answer: a
                };
                break;

            // TERMINATION SIGNALS: trigger termination by doing nothing
            case BMTYPES.TERMINATE:
            case BMTYPES.QUOTA_FULL:
                // Redirection is enabled when
                //  1) 'terminate=' is not specified in query param (i.e. TERMINATE_OVERRIDE undefined); OR
                //  2) 'terminate=' is specified and it includes 'cta'
                // we may add other cases for other values of TERMINATE_OVERRIDE, tbd
                const enableRedirect =
                    !TERMINATE_OVERRIDE ||
                    (TERMINATE_OVERRIDE &&
                        [BOT_TERMINATE_TYPES.CTA].includes(
                            TERMINATE_OVERRIDE as BOT_TERMINATE_TYPES
                        ));
                const panelEnableRedirect = this.state.selectedPanel
                    ? this.state.selectedPanel?.enableRedirect
                    : true;
                const customEnableRedirect =
                    (enableRedirect ||
                        (TERMINATE_OVERRIDE &&
                            [BOT_TERMINATE_TYPES.MSG].includes(
                                TERMINATE_OVERRIDE as BOT_TERMINATE_TYPES
                            ))) &&
                    m.redirectURL !== '';
                const REDIRECT_DELAY = 2000;

                if (this.props.handleTerminate) {
                    this.props.handleTerminate();
                } else if (
                    (enableRedirect && panelEnableRedirect) ||
                    customEnableRedirect
                ) {
                    this.handleRedirect(
                        m.status,
                        REDIRECT_DELAY,
                        m.redirectURL
                    );
                } else {
                    // TERMINATE_OVERRIDE is determined by the `TERMINATE=` query param
                    switch (TERMINATE_OVERRIDE) {
                        case BOT_TERMINATE_TYPES.MSG:
                            setTimeout(() => {
                                this.handleBotMessage({
                                    type: BMTYPES.TEXT,
                                    from: 'bot',
                                    uid: uuid(),
                                    text: `That's the end of the preview! You can close this tab now.`,
                                    timestamps: {}
                                } as TextMessage);
                            }, REDIRECT_DELAY);
                            break;
                        case BOT_TERMINATE_TYPES.DISABLED:
                            setTimeout(() => {
                                this.handleBotMessage({
                                    type: BMTYPES.TEXT,
                                    from: 'bot',
                                    uid: uuid(),
                                    text: `That's the end of the survey! You can close this tab now.`,
                                    timestamps: {}
                                } as TextMessage);
                            }, REDIRECT_DELAY);
                            break;
                    }
                    this.deleteSessionKey();
                }

                const attributes = {
                    namespace: SVC_NAMESPACE_TYPE.BOT_CLIENT,
                    stage: process.env.NODE_ENV || '',
                    name: SVC_EVENT_NAME.NEW_RESPONDENT_TERMINATE,
                    type: SVC_EVENT_TYPE.TRANSACTION,
                    clientId: String(this.state?.projectInfo?.clientID),
                    packageId: String(this.state?.packageID),
                    respondentId: String(this.state?.respondentId),
                    context: {
                        isTestMode: this.state?.testMode
                    }
                };
                this.sendEvent({ attributes });

                this.disableConnection();
                break;
            case BMTYPES.SWITCH_THEME:
                this.setState({
                    currentBotTheme: m.theme
                });
                break;
            // CASE not handled: error by default
            default: {
                console.error(m);
                throw 'unsupported message type: ' + m;
            }
        }

        // apply new task (if it has changed) and update message log
        if (newTask) {
            const newMessageLog = messageService.genMessageLog(
                newTask,
                currentLog
            );

            // update state and scroll (if needed)
            this.setState({
                currentTask: newTask,
                messageLog: newMessageLog,
                waiting: false
            });
            // console.log('UPDATE CURRENT TASK NOW!', newTask);
        }
        if (m.type !== BMTYPES.PRELOADER) {
            this.scrollToBottom();
        }
    };

    /*
     * Triggers special user request to edit an answer
     * This uses the UMTYPES.EDIT_REQUEST message type
     * @NOTE: When the ?debugsocket parameter is present,
     * the edit button triggers a client-side socket disconnect!
     */
    editAnswer = async (questionValue: string, questionID: number) => {
        if (!DEBUG_SOCKET) {
            // send edit request
            this.setState({ requesting: true });
            await this.submitAnswerQueue({
                uid: uuid(),
                type: UMTYPES.EDIT_REQUEST,
                from: 'user',
                questionId: questionID,
                questionValue: questionValue,
                timestamps: { clientSend: new Date().toJSON() }
            });
        } else {
            // disconnect from socket!
            console.log('simulating client-side socket disconnect');
            this.handleDisconnect('');
        }
    };

    editTextAnswer = async (userMessage, newText: string): Promise<void> => {
        const updatedUserMessage = {
            ...userMessage,
            text: newText,
            timestamps: {
                ...userMessage.timestamps,
                clientLastEdit: new Date().toJSON()
            }
        };
        this.conn.send(updatedUserMessage, true);
    };
    /*
     * Triggers special user request to jump to another question
     * This uses the UMTYPES.JUMP_REQUEST message type
     */
    requestJump = async (questionID: number) => {
        console.log('Request jump to:', questionID);
        this.setState({ requesting: true });
        this.submitAnswerQueue({
            uid: uuid(),
            type: UMTYPES.JUMP_REQUEST,
            from: 'user',
            questionId: questionID,
            questionValue: String(questionID),
            timestamps: { clientSend: new Date().toJSON() }
        });
    };

    /*
     * Send answer as message, or else add to queue if socket connection is down.
     */
    submitAnswerQueue = async (message: UserMessage) => {
        if (this.conn.getStatus() !== WEB_SOCKET_CONNECTION_TYPES.OPEN) {
            this.setState({
                msgQueue: this.state.msgQueue.concat(message)
            });
            console.log('connection unstable. Message stored in msgQueue');
            return;
        }
        await this.conn.send(message);
    };
    /*
     * Generic handler for all response types.
     * Sends UserMessage containing the user's answer to server.
     * Updates current task state to TTYPES.SUBMITTING
     * Updates messageLog to show user's answer as new message bubble
     */
    submitAnswer = async (resp: UserMessage) => {
        const { currentTask } = this.state;

        // reset offset and scroll since question component has unmounted
        // skipped for questions without transition between questions
        if (
            resp.type !== UMTYPES.POPUP &&
            resp.type !== UMTYPES.MULTIPLE_POPUP
        ) {
            this.setBottomOffset();
            this.scrollToBottom();
        }

        // mark as sending [so client knows it's waiting] and not requesting
        this.setState({ sending: true, requesting: false });
        // send response and await response
        await this.submitAnswerQueue(resp);

        // register new task and unmark sending [so client can proceed]
        const newTask = {
            ...currentTask,
            responseMessage: resp,
            messageLog: messageService.appendToLog(currentTask.messageLog, resp)
        };
        this.setState({
            currentTask: newTask,
            messageLog: messageService.genMessageLog(
                newTask,
                this.state.messageLog
            ),
            sending: false
        });
    };

    /*
     * Returns True if (and only if) user is allowed to edit the current message.
     * currentMessageIndex and lastUserMessageIndex are needed to confirm that
     * the message in question is the user's most recent answer: i.e., the user
     * is only ever allowed to edit their most-recent answer. The other conditions
     * are described one-by-one below.
     *
     * Note: the edit button will always show when in debug socket mode.
     */
    userCanEdit(
        currentMessage: Message,
        currentMessageIndex: number,
        lastUserMessageIndex: number
    ) {
        return (
            DEBUG_SOCKET ||
            (currentMessage.from == 'user' && // user can only edit their own messages
                currentMessage.allowUserEdit && // question has editing enabled
                currentMessageIndex == lastUserMessageIndex && // only allow editing most recent user msg
                !this.state.requesting && // disable while user is already requesting to edit an answer
                EDITABLE_UMTYPES.includes(currentMessage.type)) // only enable for EDITABLE_UMTYPES
        );
    }

    handleRedirect = (status: number, delay = 0, forceRedirectURL = '') => {
        // use default redirect URL from project for the specified project status,
        // unless overwritten by a panel redirect URL or by a forceRedirectURL
        const defaultURL = this.state.projectInfo.redirectURLs[status];
        let redirectURL = defaultURL;

        // force redirect url
        if (forceRedirectURL) redirectURL = forceRedirectURL;
        this.deleteSessionKey();
        // redirect to URL after delay milliseconds
        setTimeout(() => {
            window.location.href = redirectURL;
        }, delay);
    };

    deleteSessionKey = () => {
        const testMode = this.state.testMode ? '_testMode' : '';

        localStorage.removeItem(
            `${SESSION_ID_KEY}/${this.state.packageID}${testMode}`
        );
    };
    /*
     * Returns true if QUOTA_GROUP (extracted from url param) is valid for
     * this project, or else if this project does not require quota group.
     */
    validateQuotaGroup = (): boolean => {
        const { projectInfo } = this.state;
        let valid = false;

        if (projectInfo?.predefinedAudience?.length) {
            if (QUOTA_GROUP) {
                projectInfo.predefinedAudience.forEach((audience) => {
                    const groupNumber = textService.getLastAfterSplit(
                        audience.groupValue,
                        '/'
                    );
                    if (groupNumber === QUOTA_GROUP) {
                        valid = true;
                    }
                });
            }
        } else {
            valid = true;
        }
        return valid;
    };

    /*
     * Add reconnection message to messageLog.
     */
    handleReconnectionMessage = (status) => {
        const { messageLog } = this.state;

        const reconnectionMessage = _.findLastIndex(messageLog, {
            type: BMTYPES.RECONNECTION
        });
        if (reconnectionMessage) {
            switch (status) {
                case WEB_SOCKET_CONNECTION_TYPES.OPEN:
                    messageLog[reconnectionMessage] = {
                        ...messageLog[reconnectionMessage],
                        text: this.props.t('MSG.SESSION.RECONNECTION_SUCCESS'),
                        status: 'success'
                    } as ReconnectionMessage;
                    break;
            }

            this.setState({
                messageLog
            });
        }
    };

    /*
     * BotApp render:
     * this is ultimately where all client-side
     * bot-related components are loaded from.
     */

    render() {
        const {
            connStatus,
            projectInfo,
            currentTask,
            testMode,
            showBanner,
            bannerText
        } = this.state;
        const { t, isEmbedded } = this.props;

        if (connStatus instanceof Error) {
            return (
                <PageNotFound
                    title={t('ERROR.NOT_FOUND.HEADER')}
                    message={t('ERROR.CONNECTION')}
                />
            );
        } else if (connStatus === CONN_STATUS.ID_NOT_FOUND) {
            return (
                <PageNotFound
                    title={t('ERROR.NOT_FOUND.HEADER')}
                    message={t('ERROR.NOT_FOUND.GENERIC')}
                />
            );
        } else if (connStatus === CONN_STATUS.BLOCK_MULTI_ACCESS) {
            return (
                <PageNotFound
                    title={'Sorry, survey is no longer available.'}
                    message={
                        "It looks like you've already completed this survey. If not, please confirm you survey link and try again."
                    }
                />
            );
        } else if (connStatus === CONN_STATUS.SURVEY_CLOSE) {
            const customCloseMessage =
                this.state.projectInfo?.redirectSettings?.close;
            const message = customCloseMessage || DEFAULT_REDIRECT_MSG;
            return (
                <PageNotFound
                    title="Sorry, survey is no longer available."
                    message={message}
                />
            );
        } else if (connStatus === CONN_STATUS.INVALID_BROWSER) {
            return (
                <PageNotFound
                    title={t('ERROR.NOT_SUPPORTED.HEADER')}
                    message={
                        'Unfortunately, your device is not compatible with our survey. Please use other device to begin the survey'
                    }
                />
            );
        } else if (connStatus === CONN_STATUS.QUOTA_OVERFLOW) {
            return (
                <PageNotFound
                    title={t('ERROR.NOT_SUPPORTED.HEADER')}
                    message={
                        'The survey link is currently full and unable to accept any more participants at this time. Please try again in a few minutes.'
                    }
                />
            );
        }

        if (!this.state.synced || this.props.waiting) {
            return <MessageLoader />;
        }

        // console.log('waiting', this.state.waiting, this.props.waiting);

        const { messageLog } = this.state;

        // find index of last message sent by user and bot
        const lastUserMessageIndex = findLastIndex(
            messageLog,
            (m) => m.from == 'user'
        );
        const messageLogMask = this.isDigitalAvatarEnabled()
            ? messageLog.map((msg, msgIdx) =>
                  msg?.from == 'user' ||
                  msgIdx < this.state.messageBubbleBlock + 2
                      ? msg
                      : null
              )
            : messageLog;
        const lastBotTextMessageIndex = findLastIndex(messageLogMask, (m) =>
            [BMTYPES.RICH_TEXT, BMTYPES.TEXT].includes(
                (m as any)?.type as BMTYPES
            )
        );

        const langId = messageService.getLatestLanguage(messageLog);
        if (this.state.langId !== langId) {
            i18n.changeLanguage(langId, (err) => {
                if (err)
                    return console.log(
                        'ERROR: could not change language:',
                        err
                    );
                this.setState({ langId });
                return console.log(`Language changed to ${langId}`);
            });
        }

        // render device blocker, if applicable
        if (!projectInfo.devices.includes(deviceType)) {
            return (
                <PageNotSupported
                    title={t('ERROR.NOT_SUPPORTED.HEADER')}
                    message={t('ERROR.NOT_SUPPORTED.DEVICES')}
                    availableOptions={projectInfo.devices}
                />
            );
        }

        // invalid quota group
        if (!testMode && !this.validateQuotaGroup()) {
            return (
                <PageNotSupported
                    title={t('ERROR.NOT_SUPPORTED.HEADER')}
                    message={t('ERROR.NOT_SUPPORTED.GENERIC')}
                />
            );
        }
        // otherwise render bot
        const theme =
            BOT_THEMES[this.state.currentBotTheme] || BOT_THEMES['white'];
        return (
            <ThemeProvider theme={theme}>
                <BotContainer
                    data-reactroot
                    className={`bot-inner-container ${this.state.currentBotTheme}`}
                >
                    <GlobalStyles botTheme={theme} />
                    {!isEmbedded && showBanner ? (
                        <Banner text={bannerText} />
                    ) : null}
                    <MessagesContainer
                        ref={(e) =>
                            !this.props.isEmbedded &&
                            (this.messagesContainer = e)
                        } // needed for scrolling support...
                        style={{
                            bottom: `${this.state.bottomOffset}px`,
                            overflow: `${this.props.isEmbedded && 'hidden'}`
                        }} // enforce bottomOffset
                        id="wc-message-groups" // needed for journey qtype legacy support...
                        className="bot-width"
                    >
                        {this.isDigitalAvatarEnabled() && (
                            <DigitalAvatarContainer>
                                <DigitalAvatar
                                    messageLog={messageLog}
                                    setMessageBubbleBlock={
                                        this.setMessageBubbleBlock
                                    }
                                    inputSettings={projectInfo?.inputSettings}
                                    setResponseInputDelay={
                                        this.setResponseInputDelay
                                    }
                                    lang={this.state.langId}
                                />
                            </DigitalAvatarContainer>
                        )}

                        {/* Bot preview */}
                        {this.props.isEmbedded ? (
                            <React.Fragment>
                                <div
                                    className="message-history"
                                    ref={(e) =>
                                        this.props.isEmbedded &&
                                        (this.messagesContainer = e)
                                    }
                                    style={{
                                        maxHeight: `calc(100% - ${this.state.bottomOffset}px)`,
                                        position: 'relative'
                                    }}
                                >
                                    {/* <EmbedDigitalAvatarContainer /> */}
                                    {this.state.typistText && (
                                        <TypistContainer
                                            className={
                                                this.state.typistActive
                                                    ? 'TypistActive'
                                                    : 'TypistDone'
                                            }
                                        >
                                            <Typist
                                                onTypingDone={() => {
                                                    this.setState({
                                                        typistActive: false
                                                    });
                                                }}
                                            >
                                                {this.state.typistText}
                                            </Typist>
                                        </TypistContainer>
                                    )}
                                    {!this.state.typistActive && // only show messages when typist done
                                        messageLogMask.map((m, i) => {
                                            if (!m) return null;
                                            const isMostRecentBotTextMsg =
                                                i == lastBotTextMessageIndex;
                                            const isMsgBlocked =
                                                this.isDigitalAvatarEnabled() &&
                                                isMostRecentBotTextMsg &&
                                                i >
                                                    this.state
                                                        .messageBubbleBlock;
                                            // only show edit button for most recent user response
                                            const showEdit = this.userCanEdit(
                                                m,
                                                i,
                                                lastUserMessageIndex
                                            );

                                            return (
                                                <MessageBubble
                                                    avatar={
                                                        projectInfo.avatarImage
                                                    }
                                                    isMsgBlocked={isMsgBlocked}
                                                    key={i}
                                                    data={m}
                                                    showEdit={!!showEdit}
                                                    onRedoRequest={
                                                        this.editAnswer
                                                    }
                                                    langId={this.state.langId}
                                                    currentQuestionID={
                                                        (
                                                            currentTask as QuestionTask
                                                        )?.question?.id
                                                    }
                                                    editTextAnswer={
                                                        this.editTextAnswer
                                                    }
                                                />
                                            );
                                        })}
                                    <div style={{ position: 'relative' }}>
                                        {!fixedQTypes.includes(
                                            (currentTask as QuestionTask)
                                                ?.question?.type
                                        ) && (
                                            <QuestionContainer
                                                ref={(e) =>
                                                    (this.questionContainer = e)
                                                }
                                            >
                                                {!this.state.typistActive && ( // only show questions when typist done
                                                    <QuestionSwitch
                                                        isDelayed={
                                                            this.state
                                                                .responseInputDelay
                                                        }
                                                        scrollToBottom={
                                                            this.scrollToBottom
                                                        }
                                                        setBottomOffset={
                                                            this.setBottomOffset
                                                        }
                                                        current={
                                                            this.state
                                                                .currentTask
                                                        }
                                                        submitAnswer={
                                                            this.submitAnswer
                                                        }
                                                        timestamps={{
                                                            clientReceive:
                                                                new Date().toJSON()
                                                        }}
                                                        langId={
                                                            this.state.langId
                                                        }
                                                        src={SOURCE}
                                                        isEmbedded={
                                                            this.props
                                                                .isEmbedded
                                                        }
                                                        projectInputSettings={
                                                            projectInfo?.inputSettings
                                                        }
                                                    />
                                                )}
                                            </QuestionContainer>
                                        )}
                                    </div>
                                </div>
                                {fixedQTypes.includes(
                                    (currentTask as QuestionTask)?.question
                                        ?.type
                                ) && (
                                    <QuestionContainer
                                        ref={(e) =>
                                            (this.questionContainer = e)
                                        }
                                    >
                                        {!this.state.typistActive && ( // only show questions when typist done
                                            <QuestionSwitch
                                                isDelayed={
                                                    this.state
                                                        .responseInputDelay
                                                }
                                                scrollToBottom={
                                                    this.scrollToBottom
                                                }
                                                setBottomOffset={
                                                    this.setBottomOffset
                                                }
                                                current={this.state.currentTask}
                                                submitAnswer={this.submitAnswer}
                                                timestamps={{
                                                    clientReceive:
                                                        new Date().toJSON()
                                                }}
                                                langId={this.state.langId}
                                                src={SOURCE}
                                                isEmbedded={
                                                    this.props.isEmbedded
                                                }
                                                projectInputSettings={
                                                    projectInfo?.inputSettings
                                                }
                                            />
                                        )}
                                    </QuestionContainer>
                                )}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {/* Regular chatbot */}
                                {this.state.typistText && (
                                    <TypistContainer
                                        className={
                                            this.state.typistActive
                                                ? 'TypistActive'
                                                : 'TypistDone'
                                        }
                                    >
                                        <Typist
                                            onTypingDone={() =>
                                                this.setState({
                                                    typistActive: false
                                                })
                                            }
                                        >
                                            {this.state.typistText}
                                        </Typist>
                                    </TypistContainer>
                                )}
                                {!this.state.typistActive && // only show messages when typist done
                                    messageLogMask.map((m, i) => {
                                        if (!m) return null;
                                        const isMostRecentBotTextMsg =
                                            i == lastBotTextMessageIndex;
                                        const isMsgBlocked =
                                            this.isDigitalAvatarEnabled() &&
                                            isMostRecentBotTextMsg &&
                                            i > this.state.messageBubbleBlock;
                                        // only show edit button for most recent user response
                                        let showEdit = this.userCanEdit(
                                            m,
                                            i,
                                            lastUserMessageIndex
                                        );
                                        const disableEdit =
                                            this.state.currentQuestionValue ===
                                            (m as any).questionValue;
                                        /* @PROJECT Forcome */
                                        [989, 993].includes(projectInfo.id) &&
                                            (showEdit = false);

                                        return (
                                            <MessageBubble
                                                avatar={projectInfo.avatarImage}
                                                isMsgBlocked={isMsgBlocked}
                                                key={i}
                                                data={m}
                                                showEdit={!!showEdit}
                                                disableEdit={disableEdit}
                                                onRedoRequest={this.editAnswer}
                                                langId={this.state.langId}
                                                currentQuestionID={
                                                    (
                                                        currentTask as QuestionTask
                                                    )?.question?.id
                                                }
                                                editTextAnswer={
                                                    this.editTextAnswer
                                                }
                                            />
                                        );
                                    })}

                                <QuestionContainer
                                    ref={(e) => (this.questionContainer = e)}
                                >
                                    {!this.state.typistActive && ( // only show questions when typist done
                                        <QuestionSwitch
                                            isDelayed={
                                                this.state.responseInputDelay
                                            }
                                            scrollToBottom={this.scrollToBottom}
                                            setBottomOffset={
                                                this.setBottomOffset
                                            }
                                            current={this.state.currentTask}
                                            submitAnswer={this.submitAnswer}
                                            timestamps={{
                                                clientReceive:
                                                    new Date().toJSON()
                                            }}
                                            langId={this.state.langId}
                                            src={SOURCE}
                                            projectInputSettings={
                                                projectInfo?.inputSettings
                                            }
                                        />
                                    )}
                                </QuestionContainer>
                            </React.Fragment>
                        )}
                    </MessagesContainer>
                    {projectInfo.showProgress && (
                        <ProgressContainer>
                            <Progress
                                percent={this.state.progress}
                                theme={{
                                    error: {
                                        trailColor: 'pink',
                                        color: 'red'
                                    },
                                    default: {
                                        trailColor: theme.defaultTrail,
                                        color: theme.defaultColour
                                    },
                                    active: {
                                        trailColor: theme.activeTrail,
                                        color: theme.activeColour
                                    },
                                    success: {
                                        trailColor: theme.successTrail,
                                        color: theme.successColour
                                    }
                                }}
                            />

                            <Progress
                                type="circle"
                                percent={this.state.progress}
                                width={60}
                                theme={{
                                    error: {
                                        symbol: `${this.state.progress}%`,
                                        trailColor: 'pink',
                                        color: 'red'
                                    },
                                    default: {
                                        symbol: `${this.state.progress}%`,
                                        trailColor: theme.defaultTrail,
                                        color: theme.defaultColour
                                    },
                                    active: {
                                        symbol: `${this.state.progress}%`,
                                        trailColor: theme.activeTrail,
                                        color: theme.activeColour
                                    },
                                    success: {
                                        symbol: `${this.state.progress}%`,
                                        trailColor: theme.successTrail,
                                        color: theme.successColour
                                    }
                                }}
                            />
                        </ProgressContainer>
                    )}

                    {this.props.isEmbedded ? null : ( // @TODO fix footer in embedded bot
                        <BotFooter>
                            <div className="links">
                                <a
                                    href={`${STATIC_STORAGE_URL}/doc/Privacy Policy.pdf`}
                                    //eslint-disable-next-line react/jsx-no-target-blank
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Privacy
                                </a>
                                <a
                                    href={`${STATIC_STORAGE_URL}/doc/Terms and Conditions.pdf`}
                                    //eslint-disable-next-line react/jsx-no-target-blank
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Terms
                                </a>
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: '10px',
                                    right: '20px'
                                }}
                            >
                                <a
                                    className="by-logo"
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://nexxt.in"
                                >
                                    <img
                                        style={{ maxWidth: '200px' }}
                                        src={`${STATIC_STORAGE_URL}/img/powered_by_nexxt_intelligence_inca.png`}
                                    />
                                </a>
                            </div>{' '}
                        </BotFooter>
                    )}
                </BotContainer>
            </ThemeProvider>
        );
    }
}

export default RollbarHOC(BotApp);

declare global {
    interface Window {
        PROJECT_PACKAGE_ID: number;
        IP: string;
        USER_AGENT: string;
        BOT_MODE: BOT_MODES;
        BOT_TESTBED_MESSAGE_ID: string | undefined | null;
        DEBUG_SOCKET: boolean;
        google?: any;
    }
}
