import * as React from 'react';
import styled from 'styled-components';

/*
 * AudioVisualizer adapted from https://codepen.io/goiblas/pen/RzwQgz
 */
interface StyledAudioVisualizerProps {
    background?: string;
}

const AudioVisualizerContainer = styled.div<StyledAudioVisualizerProps>`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
        props.background ? props.background : '#e3edff'};

    .inactive-sound-bar {
        height: 3px;
        width: 50%;
        background-color: #2d67cb;
    }

    .sound-bar {
        width: 3px;
        height: 5px;
        background-color: #2d67cb;
        margin-left: 1px;
        margin-right: 1px;
        transform: scaleY(1);
        animation-duration: 0.5s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);

        &:nth-child(4n) {
            animation-name: bar-scale-xl;
            animation-duration: 12s;
        }
        &:nth-child(4) {
            animation-duration: 1.15s;
        }
        &:nth-child(3) {
            animation-name: bar-scale-lg;
            animation-duration: 0.8s;
        }
        &:nth-child(6) {
            animation-name: bar-scale-md;
            animation-duration: 0.85s;
        }

        &:nth-child(2),
        &:nth-child(5),
        &:nth-child(7),
        &:nth-child(9) {
            animation-name: bar-scale-sm;
            animation-duration: 0.9s;
        }
    }

    @keyframes bar-scale-sm {
        0%,
        50% {
            transform: scaleY(1);
        }
        25% {
            transform: scaleY(6);
        }
        75% {
            transform: scaleY(4);
        }
    }

    @keyframes bar-scale-md {
        0%,
        50% {
            transform: scaleY(2);
        }
        25% {
            transform: scaleY(6);
        }
        75% {
            transform: scaleY(5);
        }
    }

    @keyframes bar-scale-lg {
        0%,
        50% {
            transform: scaleY(8);
        }
        25% {
            transform: scaleY(4);
        }
        75% {
            transform: scaleY(6);
        }
    }

    @keyframes bar-scale-xl {
        0%,
        50% {
            transform: scaleY(1);
        }
        25% {
            transform: scaleY(7);
        }
        75% {
            transform: scaleY(11);
        }
    }
`;

interface AudioVisualizerProps {
    isActive: boolean;
    background?: string;
}

export function AudioVisualizer({
    isActive,
    background
}: AudioVisualizerProps) {
    return (
        <AudioVisualizerContainer background={background}>
            {isActive ? (
                <>
                    <div className="sound-bar"></div>
                    <div className="sound-bar"></div>
                    <div className="sound-bar"></div>
                    <div className="sound-bar"></div>
                    <div className="sound-bar"></div>
                    <div className="sound-bar"></div>
                    <div className="sound-bar"></div>
                    <div className="sound-bar"></div>
                    <div className="sound-bar"></div>
                    <div className="sound-bar"></div>
                </>
            ) : (
                <div className="inactive-sound-bar"></div>
            )}
        </AudioVisualizerContainer>
    );
}
