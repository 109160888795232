import * as React from 'react';
import { Question, Answer } from '@nexxt/common/types';

export interface BaseProps {
    question: Question;
    previousAnswer?: Answer;
    langId: string;
    scrollToBottom?: () => void;
    setBottomOffset?: (offset: number) => void;
    setPopupOffset?: (offset: number) => void;
    t?: (text: string, options?: Record<string, number>) => string; // translate function
    unitTest?: boolean;
}

export class BaseInput<P extends BaseProps, S> extends React.Component<P, S> {}
