import * as React from 'react';
import 'emoji-mart/css/emoji-mart.css';
import './../style/EmojiPicker.css';
import { Picker } from 'emoji-mart';
import { getTranslation } from '@nexxt/common/services/TextService';
const { translate } = require('react-i18next');

interface Props {
    addEmoji: (emoji) => void;
    t?: (text: string) => string;
}

@translate('translation')
export class EmojiPicker extends React.Component<Props, unknown> {
    render() {
        const { t } = this.props;
        const i18n_entries = {
            search: getTranslation(t, 'LABEL.EMOJI_PICKER.SEARCH'),
            categories: {
                search: getTranslation(
                    t,
                    'LABEL.EMOJI_PICKER.CATEGORIES.SEARCH'
                ),
                recent: getTranslation(
                    t,
                    'LABEL.EMOJI_PICKER.CATEGORIES.RECENT'
                ),
                people: getTranslation(
                    t,
                    'LABEL.EMOJI_PICKER.CATEGORIES.PEOPLE'
                ),
                nature: getTranslation(
                    t,
                    'LABEL.EMOJI_PICKER.CATEGORIES.NATURE'
                ),
                foods: getTranslation(t, 'LABEL.EMOJI_PICKER.CATEGORIES.FOODS'),
                activity: getTranslation(
                    t,
                    'LABEL.EMOJI_PICKER.CATEGORIES.ACTIVITY'
                ),
                places: getTranslation(
                    t,
                    'LABEL.EMOJI_PICKER.CATEGORIES.PLACES'
                ),
                objects: getTranslation(
                    t,
                    'LABEL.EMOJI_PICKER.CATEGORIES.OBJECTS'
                ),
                symbols: getTranslation(
                    t,
                    'LABEL.EMOJI_PICKER.CATEGORIES.SYMBOLS'
                ),
                flags: getTranslation(t, 'LABEL.EMOJI_PICKER.CATEGORIES.FLAGS')
            }
        };

        return (
            <React.Fragment>
                <Picker
                    autoFocus={true}
                    onSelect={this.props.addEmoji}
                    style={{
                        position: 'absolute',
                        bottom: '20px',
                        right: '20px'
                    }}
                    native={true}
                    i18n={i18n_entries}
                />
            </React.Fragment>
        );
    }
}
