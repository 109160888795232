export interface ITranslateCore {
    translateX?: any;
    translateY?: any;
}

import { Color } from 'csstype';

export interface JourneyMidpointData {
    type: JOURNEY_ELEMENT_TYPES.MIDPOINT;
    imgUrl: string;
    text: string;
}

export type IJourneyBank = IPopupOptions;

export type IJourneySmiley = IPopupOptions;

export interface IPopupOptions {
    id: string;
    texts: IPopupOptionsTexts[];
    altTexts: IPopupOptionsAltTexts[];
    imgUrl?: string;
    value: any; // mainly number
    label: string;
    tags: any[];
}

export interface IPopupOptionsTexts {
    language: string; // language Id
    text: string;
}
export interface IPopupOptionsAltTexts {
    language: string; // language Id
    imgAltText: string;
}
export enum JOURNEY_STEPS {
    PREFILTER = 'PREFILTER_STEPS',
    INSTRUCTION = 'INSTRUCTION',
    ADD_ITEMS = 'ADD_ITEMS',
    JOURNEY_CONFIRM = 'JOURNEY_CONFIRM',
    ADD_ITEMS_EXTRA = 'ADD_ITEMS_EXTRA',
    FOLLOW_UP = 'FOLLOW_UP',

    ADD_EMOJI = 'ADD_EMOJI',
    FINAL_JOURNEY = 'FINAL_JOURNEY',
    SELECT_STEPS = 'SELECT_STEPS'
}

export enum JOURNEY_ELEMENT_TYPES {
    OPTION = 'in.nexxt/journey/etype/option',
    STARTPOINT = 'in.nexxt/journey/etype/startpoint',
    MIDPOINT = 'in.nexxt/journey/etype/midpoint',
    ENDPOINT = 'in.nexxt/journey/etype/endpoint',
    DROPSPOT = 'in.nexxt/journey/etype/dropspot'
}
export interface JourneyOptionData {
    id: string;
    type: JOURNEY_ELEMENT_TYPES.OPTION;
    isSelected: boolean;
    color: Color;
    bgcolor: Color;
    text: string;
    listType: string; // "Actively Looking for" | "Happened To";
    bank: IJourneyBank[]; // image, text, id
    textResponse: string; // user's response to text q
    smiley: IJourneySmiley | null; // image, text, id
    bucketIndex: number;
    removable: boolean;
    editable: boolean;
    followUpAnswers?: any[];
}

export interface IJourneyBuilderAdd {
    type: JOURNEY_ELEMENT_TYPES.DROPSPOT;
}

export type JourneyElementData =
    | JourneyOptionData
    | JourneyMidpointData
    | IJourneyBuilderAdd;

export interface JourneySettings {
    startpointImageUrl: string;
    endpointImageUrl: string;
    midpoints: { imgUrl: string; text: string; value: number }[];
    buckets: {
        label: string;
        options: { id: number; text: string }[];
        color: string;
        bgcolor: string;
        allowNewOptions: boolean;
        persistOptions: boolean;
        instructions: { text: string; style: any }[];
        validationType: 'ALL' | 'NONE' | 'MIN';
    }[];
    minSteps: number;
    maxSteps: number;
    // PREFILTER_STEPS

    // INSTRUCTION
    instructionalMediaURL: string;

    // JOURNEY_CONFIRM
    journeyConfirmTexts: string[];
}
