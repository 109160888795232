import { BMTYPES, Message, QuestionMessage, Task } from '@nexxt/common/types';
import { last } from '@nexxt/common/utils/index';
import { ENGLISH } from '@nexxt/common/constants';
/*
 * Message + MessageLog handling functions.
 * NOTE: `messageLog` is used to render all messages sent
 * during the current (uninterrupted) session on the client side
 * It does not interface with the server-side, so it is not
 * a concern for serverless setup, except inasmuch as reconnected
 * sessions should display messages prior to the reconnection.
 */

export const removeTrailingTypingIndicator = (
    messages: Message[]
): Message[] => {
    const [last] = messages.slice(-1);
    if (last && last.type === BMTYPES.TYPING) {
        return messages.slice(0, -1);
    } else {
        return messages.slice();
    }
};

export const appendToLog = (messages: Message[], m: Message): Message[] => {
    return removeTrailingTypingIndicator(messages).concat([m]);
};

export const genMessageLog = (curr: Task, messages: Message[]): Message[] => {
    let newMessages = removeTrailingTypingIndicator(messages);
    if (curr) {
        newMessages = curr.messageLog.reduce(addOrReplaceMessage, newMessages);
    }
    return newMessages;
};

export const addOrReplaceMessage = (
    curr: Message[],
    mToAdd: Message
): Message[] => {
    const newArr = curr.concat();
    for (let i = 0; i < curr.length; i++) {
        if (curr[i].uid === mToAdd.uid) {
            curr[i] = mToAdd;
            return newArr;
        }
    }
    return newArr.concat([mToAdd]);
};

// return progress from most recent msg containing progress, or else 0
export const getLatestProgress = (messages: Message[]): number => {
    const lastProgressMsg = last(
        messages.filter((m) => m.type == BMTYPES.QUESTION && m.progress)
    ) as QuestionMessage;
    return lastProgressMsg ? lastProgressMsg.progress : 0;
};

// return langId from most recent msg containing lang, or else ENGLISH
export const getLatestLanguage = (messages: Message[]): string => {
    const lastLangMessage = last(
        messages.filter((m) => m.type == BMTYPES.QUESTION && m.langId)
    ) as QuestionMessage;
    return lastLangMessage ? lastLangMessage.langId : ENGLISH;
};
