import * as React from 'react';
import '../style/Banner.scss';
interface BannerProps {
    text: string;
}

const Banner = ({ text }: BannerProps) => {
    return <div className="banner--container">{text.toUpperCase()}</div>;
};

export default Banner;
