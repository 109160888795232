import * as React from 'react';
import { TextAnnotation } from '@nexxt/common/types';

interface TextHighlighterChunkProps {
    text: string;
    chunkIndex: number;
    appliedAnnotation: TextAnnotation;
    handleUpInteraction: (e, appliedAnnotation, x, y) => void;
    updateCurrentAnnotation: (keyName: string, chunkIndex: number) => void;
    classNameString: string;
    style: React.CSSProperties;
}

const TextHighlighterChunk: React.FunctionComponent<TextHighlighterChunkProps> =
    ({
        text,
        chunkIndex,
        appliedAnnotation,
        handleUpInteraction,
        updateCurrentAnnotation,
        classNameString,
        style
    }: TextHighlighterChunkProps) => {
        return (
            <span
                onTouchEnd={(e) => {
                    handleUpInteraction(
                        e,
                        appliedAnnotation,
                        e.changedTouches[0].clientX,
                        e.changedTouches[0].clientY
                    );
                }}
                onTouchStart={(e) => {
                    e.preventDefault();
                    updateCurrentAnnotation('startWordIndex', chunkIndex);
                }}
                onMouseUp={(e) => {
                    handleUpInteraction(
                        e,
                        appliedAnnotation,
                        e.clientX,
                        e.clientY
                    );
                }}
                onMouseDown={() => {
                    updateCurrentAnnotation('startWordIndex', chunkIndex);
                }}
                data-index={chunkIndex}
                className={classNameString}
                style={style}
            >
                {text}
            </span>
        );
    };

export default TextHighlighterChunk;
