import * as React from 'react';
import Modal from '../../components/Modal';
import { UilTimes } from '@iconscout/react-unicons';

interface JourneyElementDeleteModalProps {
    openModal: boolean;
    toggleModal: (toggle: boolean) => void;
    onConfirm: () => void;
    onCancel: () => void;
}

export const JourneyElementDeleteModal: React.FC<JourneyElementDeleteModalProps> =
    (props: JourneyElementDeleteModalProps) => {
        return (
            <Modal>
                <div className="modal--blur"></div>
                <div className="journey--modal delete">
                    <div
                        className="close--button"
                        onClick={() => props.toggleModal(false)}
                    >
                        <UilTimes size={20} />
                    </div>
                    <div className="journey--modal--wrapper delete">
                        <p>
                            You may remove this option if you feel it is no
                            longer relevant. Please note that this will remove
                            this option from your list and it will no longer be
                            available.
                        </p>
                        <p>Are you sure you would like to remove?</p>
                        <div style={{ display: 'flex' }}>
                            <button
                                className={`journeyq-button button-hg button-cancel`}
                                onClick={props.onCancel}
                            >
                                Cancel
                            </button>
                            <button
                                className={`journeyq-button button-hg button-continue`}
                                onClick={props.onConfirm}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };
