import {
    UserMessage,
    BotMessage,
    ProjectID,
    ProjectInfo,
    SessionDef,
    SOCKET_EVENTS,
    SESSION_TYPES,
    PanelQueryParams,
    Answer,
    INVALID_ACCESS_TYPES
} from '@nexxt/common/types';
import { parseObject } from '@nexxt/common/utils/index';

type MessageListener = (m: BotMessage, answer: Answer) => void;
type SyncListener = ({
    project
}: {
    project: ProjectInfo;
    respondentId: number;
}) => void;
type DisconnectListener = (reason: string) => void;
type InitChatReplyListener = (l: { sessionId: string }) => void;

export interface BotConnection {
    send: (message: UserMessage, edit?: boolean) => void;
    addMessageListener: (listener: MessageListener) => void;
    addSyncListener: (l: SyncListener) => void;
    addDisconnectListener: (l: DisconnectListener) => void;
    initChat: <T>(
        packageID: ProjectID,
        ip: string,
        userAgent: string,
        deviceType: string,
        connectionMode: string,
        sessionId?: string,
        localHour?: number,
        quotaGroup?: string,
        testMode?: boolean,
        panelQueryParams?: PanelQueryParams,
        terminateType?: string,
        isEmbedded?: boolean
    ) => void;
    getSessionID: (l: InitChatReplyListener) => void;
    testbed: (messageId?: string | null) => void;
    monitor: () => void;
    close: () => void;
    getStatus: () => number;
    sendConnectionCheck: () => void;
    addInvalidAccessListener: (l: (type: INVALID_ACCESS_TYPES) => void) => void;
}

export class SocketConnect {
    private ws;

    constructor() {
        this.ws = new WebSocket(process.env.BOT_WS_URL);
        this.ws.onopen = (event) => {
            // on connecting, do nothing but log it to the console
            console.log('connected');
        };

        this.ws.onerror = (event) => {
            console.error('WebSocket error', JSON.stringify(event));
            this.ws.terminate();
        };

        this.ws.onclose = (event) => {
            console.log(
                'Closed socket connection from bot-client',
                JSON.stringify(event)
            );
        };
    }

    initChat(
        packageID: number,
        ip: string,
        userAgent: string,
        deviceType: string,
        connectionMode: string,
        sessionId?: string,
        localHour?: number,
        quotaGroup?: string,
        testMode?: boolean,
        panelQueryParams?: PanelQueryParams,
        terminateType?: string,
        isEmbedded?: boolean
    ) {
        this.ws.send(
            JSON.stringify({
                eventType: SOCKET_EVENTS.INIT,
                type: SESSION_TYPES.USER,
                id: sessionId,
                ip,
                userAgent,
                deviceType,
                packageID,
                sessionId,
                messageId: null,
                localHour,
                connectionMode,
                quotaGroup,
                testMode,
                panelQueryParams,
                terminateType,
                isEmbedded
            } as SessionDef)
        );
    }

    getStatus() {
        return this.ws.readyState;
    }

    getSessionID(l): void {
        this.ws.addEventListener('message', (evt) => {
            const parsedMessage = parseObject(evt.data);
            if (parsedMessage.eventType === SOCKET_EVENTS.INIT_CHAT_REPLY) {
                l(parsedMessage);
            }
        });
    }
    send(message: UserMessage, edit = false): void {
        this.ws.send(
            JSON.stringify({
                eventType: edit
                    ? SOCKET_EVENTS.EDIT_MESSAGE
                    : SOCKET_EVENTS.USER_MESSAGE,
                ...message
            })
        );
    }
    sendConnectionCheck(): void {
        this.ws.send(
            JSON.stringify({ eventType: SOCKET_EVENTS.CONNECTION_CHECK })
        );
    }
    addMessageListener(listenerCallback: MessageListener): void {
        this.ws.addEventListener('message', (evt) => {
            const parsedMessage = parseObject(evt.data);
            const { message, answer } = parsedMessage;
            if (parsedMessage.eventType === SOCKET_EVENTS.BOT_MESSAGE) {
                listenerCallback(message, answer);
            }
        });
    }

    addSyncListener(listenerCallback: SyncListener): void {
        this.ws.addEventListener('message', (evt) => {
            const parsedMessage = parseObject(evt.data);
            if (parsedMessage.eventType === SOCKET_EVENTS.SYNC) {
                listenerCallback(parsedMessage);
            }
        });
    }

    addInvalidAccessListener(listenerCallback): void {
        this.ws.addEventListener('message', (evt) => {
            const parsedMessage = parseObject(evt.data);
            if (parsedMessage.eventType === SOCKET_EVENTS.INVALID) {
                listenerCallback(parsedMessage.type);
            }
        });
    }
    addDisconnectListener(listenerCallback: DisconnectListener): void {
        this.ws.addEventListener('message', (evt) => {
            const parsedMessage = parseObject(evt.data);
            if (parsedMessage.eventType === SOCKET_EVENTS.DISCONNECT) {
                listenerCallback(parsedMessage);
            }
        });
    }

    testbed(messageId: string | null): void {
        this.ws.send(
            JSON.stringify({
                eventType: SOCKET_EVENTS.TESTBED,
                messageId,
                type: SESSION_TYPES.TESTBED
            })
        );
    }

    monitor(): void {
        this.ws.send(
            JSON.stringify({
                eventType: SOCKET_EVENTS.MONITOR
            })
        );
    }

    close(): void {
        // Send DISCONNECTION message to server so that the last session get stored to db properly
        this.ws.send(JSON.stringify({ eventType: SOCKET_EVENTS.DISCONNECT }));
        this.ws.close();
    }
}
