export const dataTestid = {
    CONTINUEBTN: 'continue-btn',
    NABTN: 'not-applicable-btn',
    SLIDERTEXT: 'slider-text',
    TEXTAREA: 'textarea',
    TRADEOFF: 'tradeoff',
    VIDEO: 'video',
    POPUPBTN: 'popup-buttons',
    FABUTTON: 'fa-btn'
};
