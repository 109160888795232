import { createGlobalStyle } from 'styled-components';
import { COLOR_THEME, STATIC_STORAGE_URL } from '@nexxt/common/constants';
import { QTYPES } from '@nexxt/common/types';

export const BASE_THEME = {
    teal: COLOR_THEME.blue,
    darkBlue: '#17305d',
    yellow: COLOR_THEME.yellow,
    green: COLOR_THEME.green,
    purple: '#673598',
    purpleSky: '#34293f',
    black: '#333',
    red: COLOR_THEME.red,
    pink: COLOR_THEME.pink,
    borderRadius: '7px',
    boxShadow: '0 5px 10px #c4dbf2',
    dummyColor: '#bf0cc7',
    white: COLOR_THEME.white
};
export const BOT_THEMES = {
    purple_sky: {
        background: BASE_THEME.purpleSky,
        stars: 'block',
        botLinks: 'white',
        sliderText: 'white',
        clouds: 'none',
        dotsTriangles: 'none',
        squares: 'none',
        bubbleShadow: '2px 2px 4px #252526',
        multiOptionBorder: `3px solid ${BASE_THEME.purpleSky}`,
        multiOptionCheck: BASE_THEME.teal,
        multiOptionText: 'white',
        defaultColour: BASE_THEME.green,
        continueBtn: BASE_THEME.green,
        defaultTrail: 'rgba(128, 199, 250, 0.4)',
        activeColour: BASE_THEME.yellow,
        activeTrail: 'rgba(255, 216, 7, 0.3)',
        successColour: BASE_THEME.teal,
        successTrail: BASE_THEME.green,
        progressText: 'rgba(255, 255, 255, 0.7)',
        userBubble: BASE_THEME.teal,
        userBubbleEditing: BASE_THEME.darkBlue,
        sliderRail: BASE_THEME.teal,
        sliderMark: BASE_THEME.teal,
        sliderHandle: BASE_THEME.teal,
        sliderHandleActive: BASE_THEME.purple,
        sliderDot: 'white',
        backgroundSize: 'auto',
        backgroundAnimation: 'none',
        userEdit: '#71716f',
        userEditHover: BASE_THEME.teal,
        focusState: BASE_THEME.yellow,
        toastifyBgColor: BASE_THEME.yellow,
        toastifyTextColor: 'black',
        avatarBgColor: 'transparent'
    },
    custom: {
        background: '#ececec',
        stars: 'none',
        clouds: 'none',
        dotsTriangles: 'none',
        squares: 'none',
        botLinks: '#666',
        sliderText: BASE_THEME.black,
        bubbleShadow: '2px 2px 4px #cccccc',
        multiOptionBorder: `3px solid white`,
        continueBtn: '#D6B456',
        multiOptionText: BASE_THEME.black,
        multiOptionCheck: '#6BA1A9',
        defaultColour: BASE_THEME.green,
        defaultTrail: 'rgba(128, 199, 250, 0.4)',
        activeColour: '#6BA1A9',
        activeTrail: '#D6B456',
        successColour: BASE_THEME.teal,
        successTrail: BASE_THEME.green,
        progressText: BASE_THEME.black,
        sliderRail: BASE_THEME.teal,
        sliderMark: BASE_THEME.teal,
        sliderHandle: BASE_THEME.teal,
        sliderHandleActive: BASE_THEME.teal,
        sliderDot: 'white',
        backgroundSize: 'auto',
        backgroundAnimation: 'none',
        userEdit: '#71716f',
        userEditHover: BASE_THEME.teal,
        userBubble: '#6BA1A9',
        userBubbleEditing: '#50777d',

        botMessageBg: '#D6B456',
        botMessageBgHover: '#6BA1A9',

        botMessageColor: BASE_THEME.white,
        botMessageColorHover: BASE_THEME.white,
        //Not applicable, continue...
        buttonBgHover: '#D9BAB5',

        multipleChoice: '#D6B456',
        focusState: 'black',
        toastifyBgColor: BASE_THEME.green,
        toastifyTextColor: 'white',
        avatarBgColor: 'transparent'
    },
    white: {
        // background: "#f2f2f2",
        background: '#FFFFFF',
        stars: 'none',
        clouds: 'none',
        dotsTriangles: 'none',
        squares: 'none',
        botLinks: '#666',
        sliderText: BASE_THEME.black,
        bubbleShadow: '2px 2px 4px #cccccc',
        multiOptionBorder: `3px solid white`,
        continueBtn: BASE_THEME.green,
        multiOptionText: BASE_THEME.black,
        defaultColour: BASE_THEME.green,
        defaultTrail: 'rgba(128, 199, 250, 0.4)',
        activeColour: BASE_THEME.yellow,
        activeTrail: 'rgba(255, 216, 7, 0.3)',
        successColour: BASE_THEME.teal,
        successTrail: BASE_THEME.green,
        progressText: 'rgba(255, 255, 255, 0.7)',
        userBubble: BASE_THEME.teal,
        userBubbleEditing: BASE_THEME.darkBlue,
        multiOptionCheck: BASE_THEME.teal,

        sliderRail: BASE_THEME.teal,
        sliderMark: BASE_THEME.teal,
        sliderHandle: BASE_THEME.teal,
        sliderHandleActive: BASE_THEME.teal,
        sliderDot: 'white',
        backgroundSize: 'auto',
        backgroundAnimation: 'none',
        userEdit: '#71716f',
        userEditHover: BASE_THEME.teal,
        focusState: 'black',
        toastifyBgColor: BASE_THEME.green,
        toastifyTextColor: 'white',
        avatarBgColor: 'transparent'
    },
    gradients: {
        background:
            'linear-gradient(-45deg, #ffd807, rgba(232, 13, 144, 0.58), rgba(0, 147, 252, 0.66), #0cc7b7)',
        stars: 'none',
        clouds: 'none',
        botLinks: 'white',
        squares: 'none',
        dotsTriangles: 'none',
        sliderText: BASE_THEME.black,
        bubbleShadow: '2px 2px 4px rgba(37, 37, 38, .5)',
        multiOptionBorder: '1px solid hsla(51,100%,32%,1)',
        multiOptionText: BASE_THEME.black,
        defaultColour: BASE_THEME.green,
        continueBtn: BASE_THEME.green,
        defaultTrail: 'rgba(128, 199, 250, 0.4)',
        activeColour: BASE_THEME.yellow,
        activeTrail: 'rgba(255, 216, 7, 0.3)',
        successColour: BASE_THEME.teal,
        successTrail: BASE_THEME.green,
        progressText: 'rgba(255, 255, 255, 0.7)',
        userBubble: 'transparent',
        userBubbleEditing: 'transparent',
        multiOptionCheck: BASE_THEME.teal,
        sliderRail: 'rgba(37, 41, 41, 0.76)',
        sliderHandle: 'white',
        sliderMark: 'white',
        sliderHandleActive: 'white',
        sliderDot: 'rgba(255, 255, 255, 0.6)',
        backgroundSize: '400% 400%',
        backgroundAnimation: 'Gradient 15s ease infinite',
        userEdit: '#71716f',
        userEditHover: BASE_THEME.teal,
        focusState: 'black',
        toastifyBgColor: '#6d38ec',
        toastifyTextColor: 'white',
        avatarBgColor: 'transparent'
    },
    orangeClouds: {
        background:
            'radial-gradient(circle, #F9A72B 0%, #FA9026 70%, #FB6C1F 100%)',
        backgroundSize: 'auto',
        backgroundAnimation: 'none',
        stars: 'none',
        clouds: 'block',
        squares: 'none',
        dotsTriangles: 'none',
        botLinks: 'white',
        sliderText: BASE_THEME.black,
        continueBtn: BASE_THEME.green,
        bubbleShadow: '2px 2px 4px rgba(37, 37, 38, .25)',
        multiOptionBorder: '3px solid #fa9f29',
        multiOptionText: BASE_THEME.black,
        defaultColour: BASE_THEME.green,
        defaultTrail: 'rgba(128, 199, 250, 0.4)',
        activeColour: BASE_THEME.yellow,
        activeTrail: 'rgba(255, 216, 7, 0.3)',
        successColour: BASE_THEME.teal,
        successTrail: BASE_THEME.green,
        progressText: 'rgba(255, 255, 255, 0.7)',
        userBubble: BASE_THEME.teal,
        userBubbleEditing: BASE_THEME.darkBlue,
        multiOptionCheck: BASE_THEME.teal,
        sliderRail: BASE_THEME.teal,
        sliderMark: BASE_THEME.teal,
        sliderHandle: BASE_THEME.teal,
        sliderHandleActive: BASE_THEME.teal,
        sliderDot: 'white',
        userEdit: '#71716f',
        userEditHover: BASE_THEME.teal,
        focusState: 'black',
        toastifyBgColor: BASE_THEME.teal,
        toastifyTextColor: 'white',
        avatarBgColor: 'transparent'
    },
    floatingSquares: {
        background: 'linear-gradient(to left, #8f94fb, #4e54c8);  ',
        backgroundSize: 'auto',
        backgroundAnimation: 'none',
        squares: 'block',
        dotsTriangles: 'none',
        stars: 'none',
        clouds: 'none',
        botLinks: 'white',
        sliderText: 'white',
        bubbleShadow: '2px 2px 4px rgba(37, 37, 38, .2)',
        multiOptionBorder: '3px solid #696edd',
        multiOptionText: 'white',
        continueBtn: '#27bdfb',
        defaultColour: BASE_THEME.green,
        defaultTrail: 'rgba(128, 199, 250, 0.4)',
        activeColour: BASE_THEME.yellow,
        activeTrail: 'rgba(255, 216, 7, 0.3)',
        successColour: BASE_THEME.teal,
        successTrail: BASE_THEME.green,
        progressText: 'rgba(255, 255, 255, 0.7)',
        userBubble: BASE_THEME.teal,
        userBubbleEditing: BASE_THEME.darkBlue,
        multiOptionCheck: BASE_THEME.teal,
        sliderRail: BASE_THEME.teal,
        sliderMark: BASE_THEME.teal,
        sliderHandle: BASE_THEME.teal,
        sliderHandleActive: BASE_THEME.teal,
        sliderDot: 'white',
        userEdit: '#71716f',
        userEditHover: BASE_THEME.teal,
        focusState: 'black',
        toastifyBgColor: BASE_THEME.yellow,
        toastifyTextColor: 'black',
        avatarBgColor: 'transparent'
    },
    dotsAndTriangles: {
        background: '#4e54c8',
        backgroundSize: 'auto',
        backgroundAnimation: 'none',
        dotsTriangles: 'block',
        squares: 'none',
        stars: 'none',
        clouds: 'none',
        botLinks: 'white',
        sliderText: 'black',
        bubbleShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)',
        multiOptionBorder: '1px solid hsla(51,100%,12%,1)',
        multiOptionText: 'white',
        continueBtn: '#27bdfb',
        defaultColour: BASE_THEME.green,
        defaultTrail: 'rgba(128, 199, 250, 0.4)',
        activeColour: BASE_THEME.yellow,
        activeTrail: 'rgba(255, 216, 7, 0.3)',
        successColour: BASE_THEME.teal,
        successTrail: BASE_THEME.green,
        progressText: 'rgba(255, 255, 255, 0.7)',
        userBubble: 'rgba(0, 0, 0, 0.6)',
        userBubbleEditing: 'rgba(0, 0, 0, 0.6)',
        multiOptionCheck: 'rgba(0, 0, 0, 0.6)',
        sliderRail: BASE_THEME.teal,
        sliderMark: BASE_THEME.teal,
        sliderHandle: BASE_THEME.teal,
        sliderHandleActive: BASE_THEME.teal,
        sliderDot: 'white',
        userEdit: '#71716f',
        userEditHover: BASE_THEME.teal,
        focusState: 'black',
        toastifyBgColor: BASE_THEME.yellow,
        toastifyTextColor: 'black',
        avatarBgColor: 'transparent'
    },
    [QTYPES.GUIDED_FANTASY]: {
        background: 'black',
        stars: 'block',
        botLinks: 'white',
        sliderText: 'white',
        clouds: 'none',
        dotsTriangles: 'none',
        squares: 'none',
        bubbleShadow: '2px 2px 4px #252526',
        multiOptionBorder: `3px solid ${BASE_THEME.purpleSky}`,
        multiOptionCheck: BASE_THEME.teal,
        multiOptionText: 'white',
        defaultColour: BASE_THEME.green,
        continueBtn: BASE_THEME.green,
        defaultTrail: 'rgba(128, 199, 250, 0.4)',
        activeColour: BASE_THEME.yellow,
        activeTrail: 'rgba(255, 216, 7, 0.3)',
        successColour: BASE_THEME.teal,
        successTrail: BASE_THEME.green,
        progressText: 'rgba(255, 255, 255, 0.7)',
        userBubble: BASE_THEME.teal,
        userBubbleEditing: BASE_THEME.darkBlue,
        sliderRail: BASE_THEME.teal,
        sliderMark: BASE_THEME.teal,
        sliderHandle: BASE_THEME.teal,
        sliderHandleActive: BASE_THEME.purple,
        sliderDot: 'white',
        backgroundSize: 'auto',
        backgroundAnimation: 'none',
        userEdit: BASE_THEME.white,
        userEditHover: BASE_THEME.teal,
        focusState: BASE_THEME.yellow,
        toastifyBgColor: BASE_THEME.yellow,
        toastifyTextColor: 'black',
        avatarBgColor: 'white'
    }
};

type Props = {
    botTheme: {
        avatarBgColor: string;
        background: string;
        stars: string;
        clouds: string;
        dotsTriangles: string;
        squares: string;
        botLinks: string;
        sliderText: string;
        bubbleShadow: string;
        multiOptionBorder: string;
        multiOptionText: string;
        userBubble: string;
        userBubbleEditing: string;
        sliderRail: string;
        sliderMark: string;
        sliderHandle: string;
        sliderHandleActive: string;
        sliderDot: string;
        backgroundSize: string;
        backgroundAnimation: string;
        continueBtn: string;
        userEdit: string;
        userEditHover: string;
        botMessageBg: string;
        botMessageBgHover: string;
        botMessageColor: string;
        botMessageColorHover: string;
        multipleChoice: string;
        multiOptionCheck: string;
        focusState: string;
        toastifyBgColor: string;
        toastifyTextColor: string;
    };
};

export const GlobalStyles = createGlobalStyle<Props>`
  .bot-app {
    font-family: 'Open Sans', sans-serif;
    background: ${({ botTheme }) => botTheme.background};
    font-size: 14px;
    color:  ${BASE_THEME.black};
    background-size: ${({ botTheme }) => botTheme.backgroundSize};
    animation: ${({ botTheme }) => botTheme.backgroundAnimation};
  }
  .keyboardNavigation :focus {
    outline: 2px solid ${({ botTheme }) => botTheme.focusState};
    outline-offset: 5px;
}
  .Toastify__toast {
    min-height: 40px !important;
    text-align: center !important;
    border-radius: 40px !important;
    max-width: 200px !important;
    margin: auto;
  }

  .Toastify__toast--default {
    background: ${({ botTheme }) => botTheme.toastifyBgColor} !important;
    color: ${({ botTheme }) => botTheme.toastifyTextColor}!important;
    &.bottom-offset-default {
      margin-bottom: 40px;
    }
    &.bottom-offset-text-prompt {
      margin-bottom: 160px;
    }
    &.bottom-offset-popup {
      margin-bottom: 180px;
    }
    &.bottom-offset-fixed-footer {
      margin-bottom: 200px;
    }
  }

  .stars {
    display: ${({ botTheme }) => botTheme.stars};
  }
  #Clouds {
    display: ${({ botTheme }) => botTheme.clouds};
  }
  .squares {
    display: ${({ botTheme }) => botTheme.squares};
  }
  .dots-triangles {
    display: ${({ botTheme }) => botTheme.dotsTriangles};
  }
  .bot-width {
    max-width: 1100px;
    padding: 1%;
    margin: 0 auto;
  }
  .slider-color {
    background: ${({ botTheme }) => botTheme.sliderMark};

  }
  .modal-root {
    /* max-width: 1080px; */
    width:80%;
    overflow: hidden;
    z-index: 9999;
    background-color: $purple-sky;
    /* position: absolute; */
    margin: 0 auto;
    max-height: 100%;
    /* transform: translate(-50%, -50%); */
       @media (max-width: 1024px) {
      width: 100%
    }
  }


/* SLIDER */
  .rc-slider-rail {
    background-color:  ${({ botTheme }) => botTheme.sliderRail};
  }
  .rc-slider-mark-text-active span {
    background: ${({ botTheme }) => botTheme.sliderMark};
    border-radius: 2px;
    color: black !important;
    font-size: 18px;
  }


  // .wc-slider{
  //   margin: 389px auto;
  // }

/* Multi Options */
  .multi-options-container {
    padding: 0;
    display: flex;
    justify-content: center !important;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 900px;
    list-style-type: none;
    &.custom-size {
        max-width: none;
    }
    &.pure-columns-1 {
        max-width: 200px;
    }
    &.pure-columns-2 {
        max-width: 330px;
        &.big {
            max-width: 900px;
        }
    }
    &.pure-columns-3 {
        max-width: 500px;
        &.big {
            max-width: 1100px;
        }
    }
    &.pure-columns-4 {
        max-width: 640px;
    }

    &.pure-columns-5 {
        max-width: 800px;
    }
    &.pure-columns-6 {
        max-width: 960px;
    }
    &.pure-columns-7,
    &.pure-columns-8,
    &.pure-columns-9,
    &.pure-columns-10,
    &.pure-columns-11,
    &.pure-columns-12 {
        max-width: 1100px;
    }
    @media (max-width: 450px) {
      font-size: 90%;
    }
    &.two-columns {
      max-width: 650px;
    }
    &.with-image {
      max-width: 100%;
    }
  }
  .multiple-options {
    padding: 0;
    position: relative;
    border-radius: ${BASE_THEME.borderRadius};
    border: 4px transparent solid;
    text-align: center;
    &:active {
      outline: ${({ botTheme }) => BASE_THEME.teal};
    }
    p {
      margin: 0;
      padding: 2px;
      font-weight: bold;
      color: ${({ botTheme }) => botTheme.multiOptionText};
    }
    p:nth-child(2) {
      @media (max-width: 450px) {
        font-size: 90%;
      }
    }
    &.textOnly {
      p {
        color: ${({ botTheme }) =>
            botTheme.botMessageColor
                ? botTheme.botMessageColor
                : BASE_THEME.black};
      }
      .option {
        background: ${({ botTheme }) =>
            botTheme.botMessageBg ? botTheme.botMessageBg : BASE_THEME.yellow};
        &:hover {
          background: ${({ botTheme }) =>
              botTheme.botMessageBgHover
                  ? botTheme.botMessageBgHover
                  : BASE_THEME.teal};
          p {
            color: ${({ botTheme }) =>
                botTheme.botMessageColorHover
                    ? botTheme.botMessageColorHover
                    : BASE_THEME.white};
          }
        }
      }
      
    }
    &.selected { background: ${BASE_THEME.teal}; }
    img {
      width: 100%;
      border-radius: ${BASE_THEME.borderRadius};
      object-fit: contain;
      max-height: 70vh;
    }

    .multiple-option {
      &.textOnly {
        .option {
          p {
            color: ${({ botTheme }) =>
                botTheme.botMessageColor
                    ? botTheme.botMessageColor
                    : BASE_THEME.black};
          }
          background: ${({ botTheme }) =>
              botTheme.botMessageBg
                  ? botTheme.botMessageBg
                  : BASE_THEME.yellow};
          @media (hover: hover) {
            &:hover {
              background: ${({ botTheme }) =>
                  botTheme.botMessageBgHover
                      ? botTheme.botMessageBgHover
                      : BASE_THEME.teal};
              p {
                color: ${({ botTheme }) =>
                    botTheme.botMessageColorHover
                        ? botTheme.botMessageColorHover
                        : BASE_THEME.white};
              }
            }
          }
        }
        &.selected, &.active {
          .option {
            p {
              color: ${({ botTheme }) =>
                  botTheme.botMessageColorHover
                      ? botTheme.botMessageColorHover
                      : BASE_THEME.white};
            }
            background: ${({ botTheme }) =>
                botTheme.botMessageBgHover
                    ? botTheme.botMessageBgHover
                    : BASE_THEME.teal};
          }
        }
        &.disabled {
          .option {
            p {
            color: ${({ botTheme }) =>
                botTheme.botMessageColor
                    ? botTheme.botMessageColor
                    : BASE_THEME.black};
          }
            background: ${({ botTheme }) =>
                botTheme.botMessageBg
                    ? botTheme.botMessageBg
                    : BASE_THEME.yellow}; 
          }
        }
      }

&.text-image {
        .option {
          .option-img {
            img, .img-container{
                border: 2px solid transparent;
            }
          }
          .multiple-option-tick {
            #svg-tick {
              stroke: ${({ botTheme }) =>
                  botTheme.multiOptionCheck
                      ? botTheme.multiOptionCheck
                      : BASE_THEME.teal};
              &.checked {
                .tick-circle {
                  fill: ${({ botTheme }) =>
                      botTheme.multiOptionCheck
                          ? botTheme.multiOptionCheck
                          : BASE_THEME.teal};
                }
              }
            }
          }
        }
        &:hover, &.active {
          .option {
            .option-img{
              img, .img-container {
                border: 2px solid ${({ botTheme }) =>
                    botTheme.userBubble
                        ? botTheme.userBubble
                        : BASE_THEME.teal};
              }
            }
          } 
        }
        &.selected {
          .option {
            .option-img{
              img, .img-container {
                border: 2px solid ${({ botTheme }) =>
                    botTheme.userBubble
                        ? botTheme.userBubble
                        : BASE_THEME.teal};
              }
            }
          } 
        }
        &.disabled {
          img {
            border: transparent !important;
          }
        }
      }
      &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }
  .multi-choice-help {
    margin-bottom: 10px;
    text-align: center;
    color: ${({ botTheme }) =>
        botTheme.multiOptionText ? botTheme.multiOptionText : BASE_THEME.white};
    font-weight: bold;
    font-style: italic;
    &.warning { color: ${BASE_THEME.red}; }
    p {
      margin: .5em;
      font-weight: normal;
    }
  }
  .multi-choice-help--header {
    display: block;
    font-size: 16px;
    margin-bottom: 4px;
    font-weight: 600;
  }
  .triangle {
    .multi-options-container {
      display: block;
    }
    span {
      display: flex;
      justify-content: center;
    }
    li {
      max-width: 150px;
      @media (min-width: 550px) {
        max-width: 250px;
      }
    }
    @media (max-width: 430px) {
      .bottom {
        justify-content: space-around;
        display: flex;
        .btn {
          margin: 0;
        }
      }
    }
  }
  .bottom-select-links {
    display: grid;
    align-items: center;
    justify-content: space-evenly;
  }
  .bottom-select-links:not(.no-y-margin) {
    margin-top: 4em;
  }
  .bottom-select-links:not(.no-y-margin) > button {
    margin: 10px auto;
  }
  .bottom-select-links.no-y-margin > button  {
    margin: auto;
  }
  .bottom-select-links.margin-two-btn > button{
    &:first-child{
      margin-bottom: 10px
    }
  }

  .grid-u.multi-options.textOnly {
      background:${({ botTheme }) =>
          botTheme.multipleChoice
              ? botTheme.multipleChoice
              : BASE_THEME.yellow};
      border-radius: 7px;
      padding: 0.5em 0.5em 0.55em;
      cursor: pointer;
      .option {
        p {
          font-weight: bold;
          margin: 0px;
          padding: 2px;
          color: ${({ botTheme }) =>
              botTheme.botMessageColor
                  ? botTheme.botMessageColor
                  : BASE_THEME.black};
        }
      }

        &:hover {
          border: 2px solid  ${({ botTheme }) =>
              botTheme.multiOptionCheck
                  ? botTheme.multiOptionCheck
                  : BASE_THEME.teal};
          background: #EEFEFD;
          p {
            color: ${({ botTheme }) =>
                botTheme.multiOptionCheck
                    ? botTheme.multiOptionCheck
                    : BASE_THEME.teal};
          }
         }
         &.selected, &.active {
          border: 2px solid ${({ botTheme }) =>
              botTheme.userBubble ? botTheme.userBubble : BASE_THEME.teal};
          background: ${({ botTheme }) =>
              botTheme.userBubble ? botTheme.userBubble : BASE_THEME.teal};
          border-color: ${({ botTheme }) =>
              botTheme.userBubble ? botTheme.userBubble : BASE_THEME.teal};
          p {
              color: #fff;
          }
      }
      &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
        border: 2px solid  transparent;
        background: ${({ botTheme }) =>
            botTheme.botMessageBg ? botTheme.botMessageBg : BASE_THEME.yellow}; 
        p {
          color: ${({ botTheme }) =>
              botTheme.botMessageColor
                  ? botTheme.botMessageColor
                  : BASE_THEME.black};
        }
  }

  `;
