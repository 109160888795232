import axios from 'axios';
import * as React from 'react';
import { BaseInput, BaseProps } from './_BaseInput';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { COLOR_THEME } from '@nexxt/common/constants';
import {
    UploadURL,
    TextAnswer,
    USER_INPUT_TYPE,
    ProjectInputSettings,
    QuestionInputSettings
} from '@nexxt/common/types';
const { translate } = require('react-i18next');

import { BASE_THEME } from '../style/BotThemes';
import { EmojiPicker } from '../components/EmojiPicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSmile,
    faPaperPlane,
    faMicrophone,
    faPlay,
    faVideo,
    faKeyboard,
    faTriangleExclamation,
    faCamera,
    faCircleXmark,
    faCheck,
    faFile,
    faFileArrowUp
} from '@fortawesome/free-solid-svg-icons';
import { KEY_NAME } from '@nexxt/common/constants';
import { delay } from '@nexxt/common/utils/index';
import { getTranslation } from '@nexxt/common/services/TextService';
import { dataTestid } from '../constants/dataTestid';
import VoicePrompt from '../components/VoicePrompt';
import { ReactLightBox } from '../components/LightBoxImage';
import { ConsoleLog } from '@openreplay/tracker/lib/app/messages.gen';

const UserInputArea = styled.div`
    height: auto !important;
    position: fixed;
    max-width: 100%;
    padding: 10px;
    display: inline-block;
    border: ${(props: { isEmbedded: boolean }) =>
        props.isEmbedded ? 'null' : '5px solid #dbdee1'};
    border-radius: 0px;
    bottom: 0px;
    height: 46px;
    left: 0;
    justify-content: space-between;
    z-index: 10;
    right: 0;
    @keyframes slideIn {
        from {
            transform: translateY(100%);
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }
    @keyframes slideOut {
        from {
            transform: translateY(0);
            opacity: 1;
        }
        to {
            transform: translateY(100%);
            opacity: 0;
        }
    }
    @media (max-width: 1250px) {
        max-width: 100%;
        border-radius: 0;
        bottom: 0;
    }
    &.highlighted {
        border: ${(props: { isEmbedded: boolean }) =>
            props.isEmbedded ? 'null' : '3px solid #f2f2f2'};
        background: white;
        /* animation-iteration-count: infinite;
    animation: blink-border 6s ease infinite; */
        margin: 0 auto;

        > div {
            max-width: 800px;
            margin: 0 auto;
            position: relative;
            .Dropzone {
                .drag-wrap {
                    min-height: 85px;
                    .record-wrap {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        gap: 4px;

                        .record-icon {
                            width: 52px;
                            height: 50px;
                            cursor: pointer;
                            background: rgba(249, 232, 249, 1);
                            color: ${BASE_THEME.pink};
                            svg {
                                path {
                                    fill: ${BASE_THEME.pink};
                                    transition: all 0.2s ease-in;
                                    -webkit-transition: all 0.2s ease-in;
                                    -moz-transition: all 0.2s ease-in;
                                }
                            }
                            &.disabled {
                                background: rgba(0, 0, 0, 0.2);
                                color: #4a4a4a;
                                cursor: not-allowed;

                                svg {
                                    path {
                                        fill: #777777;
                                    }
                                }
                            }
                        }
                        .helper-text {
                            font-weight: bold;
                            font-size: 13px;
                            margin-top: 6px;
                            &.loading {
                                color: #2d67cb;
                            }
                            &.danger {
                                color: #c54e76;
                            }
                        }
                    }
                }
            }
        }
    }
    &.invalid {
        border: 3px solid ${COLOR_THEME.red};
    }
    svg {
        font-size: 1.5em;
        color: white;
    }
    .msg-send svg {
        &:hover {
            color: ${BASE_THEME.yellow};
        }
    }
    .msg-emoji svg,
    .msg-upload svg {
        &:hover {
            color: ${BASE_THEME.pink};
        }
    }

    .uploaded-file-wrapper {
        position: relative;
        .uploaded-file {
            margin: 0 10px 0 0;
            position: relative;
            border-radius: 5px;
            display: inline-flex;
            flex: initial !important;
            overflow: hidden;
            border: 1px solid rgba(0, 0, 0, 0.2);
            height: 50px;
            cursor: pointer;

            &.current-preview {
                border: 2.5px solid #2d67cb;
            }
            &.img {
                width: 50px;
                justify-content: center;
                align-items: center;

                img {
                    max-height: 100%;
                    margin: 0;
                }
            }
            &.video {
                width: 50px;
                background-color: rgba(0, 0, 0, 0.15);
                svg {
                    position: absolute;
                    margin: auto;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    font-size: 1.2em;
                }
                img {
                    z-index: -1;
                    max-height: 100%;
                    margin: 0;
                }
            }
        }

        .remove-file {
            position: absolute;
            top: -4px;
            right: 2px;
            z-index: 1;
            cursor: pointer;
            color: #000;
            font-size: 1.25em;
            background-color: #fff;
            border-radius: 50%;
            &:hover {
                color: ${BASE_THEME.teal};
            }
        }
    }

    .icons {
        display: flex;
        align-items: center;
        padding: 0 0.25em;
    }

    .emoji-wrapper {
        position: relative;
        display: inline-block;
    }

    .wc-prompt-row {
        display: block;
        .icons {
            display: flex;
            margin-top: 1em;
            > div {
                display: flex;
                margin: 0 1.4em 0 0;
                width: 42px;
                height: 40px;
                background: #e3edff;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                transition: all 0.2s ease-in;
                -webkit-transition: all 0.2s ease-in;
                -moz-transition: all 0.2s ease-in;
                svg {
                    path {
                        fill: #2d67cb;
                        transition: all 0.2s ease-in;
                        -webkit-transition: all 0.2s ease-in;
                        -moz-transition: all 0.2s ease-in;
                    }
                }
                &:hover,
                &.active {
                    background: #2d67cb;
                    svg {
                        path {
                            fill: #ffffff;
                        }
                    }
                }
                &.disabled {
                    background: rgba(0, 0, 0, 0.2);
                    color: #4a4a4a;
                    cursor: not-allowed;

                    svg {
                        path {
                            fill: #777777;
                        }
                    }
                }
                &:last-child {
                    margin-right: 0px;
                }

                &.msg-send {
                    float: right;
                    background: ${BASE_THEME.green};
                    position: relative;
                    box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
                    svg path {
                        fill: #fff;
                    }
                    &.disabled {
                        background: rgba(0, 0, 0, 0.2);
                        color: #4a4a4a;
                        cursor: not-allowed;

                        svg {
                            path {
                                fill: #777777;
                            }
                        }
                    }
                }
                .send {
                    margin-left: -0.25em;
                }
            }
        }
    }

    textarea,
    input {
        height: 100%;
        padding: 0.35em;
        width: 100%;
        font-size: 14pt;
        font-weight: bold;
        border: none;
        resize: none;
    }

    @-webkit-keyframes pulse {
        0% {
            -webkit-box-shadow: 0 0 0 0 rgba(12, 199, 183, 0.4);
        }
        70% {
            -webkit-box-shadow: 0 0 0 15px rgba(12, 199, 183, 0);
        }
        100% {
            -webkit-box-shadow: 0 0 0 0 rgba(12, 199, 183, 0);
        }
    }
    @keyframes pulse {
        0% {
            -moz-box-shadow: 0 0 0 0 rgba(12, 199, 183, 0.4);
            box-shadow: 0 0 0 0 rgba(12, 199, 183, 0.4);
        }
        70% {
            -moz-box-shadow: 0 0 0 15px rgba(12, 199, 183, 0);
            box-shadow: 0 0 0 15px rgba(12, 199, 183, 0);
        }
        100% {
            -moz-box-shadow: 0 0 0 0 rgba(12, 199, 183, 0);
            box-shadow: 0 0 0 0 rgba(12, 199, 183, 0);
        }
    }
`;

const PromptRow = styled.div`
    display: flex;
    display: block;
    .icons {
        display: block;
        margin-top: 1em;
        > div {
            display: flex;
            position: relative;
            margin: 0 1.4em 0 0;
            width: 42px;
            height: 40px;
            background: rgba(175, 175, 175, 0.15);
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            transition: all 0.5s ease-in;
            -webkit-transition: all 0.2s ease-in;
            -moz-transition: all 0.2s ease-in;
            cursor: pointer;
            svg {
                path {
                    fill: rgba(0, 0, 0, 20%);
                    transition: all 0.2s ease-in;
                    -webkit-transition: all 0.2s ease-in;
                    -moz-transition: all 0.2s ease-in;
                }
                &.warning-icon {
                    position: absolute;
                    top: -3;
                    right: -3;
                    font-size: 1.25em;
                    path {
                        fill: #f7ca18;
                    }
                }
            }
            &:hover {
                /* background: ${BASE_THEME.pink}; */
                background: rgba(249, 232, 249, 1);
                svg {
                    path {
                        fill: ${BASE_THEME.pink};
                        /* fill: rgba(12, 199, 183, 1); */
                    }
                }
            }
            &.msg-send {
                background: ${BASE_THEME.green};
                margin: 0;
                padding: 10px 1px;
                position: relative;
                box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
                svg path {
                    fill: #fff;
                }
            }
        }
    }
    .permission-request {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding-top: 22px;
        p {
            margin: 0;
        }
        button {
            text-align: center;
            font-weight: bold;
            display: flex;
            align-items: center;
            border-radius: ${BASE_THEME.borderRadius};
            padding: 0.5em 0.75em 0.5em 0.55em;
            border: none;
            color: white;
            background: ${(p) => p.theme.continueBtn};
            &:disabled,
            &:disabled:hover,
            &:disabled:active {
                background-color: #bbb;
                color: #888;
                cursor: default;
            }
        }
    }
`;

const UploadPreview = styled.div`
    width: 100%;
    display: flex;
    align-items: center h4 {
        padding-top: 0.5em;
        font-size: 80%;
        margin: 0.1em;
        margin-right: 0.5em;
        color: black;
    }
    & > * {
        height: 50px;
    }
    img {
        margin-left: 10px;
    }
`;

const InvalidInputWarning = styled.div`
    color: ${COLOR_THEME.red};
    font-size: 10px;
    width: auto !important;
    height: auto !important;
    background: none !important;
    margin: 0 auto !important;
    padding: 0 !important;
    max-width: 40% !important;
`;

interface Props extends BaseProps {
    handleAnswer: (
        text: string,
        uploadURLs: UploadURL[],
        transcriptTexts: string[],
        isTranscriptionEdited: boolean
    ) => void;
    setBottomOffset: (offset?: number, forceScroll?: boolean) => void;
    isEmbedded?: boolean;
    inputSettings: ProjectInputSettings | QuestionInputSettings;
    langId: string;
}

interface State {
    userText: string;
    open: boolean;
    uploadURLs: UploadURL[];
    emojiPicker: boolean;
    accepted: Array<any>;
    rejected: Array<any>;
    uploading: boolean;
    tooLong: boolean;
    submitting: boolean;
    currentInputType: USER_INPUT_TYPE;
    audioPermission: boolean;
    videoPermission: boolean;
    inputsArray: Array<any>;
    loading: boolean;
    transcriptTexts: string[];
    currentPreviewFile: string;
    openLightBox: boolean;
    isTranscriptionEdited: boolean;
    mediaTranscripts: { [key: string]: string[] };
    currentLiveTranscript: string[];
    error: boolean;
}

@translate('translation')
export class OEPrompt extends BaseInput<Props, State> {
    state: State = {
        userText: '',
        open: false,
        uploadURLs: [],
        emojiPicker: false,
        accepted: [],
        rejected: [],
        uploading: false,
        tooLong: false,
        submitting: false,
        currentInputType: USER_INPUT_TYPE.TEXT,
        audioPermission: false,
        videoPermission: false,
        inputsArray: [],
        loading: false,
        transcriptTexts: [],
        currentPreviewFile: '',
        openLightBox: false,
        isTranscriptionEdited: false,
        mediaTranscripts: {},
        currentLiveTranscript: [],
        error: false,
    };

    private textInput = React.createRef<HTMLInputElement>();
    stickyFooter: HTMLDivElement;
    form: HTMLFormElement;
    submitButton: HTMLButtonElement;

    componentDidUpdate(prevProps: any, prevState: State) {
        if (prevState.uploadURLs !== this.state.uploadURLs) {
            this.state.uploadURLs.map((i: any, idx) => {
                console.log(`Upload ${idx}`);
                this.handleMediaTranscript(
                    this.state.currentLiveTranscript,
                    idx
                );
                this.setState({
                    currentLiveTranscript: []
                });
            });
        }
    }

    async componentDidMount() {
        // reserve space at bottom for sticky footer
        const STICKY_FOOTER_HEIGHT = this.stickyFooter.clientHeight;
        this.props.setBottomOffset(STICKY_FOOTER_HEIGHT, true);

        if (this.props.previousAnswer) {
            const userText = (this.props.previousAnswer as TextAnswer).value;

            this.setState({ userText });
        }
        if (this.props.inputSettings?.preferredInputType) {
            this.setState({
                currentInputType: this.props.inputSettings?.preferredInputType
            });
        }
        const checkPermissions = async () => {
            try {
                const audioPermission = await navigator.permissions.query({
                    name: 'microphone' as PermissionName
                });
                const videoPermission = await navigator.permissions.query({
                    name: 'camera' as PermissionName
                });

                if (audioPermission.state === 'granted') {
                    this.setState({ audioPermission: true });
                }

                if (videoPermission.state === 'granted') {
                    this.setState({ videoPermission: true });
                }
            } catch (error) {
                console.error('Error checking permissions:', error);
            }
        };
        checkPermissions();
    }

    handlePermissionChange = (type, hasPermission) => {
        if (type === 'audio') {
            this.setState({ audioPermission: hasPermission });
        }

        if (type === 'video') {
            this.setState({ videoPermission: hasPermission });
        }
    };

    clearError = () => {
        this.setState({ error: false });
    };

    setUserText = (newUserText: string) => {
        this.setState({ userText: newUserText });
    };

    addTranscriptText = (newTranscriptText: string) => {
        this.setState((prevState) => ({
            transcriptTexts: [...prevState.transcriptTexts, newTranscriptText],
            currentLiveTranscript: [
                ...prevState.currentLiveTranscript,
                newTranscriptText
            ]
        }));
    };

    handleMediaTranscript = (newTranscriptText: string[], fileId: number) => {
        this.setState((prevState) => {
            if (prevState.mediaTranscripts[fileId]) {
                return null; // Do nothing if fileId already exists
            }
            return {
                mediaTranscripts: {
                    ...prevState.mediaTranscripts,
                    [fileId]: newTranscriptText
                }
            };
        });
    };

    // wait 200ms for slideOut animation before submitting answer.
    onSubmit = async (e, isInputValid: boolean) => {
        e.preventDefault();

        // if input is invalid, return
        if (!isInputValid) return;

        const { userText, uploadURLs, transcriptTexts, isTranscriptionEdited } =
            this.state;
        if (!this.state.submitting) {
            this.setState({ submitting: true });
            await delay(200);
            this.setState({
                uploadURLs: [],
                userText: '',
                currentLiveTranscript: [],
                transcriptTexts: [],
                isTranscriptionEdited: false
            });
            this.props.handleAnswer(
                userText,
                uploadURLs,
                transcriptTexts,
                isTranscriptionEdited
            );
        }
    };

    setUploadURLs = (uploadURLs: UploadURL[]) => {
        this.setState({
            uploadURLs: uploadURLs //open: false
        });
    };

    // add emoji to textInput and focus
    addEmoji = (emoji) => {
        this.closeEmojiPicker();
        this.setState({ userText: this.state.userText + emoji.native });
        this.textInput.current.focus();
    };

    toggleEmojiPicker = () => {
        this.setState((prevState) => ({ emojiPicker: !prevState.emojiPicker }));
    };

    closeEmojiPicker = () => {
        this.setState({ emojiPicker: false });
    };

    submit = () => {
        // need to simulate submit-button click so that input is HTML-validated
        this.submitButton.click();
    };

    onKeyUp = (e: React.KeyboardEvent<HTMLElement>) => {
        this.setState({ isTranscriptionEdited: true });
        // submit when enter is clicked
        if (e.key === KEY_NAME.ENTER) {
            e.preventDefault();
            if (this.state.userText.length) {
                this.submit();
                this.setState({ isTranscriptionEdited: false });
            }
        }
    };
    onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        // prevent enter from making new line (need to do shift+enter)
        if (e.key === KEY_NAME.ENTER) {
            e.preventDefault();
        }
    };

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        // close and focus textInput
        this.setState({ open: false });
        this.textInput.current.focus();
    };

    textareaChange = (e) => {
        e.preventDefault();
        this.setState({ userText: e.currentTarget.value });
    };

    uploadFiles = (files, uploadType: USER_INPUT_TYPE, thumbnail = null) => {
        const audioImage = require('../assets/audio-wave.png'); // temp
        const uploadPromises = [];
        this.setState({ uploading: true });
        files.map((file, ind) => {
            // console.log(`upload #${ind} of queue uploading: ${file}`);
            const url = `${process.env.INCA_SERVER_URL}/data/file/upload`;
            const formData = new FormData();
            formData.append('file', file);
            uploadPromises.push(
                axios
                    .post(url, formData)
                    .then((response) => {
                        if (uploadType === USER_INPUT_TYPE.VIDEO) {
                            response.data.thumb = thumbnail;
                        }
                        if (uploadType === USER_INPUT_TYPE.AUDIO) {
                            response.data.thumb = audioImage;
                        }
                        if (
                            uploadType === USER_INPUT_TYPE.AUDIO ||
                            uploadType === USER_INPUT_TYPE.VIDEO
                        ) {
                            this.setState({
                                currentPreviewFile: response.data.full
                            });
                        }

                        response.data.transcription = this.state
                            .isTranscriptionEdited
                            ? 'EDITED'
                            : this.state.currentLiveTranscript;

                        const uploadRecord = {
                            ...response.data,
                            type: uploadType,
                            isTranscriptionEdited:
                                this.state.isTranscriptionEdited
                        };
                        this.setUploadURLs([
                            ...this.state.uploadURLs,
                            uploadRecord
                        ]);
                        // scrollToBottom to display messages covered by area for uploadedFiles
                        this.props.setBottomOffset(
                            this.stickyFooter.clientHeight,
                            true
                        );
                    })
                    .catch((error) => {
                        console.log(`upload #${ind} of queue FAILED: ${file}`);
                        console.log(error);
                        const failedUploadRecord = {
                            full: '',
                            thumb: '',
                            transcription: '',
                            file: file.name,
                            type: uploadType,
                            isTranscriptionEdited: this.state.isTranscriptionEdited,
                            errorMessage: JSON.stringify(error)
                        };
    
                        this.setUploadURLs([...this.state.uploadURLs, failedUploadRecord]);
                        this.setState({
                            error: true
                        });
                    })
            );
        });

        Promise.all(uploadPromises)
        .then(() => {
            console.log('DONE');
            this.setState({ uploading: false });
        })
        .catch(() => {
            this.setState({ uploading: false });
        });
    };

    onUpload = (accepted, rejected) => {
        const MAX_NUM_UPLOADS = 10;
        const totalNumFiles =
            (accepted?.length ?? 0) + (this.state.uploadURLs?.length ?? 0);
        if (totalNumFiles > MAX_NUM_UPLOADS) {
            this.setState({
                tooLong: true
            });
        } else {
            this.setState({
                accepted,
                rejected,
                uploading: true,
                tooLong: false
            });
            this.uploadFiles(accepted, USER_INPUT_TYPE.FILE_UPLOAD);
        }
    };

    handleRemoveFile = (removeFileIdx) => {
        this.exitPreviewMode();

        const filteredFiles = this.state.uploadURLs.filter(
            (uploadedFile, fileIdx) => {
                return fileIdx != removeFileIdx;
            }
        );
        const newFiles = [
            ...filteredFiles,
            {
                ...this.state.uploadURLs[removeFileIdx],
                full: 'DELETED',
                thumb: 'DELETED',
                transcription: this.state.isTranscriptionEdited
                    ? 'EDITED'
                    : 'DELETED'
            }
        ];

        if (!this.state.isTranscriptionEdited) {
            const updatedMediaTranscripts = this.state.mediaTranscripts;
            updatedMediaTranscripts[removeFileIdx] = ['DELETED'];

            // Combine all remaining transcripts into userText, ignoring 'DELETED'
            const combinedTranscriptTexts = Object.entries(
                updatedMediaTranscripts
            )
                .filter(
                    ([_, value]) =>
                        Array.isArray(value) && !value.includes('DELETED')
                ) // Filter out 'DELETED' entries
                .flatMap(([_, texts]) => texts as string[]);

            const updatedUserText = combinedTranscriptTexts.join(' ');

            this.setState({
                uploadURLs: [...newFiles],
                mediaTranscripts: updatedMediaTranscripts,
                transcriptTexts: combinedTranscriptTexts,
                userText: updatedUserText
            });
        }
        this.setState(
            {
                uploadURLs: [...newFiles]
            },
            () => {
                this.setUploadURLs(this.state.uploadURLs);
                if ([...newFiles].length == 0) {
                    this.props.setBottomOffset(
                        this.stickyFooter.clientHeight,
                        true
                    );
                }
            }
        );
    };

    toggleLightBox = () => {
        this.setState({
            openLightBox: !this.state.openLightBox
        });
    };

    handlePreviewFile = (fileURL, fileType) => {
        this.setState({
            currentPreviewFile: fileURL
        });

        this.setState({
            currentInputType:
                fileType === USER_INPUT_TYPE.AUDIO
                    ? USER_INPUT_TYPE.AUDIO
                    : USER_INPUT_TYPE.VIDEO
        });
    };

    exitPreviewMode = () => {
        this.setState({
            currentPreviewFile: ''
        });
    };

    handleInputTypeSwitch = (inputType) => {
        this.setState({
            currentInputType: inputType
        });

        this.exitPreviewMode();
    };

    validateCustomLogic = () => {
        const { question } = this.props;
        const { userText } = this.state;
        if (question.customLogic?.inputValidators?.length) {
            const funcs = question.customLogic.inputValidators.map(
                (funcStr) => new Function('userText', funcStr)
            );
            const evals = funcs.map((f) => f(userText));
            const firstString = evals.find((e) => e != '');
            return firstString ? firstString : '';
        } else {
            return '';
        }
    };

    textInputIsClean = (text) => {
        const { question } = this.props;
        let regExp;
        if (!text?.length) return true;

        if (question.showValidation) {
            switch (question.validationType) {
                case 0: // Postal code(CA)
                    regExp = /^(?:[A-Z]\d[A-Z][ -]?\d[A-Z]\d)$/i;
                    break;
                case 1: // Email
                    regExp =
                        /^[a-z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-z0-9-]+)*$/i;
                    break;
                case 2: // Phone number
                    regExp =
                        /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
                    break;
            }
            return regExp.test(text);
        } else {
            regExp = /^[ !@#$%^&*()_\-~`’“;:\/?.>,<_=+\/\\\]\[]+$/;

            return !regExp.test(text);
            // regExp checks if a string doesn't contain non-alphanumeric characters
        }
        return true;
    };
    getValidationText = () => {
        const { question, t } = this.props;
        let text = t('LABEL.VALIDATE.TEXT_INPUT');
        if (question.showValidation) {
            switch (question.validationType) {
                case 0:
                    text = t('LABEL.VALIDATE.POSTAL_CODE');
                    break;
                case 1:
                    text = t('LABEL.VALIDATE.EMAIL');
                    break;
                case 2:
                    text = t('LABEL.VALIDATE.PHONE_NUM');
                    break;
            }
        }
        return text;
    };
    setLoading = (value) => {
        this.setState({ loading: value });
    };
    render() {
        const { emojiPicker, userText, uploading, uploadURLs, loading } =
            this.state;
        const { t, question, inputSettings } = this.props;
        const allowText = inputSettings
            ? inputSettings.inputTypes?.includes(USER_INPUT_TYPE.TEXT)
            : true;
        const allowUpload = inputSettings
            ? inputSettings.inputTypes?.includes(USER_INPUT_TYPE.FILE_UPLOAD)
            : true;
        const allowEmojis = allowText;
        const allowMicrophone = inputSettings?.inputTypes?.includes(
            USER_INPUT_TYPE.AUDIO
        );
        const allowVideo = inputSettings?.inputTypes?.includes(
            USER_INPUT_TYPE.VIDEO
        );
        const allowCamera = inputSettings?.inputTypes?.includes(
            USER_INPUT_TYPE.CAMERA
        );
        const allowDualCamera = inputSettings?.inputTypes?.includes(
            USER_INPUT_TYPE.DUAL_CAMERA
        );
        // TODO: add as project setting when making a DIY feature
        const startImmediateAudioRecording = question.advancedQuestionSettings
            ?.voice?.startImmediateAudioRecording
            ? question.advancedQuestionSettings?.voice
                  ?.startImmediateAudioRecording
            : false;

        const dropzoneRef = React.createRef<any>();
        const handleUploadInputIcon = () => {
            if (this.state.currentInputType == USER_INPUT_TYPE.FILE_UPLOAD) {
                return;
            }
            openDialog();
        };

        const openDialog = () => {
            if (dropzoneRef.current) {
                dropzoneRef.current.open();
            }
        };

        const customLogicValidationMsg = userText.length
            ? this.validateCustomLogic()
            : '';

        // TODO: better handling for media only OEPrompt (ie detect its a valid file, not a blank image, etc.)
        const textInputIsValid =
            !customLogicValidationMsg && this.textInputIsClean(userText);
        const isUploadSubmittable =
            Boolean(textInputIsValid && userText?.length) ||
            uploadURLs?.some((u) =>
                [
                    USER_INPUT_TYPE.CAMERA,
                    USER_INPUT_TYPE.DUAL_CAMERA,
                    USER_INPUT_TYPE.VIDEO,
                    USER_INPUT_TYPE.FILE_UPLOAD
                ].includes((u as any).type)
            );
        const allowSubmit =
            textInputIsValid &&
            (userText?.length || isUploadSubmittable) &&
            !loading;

        return (
            <form
                onSubmit={(e) => this.onSubmit(e, textInputIsValid)}
                ref={(f) => (this.form = f)}
            >
                <UserInputArea
                    isEmbedded={this.props.isEmbedded}
                    className={`wc-prompt highlighted ${
                        textInputIsValid ? '' : 'invalid'
                    }`}
                    style={{
                        animation: this.state.submitting
                            ? 'slideOut 0.5s'
                            : 'slideIn 0.5s'
                    }}
                    ref={(e) => (this.stickyFooter = e)}
                >
                    {this.state.uploading &&
                    this.state.uploadURLs.length === 0 ? (
                        <div className="line-loader" />
                    ) : null}
                    <PromptRow className="wc-prompt-row">
                        {this.state.uploadURLs.length > 0 ? (
                            <UploadPreview className="wc-upload-preview">
                                {/* <h4>
                                    {getTranslation(t, 'LABEL.UPLOADS.HEADER')}
                                </h4> */}
                                {this.state.uploadURLs.map((i: any, idx) => {
                                    let fileExtension = i.full.substring(
                                        i.full.lastIndexOf('.') + 1,
                                        i.full.length
                                    );
                                    fileExtension = (
                                        fileExtension || ''
                                    ).toLowerCase();
                                    if (
                                        fileExtension.includes('png') ||
                                        fileExtension.includes('jpg') ||
                                        fileExtension.includes('jpeg') ||
                                        fileExtension.includes('gif')
                                    ) {
                                        return (
                                            <div
                                                className="uploaded-file-wrapper"
                                                key={`uploaded-file-img-${idx}`}
                                            >
                                                <FontAwesomeIcon
                                                    className="remove-file"
                                                    onClick={() =>
                                                        this.handleRemoveFile(
                                                            idx
                                                        )
                                                    }
                                                    icon={faCircleXmark}
                                                />
                                                <div
                                                    className="uploaded-file img"
                                                    onClick={
                                                        this.toggleLightBox
                                                    }
                                                >
                                                    <img
                                                        src={i.thumb}
                                                        key={i.thumb}
                                                    />
                                                </div>
                                                {this.state.openLightBox && (
                                                    <ReactLightBox
                                                        imageURL={i.full}
                                                        closeLightBox={
                                                            this.toggleLightBox
                                                        }
                                                    />
                                                )}
                                            </div>
                                        );
                                    } else if (
                                        fileExtension.includes('pdf') ||
                                        fileExtension.includes('doc') ||
                                        fileExtension.includes('exe') ||
                                        fileExtension.includes('mp4') ||
                                        fileExtension.includes('mov') ||
                                        fileExtension.includes('flv') ||
                                        fileExtension.includes('webm') ||
                                        fileExtension.includes('mp3') ||
                                        fileExtension.includes('wav') ||
                                        fileExtension.includes('psd')
                                    ) {
                                        return (
                                            <div
                                                className="uploaded-file-wrapper"
                                                key={`uploaded-file-video-${idx}`}
                                            >
                                                <FontAwesomeIcon
                                                    className="remove-file"
                                                    onClick={() => {
                                                        this.handleRemoveFile(
                                                            idx
                                                        );
                                                    }}
                                                    icon={faCircleXmark}
                                                />
                                                <div
                                                    className={`uploaded-file video ${
                                                        this.state
                                                            .currentPreviewFile ===
                                                        i.full
                                                            ? 'current-preview'
                                                            : ''
                                                    }`}
                                                    onClick={() =>
                                                        this.handlePreviewFile(
                                                            i.full,
                                                            i.type
                                                        )
                                                    }
                                                >
                                                    {/* temp just showing play icon on video & audio */}
                                                    {fileExtension.includes(
                                                        'mp4'
                                                    ) ? (
                                                        <FontAwesomeIcon
                                                            icon={faPlay}
                                                        />
                                                    ) : null}
                                                    <img
                                                        src={i.thumb}
                                                        key={i.thumb}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    }
                                })}

                                {uploading && uploadURLs.length > 0 ? (
                                    <div className="line-loader" />
                                ) : null}
                            </UploadPreview>
                        ) : null}
                    </PromptRow>
                    <PromptRow className="wc-prompt-row">
                        <Dropzone
                            ref={dropzoneRef}
                            onDrop={this.onUpload}
                            noClick={true}
                            // disableClick // replace with noClick???
                            // activeStyle={{
                            //     fontSize: '14px',
                            //     color: '#fff',
                            //     border: '1px dashed #0cc7b7',
                            //     borderRadius: '5px',
                            //     background: 'rgba(89, 189, 176, 0.2)',
                            //     content: 'Drag and drop files here',
                            //     position: 'relative',
                            //     zIndex: 1,
                            //     width: '100%',
                            //     height: '100%',
                            //     transition: 'all 0.2s linear'
                            // }}
                        >
                            {({
                                getRootProps,
                                getInputProps,
                                isDragActive
                            }) => (
                                <div className="Dropzone">
                                    <div className="drag-wrap">
                                        <VoicePrompt
                                            currentPreviewFile={
                                                this.state.currentPreviewFile
                                            }
                                            exitPreviewMode={
                                                this.exitPreviewMode
                                            }
                                            userText={userText}
                                            setUserText={this.setUserText}
                                            onPermissionChange={
                                                this.handlePermissionChange
                                            }
                                            isText={[
                                                USER_INPUT_TYPE.TEXT,
                                                USER_INPUT_TYPE.FILE_UPLOAD
                                            ].includes(
                                                this.state.currentInputType
                                            )}
                                            audioPermission={
                                                [
                                                    USER_INPUT_TYPE.VIDEO,
                                                    USER_INPUT_TYPE.AUDIO
                                                ].includes(
                                                    this.state.currentInputType
                                                ) && this.state.audioPermission
                                            }
                                            videoPermission={
                                                [
                                                    USER_INPUT_TYPE.CAMERA,
                                                    USER_INPUT_TYPE.DUAL_CAMERA,
                                                    USER_INPUT_TYPE.VIDEO
                                                ].includes(
                                                    this.state.currentInputType
                                                ) && this.state.videoPermission
                                            }
                                            isAudio={
                                                this.state.currentInputType ==
                                                USER_INPUT_TYPE.AUDIO
                                            }
                                            isVideo={
                                                this.state.currentInputType ==
                                                USER_INPUT_TYPE.VIDEO
                                            }
                                            isCamera={[
                                                USER_INPUT_TYPE.CAMERA,
                                                USER_INPUT_TYPE.DUAL_CAMERA
                                            ].includes(
                                                this.state.currentInputType
                                            )}
                                            isDualCamera={
                                                this.state.currentInputType ==
                                                USER_INPUT_TYPE.DUAL_CAMERA
                                            }
                                            error={this.state.error}
                                            clearError={this.clearError}
                                            uploading={this.state.uploading}
                                            setLoading={this.setLoading}
                                            uploadFiles={this.uploadFiles}
                                            addTranscriptText={
                                                this.addTranscriptText
                                            }
                                            startImmediateAudioRecording={
                                                startImmediateAudioRecording
                                            }
                                            langId={this.props.langId}
                                        />
                                        {this.state.currentInputType ==
                                            USER_INPUT_TYPE.FILE_UPLOAD && (
                                            <div className="record-wrap">
                                                <div className="icons">
                                                    <div
                                                        onClick={openDialog}
                                                        className={`icons record-icon`}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faFileArrowUp}
                                                        />
                                                    </div>
                                                </div>
                                                <span className="helper-text">
                                                    Tap above to upload a file
                                                </span>
                                            </div>
                                        )}
                                        {this.state.currentInputType ==
                                            USER_INPUT_TYPE.TEXT && (
                                            <TextareaAutosize
                                                data-testid={
                                                    dataTestid.TEXTAREA
                                                }
                                                autoFocus
                                                inputRef={this.textInput}
                                                value={userText}
                                                onKeyUp={this.onKeyUp}
                                                onKeyDown={this.onKeyDown}
                                                onChange={this.textareaChange}
                                                placeholder={getTranslation(
                                                    t,
                                                    'PLACEHOLDER.PROMPT.TEXT'
                                                )}
                                                style={{ minHeight: 85 }}
                                                disabled={
                                                    uploading ? true : false
                                                }
                                                onPaste={(event) => {
                                                    !question.allowCopyPaste &&
                                                        event.preventDefault();
                                                }}
                                            />
                                        )}
                                    </div>

                                    <div className="icons">
                                        {allowText && (
                                            <div
                                                className={`icons ${
                                                    loading
                                                        ? 'disabled'
                                                        : this.state
                                                              .currentInputType ==
                                                          USER_INPUT_TYPE.TEXT
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                onClick={
                                                    !loading
                                                        ? () =>
                                                              this.handleInputTypeSwitch(
                                                                  USER_INPUT_TYPE.TEXT
                                                              )
                                                        : undefined
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faKeyboard}
                                                />
                                            </div>
                                        )}

                                        {allowMicrophone && (
                                            <div
                                                className={`icons ${
                                                    loading
                                                        ? 'disabled'
                                                        : this.state
                                                              .currentInputType ==
                                                          USER_INPUT_TYPE.AUDIO
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                onClick={
                                                    !loading
                                                        ? () =>
                                                              this.handleInputTypeSwitch(
                                                                  USER_INPUT_TYPE.AUDIO
                                                              )
                                                        : undefined
                                                }
                                            >
                                                {!this.state
                                                    .audioPermission && (
                                                    <FontAwesomeIcon
                                                        icon={
                                                            faTriangleExclamation
                                                        }
                                                        className="warning-icon"
                                                    />
                                                )}
                                                <FontAwesomeIcon
                                                    icon={faMicrophone}
                                                />
                                            </div>
                                        )}
                                        {allowCamera && (
                                            <div
                                                className={`icons ${
                                                    loading
                                                        ? 'disabled'
                                                        : this.state
                                                              .currentInputType ==
                                                          USER_INPUT_TYPE.CAMERA
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                onClick={
                                                    !loading
                                                        ? () =>
                                                              this.handleInputTypeSwitch(
                                                                  USER_INPUT_TYPE.CAMERA
                                                              )
                                                        : undefined
                                                }
                                            >
                                                {!this.state
                                                    .videoPermission && (
                                                    <FontAwesomeIcon
                                                        icon={
                                                            faTriangleExclamation
                                                        }
                                                        className="warning-icon"
                                                    />
                                                )}
                                                <FontAwesomeIcon
                                                    icon={faCamera}
                                                />
                                            </div>
                                        )}
                                        {allowVideo && (
                                            <div
                                                className={`icons ${
                                                    loading
                                                        ? 'disabled'
                                                        : this.state
                                                              .currentInputType ==
                                                          USER_INPUT_TYPE.VIDEO
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                onClick={
                                                    !loading
                                                        ? () =>
                                                              this.handleInputTypeSwitch(
                                                                  USER_INPUT_TYPE.VIDEO
                                                              )
                                                        : undefined
                                                }
                                            >
                                                {!(
                                                    this.state
                                                        .audioPermission &&
                                                    this.state.videoPermission
                                                ) && (
                                                    <FontAwesomeIcon
                                                        icon={
                                                            faTriangleExclamation
                                                        }
                                                        className="warning-icon"
                                                    />
                                                )}
                                                <FontAwesomeIcon
                                                    icon={faVideo}
                                                />
                                            </div>
                                        )}
                                        {allowUpload && (
                                            <div
                                                onClick={
                                                    !loading
                                                        ? handleUploadInputIcon
                                                        : undefined
                                                }
                                                onKeyDown={(e) => {
                                                    if (
                                                        e.key ===
                                                            KEY_NAME.SPACE ||
                                                        e.key === KEY_NAME.ENTER
                                                    ) {
                                                        handleUploadInputIcon();
                                                    }
                                                }}
                                                className={`icons ${
                                                    loading
                                                        ? 'disabled'
                                                        : this.state
                                                              .currentInputType ==
                                                          USER_INPUT_TYPE.FILE_UPLOAD
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                tabIndex={0}
                                            >
                                                {/* Keep the following void input so the file upload works on mobile */}
                                                <input {...getInputProps()} />
                                                <FontAwesomeIcon
                                                    icon={faFile}
                                                />
                                            </div>
                                        )}

                                        {this.state.currentInputType ==
                                            USER_INPUT_TYPE.TEXT &&
                                            allowEmojis && (
                                                <div className="emoji-wrapper">
                                                    {allowEmojis && ( // EMOJI PICKER BUTTON
                                                        <div
                                                            onClick={
                                                                this
                                                                    .toggleEmojiPicker
                                                            }
                                                            onKeyDown={(e) => {
                                                                if (
                                                                    e.key ===
                                                                        KEY_NAME.SPACE ||
                                                                    e.key ===
                                                                        KEY_NAME.ENTER
                                                                ) {
                                                                    console.log(
                                                                        'toggling'
                                                                    );
                                                                    this.toggleEmojiPicker();
                                                                }
                                                            }}
                                                            tabIndex={0}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faSmile}
                                                            />
                                                        </div>
                                                    )}
                                                    {emojiPicker && ( // EMOJI PICKER MENU
                                                        <EmojiPicker
                                                            addEmoji={
                                                                this.addEmoji
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            )}

                                        {!textInputIsValid && (
                                            <InvalidInputWarning>
                                                {customLogicValidationMsg
                                                    ? customLogicValidationMsg
                                                    : t(
                                                          'LABEL.VALIDATE.TEXT_INPUT'
                                                      )}
                                            </InvalidInputWarning>
                                        )}
                                        {/* send button */}
                                        {this.state.currentInputType !==
                                            USER_INPUT_TYPE.TEXT &&
                                        allowText ? (
                                            <div
                                                className={`icons msg-send ${
                                                    loading ? 'disabled' : ''
                                                }`}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faCheck}
                                                    onClick={
                                                        !loading
                                                            ? () => {
                                                                  this.setState(
                                                                      {
                                                                          currentInputType:
                                                                              USER_INPUT_TYPE.TEXT
                                                                      }
                                                                  );
                                                              }
                                                            : undefined
                                                    }
                                                />
                                            </div>
                                        ) : allowSubmit ? (
                                            <div
                                                className="icons msg-send"
                                                onClick={this.submit}
                                                onKeyDown={(e) => {
                                                    if (
                                                        e.key ===
                                                            KEY_NAME.SPACE ||
                                                        e.key === KEY_NAME.ENTER
                                                    ) {
                                                        this.submit();
                                                    }
                                                }}
                                                tabIndex={0}
                                                data-testid={
                                                    dataTestid.FABUTTON
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPaperPlane}
                                                    className="send"
                                                />
                                            </div>
                                        ) : (
                                            <div className="icons msg-send disabled">
                                                <FontAwesomeIcon
                                                    icon={faPaperPlane}
                                                    className="send"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </Dropzone>
                    </PromptRow>
                </UserInputArea>
                <button
                    type="submit"
                    ref={(b) => (this.submitButton = b)}
                    style={{ display: 'none' }}
                />
            </form>
        );
    }
}
