import * as React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { getStringFromMultimediaTexts } from '@nexxt/common/services/TextService';
import { OptionData } from '@nexxt/common/types';

interface OptionTextProps {
    isRichText?: boolean;
    className?: string;
    option: OptionData;
    langId: string;
}

const OptionText: React.FC<OptionTextProps> = ({
    isRichText,
    option,
    className,
    langId
}: OptionTextProps) => {
    const text = getStringFromMultimediaTexts(
        option.texts,
        langId,
        option.label
    );
    if (isRichText) {
        const { contentBlocks, entityMap } = convertFromHTML(text);

        return (
            <p
                style={{
                    fontWeight: 'initial',
                    justifySelf: 'center',
                    alignSelf: 'center'
                }}
            >
                <Editor
                    editorState={EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                            contentBlocks,
                            entityMap
                        )
                    )}
                    toolbar={{
                        options: [],
                        inline: []
                    }}
                    readOnly
                />
            </p>
        );
    }

    return <p className={className}>{text}</p>;
};

export default OptionText;
