import { IPopupOptions } from './types';
import { STATIC_STORAGE_URL } from '@nexxt/common/constants';

export const firstBucketOptionsDefault = [
    {
        id: 1,
        text: 'Went to branch and talked to the staff',
        color: '#F2AF00',
        bgcolor: ''
    },
    {
        id: 2,
        text: 'Went directly to branch manager',
        color: '#F2AF00',
        bgcolor: ''
    },
    {
        id: 3,
        text: 'Telephoned call centre',
        color: '#F2AF00',
        bgcolor: ''
    },
    {
        id: 4,
        text: 'Contacted my own relationship manager/advisor',
        color: '#F2AF00',
        bgcolor: ''
    },
    {
        id: 5,
        text: 'Asked to speak with supervisor',
        color: '#F2AF00',
        bgcolor: ''
    },
    {
        id: 6,
        text: 'Emailed customer service team',
        color: '#F2AF00',
        bgcolor: ''
    },
    {
        id: 7,
        text: 'Submitted an online complaint form',
        color: '#F2AF00',
        bgcolor: ''
    }
];

export const getSecondBucketOptionsDefault = (IS_ENGLISH: boolean) => [
    {
        id: 11,
        text: IS_ENGLISH ? 'see an advertisement online' : '看到在线广告',
        color: '#0086FF',
        bgcolor: ''
    },
    {
        id: 12,
        text: IS_ENGLISH
            ? 'see/hear an ad on TV/radio/newspaper'
            : '看到/听到电视/广播/报纸上的广告',
        color: '#0086FF',
        bgcolor: ''
    },
    {
        id: 13,
        text: IS_ENGLISH
            ? 'see/hear an advertisement on billboard'
            : '看/听到广告牌上的广告',
        color: '#0086FF',
        bgcolor: ''
    },
    {
        id: 14,
        text: IS_ENGLISH
            ? 'hear about newcomer package'
            : '听说了新移民优惠计划',
        color: '#0086FF',
        bgcolor: ''
    },
    {
        id: 15,
        text: IS_ENGLISH
            ? 'be referred to a bank representative'
            : '有人介绍给我一位银行代表',
        color: '#0086FF',
        bgcolor: ''
    },
    {
        id: 16,
        text: IS_ENGLISH
            ? 'hear about from a professional'
            : '有专业人士给我提供推荐',
        color: '#0086FF',
        bgcolor: ''
    },
    {
        id: 17,
        text: IS_ENGLISH
            ? 'be taken by friend/family/landlord'
            : '我的朋友/家人/房东直接带我去银行',
        color: '#0086FF',
        bgcolor: ''
    }
];
export const emojiListDefault = [
    {
        id: '1',
        texts: [
            {
                language: 'en',
                text: 'frustrated'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'frustrated'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/img/emojis/frustrated.png`,
        value: 1,
        label: 'Frustrated',
        tags: []
    },
    {
        id: '2',
        texts: [
            {
                language: 'en',
                text: 'annoyed'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'annoyed'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/img/emojis/mad.png`,
        value: 2,
        label: 'annoyed',
        tags: []
    },
    {
        id: '3',
        texts: [
            {
                language: 'en',
                text: 'confused'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'confused'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/img/emojis/strange.png`,
        value: 3,
        label: 'confused',
        tags: []
    },
    {
        id: '4',
        texts: [
            {
                language: 'en',
                text: 'helpless'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'helpless'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/img/emojis/disappointed.png`,
        value: 4,
        label: 'helpless',
        tags: []
    },
    {
        id: '5',
        texts: [
            {
                language: 'en',
                text: 'disrespected'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'disrespected'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/img/emojis/annoyed.png`,
        value: 5,
        label: 'disrespected',
        tags: []
    },
    {
        id: '6',
        texts: [
            {
                language: 'en',
                text: 'amused'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'amused'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/img/emojis/amused.png`,
        value: 6,
        label: 'amused',
        tags: []
    },
    {
        id: '7',
        texts: [
            {
                language: 'en',
                text: 'surprised'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'surprised'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/img/emojis/surprised.png`,
        value: 7,
        label: 'Surprised',
        tags: []
    },
    {
        id: '8',
        texts: [
            {
                language: 'en',
                text: 'relieved'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'relieved'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/img/emojis/relieved.png`,
        value: 8,
        label: 'relieved',
        tags: []
    },
    {
        id: '9',
        texts: [
            {
                language: 'en',
                text: 'satisfied'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'satisfied'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/img/emojis/happy.png`,
        value: 9,
        label: 'Satisfied',
        tags: []
    },
    {
        id: '10',
        texts: [
            {
                language: 'en',
                text: 'hopeful'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'hopeful'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/img/emojis/longing.png`,
        value: 10,
        label: 'hopeful',
        tags: []
    },
    {
        id: '11',
        texts: [
            {
                language: 'en',
                text: 'respected'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'respected'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/img/emojis/warm.png`,
        value: 11,
        label: 'respected',
        tags: []
    },
    {
        id: '12',
        texts: [
            {
                language: 'en',
                text: 'impressed'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'impressed'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/img/emojis/starstruck.png`,
        value: 12,
        label: 'impressed',
        tags: []
    },
    {
        id: '13',
        texts: [
            {
                language: 'en',
                text: 'determined'
            }
        ],
        altTexts: [
            {
                language: 'en',
                imgAltText: 'determined'
            }
        ],
        imgUrl: `${STATIC_STORAGE_URL}/img/emojis/empowered.png`,
        value: 13,
        label: 'determined',
        tags: []
    }
];
export const getBankListDefault = (IS_ENGLISH: boolean) =>
    [
        {
            id: '1',
            texts: [
                {
                    language: 'en',
                    text: IS_ENGLISH ? 'BMO' : '蒙特利尔银行'
                }
            ],
            altTexts: [
                {
                    language: 'en',
                    imgAltText: 'BMO'
                }
            ],
            imgUrl: `${STATIC_STORAGE_URL}/usr/d39e46d2-92b0-4e32-bffb-41f91614c1c3_6346f6ca-9227-4ff2-aed9-b3142537c45f_BMO%20Logo.png`,
            value: 1,
            label: 'Label1',
            tags: []
        },
        {
            id: '7',
            texts: [
                {
                    language: 'en',
                    text: IS_ENGLISH ? 'CIBC' : '帝国商业银行'
                }
            ],
            altTexts: [
                {
                    language: 'en',
                    imgAltText: 'CIBC'
                }
            ],
            imgUrl: 'https://upload.wikimedia.org/wikipedia/en/c/cf/CIBC_logo.svg',
            value: 7,
            label: 'Label2',
            tags: []
        },
        {
            id: '2',
            texts: [
                {
                    language: 'en',
                    text: IS_ENGLISH ? 'Scotiabank' : '丰业银行'
                }
            ],
            altTexts: [
                {
                    language: 'en',
                    imgAltText: 'Scotiabank'
                }
            ],
            imgUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdaetcegrQA-4k2arVu1j7dK5_p7sprSFoUGrCIZz6-YiRSye0Bw',
            value: 2,
            label: 'Label3',
            tags: []
        },
        {
            id: '5',
            texts: [
                {
                    language: 'en',
                    text: IS_ENGLISH ? 'TD' : '道明信托'
                }
            ],
            altTexts: [
                {
                    language: 'en',
                    imgAltText: 'TD'
                }
            ],
            imgUrl: 'https://is5-ssl.mzstatic.com/image/thumb/Purple113/v4/cd/f5/94/cdf594c8-d625-4fba-e18e-2eb9242b5569/AppIcon-0-1x_U007emarketing-0-0-85-220-0-8.png/246x0w.jpg',
            value: 5,
            label: 'Label4',
            tags: []
        },
        {
            id: '3',
            texts: [
                {
                    language: 'en',
                    text: IS_ENGLISH ? 'RBC' : '皇家银行'
                }
            ],
            altTexts: [
                {
                    language: 'en',
                    imgAltText: 'RBC'
                }
            ],
            imgUrl: `${STATIC_STORAGE_URL}/usr/433eb1e8-bd91-4387-982f-125b90fb1231_d680e551-1cb7-42a0-b698-4ed0b84ef295_RBC%20logo%20updated.png`,
            value: 3,
            label: 'Label5',
            tags: []
        },
        {
            id: '6',
            texts: [
                {
                    language: 'en',
                    text: 'Tangerine'
                }
            ],
            altTexts: [
                {
                    language: 'en',
                    imgAltText: 'Tangerine'
                }
            ],
            imgUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfSks6ikOzcNuJQEl3ddyIOKiC6dk9WEjVNlS5ReLmmroRwbZlMA',
            value: 6,
            label: 'Label6',
            tags: []
        }
        // {
        //     id: '4',
        //     texts: [
        //         {
        //             language: 'en',
        //             text: IS_ENGLISH ? 'All Banks' : '所有的加拿大银行'
        //         }
        //     ],
        //     altTexts: [
        //         {
        //             language: 'en',
        //             imgAltText: 'All Banks'
        //         }
        //     ],
        //     imgUrl: `${STATIC_STORAGE_URL}/usr/462c41b1-9a91-4197-9896-7a82f0b71be8_77071cbf-5b8b-4579-a9da-945a56943145_all_banks_v3.png`,
        //     value: 4,
        //     label: 'Label4',
        //     tags: []
        // },
        // {
        //     id: '8',
        //     texts: [
        //         {
        //             language: 'en',
        //             text: IS_ENGLISH ? 'Other/Unsure' : '其它/不确定'
        //         }
        //     ],
        //     altTexts: [
        //         {
        //             language: 'en',
        //             imgAltText: 'Other'
        //         }
        //     ],
        //     imgUrl: `${STATIC_STORAGE_URL}/usr/8808e5f0-06c3-4da5-87e7-a5e135832aa3_2ea8de7f-f2cd-4e75-90d3-545221b3755c_otherbank.png`,
        //     value: 8,
        //     label: 'Label8',
        //     tags: []
        // }
    ] as IPopupOptions[];
