import * as React from 'react';
import { STATIC_STORAGE_URL } from '@nexxt/common/constants';
import '../style/error.scss';

interface PageNotFoundProps {
    title?: string;
    message?: string;
}

const PageNotFound: React.FC<PageNotFoundProps> = (props) => {
    const {
        title = 'Sorry, page not found',
        message = 'Unfortunately, the link you followed may be broken or the survey may no longer be available.'
    } = props;

    return (
        <div className="error-message--container --border">
            <div className="error-message">
                <div className="error-message--header">
                    <img
                        className="error-message--header--image"
                        src={`${STATIC_STORAGE_URL}/img/404-ghost.png`}
                    />
                    <div className="error-message--header--title">{title}</div>
                </div>
                <div className="error-message--body">{message}</div>
            </div>
        </div>
    );
};
export default PageNotFound;
