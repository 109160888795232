import styled, { keyframes } from 'styled-components';
import { slideInLeft, fadeOut, slideOutUp, slideInUp } from 'react-animations';

const slideInAnimation = keyframes`${slideInLeft}`;
const slideInUpAnimation = keyframes`${slideInUp}`;
const slideOutUpAnimation = keyframes`${slideOutUp}`;
const fadeOutAnimation = keyframes`${fadeOut}`;

const SlideInDiv = styled.div`
    animation: 1s ${slideInAnimation};
`;
const SlideInUpDiv = styled.div`
    animation: 1s ${slideInUpAnimation};
`;
const FadeOut = styled.div`
    animation: 400ms ${fadeOutAnimation};
`;

const SlideOutDiv = styled.div`
    animation: 400ms ${slideOutUpAnimation};
`;

export { SlideInDiv, SlideInUpDiv, SlideOutDiv, FadeOut };
