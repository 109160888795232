import * as React from 'react';
import * as Rollbar from 'rollbar';

/**
 * https://docs.rollbar.com/docs/javascript
 *
 * Rollbar.critical("Connection error from remote Payments API");
 * Rollbar.error("Some unexpected condition");
 * Rollbar.warning("Connection error from Twitter API");
 * Rollbar.info("User opened the purchase dialog");
 * Rollbar.debug("Purchase dialog finished rendering");
 *
 * Custom data will appear in the Occurrences tab
 * i.e. Rollbar.info("Post published", {postId: 123});
 */

const RollbarHOC = (WrappedComponent) => {
    let rollbar;
    try {
        // Enable rollbar only if it is production
        if (process.env.NODE_ENV === 'production') {
            rollbar = new Rollbar({
                accessToken: process.env.ROLLBAR_TOKEN,
                captureUncaught: false,
                captureUnhandledRejections: false
            });
        } else {
            // Show console if it is dev
            rollbar = console;
        }
    } catch (err) {
        rollbar = console;
    }
    class HOC extends React.Component {
        render() {
            return (
                <>{<WrappedComponent {...this.props} rollbar={rollbar} />}</>
            );
        }
    }
    return HOC;
};

export default RollbarHOC;
