import * as React from 'react';
import * as PropTypes from 'prop-types';
import { BaseInput, BaseProps } from './_BaseInput';
import {
    MultimediaText,
    OptionData,
    OPTION_TYPES,
    SingleOptionAnswer
} from '@nexxt/common/types';
import { getStringFromMultimediaTexts } from '@nexxt/common/services/TextService';
import { KEY_NAME, OTHER_OPTION_VALUE } from '@nexxt/common/constants';

import cx from 'classnames';
import '../style/scaleslider.scss';
import NotApplicableButton from '../components/NotApplicableButton';
import { addOtherToOptions } from '../utils/questions';
import SpecifyEditor from '../components/SpecifyEditor';
import ContinueButton from '../components/ContinueButton';
import OptionText from '../components/OptionText';
const { translate } = require('react-i18next');

import { dataTestid } from '../constants/dataTestid';
import * as _ from 'lodash';
interface Props extends BaseProps {
    handleAnswer: (s: string, specify?: string) => void;
}

interface State {
    value: string;
    showSpecify: boolean;
    specifyText: string;
    submitting: boolean;
    showError: boolean;
}

const LONG_TEXT_LENGTH = 15; // length greater than Long Text will have smaller text size
@translate('translation')
export class Scale extends BaseInput<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            value: '',
            showSpecify: false,
            specifyText: '',
            submitting: false,
            showError: false
        };
    }
    stickyFooter: HTMLDivElement;

    async componentDidMount() {
        // reserve space at bottom for sticky footer
        const STICKY_FOOTER_HEIGHT = this.stickyFooter.clientHeight;
        this.props.setBottomOffset(STICKY_FOOTER_HEIGHT);
        if (this.props.previousAnswer) {
            const selectedAnswer = (
                this.props.previousAnswer as SingleOptionAnswer
            ).option;

            if (
                selectedAnswer.value === OTHER_OPTION_VALUE &&
                selectedAnswer.specify
            ) {
                this.setState({
                    showSpecify: true,
                    showError: true,
                    specifyText: selectedAnswer.specify
                });
            }
        }
    }

    handleSubmitAnswer = async (option: OptionData, other?: string) => {
        if (
            option.value === OTHER_OPTION_VALUE &&
            this.state.showSpecify &&
            !this.state.specifyText.length
        ) {
            //show warning
            this.setState({ showError: true });
            return;
        }
        if (this.state.submitting) return;
        this.setState({ submitting: true, value: option.value });

        if (
            option.value === OTHER_OPTION_VALUE &&
            this.props.question.optionSettings?.showSpecify &&
            !other
        ) {
            this.setState({ showSpecify: true, submitting: false, value: '' });
            return;
        }
        // await delay(200);
        // console.log("====> value to be send", option.value);
        this.props.handleAnswer(option.value, other);
        this.setState({ submitting: false });
    };

    loadOptions = () => {
        const { question, langId } = this.props;
        const { selectedOptionType } = question;
        const options: OptionData[] = addOtherToOptions(question);
        const hideText = selectedOptionType === OPTION_TYPES.IMAGE_ONLY;
        const hasImage = [
            OPTION_TYPES.IMAGE_ONLY,
            OPTION_TYPES.IMAGE_AND_TEXT
        ].includes(selectedOptionType as OPTION_TYPES);

        const optionTextLengths = options.map(
            (opt) =>
                (
                    getStringFromMultimediaTexts(
                        opt.texts,
                        langId,
                        opt.label
                    ) || 'placeholder'
                ).length
        );
        const longestOptionTextLength = _.max(optionTextLengths);
        const isSmallText = longestOptionTextLength > LONG_TEXT_LENGTH;

        return options.map((option: OptionData) => {
            return (
                <Slider
                    key={'Scale-' + option.id}
                    isSmallText={isSmallText}
                    isRichText={question.isRichText}
                    option={{
                        pictureUrl: option.pictureURL || '',
                        value: option.value,
                        text:
                            getStringFromMultimediaTexts(
                                option.texts,
                                langId,
                                option.label
                            ) || 'placeholder',
                        texts: option.texts,
                        hasImage,
                        hideText,
                        disabled: option.disabled,
                        langId: langId
                    }}
                    selectedValue={this.state.value}
                    onClick={() =>
                        !option.disabled && this.handleSubmitAnswer(option)
                    }
                    onKeyDown={(e) => {
                        if (
                            e.key === KEY_NAME.SPACE ||
                            e.key === KEY_NAME.ENTER
                        ) {
                            !option.disabled && this.handleSubmitAnswer(option);
                        }
                    }}
                />
            );
        });
    };

    render() {
        const optionsDom = this.loadOptions();
        const { showSpecify, specifyText, showError } = this.state;
        const { langId, question } = this.props;
        const { notApplicableTexts, optionSettings } = question;
        const notApplicableText = getStringFromMultimediaTexts(
            notApplicableTexts,
            langId
        );
        const showBothBtns =
            this.props.question.showNotApplicableOption && showSpecify;
        return (
            <div
                className={`popup-container-wrapper scale-slider white ${
                    showSpecify ? 'specify' : ''
                }`}
                ref={(e) => (this.stickyFooter = e)}
            >
                <div className="buttons-container slider-color">
                    <div className="slider-container-flex bot-width ">
                        {optionsDom}
                    </div>
                </div>
                {showSpecify ? (
                    <SpecifyEditor
                        onSubmit={() =>
                            this.handleSubmitAnswer(
                                optionSettings?.otherOption,
                                specifyText
                            )
                        }
                        showError={showError}
                        specifyText={specifyText}
                        handleChangeSpecifyText={(specifyText) =>
                            this.setState({ specifyText })
                        }
                        t={this.props.t}
                    />
                ) : null}
                <div
                    className={`bottom-select-links bot-width no-y-margin ${
                        showBothBtns && 'margin-two-btn'
                    }`}
                >
                    {this.props.question.showNotApplicableOption && (
                        <NotApplicableButton
                            onClick={() =>
                                this.props.handleAnswer(notApplicableText)
                            }
                            text={notApplicableText}
                        />
                    )}
                    {showSpecify && (
                        <ContinueButton
                            onClick={() => {
                                this.handleSubmitAnswer(
                                    optionSettings?.otherOption,
                                    this.state.specifyText
                                );
                            }}
                            disabled={!this.state.specifyText}
                            text={this.props.t('BUTTON.CONTINUE')}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export interface ISliderProps {
    isSmallText: boolean;
    isRichText?: boolean;
    option: {
        pictureUrl: string;
        text: string;
        texts: MultimediaText[];
        value: string;
        hasImage: boolean;
        hideText: boolean;
        disabled: boolean;
        langId: string;
    };

    selectedValue: string;
    onClick: () => void;
    onKeyDown: (e) => void;
}

const Slider: React.SFC<ISliderProps> = ({
    isSmallText,
    isRichText,
    option,
    selectedValue,
    onClick,
    onKeyDown
}) => {
    return (
        <div
            className={cx({
                'slider-with-emoji': option.hasImage,
                'slider-with-text': !option.hasImage,
                'slider-with-emoji-active animate':
                    option.hasImage && option.value === selectedValue,
                'slider-with-text-active animate':
                    !option.hasImage && option.value === selectedValue,
                disabled: option.disabled
            })}
            onClick={onClick}
            onKeyDown={onKeyDown}
            tabIndex={0}
        >
            {option.hasImage ? (
                <div className="emoji-holder">
                    <img src={option.pictureUrl} alt="" />
                </div>
            ) : null}
            {!option.hideText && (
                <div
                    data-testid={dataTestid.SLIDERTEXT}
                    className={`slider-text ${isSmallText ? 'text-small' : ''}`}
                    lang={option.langId}
                >
                    <OptionText
                        langId={option.langId}
                        option={option}
                        isRichText={isRichText}
                    />
                    {/* {' '}
                    {option.text} */}
                </div>
            )}
        </div>
    );
};

Slider.propTypes = {
    option: PropTypes.any,
    selectedValue: PropTypes.string,
    onClick: PropTypes.func,
    onKeyDown: PropTypes.func
};
