import * as React from 'react';
import { STATIC_STORAGE_URL } from '@nexxt/common/constants';
import '../style/error.scss';
import {
    UilMobileAndroid,
    UilTablet,
    UilDesktop
} from '@iconscout/react-unicons';

interface PageNotSupportedProps {
    availableOptions?: string[];
    title?: string;
    message?: string;
}

export const devices = [
    { value: 'browser', label: 'Desktop', title: 'desktop' },
    { value: 'tablet', label: 'Tablet', title: 'tablet' },
    { value: 'mobile', label: 'Mobile', title: 'mobile' }
];

export const deviceMapping = {
    browser: devices[0],
    tablet: devices[1],
    mobile: devices[2]
};

const PageNotSupported: React.FC<PageNotSupportedProps> = (props) => {
    const {
        availableOptions = [],
        title = 'Thank you for your interest',
        message = 'Unfortunately, this survey is not designed for this device. Please try again using one of the devices below:'
    } = props;

    const getIcon = (screen: string) => {
        switch (screen) {
            case deviceMapping.mobile.value:
                return (
                    <UilMobileAndroid size={40} style={{ marginTop: '10px' }} />
                );
            case deviceMapping.tablet.value:
                return <UilTablet size={50} />;
            default:
                return <UilDesktop size={50} />;
        }
    };

    return (
        <div className="error-message--fullpage-wrapper">
            <div className="error-message--container --border">
                <div className="error-message">
                    <div className="error-message--header">
                        <img
                            className="error-message--header--image"
                            src={`${STATIC_STORAGE_URL}/img/portal/illustrates/log-in_failed_white.gif`}
                        />
                        <div className="error-message--header--title">
                            {title}
                        </div>
                    </div>
                    <div className="error-message--body">{message}</div>
                    <div className="error-message--body">
                        {availableOptions.map((option, i) => (
                            <span
                                key={i}
                                className="error-message--body--devices"
                            >
                                {getIcon(option)}
                                {option}
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default PageNotSupported;
