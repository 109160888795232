import * as React from 'react';
import BotApp from './BotApp';
import BotError from './components/BotError';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { i18n } from './services/i18n';
import Tracker from '@openreplay/tracker';
import { PROD_DOMAIN } from '@nexxt/common/constants';
import PageNotFound from './components/PageNotFound';

interface Props {
    jumpQuestionID?: number;
    package?: number;
    isEmbedded?: boolean;
    testMode?: boolean;
    quotaGroup?: string;
    openReplayTracker?: Tracker;
}

function App(props: Props) {
    React.useEffect(() => {
        !props.isEmbedded &&
            process.env.DOMAIN_NAME === PROD_DOMAIN &&
            props.openReplayTracker
                ?.start()
                .then(() => console.log('Begin openReplay...'))
                .catch((e) =>
                    console.error(`Failed to load openReplay due to: ${e}`)
                );
    }, []);
    return (
        <I18nextProvider i18n={i18n}>
            <BotError>
                {props.isEmbedded ? (
                    <BotApp {...props} />
                ) : (
                    <Router>
                        <Switch>
                            <Route
                                exact
                                path={'/p/:codeName'}
                                component={BotApp}
                                {...props}
                            />
                            <Route component={PageNotFound} />
                        </Switch>
                    </Router>
                )}
            </BotError>
        </I18nextProvider>
    );
}

export default App;
