import * as React from 'react';
import './SVG.scss';
import { Color } from 'csstype';

export const PDFFileIcon: React.SFC = () => {
    return (
        <svg
            className="svg-icon"
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 550.8 550.8"
        >
            <g>
                <path
                    d="M160.4,282.2c0-14.8-10.3-23.7-28.5-23.7c-7.4,0-12.4,0.7-15.1,1.4v47.6c3.1,0.7,6.9,0.9,12.2,0.9
                    C148.4,308.5,160.4,298.7,160.4,282.2z"
                />
                <path
                    d="M272.9,259c-8.1,0-13.4,0.7-16.5,1.4V366c3.1,0.7,8.1,0.7,12.7,0.7c33,0.2,54.6-17.9,54.6-56.5
                    C323.8,276.7,304.2,259,272.9,259z"
                />
                <path
                    d="M488.4,197h-13.2v-63.8c0-0.4-0.1-0.8-0.1-1.2c0-2.5-0.8-5-2.6-7L366.3,3.7c0,0-0.1,0-0.1-0.1c-0.6-0.7-1.4-1.3-2.2-1.8
                    c-0.2-0.2-0.5-0.3-0.7-0.4c-0.7-0.4-1.4-0.7-2.1-0.9c-0.2-0.1-0.4-0.1-0.6-0.2C359.9,0.1,359,0,358.2,0h-261
                    C85.3,0,75.6,9.7,75.6,21.6V197H62.4c-17,0-30.9,13.8-30.9,30.9v160.5c0,17,13.8,30.9,30.9,30.9h13.2v109.9
                    c0,11.9,9.7,21.6,21.6,21.6h356.4c11.9,0,21.6-9.7,21.6-21.6V419.3h13.2c17,0,30.9-13.8,30.9-30.9V227.9
                    C519.3,210.8,505.5,197,488.4,197z M97.2,21.6h250.2v110.5c0,6,4.8,10.8,10.8,10.8h95.4V197H97.2V21.6z M362.4,309
                    c0,30.9-11.2,52.2-26.8,65.3c-17,14.1-42.8,20.8-74.4,20.8c-18.9,0-32.3-1.2-41.4-2.4V234.4c13.4-2.1,30.9-3.3,49.3-3.3
                    c30.6,0,50.5,5.5,66,17.2C351.8,260.7,362.4,280.5,362.4,309z M80.7,393.5V234.4c11.2-1.9,27-3.3,49.3-3.3
                    c22.5,0,38.5,4.3,49.3,12.9c10.3,8.1,17.2,21.5,17.2,37.3c0,15.8-5.2,29.2-14.8,38.3c-12.4,11.7-30.9,17-52.4,17
                    c-4.8,0-9.1-0.2-12.4-0.7v57.7L80.7,393.5L80.7,393.5z M453.6,523.4H97.2V419.3h356.4L453.6,523.4L453.6,523.4z M484.9,262.1h-62
                    V299h57.9v29.7h-57.9v64.8h-36.6V232.2h98.6V262.1z"
                />
            </g>
        </svg>
    );
};

export const DOCFileIcon: React.SFC = () => (
    <svg
        className="svg-icon"
        version="1.1"
        id="Capa_2"
        x="0px"
        y="0px"
        viewBox="0 0 548.3 548.3"
    >
        <g>
            <path
                d="M486.2,196.1H473v-63.5c0-0.4-0.1-0.8-0.1-1.2c0-2.5-0.8-5-2.6-7L364.7,3.7c0,0-0.1,0-0.1-0.1c-0.6-0.7-1.4-1.3-2.1-1.8
                c-0.2-0.2-0.5-0.3-0.7-0.4c-0.7-0.4-1.4-0.7-2.1-0.9c-0.2-0.1-0.4-0.1-0.6-0.2c-0.8-0.2-1.6-0.3-2.5-0.3H96.8
                C84.9,0,75.3,9.6,75.3,21.5v174.6H62.1c-17,0-30.7,13.8-30.7,30.7v159.8c0,17,13.8,30.7,30.7,30.7h13.2v109.4
                c0,11.9,9.6,21.5,21.5,21.5h354.8c11.9,0,21.5-9.6,21.5-21.5V417.4h13.2c17,0,30.7-13.8,30.7-30.7V226.9
                C516.9,209.9,503.2,196.1,486.2,196.1z M96.8,21.5h249.1v110c0,5.9,4.8,10.8,10.8,10.8h95v53.9H96.8V21.5z M354.7,298
                c0,48.9-29.6,78.5-73.2,78.5c-44.2,0-70.1-33.4-70.1-75.8c0-44.7,28.5-78.1,72.5-78.1C329.7,222.6,354.7,256.9,354.7,298z
                M64.3,373.4V227c12.4-2,28.5-3.1,45.6-3.1c28.3,0,46.7,5.1,61,15.9c15.5,11.5,25.2,29.9,25.2,56.2c0,28.5-10.4,48.2-24.8,60.4
                c-15.7,13-39.6,19.2-68.8,19.2C85.1,375.6,72.7,374.5,64.3,373.4z M451.5,521H96.8V417.4h354.8V521z M453.2,348.4
                c10.2,0,21.5-2.2,28.1-4.9l5.1,26.3c-6.2,3.1-20.1,6.4-38.3,6.4c-51.5,0-78.1-32.1-78.1-74.5c0-50.9,36.3-79.2,81.4-79.2
                c17.5,0,30.8,3.5,36.7,6.6l-6.9,26.8c-6.9-2.9-16.4-5.5-28.3-5.5c-26.8,0-47.5,16.1-47.5,49.3C405.4,329.6,423.1,348.4,453.2,348.4
                z"
            />
            <path
                d="M160.3,297.1c0.2-31-17.9-47.3-46.9-47.3c-7.5,0-12.4,0.7-15.3,1.3v97.5c2.9,0.7,7.5,0.7,11.7,0.7
                C140.4,349.5,160.3,332.7,160.3,297.1z"
            />
            <path d="M247,300c0,29.2,13.7,49.8,36.3,49.8c22.8,0,35.8-21.7,35.8-50.6c0-26.8-12.8-49.8-36-49.8C260.3,249.4,247,271,247,300z" />
        </g>
    </svg>
);

export const TXTFileIcon: React.SFC = () => (
    <svg
        className="svg-icon"
        version="1.1"
        id="Capa_3"
        x="0px"
        y="0px"
        viewBox="0 0 548.3 548.3"
    >
        <g>
            <path
                d="M486.2,196.1H473v-63.5c0-0.4-0.1-0.8-0.1-1.2c0-2.5-0.8-5-2.6-7L364.7,3.7c0,0-0.1,0-0.1-0.1c-0.6-0.7-1.4-1.3-2.1-1.8
                c-0.2-0.2-0.5-0.3-0.7-0.4c-0.7-0.4-1.4-0.7-2.1-0.9c-0.2-0.1-0.4-0.1-0.6-0.2c-0.8-0.2-1.6-0.3-2.5-0.3H96.8
                C84.9,0,75.3,9.6,75.3,21.5v174.6H62.1c-17,0-30.7,13.8-30.7,30.7v159.8c0,17,13.8,30.7,30.7,30.7h13.2v109.4
                c0,11.9,9.6,21.5,21.5,21.5h354.8c11.9,0,21.5-9.6,21.5-21.5V417.4h13.2c17,0,30.7-13.8,30.7-30.7V226.9
                C516.9,209.9,503.2,196.1,486.2,196.1z M96.8,21.5h249.1v110c0,5.9,4.8,10.8,10.8,10.8h95v53.9H96.8V21.5z M202.8,225h41.7
                l14.1,29.3c4.8,9.8,8.3,17.6,12.1,26.7h0.5c3.8-10.2,6.9-17.4,11-26.7l13.6-29.3h41.4l-45.3,78.4l47.6,82.2h-41.9l-14.5-29.1
                c-6-11.2-9.8-19.5-14.3-28.8h-0.5c-3.3,9.3-7.4,17.6-12.4,28.8l-13.3,29.1h-41.5l46.5-81.2L202.8,225z M66.1,255.5V225h123.4v30.5
                h-43.8v130h-36.4v-130H66.1z M451.5,521H96.8V417.4h354.8L451.5,521L451.5,521z M471.8,255.5h-43.8v130h-36.4v-130h-43.1V225h123.4
                L471.8,255.5L471.8,255.5z"
            />
        </g>
    </svg>
);

export const VideoIcon: React.SFC = () => (
    <svg
        className="svg-icon"
        version="1.1"
        id="Capa_1"
        x="0px"
        y="0px"
        viewBox="0 0 288.781 288.781"
    >
        <g>
            <path
                fill="#000002"
                d="M266.281,38.073H22.5c-12.407,0-22.5,10.093-22.5,22.5v167.636c0,12.407,10.093,22.5,22.5,22.5
                h243.781c12.407,0,22.5-10.093,22.5-22.5V60.573C288.781,48.166,278.688,38.073,266.281,38.073z M273.781,228.208
                c0,4.136-3.364,7.5-7.5,7.5H22.5c-4.136,0-7.5-3.364-7.5-7.5V60.573c0-4.136,3.364-7.5,7.5-7.5h243.781c4.136,0,7.5,3.364,7.5,7.5
                V228.208z"
            />
            <path
                fill="#000002"
                d="M247.744,203.336h-53.348v-2.167c0-4.142-3.358-7.5-7.5-7.5c-4.142,0-7.5,3.358-7.5,7.5v2.167
                H41.038c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h138.358v1.833c0,4.142,3.358,7.5,7.5,7.5c4.142,0,7.5-3.358,7.5-7.5
                v-1.833h53.348c4.142,0,7.5-3.358,7.5-7.5C255.244,206.694,251.886,203.336,247.744,203.336z"
            />
            <path
                fill="#000002"
                d="M144.391,189.502c31.497,0,57.122-25.625,57.122-57.121c0-31.497-25.625-57.122-57.122-57.122
                c-31.497,0-57.122,25.625-57.122,57.122C87.269,163.878,112.894,189.502,144.391,189.502z M144.391,87.259
                c24.88,0,45.122,20.242,45.122,45.122c0,24.88-20.241,45.121-45.122,45.121c-24.88,0-45.122-20.241-45.122-45.121
                C99.269,107.501,119.51,87.259,144.391,87.259z"
            />
            <path
                fill="#000002"
                d="M131.766,165.531c1.781,0,3.609-0.58,5.284-1.678l36.217-23.715
                c2.821-1.848,4.438-4.676,4.438-7.759c0-3.083-1.618-5.911-4.439-7.758l-36.216-23.712c-1.676-1.098-3.503-1.678-5.285-1.678
                c-4.068,0-8.185,3.077-8.185,8.958v48.384C123.58,162.454,127.698,165.531,131.766,165.531z M135.58,114.29l27.63,18.09
                l-27.63,18.092V114.29z"
            />
        </g>
    </svg>
);

export const SVGTick: React.SFC<{ checked: boolean; fill: Color }> = ({
    checked,
    fill
}) => (
    <svg
        id="svg-tick"
        className={'svg-tick' + (checked ? ' checked' : '')}
        fill={fill}
        viewBox="0,0,80,80"
        width="260"
    >
        <circle
            transform=""
            className="tick-circle"
            r="25"
            cx="40"
            cy="40"
            strokeLinecap="round"
            strokeWidth="4"
        />
        <polyline
            transform="translate(5,19)"
            id="tick"
            className="tick"
            fill="none"
            stroke="white"
            strokeWidth="6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
            20.3,25.4 28.6,33.9 46.7,8.3 "
            strokeDashoffset="44"
        />
    </svg>
);
