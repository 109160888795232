import * as React from 'react';
import { DragDropContext } from 'react-dnd';
import TouchBackend from 'react-dnd-touch-backend';
import MultiBackend, { TouchTransition } from 'react-dnd-multi-backend';

import NotApplicableButton from '../../components/NotApplicableButton';
import { MessageLoader } from '../../components/MessageLoader';
import { dataURLtoBlob, svgToDataURL } from '../../utils/upload';

import JourneyOption from './SVG/JourneyOption';
import { MarkersSVG } from './SVG/SVGComponents';
import * as _ from 'lodash';
import axios from 'axios';
import {
    IJourneBuilderText,
    IJourneyBuilder,
    IJourneySmiley,
    IJourneyBank
} from './types';
import {
    firstBucketOptionsDefault,
    getSecondBucketOptionsDefault,
    emojiListDefault,
    getBankListDefault
} from './data';
import { JourneyPath } from './SVG/JourneyPath';
import { JourneyElementContainer } from './SVG/JourneyElementContainer';

import '../../style/Journey.scss';
import { JourneyFollowupQuestion } from './JourneyFollowupQuestion';
import AddEvent from './AddEvent';
import { JourneyFollowupContinue } from './JourneyFollowupContinue';
import { fixedShuffleIndex } from '@nexxt/common/utils/index';
import { STATIC_STORAGE_URL } from '@nexxt/common/constants';
import { getStringFromMultimediaTexts } from '@nexxt/common/services/TextService';
import { INITIAL_JOURNEY_QUESTION } from './constant';
import { UilArrowLeft, UilArrowRight } from '@iconscout/react-unicons';

/**
 * The Journey UI is an interactive (drag-and-drop) path creation UI with two primary modes:
 * 'BUILD_JOURNEY' —
 *      involves the user dragging options from option bucket(s) onto the journey, and then asking
 *      questions about each option. This mode thus passes through several phases:
 *          1) DRAG_PHASE <-- previously ADDITEMS
 *          2) FOLLOWUP_PHASE <-- previously FOLLOWUP
 *          3) ANNOTATE_PHASE <-- previously ADDEMOJIS
 *          4) REVIEW_PHASE <-- previously FINALJOURNEY
 *
 * 'SELECT_JOURNEY' —
 *      involves the user selecting one (or more) options from a prepopulated journey path
 *      one (or more) options from the prepopulated journey path. This requires `q.journeyState`
 *      to exist, so as to provide the prepopulated journey. Under the hood, this works simply
 *      by invoking the SELECT_PHASE. Currently, the previous journey must be piped in using
 *      custom logic to populate the `answer.journeyState` into a QTYPES.JOURNEY_SELECT question.
 *
 * The Journey UI consists of several important components:
 *      - JourneyOption: options in the buckets, before being dragged onto JourneyPath (also called steps)
 *      - JourneyPath: the line which serves as background for the elements
 *      - JourneyElementContainer: arranges JourneyElements and JourneyElementDropspots in order
 *      - JourneyElement: text box or image existing as a node on the JourneyPath
 *      - JourneyElementDropspot: a 'button' on which JourneyOptions or JourneyElements can be dragged onto
 *
 * The option buckets are groups of predefined options which respondent can drag-and-drop as steps onto the journey path.
 * These are dynamically populated using the journeySettings.buckets array; and each bucket can make use of the randomizeOptions
 * and randomizeBucket properties to shuffle the order of its own options, or its order relative to other buckets, respectively.
 * The respondent is also able to create their own steps within any bucket (unless manually overriden, property TBD).
 *
 * Aside from the steps dragged on by the respondent, the Journey Path also allows for special markers called STARTPOINT, MIDPOINTS,
 * and ENDPOINT. The STARTPOINT and ENDPOINT are static placeholders which are simply used to contextualize the journey visualization
 * for the respondent. The MIDPOINTS are dynamic fixtures on the path, such that steps dragged onto the path are always relative to
 * a midpoint. Specifically, the steps are divided into segments, with segment 0 occurring between the startpoint and the first midpoint
 * (or the endpoint, in the event that there are no midpoints). Besides the ordinal position of each step, the segment value provides extra
 * metadata about where the step is situated with respect to the midpoints.
 *
 * TODO:
 *      - rename variables so that the 'modes' and the 'phases' are clearly defined according to above ^^
 *      - refactor handling for svgHeight, and for the offset from top (replacing the 50 value used throughout)
 *      - refactor 'bank' / 'smiley' / 'emoji' variables
 *      - refactor the coordinate functions in JourneyElementContainer
 *      - convert linked images into base64 so that the journey screenshot can render them
 *      - refactor journeySteps handling to allow for multiple midpoints
 */

const HTML5ToTouch = {
    backends: [
        {
            backend: TouchBackend({ enableMouseEvents: true }), // Note that you can call your backends with options
            preview: true,
            transition: TouchTransition
        }
    ]
};

interface State {
    journeyListBeforeMidpoint: IJourneBuilderText[];
    journeyListAfterMidpoint: IJourneBuilderText[];
    currentIndex: number;
    currentBucketIndex: number;
    viewedUpToBucket: number;
    state:
        | 'ADDITEMS'
        | 'ADDEMOJIS'
        | 'FINALJOURNEY'
        | 'FOLLOWUP'
        | 'SELECTSTEPS';
    currentTypeAddEmoji: 'EMOJI' | 'BANK';
    showWarning: boolean;
    requireBranch: boolean;
    minSelect: number;
    maxSelect: number;
    uploading: boolean;
    warningShown: boolean;
    journeySettings: any; // TODO: typing
}

const SVG_ELEMENT_ID = 'svg-content-id';
export interface Props {
    langId: string;
    setBottomOffset?: (offset: number) => void;
    scrollToBottom?: () => void;
    handleAnswer: (
        journeyAnswer: any,
        dataURL: string,
        journeyState: any
    ) => void;
    handleNotApplicableAnswer?: () => void;
    question: any;
    mode:
        | 'ADDITEMS'
        | 'ADDEMOJIS'
        | 'FINALJOURNEY'
        | 'FOLLOWUP'
        | 'SELECTSTEPS';
    maxSelect?: number;
}

class JourneyRaw extends React.Component<Props, State> {
    /**
     * CurrentState = SELECTSTEPS maxselect and minselect.
     * Can be set from question in the future
     *
     * @memberof Journey
     */

    constructor(props: any) {
        super(props);
        const IS_ENGLISH = this.props.langId == 'en';

        // default values, unless overridden by props.question.journeySettings
        const journeySettings = {
            midpointImageUrl:
                'https://nexxt-inca-storage.s3.us-east-2.amazonaws.com/usr/d8476bfb-5fd0-476b-8b81-a1a68d13b67c_aussie.png',
            midpointText: 'Aussie',
            startpointText: '',
            startpointImageUrl:
                'https://nexxt-inca-storage.s3.us-east-2.amazonaws.com/usr/7a0c9a76-6f77-4d4d-8274-c6f4a2a568bb_start.png',
            endpointText: '[BRAND] [PRODUCT]',
            endpointImageUrl:
                'https://nexxt-inca-storage.s3.us-east-2.amazonaws.com/usr/0534cc9d-3183-4a5a-acaa-75c7ce943832_end.png',
            journeyFollowupContinueText:
                'Thanks for sharing what led to your purchase decision of [INSERT BRAND][INSERT PRODUCT]! Now I’d like to ask a quick question about each step.',
            maxSteps: 16,
            emojiFollowupQuestionText_t:
                'What brand(s) were involved/considered when you ${ stepText }?  Please select all that apply!', // use with _.template
            emojiPlaceholderImageUrl:
                'https://nexxt-inca-storage.s3.us-east-2.amazonaws.com/usr/07877337-8d23-48ea-b5e6-d859a04887b7_b1c995ca-fc52-4496-929f-d14963467414_smiley.png',
            bankFollowupQuestionTexts: [
                { text: 'When you ', style: { fontWeight: 'normal' } },
                { dynamicText: true, style: { fontWeight: 'bold' } },
                {
                    text: ', what was the brand or brands featured/recommended/advertised/displayed/explored? If there were brands other than what you bought in the end, please don’t forget to include them. Select all that apply.',
                    style: { fontWeight: 'normal' }
                }
            ],
            bankPlaceholderImageUrl:
                'https://nexxt-inca-storage.s3.us-east-2.amazonaws.com/usr/12d80350-1b4d-4128-853a-67e8c4f4d5c4_e7d449a8-daa7-4fdc-bd49-f020f830c04c_bank.png',
            buckets: [
                {
                    label: 'Online Events',
                    header: 'ONE',
                    options: firstBucketOptionsDefault,
                    color: '#F2AF00',
                    allowNewOptions: true,
                    instructions: [
                        {
                            text: 'Drag and drop any events you’ve done on to the (+) icons on the line below corresponding to whether they happened BEFORE or AFTER you visited the store/salon/webpage. Use the arrow to see the other set of events.',
                            style: {}
                        },
                        {
                            text: 'If nothing applies, move on to the other set of events.',
                            style: { fontWeight: 'bold' }
                        }
                    ]
                },
                {
                    label: 'In-store/In-person Events',
                    header: 'TWO',
                    options: getSecondBucketOptionsDefault(IS_ENGLISH),
                    color: '#0086FF',
                    allowNewOptions: true,
                    instructions: [
                        {
                            text: 'Drag and drop any events you’ve done on to the (+) icons on the line below. Use the arrow to see the other set of events.',
                            style: {}
                        },
                        {
                            text: 'If nothing applies, move on to the other set of events.',
                            style: { fontWeight: 'bold' }
                        }
                    ]
                }
            ],
            emojiList: emojiListDefault,
            bankList: getBankListDefault(IS_ENGLISH),
            warningLessText:
                'That was quick; are you sure there weren’t anything else that led to or influenced your purchase decision? It would be really helpful if you could add every detail you can!\n\nOnce you click continue, you cannot edit anymore.',
            warningMoreText:
                'Looking good! Before we move on, could you please double check that you’ve added every detail you can?\n\nOnce you click continue, you cannot edit anymore.',
            warningStepsThreshold: 3,
            instructionalTexts: [
                'First, I will show you some examples of what you might have done/what might have happened online. Please drag and drop the relevant events on to the diagram below. If there were other online events not listed, you can click on “other” and write your own. You can also drag and drop any event multiple times.',
                'You’ll see I’ve indicated the store where you made the purchase—anything that happened BEFORE you visited the store/webpage, please drag and drop ONTO A “PLUS” SIGN before the store bubble; anything that happened AFTER you entered the store/webpage please drag and drop ONTO A “PLUS” SIGN after the store sign.',
                'Click “continue” to move on to the next step.'
            ],
            ...props.question.journeySettings
        };

        // randomize buckets + options based on randomizeBucket and randomizeOptions
        const bucketsWithRandomizedOptions = journeySettings.buckets.map((b) =>
            b.randomizeOptions ? { ...b, options: _.shuffle(b.options) } : b
        );
        const processedJourneySettings = {
            ...journeySettings,
            buckets: fixedShuffleIndex(
                bucketsWithRandomizedOptions,
                bucketsWithRandomizedOptions.map((b) => !b.randomizeBucket)
            )
        };

        if (this.props.question.journeyState) {
            // load journey select mode
            this.state = {
                ...this.props.question.journeyState,
                state: this.props.mode,
                minSelect: 1,
                maxSelect: this.props.maxSelect ? this.props.maxSelect : 1
            };
        } else {
            // load regular journey
            this.state = {
                minSelect: 1,
                maxSelect: 1,
                uploading: false,
                showWarning: false,
                journeyListAfterMidpoint: [],
                journeyListBeforeMidpoint: [],
                currentIndex: 0,
                currentBucketIndex: 0, // bucket of options the user is currently on
                viewedUpToBucket: 0, // tracks up to which bucket index user has viewed
                state: this.props.mode, // "ADDITEMS", //"ADDITEMS","FINALJOURNEY", "FOLLOWUP",
                currentTypeAddEmoji: INITIAL_JOURNEY_QUESTION,
                requireBranch: this.props.question.requireBranch,
                warningShown: false,
                journeySettings: processedJourneySettings
            };
        }
    }

    componentDidMount() {
        // this.changeCurrentState("ADDEMOJIS");
    }

    updateBeforeMidpoint = (
        objList: IJourneBuilderText[],
        isBeforeMidpoint: boolean
    ) => {
        return [
            ...objList.map((j) => {
                return { ...j, isBeforeMidpoint: isBeforeMidpoint };
            })
        ];
    };

    // Upload dataURL to Cloud storage and return full image URL
    uploadJourneyAsImage = async () => {
        this.setState({ uploading: true });
        const journeySvg = document.getElementById(SVG_ELEMENT_ID);
        const dataURL = svgToDataURL(journeySvg);
        const blob = dataURLtoBlob(dataURL);
        const formData = new FormData();
        formData.append('file', blob, 'journey_image');

        const url = `${process.env.INCA_SERVER_URL}/data/file/upload`;

        const resp = await axios.post(url, formData);
        this.setState({ uploading: false });

        return resp.data.full;
    };

    /* Drag dialogs to add button */
    moveOnDrag = (
        initialIndex: number,
        finalIndex: number,
        type?: 'ADDBUTTON' | 'MIDPOINT' | 'TEXT'
    ) => {
        /* Length of journeyList before modifying */
        const beforeLength = this.state.journeyListBeforeMidpoint.length;
        const afterLength = this.state.journeyListAfterMidpoint.length;

        /* JourneyList before and after for modifiying */
        const journeyListBeforeMidpoint = [
            ...this.state.journeyListBeforeMidpoint
        ];
        const journeyListAfterMidpoint = [
            ...this.state.journeyListAfterMidpoint
        ];

        /* If midpoint is present */
        const midpointOffset = this.state.journeySettings.midpointImageUrl
            ? 1
            : 0;

        /* If add button after midpoint is triggered */
        if (type === 'MIDPOINT') {
            // addbutton after midpoint
            /* If move triggers just before or after the current position, no need to move */
            if (
                initialIndex === beforeLength ||
                initialIndex === beforeLength + 1
            ) {
                return;
            }

            /* All condition below is only for droping the card just after midpoint */
            if (beforeLength > initialIndex) {
                const partialArray = journeyListBeforeMidpoint.splice(
                    initialIndex,
                    1
                );
                this.setState({
                    journeyListBeforeMidpoint: [...journeyListBeforeMidpoint],
                    journeyListAfterMidpoint: [
                        ...this.updateBeforeMidpoint(partialArray, false),
                        ...journeyListAfterMidpoint
                    ]
                });
            } else {
                const partialArray = journeyListAfterMidpoint.splice(
                    initialIndex - beforeLength - midpointOffset,
                    1
                );
                this.setState({
                    journeyListBeforeMidpoint: [...journeyListBeforeMidpoint],
                    journeyListAfterMidpoint: [
                        ...this.updateBeforeMidpoint(partialArray, false),
                        ...journeyListAfterMidpoint
                    ]
                });
            }
        } else if (type === 'ADDBUTTON') {
            /* If first add button is triggered */

            // first add button
            if (initialIndex === 0) {
                return;
            }
            if (beforeLength === initialIndex) {
                this.setState({
                    journeyListBeforeMidpoint: [],
                    journeyListAfterMidpoint: [
                        ...this.updateBeforeMidpoint(
                            journeyListBeforeMidpoint,
                            false
                        ),
                        ...journeyListAfterMidpoint
                    ]
                });
            } else if (beforeLength > initialIndex) {
                const partialArray = journeyListBeforeMidpoint.splice(
                    initialIndex,
                    1
                );
                this.setState({
                    journeyListBeforeMidpoint: [
                        ...this.updateBeforeMidpoint(partialArray, true),
                        ...journeyListBeforeMidpoint
                    ],
                    journeyListAfterMidpoint: [...journeyListAfterMidpoint]
                });
            } else {
                const partialArray = journeyListAfterMidpoint.splice(
                    initialIndex - beforeLength - 1,
                    1
                );
                this.setState({
                    journeyListBeforeMidpoint: [
                        ...this.updateBeforeMidpoint(partialArray, true),
                        ...journeyListBeforeMidpoint
                    ],
                    journeyListAfterMidpoint: [...journeyListAfterMidpoint]
                });
            }
            return;
        } else {
            // Type === TEXT
            /* If move triggers just before or after the current position, no need to move */
            if (
                finalIndex === initialIndex ||
                finalIndex === initialIndex - 1
            ) {
                // console.log("Return Statement reached");
                return;
            }
            if (this.state.journeySettings.midpointImageUrl) {
                // Condition applied iff midpoint is present
                /* following condition is only applicable if we are moving midpoint */
                if (beforeLength === initialIndex) {
                    // moving midpoint
                    if (beforeLength > finalIndex) {
                        /**
                         * Partial Array after current final index to last point.
                         */
                        const partialArray = journeyListBeforeMidpoint.splice(
                            finalIndex + 1,
                            beforeLength - finalIndex - 1
                        );
                        this.setState({
                            journeyListBeforeMidpoint: [
                                ...journeyListBeforeMidpoint
                            ],
                            journeyListAfterMidpoint: [
                                ...this.updateBeforeMidpoint(
                                    partialArray,
                                    false
                                ),
                                ...journeyListAfterMidpoint
                            ]
                        });
                    } else {
                        const index =
                            finalIndex - beforeLength - midpointOffset; // subtract 1 for midpoint
                        /**
                         * Partial Array after current final index to last point.
                         */
                        const partialArray = journeyListAfterMidpoint.splice(
                            index + 1,
                            afterLength - index - 1
                        );

                        this.setState({
                            journeyListBeforeMidpoint: [
                                ...journeyListBeforeMidpoint,
                                ...this.updateBeforeMidpoint(
                                    journeyListAfterMidpoint,
                                    true
                                )
                            ],
                            journeyListAfterMidpoint: [
                                ...this.updateBeforeMidpoint(
                                    partialArray,
                                    false
                                )
                            ]
                        });
                    }
                    return; // the work is done here.
                }
            }
            let removedObject;
            if (beforeLength > initialIndex) {
                removedObject = journeyListBeforeMidpoint.splice(
                    initialIndex,
                    1
                );
            } else {
                removedObject = journeyListAfterMidpoint.splice(
                    initialIndex - beforeLength - midpointOffset,
                    1
                );
            }
            if (finalIndex === beforeLength - 1) {
                this.setState({
                    journeyListBeforeMidpoint: [
                        ...journeyListBeforeMidpoint,
                        ...this.updateBeforeMidpoint(removedObject, true)
                    ],
                    journeyListAfterMidpoint: [...journeyListAfterMidpoint]
                });
                return;
            } else if (finalIndex === beforeLength + afterLength) {
                this.setState({
                    journeyListBeforeMidpoint: [...journeyListBeforeMidpoint],
                    journeyListAfterMidpoint: [
                        ...journeyListAfterMidpoint,
                        ...this.updateBeforeMidpoint(removedObject, false)
                    ]
                });
                return;
            } else {
                let index = finalIndex; //initialIndex > finalIndex ? finalIndex + 1 : finalIndex
                if (initialIndex > beforeLength && finalIndex > beforeLength) {
                    index = finalIndex - 1;
                }
                if (beforeLength > finalIndex) {
                    journeyListBeforeMidpoint.splice(
                        index + 1 - (finalIndex > initialIndex ? 1 : 0),
                        0,
                        this.updateBeforeMidpoint(removedObject, true)[0]
                    );
                } else {
                    journeyListAfterMidpoint.splice(
                        index - beforeLength - 1 + midpointOffset,
                        0,
                        this.updateBeforeMidpoint(removedObject, false)[0]
                    );
                }
                this.setState({
                    journeyListBeforeMidpoint: [...journeyListBeforeMidpoint],
                    journeyListAfterMidpoint: [...journeyListAfterMidpoint]
                });
            }
        }
    };

    /* Save a newly created event (option) */
    createNewOption = (text: string, bucketIndex: number) => {
        const { buckets } = this.state.journeySettings;
        const correspondingBucket = buckets[bucketIndex];
        const newOption = {
            id: Math.floor(Math.random() * +new Date()),
            text: text,
            color: correspondingBucket.color,
            bgColor: ''
        };
        const newBuckets = [
            ...buckets.filter((b, i) => i < bucketIndex),
            {
                ...correspondingBucket,
                options: [...correspondingBucket.options, newOption]
            },
            ...buckets.filter((b, i) => i > bucketIndex)
        ];
        this.setState({
            journeySettings: {
                ...this.state.journeySettings,
                buckets: newBuckets
            }
        });
    };

    createTextNode = (props: any, data: any, isBeforeMidpoint = false) => {
        return {
            id: `${Math.random() * +new Date()}`,
            smiley: null,
            bank: [],
            isBeforeMidpoint: props.isBeforeMidpoint || isBeforeMidpoint,
            isSelected: false,
            listType: '',
            text: data.text,
            type: 'TEXT',
            color: data.color
        };
    };
    saveSmiley = (index, emoji: IJourneySmiley) => {
        const beforeLength = this.state.journeyListBeforeMidpoint.length;
        let currentIndex;
        if (index + 1 <= beforeLength) {
            currentIndex = index;
            const currentObject = {
                ...this.state.journeyListBeforeMidpoint[currentIndex]
            };
            const journeyList = [...this.state.journeyListBeforeMidpoint];
            currentObject.smiley = { ...emoji };
            journeyList.splice(currentIndex, 1, currentObject);
            this.setState({
                journeyListBeforeMidpoint: [...journeyList]
            });
        } else {
            currentIndex = index - beforeLength;
            const currentObject = {
                ...this.state.journeyListAfterMidpoint[currentIndex]
            };
            // console.log("currentZ", currentObject, index, currentIndex, beforeLength);
            const journeyList = [...this.state.journeyListAfterMidpoint];
            currentObject.smiley = { ...emoji };
            journeyList.splice(currentIndex, 1, currentObject);
            this.setState({
                journeyListAfterMidpoint: [...journeyList]
            });
        }
    };
    saveBank = (index: number, bank: IJourneyBank[]) => {
        const beforeLength = this.state.journeyListBeforeMidpoint.length;
        let currentIndex;
        if (index + 1 <= beforeLength) {
            currentIndex = index;
            const currentObject = {
                ...this.state.journeyListBeforeMidpoint[currentIndex]
            };
            const journeyList = [...this.state.journeyListBeforeMidpoint];
            currentObject.bank = [...bank];
            journeyList.splice(currentIndex, 1, currentObject);
            this.setState({
                journeyListBeforeMidpoint: [...journeyList]
            });
        } else {
            currentIndex = index - beforeLength;
            const currentObject = {
                ...this.state.journeyListAfterMidpoint[currentIndex]
            };
            const journeyList = [...this.state.journeyListAfterMidpoint];
            currentObject.bank = [...bank];
            journeyList.splice(currentIndex, 1, currentObject);
            this.setState({
                journeyListAfterMidpoint: [...journeyList]
            });
        }
    };
    saveEverything = (
        index: number,
        bank: IJourneyBank[],
        emoji: IJourneySmiley,
        textResponse: string,
        isLastOption: boolean
    ) => {
        const beforeLength = this.state.journeyListBeforeMidpoint.length;
        let currentIndex;
        if (index + 1 <= beforeLength) {
            currentIndex = index;
            const currentObject = {
                ...this.state.journeyListBeforeMidpoint[currentIndex]
            };
            // taking copies here.. not sure if necessary?
            const journeyList = [...this.state.journeyListBeforeMidpoint];
            currentObject.bank = [...bank];
            currentObject.smiley = { ...emoji };
            currentObject.textResponse = `${textResponse}`;
            journeyList.splice(currentIndex, 1, currentObject);
            this.setState(
                {
                    journeyListBeforeMidpoint: [...journeyList]
                },
                () => {
                    if (isLastOption) {
                        this.goToFinalJourney();
                    }
                }
            );
        } else {
            currentIndex = index - beforeLength;
            const currentObject = {
                ...this.state.journeyListAfterMidpoint[currentIndex]
            };
            // console.log("currentZ", currentObject, index, currentIndex, beforeLength);
            const journeyList = [...this.state.journeyListAfterMidpoint];
            currentObject.bank = [...bank];
            currentObject.smiley = { ...emoji };
            journeyList.splice(currentIndex, 1, currentObject);
            this.setState(
                {
                    journeyListAfterMidpoint: [...journeyList]
                },
                () => {
                    if (isLastOption) {
                        this.goToFinalJourney();
                    }
                }
            );
        }
    };

    handleAddItemsContinue = () => {
        // custom validation logic.. this can be replaced with customLogic!
        if (!this.state.warningShown) {
            const numSteps =
                this.state.journeyListBeforeMidpoint.length +
                this.state.journeyListAfterMidpoint.length;
            if (numSteps < this.state.journeySettings.warningStepsThreshold)
                alert(this.state.journeySettings.warningLessText);
            else alert(this.state.journeySettings.warningMoreText);
            this.setState({ warningShown: true });
        } else {
            this.goToFollowUp();
        }
    };

    goToFollowUp = () => {
        const PHYSICAL_BANK_IDS = [1, 2, 3, 5, 7];
        // const PHYSICAL_BANK_IDS = [1,2,3,4,5,6,7,8,9,10,11,12,13,14];
        const bankID = parseInt(this.props.question.bank);
        const VISITED_BRANCH_TEXTS = [
            'visited a branch with appointment',
            '预约后去分行交谈',
            'visited a branch without appointment',
            '未经预约直接去分行交谈',
            'be taken by friend/family/landlord',
            '我的朋友/家人/房东直接带我去银行'
        ];
        if (PHYSICAL_BANK_IDS.includes(bankID)) {
            const steps = [
                ...this.state.journeyListBeforeMidpoint,
                ...this.state.journeyListAfterMidpoint
            ];
            const visitBankSteps = steps.find((s) =>
                VISITED_BRANCH_TEXTS.includes(s.text)
            );
            console.log('steps:', [
                ...this.state.journeyListBeforeMidpoint,
                ...this.state.journeyListAfterMidpoint
            ]);
            if (!visitBankSteps && this.state.requireBranch) {
                this.setState({ showWarning: true });
                return;
            }
        }
        this.setState({ showWarning: false });
        this.changeCurrentState('FOLLOWUP');
    };

    // goToSelectSteps = () => {
    //     this.changeCurrentState("SELECTSTEPS");
    // }

    goToPopup = () => {
        this.changeCurrentState('ADDEMOJIS');
        this.props.setBottomOffset(250);
    };

    goToFinalJourney = () => {
        this.changeCurrentState('FINALJOURNEY');
    };

    setCurrentTypeAddEmoji = (value: 'BANK' | 'EMOJI') => {
        this.setState({
            currentTypeAddEmoji: value
        });
    };

    changeCurrentState = (value: any) => {
        this.setState(
            {
                state: value
            },
            () => {
                if (value === 'ADDEMOJIS') {
                    this.showEmoji();
                    this.hideFollowup();
                } else if (value === 'FOLLOWUP') {
                    this.showFollowup();
                    this.hideEmoji();
                } else {
                    this.hideEmoji();
                    this.hideFollowup();
                }
            }
        );

        this.props.scrollToBottom();
    };

    showEmoji = () => {
        const el = document.getElementById('wc-message-groups');
        el.classList.add('wc-message-groups-collapse-journey');
    };

    showFollowup = () => {
        const el = document.getElementById('wc-message-groups');
        el.classList.add('wc-message-groups-collapse-journey-followup');
    };

    hideEmoji = () => {
        const el = document.getElementById('wc-message-groups');
        el.classList.remove('wc-message-groups-collapse-journey');
    };

    hideFollowup = () => {
        const el = document.getElementById('wc-message-groups');
        el.classList.remove('wc-message-groups-collapse-journey-followup');
    };

    removeDialog = (index: number) => {
        const beforeLength = this.state.journeyListBeforeMidpoint.length;
        const midpointOffset = this.state.journeySettings.midpointImageUrl
            ? 1
            : 0;
        if (index >= beforeLength) {
            const currentIndex = index - beforeLength - midpointOffset;
            const journeyListAfterMidpoint = [
                ...this.state.journeyListAfterMidpoint
            ];
            journeyListAfterMidpoint.splice(currentIndex, 1);
            this.setState({
                journeyListAfterMidpoint
            });
        } else {
            const currentIndex = index;
            const journeyListBeforeMidpoint = [
                ...this.state.journeyListBeforeMidpoint
            ];
            journeyListBeforeMidpoint.splice(currentIndex, 1);
            this.setState({
                journeyListBeforeMidpoint
            });
        }
    };

    addDialogOnDrop = ({ props, monitor }) => {
        //   console.log("drop on add button", props, monitor.getItem(), monitor.getItemType());
        const monitorItem = monitor.getItem();
        if (props.type === 'ADDBUTTON') {
            const data: any = this.createTextNode(props, monitorItem, true);
            this.setState({
                journeyListBeforeMidpoint: [
                    { ...data },
                    ...this.state.journeyListBeforeMidpoint
                ]
            });
        } else if (props.type === 'MIDPOINT') {
            const data: any = this.createTextNode(props, monitorItem, false);
            this.setState({
                journeyListAfterMidpoint: [
                    { ...data },
                    ...this.state.journeyListAfterMidpoint
                ]
            });
        } else {
            const data: any = this.createTextNode(props, monitorItem);
            if (props.isBeforeMidpoint) {
                const journeyListBeforeMidpoint = [
                    ...this.state.journeyListBeforeMidpoint
                ];
                const index = props.currentIndex;
                //   console.log("index===>", index, props.currentIndex, props, this.state.journeyListBeforeMidpoint.length);
                journeyListBeforeMidpoint.splice(index + 1, 0, data);
                this.setState({
                    journeyListBeforeMidpoint: [...journeyListBeforeMidpoint]
                });
            } else {
                const journeyListAfterMidpoint = [
                    ...this.state.journeyListAfterMidpoint
                ];
                const index =
                    props.currentIndex -
                    (this.state.journeyListBeforeMidpoint.length + 1);
                journeyListAfterMidpoint.splice(index + 1, 0, data);
                // console.log("index===>", index, props.currentIndex, props, this.state.journeyListBeforeMidpoint.length);
                this.setState({
                    journeyListAfterMidpoint: [...journeyListAfterMidpoint]
                });
            }
        }
    };

    /**
     * State: SELECTSTEPS
     * Toggle isSelect on click event on journey dialog box in this specific step
     *
     * @memberof Journey
     */
    selectCurrentDialog = async (index: number) => {
        const maxSelect = this.state.maxSelect;

        const allList = [
            ...this.state.journeyListBeforeMidpoint,
            ...this.state.journeyListAfterMidpoint
        ].filter((l) => l.isSelected);
        if (this.state.state !== 'SELECTSTEPS') {
            return;
        }
        const beforeLength = this.state.journeyListBeforeMidpoint.length;
        const midpointOffset = this.state.journeySettings.midpointImageUrl
            ? 1
            : 0;
        if (index >= beforeLength) {
            const currentIndex = index - beforeLength - midpointOffset;
            const journeyListAfterMidpoint = [
                ...this.state.journeyListAfterMidpoint
            ];
            const journeyItem = { ...journeyListAfterMidpoint[currentIndex] };
            if (
                allList.length >= maxSelect &&
                journeyItem.isSelected === false
            ) {
                return;
            }
            journeyItem.isSelected = !journeyItem.isSelected;
            journeyListAfterMidpoint.splice(currentIndex, 1, journeyItem);
            this.setState({
                journeyListAfterMidpoint
            });
            if (maxSelect == 1) {
                this.props.handleAnswer(
                    [
                        ...this.state.journeyListBeforeMidpoint,
                        ...journeyListAfterMidpoint
                    ],
                    await this.uploadJourneyAsImage(),
                    this.state
                );
            }
        } else {
            const currentIndex = index;
            const journeyListBeforeMidpoint = [
                ...this.state.journeyListBeforeMidpoint
            ];
            const journeyItem = { ...journeyListBeforeMidpoint[currentIndex] };
            if (
                allList.length >= maxSelect &&
                journeyItem.isSelected === false
            ) {
                return;
            }
            journeyItem.isSelected = !journeyItem.isSelected;
            journeyListBeforeMidpoint.splice(currentIndex, 1, journeyItem);
            this.setState({
                journeyListBeforeMidpoint
            });
            if (maxSelect == 1) {
                this.props.handleAnswer(
                    [
                        ...journeyListBeforeMidpoint,
                        ...this.state.journeyListAfterMidpoint
                    ],
                    await this.uploadJourneyAsImage(),
                    this.state
                );
            }
        }
    };

    handleAnswer = async () => {
        const journeyAnswer = [
            ...this.state.journeyListBeforeMidpoint,
            ...this.state.journeyListAfterMidpoint
        ];
        this.props.handleAnswer(
            journeyAnswer,
            await this.uploadJourneyAsImage(),
            this.state
        );
    };

    continueButton = (continueText, list) => {
        const { buckets } = this.state.journeySettings;
        const { journeyListBeforeMidpoint, journeyListAfterMidpoint } =
            this.state;
        if (this.state.state === 'FINALJOURNEY') {
            return (
                <button
                    className={`journeyq-button button-hg button-continue`}
                    disabled={this.state.uploading}
                    onClick={this.handleAnswer}
                >
                    {this.state.uploading ? <MessageLoader /> : continueText}
                </button>
            );
        } else if (this.state.state === 'ADDITEMS') {
            const notValidated =
                buckets.length - 1 > this.state.viewedUpToBucket ||
                journeyListBeforeMidpoint.length +
                    journeyListAfterMidpoint.length ==
                    0;

            return (
                <React.Fragment>
                    {/* <JourneyMaxCounter max={maxSteps} total={this.state.journeyListBeforeMidpoint.length+this.state.journeyListAfterMidpoint.length} /> */}
                    <div>
                        {notValidated
                            ? 'Please view all options and select at least one before continuing.'
                            : "Once you've added every event that led to your purchase decision, click continue."}
                    </div>
                    <button
                        className={`journeyq-button button-hg button-continue ${
                            notValidated ? 'disabled-dark' : ''
                        }`}
                        disabled={notValidated}
                        onClick={
                            this
                                .handleAddItemsContinue /* this.goToSelectSteps */
                        }
                        style={{ marginTop: '40px' }}
                    >
                        {continueText}
                    </button>
                </React.Fragment>
            );

            /* else if (this.state.state === 'FOLLOWUP') {
                <button className={`journeyq-button button-hg button-continue`} onClick={this.goToPopup}>Continue</button>
            } */
        } else if (this.state.state === 'SELECTSTEPS') {
            const allList = [
                ...this.state.journeyListBeforeMidpoint,
                ...this.state.journeyListAfterMidpoint
            ].filter((l) => l.isSelected);
            {
                /*allList.length < this.state.maxSelect
            ? <span className="journey-warning">You can select upto {this.state.maxSelect} cards</span>
            : <span className="journey-warning">All {this.state.maxSelect} are selected</span>
            */
            }
            return this.state.maxSelect == 1 ? null : (
                <button
                    className={`journeyq-button button-hg button-continue ${
                        allList.length < this.state.minSelect ? 'disabled' : ''
                    }`}
                    disabled={allList.length < this.state.minSelect}
                    onClick={this.handleAnswer}
                >
                    {continueText}
                </button>
            );
        }
        return null;
    };

    renderJourney() {
        const {
            twoBucketMode,
            midpointImageUrl,
            midpointText,
            startpointImageUrl,
            startpointText,
            endpointImageUrl,
            endpointText,
            maxSteps,
            buckets,
            instructionalTexts
        } = this.state.journeySettings;
        const { currentBucketIndex } = this.state;

        // OPTIONS
        const svgWidth = 1024;

        // GENERATE journeyList (full path) and chunkList (articulated path)
        let journeyList: any[] = [];
        if (midpointImageUrl) {
            journeyList = [
                ...this.state.journeyListBeforeMidpoint,
                {
                    imgUrl: midpointImageUrl,
                    type: 'MIDPOINT',
                    text: midpointText
                },
                ...this.state.journeyListAfterMidpoint
            ];
        } else {
            journeyList = [
                ...this.state.journeyListBeforeMidpoint,
                ...this.state.journeyListAfterMidpoint
            ];
        }
        const showAddButton =
            this.state.state === 'ADDITEMS' &&
            journeyList.length <= maxSteps + 1;
        let hightLightCurrent = false;
        if (this.state.state === 'ADDEMOJIS') {
            hightLightCurrent = true;
        }
        let chunkList: IJourneyBuilder[][] = [];
        if (journeyList.length < 5) {
            chunkList = [[...journeyList]];
        } else {
            const firstElement: IJourneyBuilder =
                journeyList.shift() as IJourneyBuilder;
            chunkList = _.chunk(journeyList, 5);
            chunkList[0] = [firstElement, ...chunkList[0]];
        }
        const svgHeight = Math.max(200 * chunkList.length + 20, 300);
        // if (chunkList.length > 1) {
        //     svgHeight = 270 + (chunkList.length - 1) * 150;
        //     if (chunkList[chunkList.length - 1].length > 3) {
        //         svgHeight += 50;
        //     }
        // } else if (chunkList[0].length > 4) {
        //     svgHeight = 320;
        // }
        let currentIndex = this.state.currentIndex;
        if (
            this.state.journeyListBeforeMidpoint.length <
            this.state.currentIndex + 1
        ) {
            currentIndex = currentIndex + 1;
        }

        // TEXT LITERALS
        // this.props.langId == 'en'
        //     ? 'I was actively looking. I...'
        //     : '我主动寻找找信息...';
        // this.props.langId == 'en' ? 'I happened to...' : '我碰巧......';
        const continueText = this.props.langId == 'en' ? 'Continue' : '继续';
        const warningText =
            this.props.langId == 'en'
                ? 'I would think you\'d need to go to a branch to open an account? Please make sure that you add a "visited a branch“ step, then "continue".'
                : '第一次开户好像应该是要本人去银行的吧？请添加一个和“去分行”相关的步骤，然后 “继续”';

        // important params
        const showStartpoint = Boolean(startpointImageUrl || startpointText);
        const firstRowOffset = showStartpoint ? 100 : 0;

        // bucket vars
        const positionalHeaders = [
            'ONE',
            'TWO',
            'THREE',
            'FOUR',
            'FIVE',
            'SIX'
        ];
        const showPreviousBucketButton = currentBucketIndex > 0;
        const currentBucket = buckets[currentBucketIndex];
        const onLastBucket = currentBucketIndex === buckets.length - 1;

        return (
            <React.Fragment>
                {/* JOURNEY BUCKETS CONTAINER */}
                {this.state.state === 'ADDITEMS' ? (
                    <div className="content from-bot bot-width">
                        <div className="journeyq-options-container">
                            <div className="journeyq-tabs">
                                <div className="journeyq-tabs-header">
                                    {/* JOURNEY BUCKET LABEL */}
                                    <div className="journeyq-tabs-header-label">
                                        {currentBucket.label}
                                    </div>
                                    {/* JOURNEY BUCKET INSTRUCTIONS */}
                                    <div className="journeyq-tabs-header-instructions">
                                        {currentBucket.instructions.map(
                                            (instruction, i) => (
                                                <span
                                                    key={i}
                                                    style={instruction.style}
                                                >
                                                    {instruction.text}
                                                </span>
                                            )
                                        )}
                                    </div>
                                </div>

                                {/* JOURNEY BUCKETS OPTIONS */}
                                <div className="journeyq-options">
                                    <div className="journeyq-option-container">
                                        {buckets[
                                            currentBucketIndex
                                        ].options.map((opt: any) => {
                                            return (
                                                <JourneyOption
                                                    key={opt.id}
                                                    text={opt.text}
                                                    color={opt.color}
                                                    bgColor={opt.bgcolor}
                                                    id={opt.id}
                                                />
                                            );
                                        })}
                                        {currentBucket.allowNewOptions ? (
                                            <AddEvent
                                                bgcolor={currentBucket.color}
                                                bucketIndex={currentBucketIndex}
                                                onSave={this.createNewOption}
                                                langId={this.props.langId}
                                            />
                                        ) : null}
                                    </div>
                                </div>

                                {/* JOURNEY BUCKETS BUTTONS */}
                                <div className="journeyq-tabs-buckets-buttons">
                                    {showPreviousBucketButton && (
                                        <button
                                            className={`journeyq-button-arrow button-arrow-previous`}
                                            onClick={() =>
                                                this.setState({
                                                    currentBucketIndex:
                                                        currentBucketIndex - 1
                                                })
                                            }
                                        >
                                            <span className="button-arrow-vector">
                                                <UilArrowLeft size={32} />
                                            </span>
                                            <span>
                                                {
                                                    buckets[
                                                        currentBucketIndex - 1
                                                    ].label
                                                }
                                            </span>
                                        </button>
                                    )}
                                    {!onLastBucket && (
                                        <button
                                            className={`journeyq-button-arrow button-arrow-continue`}
                                            onClick={() =>
                                                this.setState((prevState) => ({
                                                    ...prevState,
                                                    currentBucketIndex:
                                                        currentBucketIndex + 1,
                                                    viewedUpToBucket:
                                                        currentBucketIndex + 1 >
                                                        prevState.viewedUpToBucket
                                                            ? currentBucketIndex +
                                                              1
                                                            : prevState.viewedUpToBucket
                                                }))
                                            }
                                        >
                                            <span>
                                                {
                                                    buckets[
                                                        currentBucketIndex + 1
                                                    ].label
                                                }
                                            </span>
                                            <span className="button-arrow-vector">
                                                <UilArrowRight size={32} />
                                            </span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}

                {/* JOURNEY PATH CONTAINER */}
                <div className="content from-bot bot-width">
                    <div
                        className="svg-container"
                        style={{ textAlign: 'center' }}
                    >
                        {/* JOURNEY PATH SVG */}
                        <svg
                            id={SVG_ELEMENT_ID}
                            version="1.1"
                            viewBox={`0 0 ${svgWidth} ${svgHeight}`}
                            preserveAspectRatio="xMinYMin meet"
                            className="svg-content"
                        >
                            {/* NOT SURE WHAT THIS DOES?? */}
                            <MarkersSVG />

                            {/* JOURNEY PATH LINE COMPONENT */}
                            <JourneyPath
                                startPointX={firstRowOffset}
                                startPointY={0}
                                chunkList={chunkList}
                                firstRowOffset={firstRowOffset}
                            />

                            {/* JOURNEY ELEMENT CONTAINER (OPTIONS, PLACEHOLDERS, & DROP ADD BUTTONS) */}
                            <JourneyElementContainer
                                chunkList={chunkList}
                                // bank={this.props.question.bank}
                                currentType={this.state.currentTypeAddEmoji}
                                addDialogOnDrop={this.addDialogOnDrop}
                                hightLightCurrent={hightLightCurrent}
                                currentIndex={currentIndex}
                                startpointText={startpointText}
                                startpointImageUrl={startpointImageUrl}
                                endpointText={endpointText}
                                endpointImageUrl={endpointImageUrl}
                                currentState={this.state.state}
                                showAddButton={showAddButton}
                                moveOnDrag={this.moveOnDrag}
                                removeDialog={this.removeDialog}
                                selectCurrentDialog={this.selectCurrentDialog}
                                showStartpoint={showStartpoint}
                                firstRowOffset={firstRowOffset}
                            />
                        </svg>

                        {
                            /* JOURNEY VALIDATION UI */
                            this.state.showWarning ? (
                                <span className="journey-warning">
                                    {warningText}
                                </span>
                            ) : null
                        }
                        {
                            /* JOURNEY CONTINUE BUTTON */
                            this.continueButton(continueText, journeyList)
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    updateCurrentIndex = (index, emoji?: 'EMOJI' | 'BANK') => {
        this.setState({
            journeySettings: {
                ...this.state.journeySettings,
                emojiList: _.shuffle(this.state.journeySettings.emojiList)
            }
        });
        if (emoji) {
            this.setState({
                currentIndex: index,
                currentTypeAddEmoji: emoji
            });
        } else {
            this.setState({
                currentIndex: index
            });
        }
    };

    render() {
        const {
            emojiFollowupQuestionText_t,
            bankFollowupQuestionTexts,
            journeyFollowupContinueText,
            emojiList
        } = this.state.journeySettings;
        const journeyList: any[] = [
            ...this.state.journeyListBeforeMidpoint,
            ...this.state.journeyListAfterMidpoint
        ];
        return (
            <React.Fragment>
                {
                    /* JOURNEY PATH UI */
                    this.renderJourney()
                }

                {
                    /* JOURNEY FOLLOWUP QUESTION UI */
                    this.state.state === 'ADDEMOJIS' ? (
                        <JourneyFollowupQuestion
                            journeyList={journeyList}
                            bankOptions={this.state.journeySettings.bankList}
                            handleAnswer={() => {}}
                            smileyOptions={emojiList}
                            langId={this.props.langId}
                            changeCurrentState={this.changeCurrentState}
                            saveEverything={this.saveEverything}
                            saveBank={this.saveBank}
                            saveSmiley={this.saveSmiley}
                            currentIndex={this.state.currentIndex}
                            updateCurrentIndex={this.updateCurrentIndex}
                            setCurrentType={this.setCurrentTypeAddEmoji}
                            currentType={this.state.currentTypeAddEmoji}
                            emojiFollowupQuestionText_t={
                                emojiFollowupQuestionText_t
                            }
                            bankFollowupQuestionTexts={
                                bankFollowupQuestionTexts
                            }
                        />
                    ) : null
                }
                {
                    /* JOURNEY FOLLOWUP BUTTON */
                    this.state.state === 'FOLLOWUP' ? (
                        <JourneyFollowupContinue
                            goToPopup={this.goToPopup}
                            langId={this.props.langId}
                            text={journeyFollowupContinueText}
                        />
                    ) : null
                }
                {
                    /* NOT APPLICABLE BUTTON */
                    this.props.mode === 'SELECTSTEPS' &&
                    this.props.question.showNotApplicableOption ? (
                        <div className="bottom-select-links bot-width">
                            <NotApplicableButton
                                onClick={this.props.handleNotApplicableAnswer}
                                text={getStringFromMultimediaTexts(
                                    this.props.question.notApplicableTexts,
                                    'en'
                                )}
                            />
                        </div>
                    ) : null
                }
            </React.Fragment>
        );
    }
}

export const Journey = DragDropContext(MultiBackend(HTML5ToTouch))(JourneyRaw);
