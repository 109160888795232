import * as React from 'react';
import * as _ from 'lodash';
import { BaseInput, BaseProps } from './_BaseInput';
import { getUniqueAnnotations } from '@nexxt/common/utils/question';
import {
    getStringFromMultimediaTexts,
    getTranslation,
    getUseWordDelimiterByLanguage
} from '@nexxt/common/services/TextService';
import { OptionData, TextAnnotation } from '@nexxt/common/types';
import * as uuid from 'uuid';
import ContinueButton from '../components/ContinueButton';
import '../style/TextHighlighter.scss';
import BottomModal from '../components/BottomModal';
import TextHighlighterChunk from '../components/TextHighlighterChunk';
import { BUILTIN_QUESTION_ID, KEY_NAME } from '@nexxt/common/constants';
import { UilTimes } from '@iconscout/react-unicons';
import { lighten, readableColor } from 'polished';
const { translate } = require('react-i18next');
import { isMobile, isTablet } from 'react-device-detect';

interface Props extends BaseProps {
    handleAnswer: (selectedOptions: OptionData[]) => void;
    readonly: boolean;
}

interface State {
    error: string;
    isDragging: boolean;
    showTutorial: boolean;
    uniqueAnnotations: TextAnnotation[];
    openAnnotationModal: boolean;
    openWarningModal: boolean;
    options: OptionData[];
    currentAnnotation: TextAnnotation;
    numTrySubmit: number;
    useWordDelimiter: boolean;
}

const PARAGRAPH_DELIMITER = '\n';
const WORD_DELIMITER = ' ';
const QUOTE_DELIMITER = '"';
const WORD_NUM_BREAKPOINTS = [40, 100]; // breakpoints for sm and md word-chunk font-size

const SPECSAVER_CONFUSION_QIDS = [
    15895, 15904, 15905, 16290, 16298, 16299, 16384, 16392, 16393, 16478, 16486,
    16487, 16010, 16018, 16019
];
const SPECSAVER_INTEREST_QIDS = [
    15911, 15912, 15913, 16304, 16305, 16306, 16398, 16399, 16400, 16492, 16493,
    16494, 16024, 16025, 16026
];

@translate('translation')
export class TextHighlighter extends BaseInput<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            error: null,
            isDragging: false,
            showTutorial: true,
            uniqueAnnotations: [],
            openAnnotationModal: false,
            openWarningModal: false,
            options: [], //
            currentAnnotation: null,
            numTrySubmit: 0,
            useWordDelimiter: true
        };
    }

    async componentDidMount() {
        const highlightText = getStringFromMultimediaTexts(
            this.props.question.alternateWordingTexts,
            this.props.langId
        );
        const useWordDelimiter = getUseWordDelimiterByLanguage(
            this.props.question.alternateWordingTexts,
            this.props.langId
        );
        let wordCount = 0;

        // setup options data
        const annotationsByParagraph = highlightText
            ?.split(PARAGRAPH_DELIMITER)
            .filter((x) => x !== '')
            .map((paragraph, paragraphIndex) => {
                const wordChunks = paragraph
                    ?.split(WORD_DELIMITER)
                    .filter((x) => x !== '')
                    .map((chunk, chunkIndex, arr) => {
                        const index = wordCount;
                        wordCount++;
                        return {
                            id: BUILTIN_QUESTION_ID,
                            label:
                                chunkIndex !== arr.length - 1
                                    ? chunk
                                    : `${chunk}${PARAGRAPH_DELIMITER}`, // word text
                            value: String(index), //  wordIndex
                            annotations: []
                        };
                    });

                return wordChunks;
            });

        const options: OptionData[] = _.flatten(annotationsByParagraph);

        this.setState({ options, useWordDelimiter });
    }

    // helper function to swap start and end index values
    swapStartEnd = (annotation: TextAnnotation): TextAnnotation => {
        [annotation['startWordIndex'], annotation['endWordIndex']] = [
            annotation['endWordIndex'],
            annotation['startWordIndex']
        ];
        return annotation;
    };

    toggleAnnotationModal = (
        value: boolean,
        annotation?: TextAnnotation
    ): void => {
        const { options, currentAnnotation, uniqueAnnotations } = this.state;

        if (annotation && annotation.annotationId) {
            // update state with existing annotation
            this.setState({ currentAnnotation: annotation });
        } else {
            // add temp annotation when opening
            if (value) {
                const tempAnnotation = {
                    ...this.state.currentAnnotation,
                    annotationId: 'temp',
                    userRating:
                        this.props.question.buttons.length === 1
                            ? {
                                  value: this.props.question.buttons[0].value,
                                  label: this.props.question.buttons[0].label
                              }
                            : { value: '4', label: 'temp' }
                };

                const updatedAnnotations = options.map((option) => {
                    if (
                        parseInt(option.value) >=
                            currentAnnotation.startWordIndex &&
                        parseInt(option.value) <= currentAnnotation.endWordIndex
                    ) {
                        option.annotations.push(tempAnnotation);
                    }

                    return option;
                });

                const updatedUniqueAnnotations = [
                    ...uniqueAnnotations,
                    tempAnnotation
                ];

                this.setState({
                    currentAnnotation: tempAnnotation,
                    options: updatedAnnotations,
                    uniqueAnnotations: updatedUniqueAnnotations
                });
            } else {
                // remove temp option when closing
                if (currentAnnotation?.annotationId === 'temp') {
                    this.handleDeleteAnnotation(currentAnnotation);
                }
            }
        }
        if (window.getSelection) {
            window.getSelection().removeAllRanges();
        }
        this.setState({ openAnnotationModal: value });

        if (!value) {
            this.setState({ error: null, currentAnnotation: null });
        }
    };

    // helper function to check values in array with content in paragraph based on a single index
    // used to verify if start and end words in window.selection match the corresponding indexes in options
    // reverse prop to indicate whether selection is made right-to-left or left-to-right
    getWordsFromOptions = (
        index: number,
        length: number,
        reverse = false
    ): string[] => {
        // assign the index to the start if selection is left-to-right
        // or to end if selection is right-to-left
        const start = !reverse ? index : index - (length - 1);
        const end = !reverse ? index + length : index + 1;

        // return words in options based off given start and end index
        return this.state.options
            .map((options) => options.label.replace(PARAGRAPH_DELIMITER, ''))
            .slice(start, end);
    };

    // update current annotation values such as start and end index, comment, user rating, etc.
    updateCurrentAnnotation = (
        keyName: string,
        value: string | number | Partial<OptionData>
    ): void => {
        const { currentAnnotation } = this.state;

        // create annotation object to be mutated and used to update state
        const annotation = { ...currentAnnotation, [keyName]: value };

        const regex = RegExp(`(?:${PARAGRAPH_DELIMITER}|${WORD_DELIMITER})`);

        // array of selected text which includes whitespace and paragraphs
        const unfilteredText: string[] = window
            .getSelection()
            .toString()
            ?.split(regex) // split by both ' ' and '\n'
            .filter((i) => i !== PARAGRAPH_DELIMITER);

        // array of selected text only containing words
        const selectionArray: string[] = window
            .getSelection()
            .toString()
            ?.split(regex) // split by both ' ' and '\n'
            .filter((i) => i !== '');

        // trigger data validation when end word index is updated
        if (keyName === 'endWordIndex') {
            if (
                annotation.startWordIndex >= 0 &&
                annotation.endWordIndex > 0 &&
                annotation.startWordIndex !== annotation.endWordIndex
            ) {
                // ideal scenario when both the start and end index are valid
                if (annotation.startWordIndex > annotation.endWordIndex) {
                    // handle reverse selection scenarios
                    this.swapStartEnd(annotation);
                }

                if (
                    (unfilteredText[0] == '' ||
                        unfilteredText[0] ===
                            this.state.options[annotation.startWordIndex + 1]
                                .label) &&
                    !isMobile &&
                    !isTablet
                ) {
                    // handle scenarios where a word delimiter (SPACE) is selected as the start
                    annotation.startWordIndex = annotation.startWordIndex + 1;
                }

                if (
                    selectionArray.length > 0 &&
                    unfilteredText[unfilteredText.length - 1] === '' &&
                    this.state.options[annotation.endWordIndex].label !==
                        unfilteredText[unfilteredText.length - 2]
                ) {
                    // handle scenarios where a word delimiter (SPACE) is selected as the end
                    annotation.endWordIndex = annotation.endWordIndex - 1;
                }
            } else if (
                (!annotation.startWordIndex &&
                    annotation.startWordIndex !== 0) ||
                (!annotation.endWordIndex && annotation.endWordIndex !== 0)
            ) {
                // when either start or end word index is not recognized
                // occurs when word delimiter (SPACE) around the paragraphs are selected
                const matchingArray = [];

                if (annotation.startWordIndex) {
                    // when only a start index is identified

                    if (
                        selectionArray[0] ===
                        this.state.options[annotation.startWordIndex + 1].label
                    ) {
                        // handle scenarios where a word delimiter (SPACE) is selected as the start
                        annotation.startWordIndex =
                            annotation.startWordIndex + 1;
                    }

                    if (
                        selectionArray[selectionArray.length - 1] ===
                        this.state.options[annotation.startWordIndex - 1].label
                    ) {
                        annotation.startWordIndex =
                            annotation.startWordIndex - 1;
                    }

                    // verify to check if selection was made right-to-left or left-to-right
                    const rightToLeft: string[] = this.getWordsFromOptions(
                        annotation.startWordIndex,
                        selectionArray.length,
                        true
                    );

                    // remove last word in case of partial word or word delimiter (SPACE) selection
                    rightToLeft.pop();

                    // update start and end word indexes based on verified selection of text
                    if (
                        selectionArray.length > 1 &&
                        rightToLeft.toString() ===
                            selectionArray
                                .filter(
                                    (word, i) => i !== selectionArray.length - 1
                                )
                                .toString()
                    ) {
                        // if selection was made right-to-left
                        annotation.endWordIndex =
                            annotation.startWordIndex -
                            (selectionArray.length - 1);
                    } else {
                        // if selection was made left-to-right
                        annotation.endWordIndex =
                            annotation.startWordIndex +
                            (selectionArray.length - 1);
                    }
                } else if (annotation.endWordIndex) {
                    // when only an end index is identified

                    if (
                        selectionArray[0] ===
                        this.state.options[annotation.endWordIndex + 1].label
                    ) {
                        // handle cases where start index occurs on a word delimiter (SPACE)
                        annotation.endWordIndex = annotation.endWordIndex + 1;
                    }

                    if (
                        selectionArray[selectionArray.length - 1] ===
                        this.state.options[annotation.endWordIndex - 1].label
                    ) {
                        annotation.endWordIndex = annotation.endWordIndex - 1;
                    }

                    // verify to check if selection was made right-to-left or left-to-right
                    const rightToLeft: string[] = this.getWordsFromOptions(
                        annotation.endWordIndex,
                        selectionArray.length
                    );

                    // remove first word in case of partial word or word delimiter (SPACE) selection
                    rightToLeft.shift();

                    // update start and end word indexes based on verified selection of text
                    if (
                        selectionArray.length > 1 &&
                        rightToLeft.toString() ===
                            selectionArray
                                .filter((word, i) => i !== 0)
                                .toString()
                    ) {
                        // if selection was made right-to-left
                        annotation.startWordIndex =
                            annotation.endWordIndex +
                            (selectionArray.length - 1);
                    } else {
                        // if selection was made left-to-right
                        annotation.startWordIndex =
                            annotation.endWordIndex -
                            (selectionArray.length - 1);
                    }
                } else {
                    // when neither start or end word index is recognized
                    // occurs when whole paragraphs are selected without triggering an event on a word
                    for (
                        let i = 0;
                        i <=
                        this.state.options.length - (selectionArray.length - 1);
                        i++
                    ) {
                        if (
                            this.getWordsFromOptions(
                                i,
                                selectionArray.length
                            ).toString() === selectionArray.toString()
                        ) {
                            matchingArray.push([
                                i,
                                i + selectionArray.length - 1
                            ]);
                        }
                    }

                    annotation.startWordIndex = matchingArray[0][0];
                    annotation.endWordIndex = matchingArray[0][1];
                }
            }
        }

        // handle reverse highlights
        if (annotation.startWordIndex > annotation.endWordIndex) {
            this.swapStartEnd(annotation);
        }

        // update selectedText
        annotation.selectedText = this.state.options
            .filter(
                (opt, idx) =>
                    idx >= annotation.startWordIndex &&
                    idx <= annotation.endWordIndex
            )
            .map((opt) => opt.label)
            .join(WORD_DELIMITER);

        // set currentAnnotation based off keyName
        this.setState(
            {
                currentAnnotation: annotation
            },
            () => {
                if (
                    keyName === 'endWordIndex' &&
                    annotation.startWordIndex >= 0
                ) {
                    this.toggleAnnotationModal(true);
                }
            }
        );
    };
    // validate data from new annotation
    validateNewAnnotation = (currentAnnotation: TextAnnotation): string => {
        if (
            !currentAnnotation.userRating ||
            currentAnnotation.userRating.value === '4'
        )
            return this.props.t('LABEL.VALIDATE.SELECT');
        // return 'Please select a rating';
        if (
            !currentAnnotation.userComment ||
            currentAnnotation.userComment.trim() === ''
        )
            return this.props.t('LABEL.INSTRUCTIONS.ANNOTATION.COMMENT');
        // return 'Please enter a comment';
    };
    // when a respondent saves each comments/annotation
    handleAnnotation = (
        e:
            | React.KeyboardEvent<HTMLTextAreaElement>
            | React.FormEvent<HTMLFormElement>
            | React.KeyboardEvent<HTMLButtonElement>
    ): void => {
        e.preventDefault();
        const { currentAnnotation, options } = this.state;
        const error = this.validateNewAnnotation(currentAnnotation);

        if (error) {
            return this.setState({ error });
        }

        const annotationItem = { ...currentAnnotation, annotationId: uuid() };

        // add annotationItem to applicable annotations
        const updatedOptions = options.map((option) => {
            if (
                parseInt(option.value) >= currentAnnotation.startWordIndex &&
                parseInt(option.value) <= currentAnnotation.endWordIndex
            ) {
                const foundIndex = option.annotations.findIndex(
                    (annotation) =>
                        annotation.annotationId ===
                        currentAnnotation.annotationId
                );

                if (currentAnnotation.annotationId === 'temp') {
                    // replace temp annotation with annotationItem
                    option.annotations?.splice(foundIndex, 1, annotationItem);
                } else {
                    // update existing annotationItem
                    option.annotations?.splice(
                        foundIndex,
                        1,
                        currentAnnotation
                    );
                }
            }

            return option;
        });

        const uniqueAnnotations = getUniqueAnnotations(updatedOptions);

        // Clear currentAnnotation and update annotations
        this.setState({
            error: null,
            openAnnotationModal: false,
            uniqueAnnotations: uniqueAnnotations,
            currentAnnotation: null,
            options: updatedOptions
        });
    };

    handleDeleteAnnotation = (deletingAnnotation: TextAnnotation): void => {
        const { options, uniqueAnnotations } = this.state;
        const updatedOptions = options.map((option) => {
            if (
                parseInt(option.value) >= deletingAnnotation.startWordIndex &&
                parseInt(option.value) <= deletingAnnotation.endWordIndex
            ) {
                const foundIndex = option.annotations.findIndex(
                    (annotation) =>
                        annotation.annotationId ===
                        deletingAnnotation.annotationId
                );
                option.annotations?.splice(foundIndex, 1);
            }

            return option;
        });
        const updatedUniqueAnnotations = uniqueAnnotations.filter(
            (annotation) =>
                annotation.annotationId !== deletingAnnotation.annotationId
        );

        this.setState({
            options: updatedOptions,
            uniqueAnnotations: updatedUniqueAnnotations,
            openWarningModal: false
        });
    };

    handleSubmission = () => {
        const numOptionsSelected = getUniqueAnnotations(
            this.state.options
        ).length;
        if (numOptionsSelected < 2 && this.state.numTrySubmit === 0) {
            if (
                SPECSAVER_CONFUSION_QIDS.includes(
                    this.props.question.id as number
                )
            ) {
                alert(this.props.t('CUSTOM.SPECSAVERS.TH_CONFUSION_ALERT'));
            }
            if (
                SPECSAVER_INTEREST_QIDS.includes(
                    this.props.question.id as number
                )
            ) {
                alert(this.props.t('CUSTOM.SPECSAVERS.TH_LIKE_ALERT'));
            }
            this.setState({ numTrySubmit: this.state.numTrySubmit + 1 });
            return;
        }
        this.props.handleAnswer(
            this.state.options.filter((option) => option.annotations.length > 0)
        );
    };

    // functions that run on mouseUp and touchUp events
    handleUpInteraction = (
        e: Event | TouchEvent,
        appliedAnnotation: TextAnnotation,
        x: number,
        y: number
    ): void => {
        const chunkIndex = parseInt(
            document.elementFromPoint(x, y).getAttribute('data-index')
        );

        e.stopPropagation();
        if (
            appliedAnnotation &&
            chunkIndex === this.state.currentAnnotation.startWordIndex &&
            window.getSelection().toString() === ''
        ) {
            this.toggleAnnotationModal(true, appliedAnnotation);
        } else {
            if (this.state.isDragging) {
                this.updateCurrentAnnotation('endWordIndex', chunkIndex);
            }
        }
        if (this.state.isDragging) {
            this.setState({ isDragging: false });
        }
    };

    closeWarningModal = () => {
        this.setState({ currentAnnotation: null, openWarningModal: false });
    };

    // function that stops event bubbling and triggers deletion of annotation
    handleDeleteInteraction = (
        e:
            | React.MouseEvent<HTMLSpanElement, MouseEvent>
            | React.TouchEvent<HTMLSpanElement>,
        appliedAnnotation: TextAnnotation
    ): void => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            openWarningModal: true,
            currentAnnotation: appliedAnnotation
        });
    };

    // renders the body of the text
    // handles whether text is highlighted or selectable
    renderHighlightBody = (): JSX.Element[] => {
        const { question } = this.props;
        const { options, uniqueAnnotations, isDragging, useWordDelimiter } =
            this.state;
        return options.map((chunk, chunkIndex, arr) => {
            let appliedAnnotation,
                ratingStyleName,
                ratingStyle = {};

            const applicableAnnotations = uniqueAnnotations.filter(
                (annotation) =>
                    chunkIndex >= annotation.startWordIndex &&
                    chunkIndex <= annotation.endWordIndex
            );

            if (applicableAnnotations.length > 0) {
                appliedAnnotation = applicableAnnotations.reduce(
                    (acc, annotation) =>
                        annotation.startWordIndex > acc.startWordIndex
                            ? annotation
                            : annotation.startWordIndex ===
                                  acc.startWordIndex &&
                              annotation.endWordIndex < acc.endWordIndex
                            ? annotation
                            : acc
                );
                const button = question.buttons?.find(
                    (b) => b.value === appliedAnnotation.userRating?.value
                );
                if (button?.color) {
                    ratingStyle = {
                        backgroundColor: button.color,
                        color: readableColor(button.color)
                    };
                } else {
                    switch (appliedAnnotation.userRating?.value) {
                        case '1':
                            ratingStyleName = 'like';
                            break;
                        case '2':
                            ratingStyleName = 'confused';
                            break;
                        case '3':
                            ratingStyleName = 'dislike';
                            break;
                        case '4':
                            ratingStyleName = 'temp';
                        default:
                            break;
                    }
                }
            }

            const chunkClass = 'text-highlighter--word-chunk';
            const ratingStyleClass = ratingStyleName
                ? ` --${ratingStyleName}`
                : '';
            const draggingStyleClass = isDragging ? ' --dragging' : '';
            const wordChunkClassName = `${chunkClass}${ratingStyleClass}${draggingStyleClass}`;
            // show rating highlight in word delimiter (SPACE) chunk if not at end of annotation, or if there is another annotation immediately adjacent
            const isLastChunkInAnnotation =
                chunkIndex == parseInt(appliedAnnotation?.endWordIndex);
            const nextChunkHasAnnotation =
                options[chunkIndex + 1]?.annotations.length > 0;
            const showRatingStyleInWordDelimiter =
                !isLastChunkInAnnotation || nextChunkHasAnnotation;
            const wordDelimiterChunkClassName = `${chunkClass}${
                showRatingStyleInWordDelimiter ? ratingStyleClass : ''
            }${draggingStyleClass}`;
            const showParagraphBreak =
                chunk.label.includes(PARAGRAPH_DELIMITER) &&
                chunkIndex !== arr.length - 1;
            const showWordDelimiter = !showParagraphBreak && useWordDelimiter;

            return (
                <React.Fragment key={`chunk-${chunkIndex}`}>
                    <span className="text-highlighter--word-chunk--wrapper">
                        {
                            // show annotation highlight UI
                            chunkIndex ===
                                parseInt(appliedAnnotation?.startWordIndex) &&
                                ratingStyleName !== 'temp' && (
                                    <span
                                        className="text-highlighter--word-chunk--remove"
                                        onTouchEnd={(e) => {
                                            this.handleDeleteInteraction(
                                                e,
                                                appliedAnnotation
                                            );
                                        }}
                                        onClick={(e) => {
                                            this.handleDeleteInteraction(
                                                e,
                                                appliedAnnotation
                                            );
                                        }}
                                    >
                                        <UilTimes size={12} />
                                    </span>
                                )
                        }
                        {/* show chunk for word */}
                        <TextHighlighterChunk
                            text={chunk.label.replace(PARAGRAPH_DELIMITER, '')}
                            chunkIndex={chunkIndex}
                            handleUpInteraction={
                                this.props.readonly
                                    ? () => {}
                                    : this.handleUpInteraction
                            }
                            appliedAnnotation={appliedAnnotation}
                            updateCurrentAnnotation={
                                this.updateCurrentAnnotation
                            }
                            classNameString={wordChunkClassName}
                            data-index={chunkIndex}
                            style={ratingStyle}
                        />
                    </span>
                    {
                        // show paragraph break
                        showParagraphBreak && (
                            <span>
                                <br />
                                <br />
                            </span>
                        )
                    }
                    {
                        // show word delimiter (SPACE) chunk
                        showWordDelimiter && (
                            <span className="text-highlighter--word-chunk--wrapper">
                                <TextHighlighterChunk
                                    text={WORD_DELIMITER}
                                    chunkIndex={chunkIndex}
                                    handleUpInteraction={
                                        this.props.readonly
                                            ? () => {}
                                            : this.handleUpInteraction
                                    }
                                    appliedAnnotation={appliedAnnotation}
                                    updateCurrentAnnotation={
                                        this.updateCurrentAnnotation
                                    }
                                    classNameString={
                                        wordDelimiterChunkClassName
                                    }
                                    data-index={chunkIndex}
                                    style={ratingStyle}
                                />
                            </span>
                        )
                    }
                </React.Fragment>
            );
        });
    };

    // helper function that formats highlighted text shown on BottomModal
    getTruncText = (text: string, numWords: number = 10): string => {
        const { useWordDelimiter } = this.state;
        const ellipses = useWordDelimiter ? '...' : ' ... '; // only add spaces if other text not using spaces!
        const textArray = text?.split(WORD_DELIMITER);
        if (textArray.length > numWords)
            textArray.splice(
                numWords / 2,
                textArray.length - numWords,
                ellipses
            );
        return textArray?.join(useWordDelimiter ? WORD_DELIMITER : '');
    };

    validatorText = (max: number, min: number) => {
        const { t } = this.props;
        const selected = Object.keys(this.state.uniqueAnnotations).length;
        if (selected === 0) {
            return (
                <div className="multi-choice-help">
                    <p>{getTranslation(t, 'LABEL.VALIDATE.SELECT')}</p>
                </div>
            );
        } else if (selected > max) {
            return (
                <div className="multi-choice-help warning">
                    <p>
                        {getTranslation(t, 'LABEL.VALIDATE.SELECT_MIN_MAX', {
                            min,
                            max
                        })}
                    </p>
                </div>
            );
        } else if (selected < min) {
            return (
                <div className="multi-choice-help">
                    <p>
                        {getTranslation(t, 'LABEL.VALIDATE.MORE', {
                            more: min - selected
                        })}
                    </p>
                </div>
            );
        } else if (selected === max) {
            return (
                <div className="multi-choice-help">
                    <p>{getTranslation(t, 'LABEL.VALIDATE.SATISFIED')}</p>
                </div>
            );
        } else {
            return (
                <div className="multi-choice-help">
                    <p>
                        <span className="multi-choice-help--header">
                            {getTranslation(
                                t,
                                'LABEL.VALIDATE.CONTINUE_OR_MORE_HEADER_TEXT'
                            )}
                        </span>
                        {getTranslation(
                            t,
                            'LABEL.VALIDATE.CONTINUE_OR_MORE_SPECIALTY'
                        )}
                    </p>
                </div>
            );
        }
    };

    render() {
        const {
            openAnnotationModal,
            openWarningModal,
            uniqueAnnotations,
            currentAnnotation,
            useWordDelimiter,
            error
        } = this.state;
        const { langId, question, t } = this.props;
        const { continueTexts, buttons, min, max } = question;
        const continueText = getStringFromMultimediaTexts(
            continueTexts,
            langId
        );

        const annotationsCounter: number = uniqueAnnotations.filter(
            (annotation) => annotation.annotationId !== 'temp'
        ).length;

        // @PROJECT specsavers: use custom instructions
        const addCommentInstructions = SPECSAVER_CONFUSION_QIDS.includes(
            this.props.question.id as number
        )
            ? t('CUSTOM.SPECSAVERS.TH_CONFUSION_INSTRUCTIONS')
            : SPECSAVER_INTEREST_QIDS.includes(this.props.question.id as number)
            ? t('CUSTOM.SPECSAVERS.TH_LIKE_INSTRUCTIONS')
            : t(
                  buttons.length === 1
                      ? 'LABEL.INSTRUCTIONS.TEXT_HIGHLIGHTER_COMMENT_ONLY'
                      : 'LABEL.INSTRUCTIONS.TEXT_HIGHLIGHTER'
              ).toUpperCase();

        return (
            <>
                {openWarningModal && (
                    <BottomModal onClose={this.closeWarningModal}>
                        <div className="text-highlighter--modal">
                            <div className="text-highlighter--modal--header">
                                {t('PLACEHOLDER.CONFIRM')}
                                <p>
                                    <span className="text-highlighter--modal--header--selected-text">
                                        {t(
                                            'LABEL.INSTRUCTIONS.TEXT_HIGHLIGHTER_CONFIRM'
                                        )}
                                    </span>
                                </p>
                            </div>
                            <div className="text-highlighter--modal--actions">
                                <button
                                    tabIndex={1}
                                    className="text-highlighter--modal--actions--button --cancel"
                                    onKeyPress={(e) => {
                                        if (
                                            e.key === KEY_NAME.ENTER ||
                                            KEY_NAME.SPACE
                                        ) {
                                            this.closeWarningModal();
                                        }
                                    }}
                                    onClick={this.closeWarningModal}
                                >
                                    {t('BUTTON.CANCEL')}
                                </button>
                                <button
                                    tabIndex={1}
                                    className="text-highlighter--modal--actions--button --remove"
                                    onKeyPress={(e) => {
                                        if (
                                            e.key === KEY_NAME.ENTER ||
                                            KEY_NAME.SPACE
                                        ) {
                                            this.handleDeleteAnnotation(
                                                currentAnnotation
                                            );
                                            this.closeWarningModal();
                                        }
                                    }}
                                    onClick={() => {
                                        this.handleDeleteAnnotation(
                                            currentAnnotation
                                        );
                                        this.closeWarningModal();
                                    }}
                                >
                                    {t('BUTTON.REMOVE')}
                                </button>
                            </div>
                        </div>
                    </BottomModal>
                )}
                {openAnnotationModal && (
                    <BottomModal
                        onClose={() => this.toggleAnnotationModal(false)}
                    >
                        {annotationsCounter === max &&
                        max !== 0 &&
                        currentAnnotation?.annotationId === 'temp' ? (
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    this.toggleAnnotationModal(false);
                                }}
                            >
                                <div className="text-highlighter--modal">
                                    <div className="text-highlighter--modal--header">
                                        {t('LABEL.VALIDATE.SELECT_MIN_MAX', {
                                            min,
                                            max
                                        }).toUpperCase()}
                                        <p>
                                            <span className="text-highlighter--modal--header--selected-text">
                                                {t(
                                                    'LABEL.INSTRUCTIONS.REMOVE_HIGHLIGHTS'
                                                )}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="text-highlighter--modal--actions">
                                        <button
                                            type="submit"
                                            className="text-highlighter--modal--actions--button --cancel"
                                        >
                                            {t('BUTTON.BACK')}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        ) : (
                            <form onSubmit={(e) => this.handleAnnotation(e)}>
                                <div className="text-highlighter--modal">
                                    <div className="text-highlighter--modal--header">
                                        {t(
                                            'LABEL.INSTRUCTIONS.TEXT_HIGHLIGHTER_SELECTED'
                                        ).toUpperCase()}
                                        <p>
                                            <span className="text-highlighter--modal--header--selected-text">
                                                {QUOTE_DELIMITER}
                                                {this.getTruncText(
                                                    currentAnnotation?.selectedText
                                                )}
                                                {QUOTE_DELIMITER}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="text-highlighter--modal--header">
                                        {addCommentInstructions}
                                        {error && (
                                            <p className="text-highlighter--modal--header--error">
                                                {error}
                                            </p>
                                        )}
                                    </div>
                                    {buttons.length > 1 && (
                                        <div className="text-highlighter--modal--buttons">
                                            {buttons.map((button, index) => {
                                                const text =
                                                    getStringFromMultimediaTexts(
                                                        button.texts,
                                                        langId
                                                    );
                                                const hasColorInButton =
                                                    !!button?.color;
                                                const selected =
                                                    currentAnnotation.userRating
                                                        ?.value ===
                                                    button.value.toString()
                                                        ? '--selected'
                                                        : '';

                                                return (
                                                    <button
                                                        tabIndex={1}
                                                        className={`text-highlighter--modal--buttons--item ${
                                                            selected &&
                                                            !hasColorInButton
                                                                ? `--val${button.value}`
                                                                : ''
                                                        }`}
                                                        style={
                                                            selected &&
                                                            hasColorInButton
                                                                ? {
                                                                      backgroundColor:
                                                                          lighten(
                                                                              0.3,
                                                                              button.color
                                                                          ),
                                                                      border: `2px solid ${button.color}`
                                                                  }
                                                                : {}
                                                        }
                                                        key={`button-${index}`}
                                                        onKeyPress={(e) => {
                                                            if (
                                                                e.key ===
                                                                    KEY_NAME.ENTER ||
                                                                KEY_NAME.SPACE
                                                            ) {
                                                                this.updateCurrentAnnotation(
                                                                    'userRating',
                                                                    button
                                                                );
                                                            }
                                                        }}
                                                        onClick={() =>
                                                            this.updateCurrentAnnotation(
                                                                'userRating',
                                                                button
                                                            )
                                                        }
                                                        type="button"
                                                    >
                                                        <div
                                                            className={`text-highlighter--modal--buttons--item--radio ${
                                                                hasColorInButton
                                                                    ? ''
                                                                    : `--val${button.value}`
                                                            }`}
                                                            style={
                                                                hasColorInButton
                                                                    ? {
                                                                          color: button.color
                                                                      }
                                                                    : {}
                                                            }
                                                        ></div>
                                                        {text}
                                                    </button>
                                                );
                                            })}
                                        </div>
                                    )}
                                    <textarea
                                        tabIndex={1}
                                        className="text-highlighter--modal--comment"
                                        placeholder={t(
                                            'LABEL.INSTRUCTIONS.ANNOTATION.COMMENT'
                                        )}
                                        value={currentAnnotation.userComment}
                                        onKeyPress={(e) => {
                                            if (
                                                e.key === KEY_NAME.ENTER &&
                                                !e.shiftKey
                                            ) {
                                                this.handleAnnotation(e);
                                            }
                                        }}
                                        onChange={(e) =>
                                            this.updateCurrentAnnotation(
                                                'userComment',
                                                e.target.value
                                            )
                                        }
                                        onPaste={(event) => {
                                            event.preventDefault();
                                        }}
                                    />

                                    <div className="text-highlighter--modal--actions">
                                        <button
                                            tabIndex={1}
                                            className="text-highlighter--modal--actions--button --cancel"
                                            onKeyPress={(e) => {
                                                if (
                                                    e.key === KEY_NAME.ENTER ||
                                                    KEY_NAME.SPACE
                                                ) {
                                                    this.toggleAnnotationModal(
                                                        false
                                                    );
                                                }
                                            }}
                                            onClick={() =>
                                                this.toggleAnnotationModal(
                                                    false
                                                )
                                            }
                                        >
                                            {t('BUTTON.CANCEL')}
                                        </button>
                                        <button
                                            tabIndex={1}
                                            className="text-highlighter--modal--actions--button --submit"
                                            type="submit"
                                            onKeyPress={(e) => {
                                                if (
                                                    e.key === KEY_NAME.ENTER ||
                                                    KEY_NAME.SPACE
                                                ) {
                                                    this.handleAnnotation(e);
                                                }
                                            }}
                                        >
                                            {currentAnnotation.annotationId !==
                                            'temp'
                                                ? t('BUTTON.UPDATE')
                                                : t('BUTTON.ADD')}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </BottomModal>
                )}
                {this.state.showTutorial && isMobile && !this.props.readonly ? (
                    <div className="text-highlighter--instructions">
                        <div className="bottom-select-links bot-width">
                            <img
                                className="text-highlighter--instructions--gif"
                                src={this.props.t(
                                    'URL.TEXT_HIGHLIGHTER_INSTRUCTION'
                                )}
                            />
                            <ContinueButton
                                onClick={() =>
                                    this.setState({ showTutorial: false })
                                }
                                text={continueText}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="text-highlighter--container" dir="auto">
                        <div
                            className="text-highlighter--title"
                            onMouseDown={(e) => {
                                this.updateCurrentAnnotation(
                                    'startWordIndex',
                                    0
                                );
                            }}
                        >
                            {!this.props.readonly
                                ? t('LABEL.INSTRUCTIONS.HIGHLIGHTER')
                                : ''}
                        </div>
                        <div
                            className={`text-highlighter--body ${
                                this.state.options.length >
                                WORD_NUM_BREAKPOINTS[1]
                                    ? '--sm'
                                    : this.state.options.length >
                                      WORD_NUM_BREAKPOINTS[0]
                                    ? '--md'
                                    : '--lg'
                            }`}
                            onMouseUp={(e) => {
                                if (!this.props.readonly) {
                                    if (
                                        window.getSelection().toString() !==
                                            '' &&
                                        !this.state.currentAnnotation
                                            ?.endWordIndex
                                    ) {
                                        this.updateCurrentAnnotation(
                                            'endWordIndex',
                                            null
                                        );
                                    }
                                    if (this.state.isDragging) {
                                        this.setState({ isDragging: false });
                                    }
                                }
                            }}
                            onMouseMove={() => {
                                if (!this.props.readonly) {
                                    if (this.state.currentAnnotation) {
                                        this.setState({ isDragging: true });
                                    }
                                }
                            }}
                            onTouchMove={() => {
                                if (!this.props.readonly) {
                                    if (this.state.currentAnnotation) {
                                        this.setState({ isDragging: true });
                                    }
                                }
                            }}
                        >
                            <p className="text-highlighter--paragraph">
                                {this.renderHighlightBody()}
                            </p>
                        </div>
                    </div>
                )}

                {!(this.state.showTutorial && isMobile) && (
                    <div className="bottom-select-links bot-width">
                        {this.validatorText(max, min)}
                        <ContinueButton
                            onClick={this.handleSubmission}
                            disabled={annotationsCounter < min}
                            text={continueText}
                        />
                    </div>
                )}
            </>
        );
    }
}
