import * as React from 'react';
import * as PropTypes from 'prop-types';
import '../../style/PopupCard.scss';
import styled, { keyframes } from 'styled-components';
import { slideInLeft } from 'react-animations';
import * as _ from 'lodash';
import {
    IJourneBuilderText,
    IJourneySmiley,
    IJourneyBank,
    IPopupOptions
} from './types';
import { INITIAL_JOURNEY_QUESTION } from './constant';

const slideInAnimation = keyframes`${slideInLeft}`;

const SlideInDiv = styled.div`
    animation: 1s ${slideInAnimation};
`;

interface Props {
    journeyList: IJourneBuilderText[];
    changeCurrentState: (value: string) => any;
    langId: string;
    currentIndex: number;
    currentType: 'BANK' | 'EMOJI' | 'TEXT';
    smileyOptions: IJourneySmiley[];
    bankOptions: IJourneyBank[];
    handleAnswer: (value: string) => void;
    saveEverything: (
        index: number,
        bank: IJourneyBank[],
        emoji: IJourneySmiley,
        textResponse: string,
        isLastOption: boolean
    ) => void;
    saveBank: (index: number, bank: IJourneyBank[]) => void;
    saveSmiley: (index: number, emoji: IJourneySmiley) => void;
    updateCurrentIndex: (
        index: number,
        emoji: 'BANK' | 'EMOJI' | 'TEXT'
    ) => void;
    setCurrentType: (value: 'BANK' | 'EMOJI' | 'TEXT') => void;
    emojiFollowupQuestionText_t: string; // used with _.template
    bankFollowupQuestionTexts: any[]; // @TODO: typing
}

interface State {
    selectedValues: IJourneyBank[];
    selectedEmoji: IJourneySmiley;
    textResponse: string;
}

interface Card {
    questionValue: string;
}

interface Stack extends Object {
    on: (eventName: string, fn: unknown) => void;
    createCard: (el: HTMLElement, prepend?: boolean) => Card;
}

const eventAltTenseTexts = {
    // 'talked to family/friends': '',
    // 'researched online': '',
    // 'called a bank': '',
    // 'visited a branch with appointment': '',
    // 'visited a branch without appointment': '',
    // 'consulted a professional': '',
    // 'searched for product information': '',
    'see an advertisement online': 'saw an ad online',
    'see/hear an ad on TV/radio/newspaper':
        'saw/heard an ad on TV/radio/newspaper',
    'see/hear an advertisement on billboard': 'saw/heard an ad on billboard',
    'hear about newcomer package': 'heard about newcomer package',
    'be referred to a bank representative':
        'were referred to a bank representative',
    'hear about from a professional': 'heard about from a professional',
    'be taken by friend/family/landlord': 'were taken by friend/family/landlord'
};

export class JourneyFollowupQuestion extends React.Component<Props, State> {
    constructor(props, context) {
        super(props, context);
        this.state = {
            selectedValues: [],
            selectedEmoji: null,
            textResponse: ''
        };
    }

    handleNextQuestion = async () => {
        // if (!this.state.selectedValues.length) {
        //     return; // solution for now. Need to implement debounce to select again or disable
        // }

        // unselect choice

        // SWAP LINES BELOW TO DISABLE EMOJI SELECTION!
        // this.props.setCurrentType('EMOJI');
        // if (this.props.currentType === 'EMOJI')
        //     this.props.setCurrentType('TEXT');
        // this.handleNextSmiley();

        // this is used since there is only 1 question per step right now..
        this.handleNextStep();
    };

    handleNextStep = async () => {
        // if (!this.state.selectedEmoji) {
        //     return;
        // }
        this.props.saveEverything(
            this.props.currentIndex,
            this.state.selectedValues,
            this.state.selectedEmoji,
            this.state.textResponse,
            this.props.currentIndex === this.props.journeyList.length - 1
        );
        if (this.props.currentIndex < this.props.journeyList.length - 1) {
            this.setState(
                {
                    selectedValues: [],
                    selectedEmoji: null,
                    textResponse: ''
                    // currentIndex: this.props.currentIndex + 1
                },
                () =>
                    this.props.updateCurrentIndex(
                        this.props.currentIndex + 1,
                        INITIAL_JOURNEY_QUESTION
                    )
            );
        } else {
            this.setState(
                {
                    selectedValues: [],
                    selectedEmoji: null,
                    textResponse: ''
                },
                () => this.props.updateCurrentIndex(0, INITIAL_JOURNEY_QUESTION)
            );
        }
    };

    getCurrentStep = () => {
        return this.props.journeyList[this.props.currentIndex];
    };

    getBankIcons = (bankOptions: IJourneyBank[]) => {
        if (!bankOptions) {
            return null;
        }
        return bankOptions.map((options, index) => {
            return (
                <span key={'bankicons-' + index} className="bank-icon">
                    <img src={options.imgUrl} />
                </span>
            );
        });
    };

    getEmojiIcons = (emojiOption: IJourneyBank) => {
        return (
            <span
                key={'emojiicon-' + emojiOption.id || '1'}
                className="emoji-icon"
            >
                <img src={emojiOption.imgUrl} />
            </span>
        );
    };

    getBankOptions = () => {
        const selectedValues: any[] = this.state.selectedValues.map(
            (s) => s.value
        );
        return this.props.bankOptions.map((option, index) => {
            let isSelected = false;
            if (selectedValues.includes(option.value)) {
                isSelected = true;
            }
            return (
                <PopupButtons
                    key={'bankoptions-' + index}
                    buttonOptions={option}
                    langId={this.props.langId}
                    onClick={this.selectBank}
                    value={selectedValues}
                    isSelected={isSelected}
                />
            );
        });
    };

    getEmojiOptions = () => {
        const selectedValue: any = this.state.selectedEmoji;
        return this.props.smileyOptions.map((option, index) => {
            let isSelected = false;
            if (selectedValue && selectedValue.value === option.value) {
                isSelected = true;
            }
            return (
                <PopupButtons
                    key={'emoji-' + index}
                    buttonOptions={option}
                    langId={this.props.langId}
                    onClick={this.selectEmoji}
                    value={selectedValue}
                    isSelected={isSelected}
                />
            );
        });
    };

    getTextPrompt = () => {
        return (
            <textarea
                style={{
                    width: '600px',
                    height: '120px',
                    padding: '10px',
                    border: '1px solid rgba(0,0,0,0.3) !important'
                }}
                placeholder="Start typing..."
                value={this.state.textResponse}
                onChange={(e) =>
                    this.setState({ textResponse: e.target.value })
                }
            />
        );
    };

    selectBank = (option: IJourneyBank) => {
        const selectedValues: any[] = this.state.selectedValues.map(
            (s) => s.value
        );
        const indexOf = selectedValues.indexOf(option.value);
        if (indexOf > -1) {
            const s = [...this.state.selectedValues];
            s.splice(indexOf, 1);
            this.setState(
                {
                    selectedValues: [...s]
                },
                () =>
                    this.props.saveBank(
                        this.props.currentIndex,
                        this.state.selectedValues
                    )
            );
        } else {
            this.setState(
                {
                    selectedValues: [
                        ...this.state.selectedValues,
                        { ...option }
                    ]
                },
                () =>
                    this.props.saveBank(
                        this.props.currentIndex,
                        this.state.selectedValues
                    )
            );
        }
    };

    selectEmoji = (option: IJourneyBank) => {
        this.setState(
            {
                selectedEmoji: { ...option }
            },
            () =>
                this.props.saveSmiley(
                    this.props.currentIndex,
                    this.state.selectedEmoji
                )
        );
    };

    render() {
        const currentStep: IJourneBuilderText = this.getCurrentStep();
        const continueText = this.props.langId == 'en' ? 'Continue' : '继续';
        // const stepTextPastTense =
        //     currentStep.text in eventAltTenseTexts
        //         ? eventAltTenseTexts[currentStep.text]
        //         : currentStep.text;
        let bottomText = '';
        let dynamicBottomText = null;
        // this.props.langId == 'en'
        //     ? `What banks were involved when you ${stepTextPastTense}?`
        //     : `当您${currentStep.text}时，涉及了哪些银行？请选择所有适用的选项。`;
        if (this.props.currentType === 'EMOJI') {
            bottomText = _.template(this.props.emojiFollowupQuestionText_t)({
                stepText: currentStep.text
            });
            // bottomText =
            //     this.props.langId == 'en'
            //         ? `How did you feel when you ${stepTextPastTense}? Please pick ONE emoji that best describes how you felt.`
            //         : `当您${stepTextPastTense}，您的感觉如何？请选择最能代表您感受的表情符号`;
        }
        if (this.props.currentType === 'BANK') {
            dynamicBottomText = this.props.bankFollowupQuestionTexts.map(
                (textObj, i) => (
                    <span key={i} style={textObj.style}>
                        {textObj.dynamicText ? currentStep?.text : textObj.text}
                    </span>
                )
            );
        }
        if (this.props.currentType === 'TEXT')
            bottomText =
                'And what happened at that step? What did you do and what were you asked to do?';

        return (
            <React.Fragment>
                <div className="content from-bot bot-width">
                    <div
                        className="plain-text popup-wrapper"
                        style={{ textAlign: 'center' }}
                    >
                        <div className="popup-container-wrapper popup-journey">
                            <SlideInDiv
                                key={'bottomText-' + this.props.currentIndex}
                                className="buttons-container"
                            >
                                <div className="question">
                                    {bottomText}
                                    {dynamicBottomText}
                                </div>
                                <div
                                    className="buttons-container-flex"
                                    style={{
                                        maxWidth: '100%',
                                        paddingTop: '5px'
                                    }}
                                >
                                    {this.props.currentType === 'BANK'
                                        ? this.getBankOptions()
                                        : this.props.currentType === 'EMOJI'
                                        ? this.getEmojiOptions()
                                        : this.props.currentType === 'TEXT'
                                        ? this.getTextPrompt()
                                        : null}
                                </div>
                                <div className="footer">
                                    {this.props.currentType === 'BANK' ? (
                                        <button
                                            className={`journeyq-button button-hg button-continue ${
                                                !this.state.selectedValues
                                                    .length
                                                    ? 'disabled'
                                                    : ''
                                            }`}
                                            disabled={
                                                !this.state.selectedValues
                                                    .length
                                            }
                                            onClick={this.handleNextQuestion}
                                        >
                                            {continueText}
                                        </button>
                                    ) : this.props.currentType === 'EMOJI' ? (
                                        <button
                                            className={`journeyq-button button-hg button-continue ${
                                                !this.state.selectedEmoji
                                                    ? 'disabled'
                                                    : ''
                                            }`}
                                            disabled={!this.state.selectedEmoji}
                                            onClick={this.handleNextQuestion}
                                        >
                                            {continueText}
                                        </button>
                                    ) : this.props.currentType === 'TEXT' ? (
                                        <button
                                            className={`journeyq-button button-hg button-continue ${
                                                !this.state.textResponse.length
                                                    ? 'disabled'
                                                    : ''
                                            }`}
                                            disabled={
                                                !this.state.textResponse.length
                                            }
                                            onClick={this.handleNextStep}
                                        >
                                            {continueText}
                                        </button>
                                    ) : null}
                                </div>
                            </SlideInDiv>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

/* Todo: Separate Folder */
export interface IPopupButtonsProps {
    buttonOptions: IPopupOptions;
    value: string | string[];
    langId: any;
    isSelected: boolean;
    onClick: (option: IPopupOptions) => void;
}

/* Use Flex design */
export const PopupButtons: React.FunctionComponent<IPopupButtonsProps> = (
    props
) => {
    const buttonText = props.buttonOptions.texts.filter(
        (t) => (t.language = props.langId)
    )[0];
    if (props.buttonOptions.imgUrl) {
        return (
            <div
                className={`popup-button  popup journey ${
                    props.isSelected ? ' active ' : ''
                }`}
                style={{ width: '80px', height: '80px', margin: '5px' }}
                onClick={() => props.onClick(props.buttonOptions)}
            >
                <span className="checkmark">
                    <span className="checkmark_stem"></span>
                    <span className="checkmark_kick"></span>
                </span>
                <span className="p-icon" style={{ marginTop: '0px' }}>
                    <img
                        src={`${props.buttonOptions.imgUrl}`}
                        className="img"
                    />
                </span>
                <span className="p-text">{buttonText.text}</span>
            </div>
        );
    }
    return (
        <div
            className="popup-button"
            onClick={() => props.onClick(props.buttonOptions)}
        >
            <span className="p-text">{buttonText.text}</span>
        </div>
    );
};

PopupButtons.propTypes = {
    buttonOptions: PropTypes.any,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    langId: PropTypes.any,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func
};
