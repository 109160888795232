import * as React from 'react';
import { createPortal } from 'react-dom';

interface Props {
    width?: string;
}

// TODO: refactor into a functional component
export default class Modal extends React.Component<Props, unknown> {
    modalRoot = document.getElementById('bot-modal-root');
    constructor(props: Props) {
        super(props);
        if (props.width) {
            this.modalRoot.style.width = props.width;
        }
    }

    el: HTMLDivElement = document.createElement('div');
    componentDidMount() {
        this.modalRoot.appendChild(this.el);
        this.modalRoot.style.display = 'block';
    }

    componentWillUnmount() {
        this.modalRoot.removeChild(this.el);
        this.modalRoot.style.display = 'none';
    }

    render() {
        return createPortal(this.props.children, this.el);
    }
}
