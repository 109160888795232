import * as React from 'react';
import * as _ from 'lodash';
import { BaseInput, BaseProps } from './_BaseInput';
import ContinueButton from '../components/ContinueButton';
import {
    getStringFromMultimediaTexts,
    getTranslation
} from '@nexxt/common/services/TextService';
import { Question } from '@nexxt/common/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCamera,
    faMicrophone,
    faPlay,
    faStop,
    faVideo
} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { BASE_THEME } from '../style/BotThemes';
import { STATIC_STORAGE_URL } from '@nexxt/common/constants';

const SOUNDCHECK_FILE = `${STATIC_STORAGE_URL}/pub/soundcheck.mp3`;

interface Props extends BaseProps {
    question: Question;
    onAck: () => void;
    confirm: boolean;
    langId: string;
}

const PermissionWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .permission-text {
        width: 60%;
        max-width: 600px;
        text-align: center;
    }
    .permission-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        cursor: pointer;
        background: rgba(249, 232, 249, 1);
        color: ${BASE_THEME.pink};
        border-radius: 50%;
        gap: 8px;
        outline: none;
        &--button {
            border: none;
        }

        svg {
            font-size: 1.8em;
            path {
                fill: ${BASE_THEME.pink};
                transition: all 0.2s ease-in;
                -webkit-transition: all 0.2s ease-in;
                -moz-transition: all 0.2s ease-in;
            }
        }

        &.--granted {
            background: #d5f5e3;
            color: ${BASE_THEME.green};

            svg {
                path {
                    fill: ${BASE_THEME.green};
                }
            }
        }
        &.disabled {
            background: rgba(0, 0, 0, 0.2);
            color: #4a4a4a;
            cursor: not-allowed;

            svg {
                path {
                    fill: #4a4a4a;
                }
            }
        }
    }
    .live-player {
        height: 100%;
        width: 100%;
        object-fit: cover;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
`;

export const PermissionPrompt = (props: Props) => {
    const [valid, setValid] = React.useState(false);
    const [hasCameraPermission, setHasCameraPermission] = React.useState(false);
    const [hasMicPermission, setHasMicPermission] = React.useState(false);
    const [soundcheck, setSoundcheck] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [playing, setPlaying] = React.useState(false);
    const [denied, setDenied] = React.useState(false);
    const stream = React.useRef(null);
    const DEFAULT_WIDTH = 150;
    const DEFAULT_HEIGHT = 150;
    const [mediaWidth, setMediaWidth] = React.useState(DEFAULT_WIDTH);
    const [mediaHeight, setMediaHeight] = React.useState(DEFAULT_HEIGHT);

    const { t, langId, onAck, question } = props;
    const { continueTexts } = question;

    const continueText = getStringFromMultimediaTexts(continueTexts, langId);
    const loadingText = getTranslation(t, 'PLACEHOLDER.LOADING', {}, 'Loading');
    const permissionText = denied
        ? 'It appears you have denied device permissions which may affect your eligibility and ability to continue the chat. Please update or reset your permission settings in your browser settings'
        : valid
        ? `Permission has been granted`
        : `You may click button above to request device permission`;

    const updatePermissionStatus = (permission, value) => {
        if (permission == 'camera') {
            setHasCameraPermission(value);
        } else if (permission == 'microphone') {
            setHasMicPermission(value);
        }
    };

    const getPermission = async (permission) => {
        try {
            const result = await navigator.permissions.query({
                name: permission
            });
            if (result) {
                result.onchange = () => {
                    console.log(
                        `${permission} permission status has changed to ${result.state}`
                    );

                    if (result.state == 'granted') {
                        updatePermissionStatus(permission, true);
                    } else if (result.state == 'denied') {
                        updatePermissionStatus(permission, false);
                        setDenied(true);
                    }
                };
            }
        } catch (error) {
            console.log(error);
        }
    };

    React.useEffect(() => {
        if (!props.question.showContinueButton) {
            props.onAck();
        }
        if (question.multimediaSettings?.showCameraPermissionPrompt) {
            getPermission('camera');
        } else if (question.multimediaSettings?.showMicPermissionPrompt) {
            getPermission('microphone');
        } else if (question.multimediaSettings?.showVideoPermissionPrompt) {
            getPermission('microphone');
            getPermission('camera');
        } else if (question.multimediaSettings?.showSoundCheckPrompt) {
            const audio = new Audio(SOUNDCHECK_FILE);
            setSoundcheck(audio);
        }
    }, []);

    React.useEffect(() => {
        const isValid =
            (question.multimediaSettings?.showCameraPermissionPrompt &&
                hasCameraPermission) ||
            (question.multimediaSettings?.showMicPermissionPrompt &&
                hasMicPermission) ||
            (question.multimediaSettings?.showVideoPermissionPrompt &&
                hasCameraPermission &&
                hasMicPermission) ||
            question.multimediaSettings?.showSoundCheckPrompt;

        if (isValid) {
            setValid(true);
            setDenied(false);
        } else {
            setValid(false);

            // hide video stream if previously showing
            if (
                stream?.current &&
                (question.multimediaSettings?.showVideoPermissionPrompt ||
                    question.multimediaSettings?.showCameraPermissionPrompt)
            ) {
                stream.current.srcObject = null;
            }
        }
    }, [hasCameraPermission, hasMicPermission]);

    const setIsPlaying = (event) => {
        setPlaying(true);
    };
    const setIsNotPlaying = (event) => {
        setPlaying(false);
    };

    React.useEffect(() => {
        if (soundcheck) {
            soundcheck.addEventListener('canplaythrough', (event) => {
                setLoading(false);
            });
            soundcheck.addEventListener('playing', setIsPlaying);
            soundcheck.addEventListener('ended', setIsNotPlaying);
            soundcheck.addEventListener('pause', setIsNotPlaying);
        }
        return () => {
            if (soundcheck) {
                soundcheck.pause();
                soundcheck.removeEventListener('canplaythrough', (event) => {
                    setLoading(false);
                });
                soundcheck.removeEventListener('playing', setIsPlaying);
                soundcheck.removeEventListener('ended', setIsNotPlaying);
                soundcheck.removeEventListener('pause', setIsNotPlaying);
            }
        };
    }, [soundcheck]);

    const requestPermission = async (e, request) => {
        e.preventDefault();
        try {
            const userMedia = await navigator.mediaDevices.getUserMedia(
                request
            );
            if (userMedia) {
                setValid(true);
                if (
                    question.multimediaSettings?.showVideoPermissionPrompt ||
                    question.multimediaSettings?.showCameraPermissionPrompt
                ) {
                    stream.current.srcObject = userMedia;
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSoundCheckClick = () => {
        soundcheck.pause();
        soundcheck.currentTime = 0;
        if (!playing) {
            soundcheck.play();
        }
    };

    const updateDimensions = () => {
        if (stream.current.videoWidth) {
            console.log(stream.current.videoWidth, mediaWidth);
            const scaledWidth =
                stream.current.videoWidth *
                (mediaHeight / stream.current.videoHeight);
            setMediaWidth(scaledWidth);
        }
        props.scrollToBottom();
    };

    return (
        <React.Fragment>
            <PermissionWrapper>
                <div className="permission-wrap">
                    {question.multimediaSettings?.showMicPermissionPrompt && (
                        <div
                            className={`icons permission-icon ${
                                valid ? '--granted' : ''
                            }`}
                            onClick={(e) =>
                                requestPermission(e, { audio: true })
                            }
                        >
                            <FontAwesomeIcon icon={faMicrophone} />
                        </div>
                    )}
                    {question.multimediaSettings?.showCameraPermissionPrompt ? (
                        !valid ? (
                            <div
                                className={`icons permission-icon ${
                                    valid ? '--granted' : ''
                                }`}
                                onClick={(e) =>
                                    requestPermission(e, { video: true })
                                }
                            >
                                <FontAwesomeIcon icon={faCamera} />
                            </div>
                        ) : (
                            <div
                                style={{
                                    width: mediaWidth,
                                    height: mediaHeight
                                }}
                            >
                                <video
                                    ref={stream}
                                    autoPlay
                                    className="live-player"
                                    onLoadedMetadata={updateDimensions}
                                    playsInline
                                    muted
                                />
                            </div>
                        )
                    ) : null}
                    {question.multimediaSettings?.showVideoPermissionPrompt ? (
                        !valid ? (
                            <div
                                className={`icons permission-icon ${
                                    valid ? '--granted' : ''
                                }`}
                                onClick={(e) =>
                                    requestPermission(e, {
                                        audio: true,
                                        video: true
                                    })
                                }
                            >
                                <FontAwesomeIcon icon={faVideo} />
                            </div>
                        ) : (
                            <div
                                style={{
                                    width: mediaWidth,
                                    height: mediaHeight
                                }}
                            >
                                <video
                                    ref={stream}
                                    autoPlay
                                    className="live-player"
                                    onLoadedMetadata={updateDimensions}
                                    playsInline
                                    muted
                                />
                            </div>
                        )
                    ) : null}
                    {question.multimediaSettings?.showSoundCheckPrompt && (
                        <button
                            className={`icons permission-icon permission-icon--button ${
                                loading ? 'disabled' : ''
                            }`}
                            onClick={handleSoundCheckClick}
                            disabled={loading}
                        >
                            {loading ? (
                                loadingText
                            ) : playing ? (
                                <FontAwesomeIcon icon={faStop} />
                            ) : (
                                <FontAwesomeIcon icon={faPlay} />
                            )}
                        </button>
                    )}
                </div>
                {!question.multimediaSettings?.showSoundCheckPrompt && (
                    <div className="permission-text">{permissionText}</div>
                )}
            </PermissionWrapper>
            {question.showContinueButton && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '20px 0'
                    }}
                >
                    <ContinueButton
                        disabled={false}
                        onClick={onAck}
                        text={continueText}
                    />
                </div>
            )}
        </React.Fragment>
    );
};
