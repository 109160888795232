import * as React from 'react';
import * as classnames from 'classnames';
import {
    ConnectDragSource,
    DragSource,
    DragSourceConnector,
    DragSourceMonitor,
    DragSourceSpec,
    XYCoord
} from 'react-dnd';
import ItemTypes from '../constant';
import { Color } from 'csstype';
import { darken, lighten } from 'polished';
import {
    UilPen,
    UilTimesCircle,
    UilCheckCircle
} from '@iconscout/react-unicons';

export interface IProps {
    id: any;
    text: string;
    index: number;
    color?: Color;
    bgColor?: Color;
    type?: string;
    removable?: boolean;
    editable?: boolean;
    used?: boolean;
    bucketIndex: number;
    editOption?: (id, label) => void;
    deleteOption?: (id) => void;
}

class JourneyOption extends React.Component<IProps & DragSourceCollectedProps> {
    state = {
        blink: false,
        hover: false,
        editing: false,
        text: this.props.text
    };

    componentDidMount() {
        if (this.props.editable) {
            this.setState({ blink: true });
        }
    }
    public render() {
        const {
            isDragging,
            connectDragSource,
            sourceOffset,
            editable,
            removable
        } = this.props;
        const { text } = this.state;
        // when dragging the item
        const opacity = isDragging ? 0 : 1;
        const otherStyle: React.CSSProperties = {
            opacity
        };
        if (this.props.bgColor) {
            otherStyle.backgroundColor = this.state.hover
                ? darken(0.2, this.props.bgColor)
                : this.props.bgColor;
        }

        if (this.props.color) {
            otherStyle.color = this.props.color;
        }

        return (
            <React.Fragment>
                {connectDragSource(
                    <div
                        className={classnames('journeyq-option', {
                            'option-input': this.state.editing,
                            blink: this.state.blink,
                            used: this.props.used
                        })}
                        ref={connectDragSource as any}
                        style={{ ...otherStyle, transition: 'all .5s' }}
                        onPointerOver={() => this.setState({ hover: true })}
                        onPointerOut={() => this.setState({ hover: false })}
                        onMouseDown={(e) => {
                            this.state.blink && this.setState({ blink: false });
                        }}
                    >
                        <span className="checkmark">
                            <span className="checkmark_stem"></span>
                            <span className="checkmark_kick"></span>
                        </span>
                        {this.state.editing ? (
                            <>
                                <input
                                    defaultValue={text}
                                    onChange={(e) =>
                                        this.setState({ text: e.target.value })
                                    }
                                    type="text"
                                    style={{
                                        color: this.props.color || '#FFFF'
                                    }}
                                    autoFocus
                                    onBlur={() => {
                                        this.props.editOption(
                                            this.props.id,
                                            text
                                        );
                                        this.setState({ editing: false });
                                    }}
                                />
                                <span
                                    className="journeyq-option--button"
                                    onClick={() => {
                                        this.props.editOption(
                                            this.props.id,
                                            text
                                        );
                                        this.setState({ editing: false });
                                    }}
                                >
                                    <UilCheckCircle size={20} />
                                </span>
                            </>
                        ) : (
                            <>
                                {text}
                                {editable && (
                                    <span
                                        className="journeyq-option--button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            console.log('edit pressed');
                                            this.setState({
                                                editing: true
                                            });
                                        }}
                                    >
                                        <UilPen size={20} />
                                    </span>
                                )}
                                <span
                                    className="journeyq-option--button"
                                    onClick={() =>
                                        this.props.deleteOption(this.props.id)
                                    }
                                >
                                    <UilTimesCircle size={20} />
                                </span>
                            </>
                        )}
                    </div>
                )}
                <DragPreview
                    isDragging={isDragging}
                    sourceOffset={sourceOffset}
                    text={text}
                    style={otherStyle}
                />
            </React.Fragment>
        );
    }
}
const DragPreview = ({ isDragging, sourceOffset, text, style }: any) => {
    // If not dragging completely remove the element
    if (!isDragging) {
        return null;
    }
    const newStyle: React.CSSProperties = {
        ...style,
        opacity: 1,
        zIndex: 1000,
        position: 'fixed',
        top: 0,
        left: 0,
        border: `3px solid ${lighten(0.2, style.backgroundColor)}`,
        transform: sourceOffset
            ? `translate(${sourceOffset.x}px, ${sourceOffset.y}px)`
            : ''
    };
    return (
        <div className="journeyq-option" style={newStyle}>
            {text}
        </div>
    );
};

const cardSource: DragSourceSpec<IProps, IJourneyOptionObject> = {
    beginDrag(props: IProps) {
        return {
            id: props.id,
            index: props.index,
            text: props.text,
            color: props.color,
            bgColor: props.bgColor,
            bucketIndex: props.bucketIndex,
            editable: props.editable,
            removable: props.removable
        };
    }
};

export default DragSource<IProps, DragSourceCollectedProps>(
    (props: IProps) => props.type || ItemTypes.CARD, // ItemTypes.CARD,
    cardSource,
    (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
        sourceOffset: monitor.getClientOffset()
    })
)(JourneyOption as any) as any;

export interface IJourneyOptionObject {
    id: any;
    text: string;
    index: number;
    type?: string;
    color?: Color;
    bgColor?: Color;
}

interface DragSourceCollectedProps {
    isDragging: boolean;
    connectDragSource: ConnectDragSource;
    sourceOffset?: XYCoord;
}
