import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { render } from 'react-dom';
import * as anime from 'animejs';
import * as TetherComponent from 'react-tether';
import cx from 'classnames';
import { OptionData, OPTION_TYPES } from '@nexxt/common/types';
import {
    getPureStringFromHTML,
    getStringFromMultimediaTexts
} from '@nexxt/common/services/TextService';

interface Props {
    useOptions?: boolean;
    options?: OptionData[];
    selectedOptionType?: OPTION_TYPES;
    text: string;
    pictureURL?: string;
    fontSize?: number;
    onLeft?: boolean;
    langId?: string;
}

interface State {
    enableAnimation: boolean;
    hideFirework: boolean;
}
const DEFAULT_FONT_SIZE = 14;

export default class FireworksWrapper extends React.Component<Props, State> {
    state: State = {
        enableAnimation: false,
        hideFirework: false
    };

    canvas: HTMLCanvasElement;
    button: any;

    doMountThings() {
        if (this.state.enableAnimation) return;
        const canvasEl = this.canvas;
        const ctx = canvasEl.getContext('2d');
        const numberOfParticules = 12;
        const pointerX = 0;
        const pointerY = 0;
        const tap =
            'ontouchstart' in window || navigator.maxTouchPoints
                ? 'touchstart'
                : 'mousedown';
        // const colors = ['#FF1461', '#18FF92', '#5A87FF', '#FBF38C'];
        const colors = ['#01ECD5', '#FFE347', '#6457A6', '#F21B3F'];
        const dur1 = 3200;
        const dur2 = 3500;
        const duration = anime.random(dur1, dur2);
        function setParticuleDirection(p) {
            const angle = (anime.random(0, 360) * Math.PI) / 180;
            const value = anime.random(50, 180);
            const radius = [-0.5, 0.5][anime.random(0, 1)] * value;
            return {
                x: p.x + radius * Math.cos(angle),
                y: p.y - Math.abs(radius * Math.sin(angle))
            };
        }

        const createParticule = () => {
            const p: any = {};
            const { height, width } = this.canvas.getBoundingClientRect();
            p.x = width / 2;
            p.y = height;
            p.color = colors[anime.random(0, colors.length - 1)];
            p.radius = anime.random(2, 10);
            p.endPos = setParticuleDirection(p);
            p.draw = function () {
                ctx.beginPath();
                ctx.arc(p.x, p.y, p.radius, 0, 2 * Math.PI, true);
                ctx.fillStyle = p.color;
                ctx.fill();
            };
            return p;
        };

        function renderParticule(anim) {
            for (let i = 0; i < anim.animatables.length; i++) {
                anim.animatables[i].target.draw();
            }
        }

        function animateParticules() {
            const particules = [];
            for (let i = 0; i < numberOfParticules; i++) {
                particules.push(createParticule());
            }
            anime.timeline().add({
                targets: particules,
                x: function (p) {
                    return p.endPos.x;
                },
                y: function (p) {
                    return p.endPos.y;
                },
                radius: 0.1,
                // duration: anime.random(1200, 1800),
                duration,
                easing: 'easeOutExpo',
                update: renderParticule
            });
        }

        const render = anime({
            duration: Infinity,
            update: function () {
                ctx.clearRect(0, 0, canvasEl.width, canvasEl.height);
            }
        });

        render.play();
        animateParticules();

        this.setState({ enableAnimation: true });

        setTimeout(() => {
            this.setState({ hideFirework: true });
        }, duration);
    }

    renderText = () => {
        const {
            useOptions: isMultiple,
            options,
            langId,
            selectedOptionType
        } = this.props;
        const fontSize = this.props.fontSize
            ? this.props.fontSize
            : DEFAULT_FONT_SIZE;
        const showImages =
            selectedOptionType &&
            [OPTION_TYPES.IMAGE_AND_TEXT, OPTION_TYPES.IMAGE_ONLY].includes(
                selectedOptionType
            );
        const hideText =
            selectedOptionType &&
            selectedOptionType === OPTION_TYPES.IMAGE_ONLY;
        if (isMultiple) {
            const isImage: boolean =
                showImages && (options || []).every((o) => !!o.pictureURL);

            if (isImage) {
                return (
                    <div
                        ref={(b) => (this.button = b)}
                        className={cx('format-plain multiple', {
                            'display-flex': isImage
                        })}
                        style={{ fontSize }}
                    >
                        {(options || []).map((option, index) => {
                            const isImage = !!option.pictureURL;
                            return (
                                <span
                                    key={`options-${option.id}-${index}`}
                                    className="format-plain-single display-flex"
                                >
                                    {isImage ? (
                                        <span className="format-plain-icon">
                                            <img
                                                className="format-plain-img"
                                                src={option.pictureURL}
                                            />
                                        </span>
                                    ) : null}
                                    {hideText
                                        ? null
                                        : option.specify ||
                                          getPureStringFromHTML(
                                              getStringFromMultimediaTexts(
                                                  option.texts,
                                                  langId,
                                                  option.label
                                              )
                                          )}
                                </span>
                            );
                        })}
                    </div>
                );
            } else {
                return (
                    <div
                        ref={(b) => (this.button = b)}
                        className={cx('format-plain')}
                        style={{ fontSize }}
                    >
                        {(options || [])
                            .map(
                                (option) =>
                                    option?.specify ||
                                    getPureStringFromHTML(
                                        getStringFromMultimediaTexts(
                                            option.texts,
                                            langId,
                                            option.label
                                        )
                                    )
                            )
                            .join(', ')}
                    </div>
                );
            }
        } else {
            const isImage = showImages && !!this.props.pictureURL;
            return (
                <div
                    ref={(b) => (this.button = b)}
                    className={cx('format-plain', {
                        'display-flex': isImage
                    })}
                    style={{ fontSize }}
                >
                    <span
                        className={cx('format-plain-single', {
                            'display-flex': isImage
                        })}
                    >
                        {isImage ? (
                            <span className="format-plain-icon">
                                <img
                                    className="format-plain-img"
                                    src={this.props.pictureURL}
                                />
                            </span>
                        ) : null}
                        {hideText ? null : this.props.text}
                    </span>
                </div>
            );
        }
    };

    render() {
        const fontSize = this.props.fontSize
            ? this.props.fontSize
            : DEFAULT_FONT_SIZE;
        const isImage = !!this.props.pictureURL;
        return (
            <TetherComponent
                attachment="bottom center"
                targetAttachment="top left"
            >
                {this.renderText()}
                {!this.state.hideFirework && (
                    <canvas
                        ref={(c) => {
                            if (!this.canvas) {
                                this.canvas = c;
                                this.doMountThings();
                            }
                            this.canvas = c;
                        }}
                        className="fireworks"
                        style={{
                            display: `${
                                this.state.hideFirework ? 'none' : 'block'
                            }`
                        }}
                        width="100px"
                    ></canvas>
                )}
            </TetherComponent>
        );
    }
}
