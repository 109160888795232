import * as React from 'react';
import * as _ from 'lodash';

import { BaseInput, BaseProps } from './_BaseInput';
import { PopupButtons } from './_PopupInterface';
import {
    ACTION_TYPES,
    OptionButton,
    OptionData,
    RichTextContent,
    STAGE_TYPES
} from '@nexxt/common/types';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw } from 'draft-js';
import '../style/PopupCard.scss';
import '../style/TradeOff.scss';
import { fixedShuffleIndex } from '@nexxt/common/utils/index';
import styled, { keyframes } from 'styled-components';
import { slideInLeft } from 'react-animations';

import {
    getStringFromMultimediaTexts,
    getMultimediaTextByLanguage
} from '@nexxt/common/services/TextService';
import { KEY_NAME } from '@nexxt/common/constants';
import { dataTestid } from '../constants/dataTestid';

const slideInAnimation = keyframes`${slideInLeft}`;

const SlideInDiv = styled.div`
    animation: 1s ${slideInAnimation};
`;

interface Props extends BaseProps {
    handleAnswer: (
        selectedOption: OptionData,
        dislikes: OptionData[],
        log: any
    ) => void;
    setBottomOffset: (offset?: number, forceScroll?: boolean) => void;
    isEmbedded: boolean;
}
// FIRST_STAGE is like/not sure/dislike
// SECOND_STAGE is the compare mode
// THIRD_STAGE is open ended question on why __ is the favorite
// FOURTH_STAGE is open ended question on why you disliked __

interface FirstStageAction {
    mode: STAGE_TYPES.FIRST;
    media: OptionData;
    action: {
        label: string;
        value: string;
        type: ACTION_TYPES;
    };
}
interface SecondStageAction {
    mode: STAGE_TYPES.SECOND;
    leftMedia: OptionData;
    rightMedia: OptionData;
    selectedMedia: OptionData;
}
interface State {
    log: (FirstStageAction | SecondStageAction)[];
    mode: STAGE_TYPES;
    media: OptionData;
    liked: OptionData[];
    disliked: OptionData[];
    tradeOff: OptionData[];
    comprehensiveOptions: any;
    tradeOffComprehensive: OptionData[];
    disableButtons: boolean;
    fadeOut: boolean;
    inputValue: string;
    size: string;
    isComprehensive: boolean;
}

export class TradeOff extends BaseInput<Props, State> {
    constructor(props, context) {
        super(props, context);
        this.state = {
            log: [],
            mode: STAGE_TYPES.FIRST,
            media: props.question.options[0],
            liked: [],
            disliked: [],
            tradeOff: [],
            comprehensiveOptions: [],
            tradeOffComprehensive: [],
            disableButtons: true,
            fadeOut: false,
            inputValue: '',
            size: 'desktop',
            isComprehensive:
                this.props.question.advancedQuestionSettings?.tradeoff
                    ?.comprehensive
        };
    }
    stickyFooter: HTMLDivElement;
    popupWrapper: HTMLDivElement;

    updateSize = () => {
        if (this.popupWrapper.clientWidth <= 480) {
            this.setState({ size: 'mobile' });
        } else if (this.popupWrapper.clientWidth <= 768) {
            this.setState({ size: 'tablet' });
        } else {
            this.setState({ size: 'desktop' });
        }
    };

    async componentDidMount() {
        const STICKY_FOOTER_HEIGHT = this.stickyFooter.clientHeight;
        !this.props.isEmbedded &&
            this.props.setBottomOffset(STICKY_FOOTER_HEIGHT);
        this.updateDisabled();
        this.updateSize();
        window.addEventListener('resize', this.updateSize);

        // if skip first stage is enabled
        if (this.props.question.isVisible) {
            await this.handleSkipFirstStage();
            const randomizeLiked = this.randomizeOptions(this.state.liked);
            await this.setState(
                {
                    mode: STAGE_TYPES.SECOND,
                    media: null,
                    liked: randomizeLiked
                },
                this.onProcessSecondStage
            );
        }

        // if comprehensive is enabled
        if (this.state.isComprehensive) {
            const opts = this.props.question.options;
            for (let i = 0; i < opts.length - 1; i++) {
                for (let j = i; j < opts.length - 1; j++) {
                    await this.setState({
                        comprehensiveOptions: [
                            ...this.state.comprehensiveOptions,
                            [opts[i], opts[j + 1]]
                        ]
                    });
                }
            }

            this.onProcessSecondStageComprehensive();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevState.mode !== this.state.mode &&
            this.state.mode === STAGE_TYPES.SECOND
        ) {
            // when the question goes to the second stage, remove bottom offset
            this.props.setBottomOffset();
        } else if (
            prevState.mode !== this.state.mode &&
            this.state.mode !== STAGE_TYPES.SECOND
        ) {
            const STICKY_FOOTER_HEIGHT = this.stickyFooter.clientHeight;
            !this.props.isEmbedded &&
                this.props.setBottomOffset(STICKY_FOOTER_HEIGHT);
            this.props.scrollToBottom();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateSize);
    }

    handleSkipFirstStage = () => {
        this.props.question.options.forEach((option) => {
            const newLog = {
                mode: STAGE_TYPES.FIRST,
                media: option,
                action: {
                    label: ACTION_TYPES.LIKE,
                    value: '3',
                    type: ACTION_TYPES.LIKE
                }
            } as FirstStageAction;

            this.setState((prevState) => ({
                ...prevState,
                log: [...prevState.log, newLog],
                ['liked']: [...prevState['liked'], option]
            }));
        });
    };

    onProcessFirstStage = () => {
        this.setState({
            disableButtons: true
        });
        const currentIndex = this.props.question.options.indexOf(
            this.state.media
        );
        const nextIndex = currentIndex + 1;

        //if the current index reaches the end of the array, switch state to secondStage
        if (currentIndex === this.props.question.options.length - 1) {
            /** If no likes, skip second stage and go straight to fourth stage */
            if (this.state.liked.length === 0) {
                // Skip the trade-off part
                this.handleSubmit();
            } else {
                /** shuffle/randomize liked array here, update mode to SECOND_STAGE and call onProcessSecondStage */
                const randomizeLiked = this.randomizeOptions(this.state.liked);
                this.setState(
                    {
                        mode: STAGE_TYPES.SECOND,
                        media: null,
                        liked: randomizeLiked
                    },
                    this.onProcessSecondStage
                );
            }
        } else {
            //if not then go to next item in array
            this.setState({
                media: this.props.question.options[nextIndex]
            });
        }
        this.updateDisabled();
    };

    onProcessSecondStage = () => {
        const { liked } = this.state;
        if (liked.length === 1) {
            /** Tradeoff stage is complete -> skip second stage and proceed to third stage */
            this.handleSubmit();
        } else {
            this.setState({
                tradeOff: [liked[0], liked[1]]
            });
        }
    };

    onProcessSecondStageComprehensive = () => {
        const { comprehensiveOptions } = this.state;
        if (comprehensiveOptions.length < 1) {
            this.handleSubmit();
        } else {
            // add last item to compare
            this.setState({
                tradeOffComprehensive: [
                    ...comprehensiveOptions[comprehensiveOptions.length - 1]
                ]
            });
        }
    };

    /**
     * First stage button onClick action
     * There will be log based on answer type(LIKE, DISLIKE)
     */
    onClickFirstStage = (selectedButton: OptionButton) => () => {
        /** @BACKWARD_COMPATIBILITY - We used to use button label to evaluate button type */
        const isLegacyStructure = !_.hasIn(selectedButton, 'type');

        const newLog = {
            mode: STAGE_TYPES.FIRST,
            media: this.state.media,
            action: {
                label: selectedButton.label,
                value: selectedButton.value,
                type: isLegacyStructure
                    ? selectedButton.label
                    : selectedButton.type
            }
        } as FirstStageAction;
        let answerType;
        const LEGACY_isLikeSelected =
            isLegacyStructure && selectedButton.label === ACTION_TYPES.LIKE;
        const isLikedSelected =
            !isLegacyStructure && selectedButton.type === ACTION_TYPES.LIKE;
        const LEGACY_isDislikeSelected =
            isLegacyStructure && selectedButton.label === ACTION_TYPES.DISLIKE;
        const isDislikedSelected =
            !isLegacyStructure && selectedButton.type === ACTION_TYPES.DISLIKE;

        switch (true) {
            case LEGACY_isLikeSelected:
            case isLikedSelected:
                answerType = 'liked';
                break;
            case LEGACY_isDislikeSelected:
            case isDislikedSelected:
                answerType = 'disliked';
                break;
            default:
                break;
        }

        if (answerType) {
            this.setState(
                (prevState) => ({
                    ...prevState,
                    log: [...prevState.log, newLog],
                    [answerType]: [...prevState[answerType], this.state.media]
                }),
                this.onProcessFirstStage
            );
        } else {
            this.setState(
                (prevState) => ({
                    ...prevState,
                    log: [...prevState.log, newLog]
                }),
                this.onProcessFirstStage
            );
        }
    };

    // add the item(s) to the liked array to be compared in stage 2
    onClickSecondStage = (selectedMedia) => {
        const unselected = this.state.tradeOff.find((t) => t !== selectedMedia);
        const currentIndex = this.state.liked.indexOf(unselected);
        const liked = this.state.liked;
        liked.splice(currentIndex, 1);

        const newLog = {
            mode: STAGE_TYPES.SECOND,
            leftMedia: this.state.tradeOff[0],
            rightMedia: this.state.tradeOff[1],
            selectedMedia: selectedMedia
        } as SecondStageAction;
        this.setState(
            {
                liked,
                log: [...this.state.log, newLog]
            },
            this.onProcessSecondStage
        );
    };

    onClickSecondStageComprehensive = (selectedMedia) => {
        const options = this.state.comprehensiveOptions;
        options.splice(-1);
        const newLog = {
            mode: STAGE_TYPES.SECOND,
            leftMedia: this.state.tradeOffComprehensive[0],
            rightMedia: this.state.tradeOffComprehensive[1],
            selectedMedia: selectedMedia
        } as SecondStageAction;
        this.setState(
            {
                comprehensiveOptions: options,
                log: [...this.state.log, newLog]
            },
            this.onProcessSecondStageComprehensive
        );
    };

    //THIRD_STAGE - if no items in dislike array, then go to end, else, go to FOURTH_STAGE
    handleInputChange = (e) => {
        this.setState({
            inputValue: e.target.value
        });
    };

    /** Disable the buttons for a second */
    updateDisabled = () => {
        setTimeout(() => {
            this.setState({ disableButtons: false });
        }, 1000);
    };

    /** Randomize option(liked array) after finishing the first stage */
    randomizeOptions(options: OptionData[]): OptionData[] {
        const fixedBitMask = options.map((o) =>
            o.randomizeOption ? false : true
        );
        const sorted_options = fixedShuffleIndex(
            options,
            fixedBitMask
        ) as OptionData[];
        return sorted_options;
    }

    handleSubmit = async () => {
        const { liked, disliked, log, isComprehensive } = this.state;
        let favourite;

        if (!isComprehensive) {
            favourite = liked && liked[0];
        } else {
            favourite = [];
        }

        await this.props.handleAnswer(favourite, disliked, log);
    };

    render() {
        const { question, langId } = this.props;
        const { buttons } = question;
        const width = 100 / buttons.length;

        const buttonOptions = buttons.map((button, i) => (
            <PopupButtons
                key={`button-${i}`}
                selected={false}
                hasIcon={true}
                disableButtons={this.state.disableButtons}
                buttonText={getStringFromMultimediaTexts(button.texts, langId)}
                buttonSet={{ ...button, color: '' }}
                onClick={this.onClickFirstStage(button)}
                onKeyDown={(e) => {
                    if (e.key === KEY_NAME.SPACE || e.key === KEY_NAME.ENTER) {
                        this.onClickFirstStage(button);
                    }
                }}
                value={''}
                width={width}
                size={this.state.size}
                numOptions={buttons.length}
            />
        ));

        const alternateWordingText = (
            getMultimediaTextByLanguage(question.alternateWordingTexts, langId)
                ?.contents[0] as RichTextContent
        )?.richText;
        const AnimationClass = this.state.fadeOut ? 'fadeOut' : '';
        if (
            this.state.mode === STAGE_TYPES.FIRST &&
            !(this.state.liked.length === this.props.question.options.length)
        ) {
            return (
                <React.Fragment>
                    <div
                        className="content from-bot bot-width"
                        ref={(e) => (this.popupWrapper = e)}
                    >
                        <div className="plain-text popup-wrapper">
                            <SlideInDiv
                                className={`image-wrapper ${AnimationClass}`}
                                key={question.value}
                            >
                                <div
                                    className={`image-contents trade-off ${
                                        question.isRichText ? '--text' : ''
                                    }`}
                                >
                                    <TradeOffConcept
                                        isRichText={question.isRichText}
                                        mediaURL={this.state.media.pictureURL}
                                        langId={this.props.langId}
                                        option={this.state.media}
                                    />
                                </div>
                            </SlideInDiv>

                            <div
                                className="popup-container-wrapper"
                                ref={(e) => (this.stickyFooter = e)}
                            >
                                <div className="buttons-container">
                                    <div className="buttons-container-flex bot-width">
                                        {buttonOptions}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            if (this.state.isComprehensive) {
                return (
                    <React.Fragment>
                        <div className="tradeoff-wrapper">
                            <SlideInDiv
                                className={`tradeoff-image-wrapper ${AnimationClass}`}
                            >
                                {/* Trade-off message from the portal */}
                                <div
                                    className="editor-title-wrapper"
                                    style={{ alignSelf: 'center' }}
                                >
                                    <Editor
                                        editorState={EditorState.createWithContent(
                                            convertFromRaw(alternateWordingText)
                                        )}
                                        toolbar={{ options: [], inline: [] }}
                                        readOnly={true}
                                    />
                                </div>
                                <div
                                    className={`tradeoff-image-contents trade-off ${
                                        question.isRichText ? '--text' : ''
                                    }`}
                                >
                                    {this.state.tradeOffComprehensive &&
                                        this.state.tradeOffComprehensive.map(
                                            (t) => {
                                                return (
                                                    <div
                                                        key={t.id}
                                                        onClick={() =>
                                                            this.onClickSecondStageComprehensive(
                                                                t
                                                            )
                                                        }
                                                    >
                                                        <TradeOffConcept
                                                            className="compareImg"
                                                            isRichText={
                                                                question.isRichText
                                                            }
                                                            mediaURL={
                                                                t?.pictureURL ||
                                                                ''
                                                            }
                                                            option={t}
                                                            langId={
                                                                this.props
                                                                    .langId
                                                            }
                                                        />
                                                    </div>
                                                );
                                            }
                                        )}
                                </div>
                            </SlideInDiv>
                        </div>
                    </React.Fragment>
                );
            }
            return (
                <React.Fragment>
                    <div className="tradeoff-wrapper">
                        <SlideInDiv
                            className={`tradeoff-image-wrapper ${AnimationClass}`}
                        >
                            {/* Trade-off message from the portal */}
                            <div
                                className="editor-title-wrapper"
                                style={{ alignSelf: 'center' }}
                            >
                                <Editor
                                    editorState={EditorState.createWithContent(
                                        convertFromRaw(alternateWordingText)
                                    )}
                                    toolbar={{ options: [], inline: [] }}
                                    readOnly={true}
                                />
                            </div>
                            <div
                                className={`tradeoff-image-contents trade-off ${
                                    question.isRichText ? '--text' : ''
                                }`}
                            >
                                {this.state.tradeOff &&
                                    this.state.tradeOff.map((t) => {
                                        return (
                                            <div
                                                key={t.id}
                                                onClick={() =>
                                                    this.onClickSecondStage(t)
                                                }
                                            >
                                                <TradeOffConcept
                                                    className="compareImg"
                                                    isRichText={
                                                        question.isRichText
                                                    }
                                                    mediaURL={
                                                        t?.pictureURL || ''
                                                    }
                                                    option={t}
                                                    langId={this.props.langId}
                                                />
                                            </div>
                                        );
                                    })}
                            </div>
                        </SlideInDiv>
                    </div>
                </React.Fragment>
            );
        }
    }
}

interface TradeOffConceptProps {
    className?: string;
    isRichText: boolean;
    option?: OptionData;
    mediaURL?: string;
    langId?: string;
}

const TradeOffConcept: React.FC<TradeOffConceptProps> = ({
    className,
    isRichText,
    mediaURL,
    option,
    langId
}: TradeOffConceptProps) => {
    if (isRichText) {
        const text = (
            getMultimediaTextByLanguage(option.texts, langId, undefined, true)
                ?.contents[0] as RichTextContent
        )?.richText;
        return (
            <Editor
                editorState={EditorState.createWithContent(
                    convertFromRaw(text)
                )}
                toolbar={{ options: [], inline: [] }}
                readOnly={true}
            />
        );
    }

    return (
        <img
            data-testid={dataTestid.TRADEOFF}
            className={className}
            src={mediaURL}
            alt=""
        />
    );
};
