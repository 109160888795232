import * as React from 'react';
import * as PropTypes from 'prop-types';
import '../../style/PopupCard.scss';
import styled, { keyframes } from 'styled-components';
import { slideInLeft } from 'react-animations';
import * as _ from 'lodash';
import {
    JourneyOptionData,
    IJourneySmiley,
    IJourneyBank,
    IPopupOptions
} from './types';
import { INITIAL_JOURNEY_QUESTION } from './constant';
import { QTYPES, Question } from '@nexxt/common/types';
import {
    emojiListDefault,
    emoji_10_ListDefault,
    vehicleBrandList
} from './data';
import { TextPrompt } from '../../questions/TextPrompt';

const slideInAnimation = keyframes`${slideInLeft}`;

const SlideInDiv = styled.div`
    animation: 1s ${slideInAnimation};
`;

interface Props {
    journeyStepsBySegment: JourneyOptionData[][];
    followUpSettings: {
        type: QTYPES;
        random: boolean;
        preset?: string;
        texts: string;
        options: any[];
    }[];
    langId: string;
    currentIndex: number;
    question: Question;
    saveFollowUp: (currentIndex: number, ans: any) => void;
    updateCurrentIndex: (
        index: number,
        emoji: 'BANK' | 'EMOJI' | 'TEXT'
    ) => void;

    saveEverything: (isLastOption: boolean) => void;
}

interface State {
    currentFollowUpIndex: number;
    currentFollowUpAns: any[];
    currentFollowUpOptions: any[];
    selectedValues: IJourneyBank[];
    selectedEmoji: IJourneySmiley;
    textResponse: string;
}

interface Card {
    questionValue: string;
}

export class JourneyFollowupQuestion extends React.Component<Props, State> {
    constructor(props, context) {
        super(props, context);
        this.state = {
            currentFollowUpIndex: 0,
            currentFollowUpAns: [],
            currentFollowUpOptions: [],
            selectedValues: [],
            selectedEmoji: null,
            textResponse: ''
        };
    }

    componentDidMount(): void {
        this.scrollToCurrentSteps();

        const { followUpSettings } = this.props;
        const { currentFollowUpIndex } = this.state;
        const currentFollowUpSetting = followUpSettings[currentFollowUpIndex];

        let options = currentFollowUpSetting?.options || [];

        if (currentFollowUpSetting?.preset) {
            switch (currentFollowUpSetting?.preset) {
                case 'EMOJI':
                    options = emojiListDefault;
                    break;
                case 'VEHICLE':
                    options = vehicleBrandList;
                    break;
                case 'EMOJI_10':
                    options = emoji_10_ListDefault;
                    break;
            }
        }

        if (currentFollowUpSetting?.random) {
            options = _.shuffle(options);
        }
        this.setState({
            currentFollowUpOptions: options
        });
    }

    scrollToCurrentSteps = () => {
        const currentStep = this.getCurrentStep() as JourneyOptionData;

        const element = document.getElementById(
            `journey-step-${currentStep.id}`
        );

        setTimeout(() => {
            element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 200);
    };

    handleNextQuestion = async () => {
        this.handleNextJourneyStep();
    };

    proceedNextFollowUp = async () => {
        const { followUpSettings } = this.props;
        const { currentFollowUpIndex, currentFollowUpAns } = this.state;
        const currentFollowUpSetting =
            followUpSettings[currentFollowUpIndex + 1];
        let options = currentFollowUpSetting?.options || [];

        if (currentFollowUpSetting?.preset) {
            switch (currentFollowUpSetting?.preset) {
                case 'EMOJI':
                    options = emojiListDefault;
                    break;
                case 'VEHICLE':
                    options = vehicleBrandList;
                    break;
                case 'EMOJI_10':
                    options = emoji_10_ListDefault;
                    break;
            }
        }
        if (currentFollowUpSetting?.random) {
            options = _.shuffle(options);
        }
        this.setState({
            currentFollowUpOptions: options,
            currentFollowUpIndex: currentFollowUpIndex + 1,
            currentFollowUpAns
        });
    };

    handleNextJourneyStep = async () => {
        // if (!this.state.selectedEmoji) {
        //     return;
        // }
        const { journeyStepsBySegment, currentIndex, followUpSettings } =
            this.props;
        const currentFollowUpSetting = followUpSettings[0];
        let options = currentFollowUpSetting?.options || [];

        if (currentFollowUpSetting?.preset) {
            switch (currentFollowUpSetting?.preset) {
                case 'EMOJI':
                    options = emojiListDefault;
                    break;
                case 'VEHICLE':
                    options = vehicleBrandList;
                    break;
                case 'EMOJI_10':
                    options = emoji_10_ListDefault;
                    break;
            }
        }
        if (currentFollowUpSetting?.random) {
            options = _.shuffle(options);
        }
        // finish when no more steps OR next step is in last two segments
        const numSteps = _.flatten(journeyStepsBySegment).length;
        const firstIndexOfLastSegments =
            journeyStepsBySegment.length > 1
                ? numSteps -
                  journeyStepsBySegment[journeyStepsBySegment.length - 1]
                      .length -
                  journeyStepsBySegment[journeyStepsBySegment.length - 2].length
                : journeyStepsBySegment[0].length;
        const isLastStep = currentIndex === numSteps - 1;
        const isFinished = isLastStep;
        this.props.saveEverything(isFinished);
        console.log('isFinished:', isFinished);
        console.log('firstIndexOfLastSegments:', firstIndexOfLastSegments);
        console.log('this.props.currentIndex:', currentIndex);
        console.log('numSteps - 1:', numSteps - 1);
        if (currentIndex < numSteps - 1) {
            this.setState(
                {
                    currentFollowUpAns: [],
                    currentFollowUpIndex: 0,
                    currentFollowUpOptions: options
                },
                () =>
                    this.props.updateCurrentIndex(
                        currentIndex + 1,
                        INITIAL_JOURNEY_QUESTION
                    )
            );
        } else {
            this.setState(
                {
                    currentFollowUpAns: [],
                    currentFollowUpIndex: 0,
                    currentFollowUpOptions: options
                },
                () => this.props.updateCurrentIndex(0, INITIAL_JOURNEY_QUESTION)
            );
        }
        this.scrollToCurrentSteps();
    };

    getCurrentStep = () => {
        // @TODO: fix this!
        const { selectedSegmentIndex, elemIndexWithinSegment, flatIndex } =
            this.props.journeyStepsBySegment.reduce(
                (acc, segment) => {
                    const flatIndex = acc.flatIndex + segment.length; // index of end of segment (@TODO: more clarity!)
                    console.log('inter flatIndex:', flatIndex);
                    // keep updating acc until segment is hit
                    return this.props.currentIndex >= flatIndex
                        ? {
                              // update progress vars while scanning through segments
                              ...acc,
                              selectedSegmentIndex:
                                  acc.selectedSegmentIndex + 1,
                              flatIndex: flatIndex,
                              elemIndexWithinSegment:
                                  this.props.currentIndex - flatIndex
                          }
                        : {
                              ...acc,
                              flatIndex: flatIndex
                          };
                },
                {
                    // initial vals
                    selectedSegmentIndex: 0,
                    elemIndexWithinSegment: 0,
                    flatIndex: 0
                }
            );
        return this.props.journeyStepsBySegment[selectedSegmentIndex][
            elemIndexWithinSegment
        ];
    };

    getBankIcons = (bankOptions: IJourneyBank[]) => {
        if (!bankOptions) {
            return null;
        }
        return bankOptions.map((options, index) => {
            return (
                <span key={'bankicons-' + index} className="bank-icon">
                    <img src={options.imgUrl} />
                </span>
            );
        });
    };

    getEmojiIcons = (emojiOption: IJourneyBank) => {
        return (
            <span
                key={'emojiicon-' + emojiOption.id || '1'}
                className="emoji-icon"
            >
                <img src={emojiOption.imgUrl} />
            </span>
        );
    };

    // getBankOptions = () => {
    //     const selectedValues: any[] = this.state.selectedValues.map(
    //         (s) => s.value
    //     );
    //     return this.props.bankOptions.map((option, index) => {
    //         let isSelected = false;
    //         if (selectedValues.includes(option.value)) {
    //             isSelected = true;
    //         }
    //         return (
    //             <PopupButtons
    //                 key={'bankoptions-' + index}
    //                 buttonOptions={option}
    //                 langId={this.props.langId}
    //                 onClick={this.selectBank}
    //                 value={selectedValues}
    //                 isSelected={isSelected}
    //             />
    //         );
    //     });
    // };

    getEmojiOptions = () => {
        const selectedValues: any[] = (
            this.state.currentFollowUpAns[this.state.currentFollowUpIndex] || []
        ).map((s) => s.value);
        return this.state.currentFollowUpOptions.map((option, index) => {
            let isSelected = false;
            if (selectedValues.includes(option.value)) {
                isSelected = true;
            }
            return (
                <PopupButtons
                    key={'emoji-' + index}
                    buttonOptions={option}
                    langId={this.props.langId}
                    onClick={this.selectEmoji}
                    value={selectedValues}
                    isSelected={isSelected}
                />
            );
        });
    };

    getTextPrompt = (processNextQuestion) => {
        const { currentFollowUpAns, currentFollowUpIndex } = this.state;
        return (
            <TextPrompt
                langId={this.props.langId}
                setBottomOffset={() => {}}
                handleAnswer={(text, url) => {
                    currentFollowUpAns[currentFollowUpIndex] = {
                        value: text,
                        url
                    };
                    this.setState({ currentFollowUpAns }, () => () => {
                        this.props.saveFollowUp(
                            this.props.currentIndex,
                            currentFollowUpAns[currentFollowUpIndex]
                        );
                    });
                    processNextQuestion();
                }}
                question={{ ...this.props.question }}
            />
        );
    };

    // selectBank = (option: IJourneyBank) => {
    //     const SINGLE_CHOICE_MODE = true;
    //     const newSelectedValues = SINGLE_CHOICE_MODE
    //         ? [option]
    //         : [...this.state.selectedValues, option];
    //     this.setState({ selectedValues: newSelectedValues }, () =>
    //         this.props.saveBank(
    //             this.props.currentIndex,
    //             this.state.selectedValues
    //         )
    //     );
    // };

    selectEmoji = (option: any) => {
        const { followUpSettings } = this.props;
        const { currentFollowUpIndex, currentFollowUpAns } = this.state;

        const { type } = followUpSettings[currentFollowUpIndex];
        if (!currentFollowUpAns[currentFollowUpIndex]) {
            currentFollowUpAns.push([]);
        }
        switch (type) {
            case QTYPES.SINGLE_CHOICE:
                currentFollowUpAns[currentFollowUpIndex] = [option];
                break;
            case QTYPES.MULTIPLE_CHOICE:
                (currentFollowUpAns[currentFollowUpIndex] || []).push(option);
                break;
        }

        this.setState(
            {
                currentFollowUpAns
            },
            () =>
                this.props.saveFollowUp(
                    this.props.currentIndex,
                    currentFollowUpAns
                )
        );
    };

    render() {
        const { currentFollowUpIndex, currentFollowUpAns } = this.state;
        const { followUpSettings } = this.props;

        const totalFollowUpLength = followUpSettings?.length || 0;
        const isLastFollowUpForJourneyStep =
            currentFollowUpIndex >= totalFollowUpLength - 1;
        const { texts, type } = followUpSettings[currentFollowUpIndex];
        const disabled = !(currentFollowUpAns[currentFollowUpIndex] || [])
            .length;
        const currentStep = this.getCurrentStep() as JourneyOptionData;
        const continueText = this.props.langId == 'en' ? 'Continue' : '继续';
        const processNextQuestion = isLastFollowUpForJourneyStep
            ? this.handleNextQuestion
            : this.proceedNextFollowUp;
        return (
            <React.Fragment>
                <div className="content from-bot bot-width">
                    <div
                        className="plain-text popup-wrapper"
                        style={{ textAlign: 'center' }}
                    >
                        <div className="popup-container-wrapper popup-journey">
                            <SlideInDiv
                                key={'bottomText-' + this.props.currentIndex}
                                className="buttons-container"
                            >
                                <div className="question">
                                    <p style={{ fontWeight: 'normal' }}>
                                        Now we’ll go through your steps one by
                                        one. I have a few questions for each of
                                        the steps highlighted above.
                                    </p>
                                    {_.template(texts)({
                                        stepText: currentStep.text
                                    })}
                                </div>
                                <div
                                    className="buttons-container-flex"
                                    style={{
                                        maxWidth: '100%',
                                        paddingTop: '5px'
                                    }}
                                >
                                    {type === QTYPES.OPEN
                                        ? this.getTextPrompt(
                                              processNextQuestion
                                          )
                                        : this.getEmojiOptions()}
                                </div>
                                {type !== QTYPES.OPEN && (
                                    <div className="footer">
                                        <button
                                            className={`journeyq-button button-hg button-continue ${
                                                disabled ? 'disabled' : ''
                                            }`}
                                            disabled={disabled}
                                            onClick={processNextQuestion}
                                        >
                                            {continueText}
                                        </button>
                                    </div>
                                )}
                            </SlideInDiv>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

/* Todo: Separate Folder */
export interface IPopupButtonsProps {
    buttonOptions: IPopupOptions;
    value: string | string[];
    langId: any;
    isSelected: boolean;
    onClick: (option: IPopupOptions) => void;
}

/* Use Flex design */
export const PopupButtons: React.FunctionComponent<IPopupButtonsProps> = (
    props
) => {
    const buttonText = props.buttonOptions.texts.filter(
        (t) => (t.language = props.langId)
    )[0];
    if (props.buttonOptions.imgUrl) {
        return (
            <div
                className={`popup-button  popup journey ${
                    props.isSelected ? ' active ' : ''
                }`}
                style={{ width: '80px', height: '80px', margin: '5px' }}
                onClick={() => props.onClick(props.buttonOptions)}
            >
                <span className="checkmark">
                    <span className="checkmark_stem"></span>
                    <span className="checkmark_kick"></span>
                </span>
                <span className="p-icon" style={{ marginTop: '0px' }}>
                    <img
                        src={`${props.buttonOptions.imgUrl}`}
                        className="img"
                    />
                </span>
                <span className="p-text">{buttonText.text}</span>
            </div>
        );
    }
    return (
        <div
            className="popup-button"
            onClick={() => props.onClick(props.buttonOptions)}
        >
            <span className="p-text">{buttonText.text}</span>
        </div>
    );
};

PopupButtons.propTypes = {
    buttonOptions: PropTypes.any,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    langId: PropTypes.any,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func
};
