import axios from 'axios';
import * as React from 'react';
import { BaseInput, BaseProps } from './_BaseInput';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { COLOR_THEME, NA_OPTION_VALUE } from '@nexxt/common/constants';
import { UploadURL, TextAnswer } from '@nexxt/common/types';

const { translate } = require('react-i18next');

import { BASE_THEME } from '../style/BotThemes';
import { EmojiPicker } from '../components/EmojiPicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSmile,
    faImage,
    faPaperPlane,
    faMicrophone,
    faPlayCircle
} from '@fortawesome/free-solid-svg-icons';
import { KEY_NAME } from '@nexxt/common/constants';
import { delay } from '@nexxt/common/utils/index';
import {
    getStringFromMultimediaTexts,
    getTranslation
} from '@nexxt/common/services/TextService';
import { dataTestid } from '../constants/dataTestid';
import NotApplicableButton from '../components/NotApplicableButton';

const UserInputArea = styled.div`
    height: auto !important;
    position: fixed;
    max-width: 100%;
    padding: 10px;
    display: inline-block;
    border: ${(props: { isEmbedded: boolean }) =>
        props.isEmbedded ? 'null' : '5px solid #dbdee1'};
    border-radius: 0px;
    bottom: 0px;
    height: 46px;
    left: 0;
    justify-content: space-between;
    z-index: 10;
    right: 0;
    @keyframes slideIn {
        from {
            transform: translateY(100%);
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }
    @keyframes slideOut {
        from {
            transform: translateY(0);
            opacity: 1;
        }
        to {
            transform: translateY(100%);
            opacity: 0;
        }
    }
    @media (max-width: 1250px) {
        max-width: 100%;
        border-radius: 0;
        bottom: 0;
    }
    &.highlighted {
        border: ${(props: { isEmbedded: boolean }) =>
            props.isEmbedded ? 'null' : '3px solid #f2f2f2'};
        background: white;
        /* animation-iteration-count: infinite;
    animation: blink-border 6s ease infinite; */
        margin: 0 auto;

        > div {
            max-width: 800px;
            margin: 0 auto;
            position: relative;
            .Dropzone {
                .drag-wrap {
                }
            }
        }
    }
    &.invalid {
        border: 3px solid ${COLOR_THEME.red};
    }
    svg {
        cursor: pointer;
        font-size: 1.5em;
        color: white;
    }
    .msg-send svg {
        &:hover {
            color: ${BASE_THEME.yellow};
        }
    }
    .msg-emoji svg,
    .msg-upload svg {
        &:hover {
            color: ${BASE_THEME.pink};
        }
    }

    .uploaded-file {
        margin: 0 10px 0 0;
        position: relative;
        border-radius: 5px;
        display: inline-flex;
        flex: initial !important;
        overflow: hidden;
        border: 1px solid rgba(0, 0, 0, 0.2);
        height: 50px;
        &.img {
            width: 50px;
            justify-content: center;
            align-items: center;

            img {
                max-height: 100%;
                margin: 0;
            }
        }

        &.video {
            width: 150px;
            span {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                &:nth-child(2) {
                    width: 50px;
                    height: 50px;
                    background: #0086ff;
                    color: #fff;
                    &:hover {
                        color: #fff;
                    }
                }
                &:nth-child(3) {
                    color: #4a4a4a;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: initial;
                    width: calc(100% - 50px);
                    padding: 0 5px;
                    background: #fff;
                }
            }
        }

        .remove-file {
            position: absolute;
            top: 5px;
            right: 5px;
            z-index: 1;
            color: #fff;
            cursor: pointer;
            font-size: 16px;
            background: rgba(0, 0, 0, 0.5);
            width: 16px;
            height: 16px;
            border-radius: 50%;
            text-align: center;
            &:hover {
                background: ${BASE_THEME.teal};
            }
        }
    }

    .icons {
        display: flex;
        align-items: center;
        padding: 0 0.25em;
        svg {
            margin: 0 0.3em;
        }
    }

    .emoji-wrapper {
        position: relative;
        display: inline-block;
    }

    .wc-prompt-row {
        display: block;
        .icons {
            display: flex;
            margin-top: 1em;
            > div {
                display: inline-block;
                margin: 0 1.4em 0 0;
                width: 42px;
                height: 40px;
                background: rgba(175, 175, 175, 0.15);
                padding: 9px 4px;
                vertical-align: middle;
                border-radius: 50%;
                transition: all 0.2s ease-in;
                -webkit-transition: all 0.2s ease-in;
                -moz-transition: all 0.2s ease-in;
                svg {
                    path {
                        fill: rgba(0, 0, 0, 20%);
                        transition: all 0.2s ease-in;
                        -webkit-transition: all 0.2s ease-in;
                        -moz-transition: all 0.2s ease-in;
                    }
                }
                &:hover {
                    /* background: ${BASE_THEME.pink}; */
                    background: rgba(249, 232, 249, 1);
                    svg {
                        path {
                            fill: ${BASE_THEME.pink};
                            /* fill: rgba(12, 199, 183, 1); */
                        }
                    }
                }

                &.msg-send {
                    float: right;
                    background: ${BASE_THEME.green};
                    margin: 0;
                    padding: 10px 1px;
                    position: relative;
                    box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
                    /* &:hover,
          &:focus {
            animation: pulse 1s infinite;
          } */
                    svg path {
                        fill: #fff;
                    }
                    &.disabled {
                        background: rgba(0, 0, 0, 0.2);
                    }
                }
            }
        }
    }
    .send {
        margin: 0.45em 0.25em 0;
    }

    textarea,
    input {
        height: 100%;
        padding: 0.35em;
        width: 100%;
        font-size: 14pt;
        font-weight: bold;
        border: none;
        resize: none;
    }

    @-webkit-keyframes pulse {
        0% {
            -webkit-box-shadow: 0 0 0 0 rgba(12, 199, 183, 0.4);
        }
        70% {
            -webkit-box-shadow: 0 0 0 15px rgba(12, 199, 183, 0);
        }
        100% {
            -webkit-box-shadow: 0 0 0 0 rgba(12, 199, 183, 0);
        }
    }
    @keyframes pulse {
        0% {
            -moz-box-shadow: 0 0 0 0 rgba(12, 199, 183, 0.4);
            box-shadow: 0 0 0 0 rgba(12, 199, 183, 0.4);
        }
        70% {
            -moz-box-shadow: 0 0 0 15px rgba(12, 199, 183, 0);
            box-shadow: 0 0 0 15px rgba(12, 199, 183, 0);
        }
        100% {
            -moz-box-shadow: 0 0 0 0 rgba(12, 199, 183, 0);
            box-shadow: 0 0 0 0 rgba(12, 199, 183, 0);
        }
    }
`;

const PromptRow = styled.div`
    display: flex;
    display: block;
    .icons {
        display: block;
        margin-top: 1em;
        > div {
            display: inline-block;
            margin: 0 1.4em 0 0;
            width: 42px;
            height: 40px;
            background: rgba(175, 175, 175, 0.15);
            padding: 9px 4px;
            vertical-align: middle;
            border-radius: 50%;
            transition: all 0.5s ease-in;
            -webkit-transition: all 0.2s ease-in;
            -moz-transition: all 0.2s ease-in;
            svg {
                path {
                    fill: rgba(0, 0, 0, 20%);
                    transition: all 0.2s ease-in;
                    -webkit-transition: all 0.2s ease-in;
                    -moz-transition: all 0.2s ease-in;
                }
            }
            &:hover {
                /* background: ${BASE_THEME.pink}; */
                background: rgba(249, 232, 249, 1);
                svg {
                    path {
                        fill: ${BASE_THEME.pink};
                        /* fill: rgba(12, 199, 183, 1); */
                    }
                }
            }

            &.msg-send {
                float: right;
                background: ${BASE_THEME.green};
                margin: 0;
                padding: 10px 1px;
                position: relative;
                box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
                /* &:hover,
        &:focus {
          animation: pulse 1s infinite;
        } */
                svg path {
                    fill: #fff;
                }
                &.disabled {
                    background: rgba(0, 0, 0, 0.2);
                }
            }
        }
    }
`;

const UploadPreview = styled.div`
    width: 100%;
    display: flex;
    align-items: center h4 {
        padding-top: 0.5em;
        font-size: 80%;
        margin: 0.1em;
        margin-right: 0.5em;
        color: black;
    }
    & > * {
        height: 40px;
    }
    img {
        margin-left: 10px;
    }
`;

const InvalidInputWarning = styled.div`
    color: ${COLOR_THEME.red};
    font-size: 10px;
    width: auto !important;
    height: auto !important;
    background: none !important;
    margin: 0 auto !important;
    padding: 0 !important;
    max-width: 40% !important;
`;

interface Props extends BaseProps {
    handleAnswer: (text: string, uploadURLs: UploadURL[]) => void;
    setBottomOffset: (offset?: number, forceScroll?: boolean) => void;
    isEmbedded?: boolean;
}

interface State {
    userText: string;
    open: boolean;
    uploadURLs: UploadURL[];
    emojiPicker: boolean;
    accepted: Array<any>;
    rejected: Array<any>;
    uploading: boolean;
    tooLong: boolean;
    submitting: boolean;
}

@translate('translation')
export class TextPrompt extends BaseInput<Props, State> {
    state: State = {
        userText: '',
        open: false,
        uploadURLs: [],
        emojiPicker: false,
        accepted: [],
        rejected: [],
        uploading: false,
        tooLong: false,
        submitting: false
    };

    private textInput = React.createRef<HTMLInputElement>();
    stickyFooter: HTMLDivElement;
    form: HTMLFormElement;
    submitButton: HTMLButtonElement;

    async componentDidMount() {
        // reserve space at bottom for sticky footer
        const STICKY_FOOTER_HEIGHT = this.stickyFooter.clientHeight;
        this.props.setBottomOffset(STICKY_FOOTER_HEIGHT);

        if (this.props.previousAnswer) {
            const userText = (this.props.previousAnswer as TextAnswer).value;

            this.setState({ userText });
        }
    }

    // wait 200ms for slideOut animation before submitting answer.
    onSubmit = async (e, isInputValid: boolean) => {
        e.preventDefault();

        // if input is invalid, return
        if (!isInputValid) return;

        const { userText, uploadURLs } = this.state;
        if (!this.state.submitting) {
            this.setState({ submitting: true });
            await delay(200);
            this.setState({ uploadURLs: [], userText: '' });
            this.props.handleAnswer(userText, uploadURLs);
        }
    };

    setUploadURLs = (uploadURLs: UploadURL[]) => {
        this.setState({
            uploadURLs: uploadURLs //open: false
        });
    };

    // add emoji to textInput and focus
    addEmoji = (emoji) => {
        this.closeEmojiPicker();
        this.setState({ userText: this.state.userText + emoji.native });
        this.textInput.current.focus();
    };

    toggleEmojiPicker = () => {
        this.setState((prevState) => ({ emojiPicker: !prevState.emojiPicker }));
    };

    closeEmojiPicker = () => {
        this.setState({ emojiPicker: false });
    };

    submit = () => {
        // need to simulate submit-button click so that input is HTML-validated
        this.submitButton.click();
    };

    onKeyUp = (e: React.KeyboardEvent<HTMLElement>) => {
        // submit when enter is clicked
        if (e.key === KEY_NAME.ENTER) {
            e.preventDefault();
            if (this.state.userText.length) {
                this.submit();
            }
        }
    };
    onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        // prevent enter from making new line (need to do shift+enter)
        if (e.key === KEY_NAME.ENTER) {
            e.preventDefault();
        }
    };

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        // close and focus textInput
        this.setState({ open: false });
        this.textInput.current.focus();
    };

    textareaChange = (e) => {
        e.preventDefault();
        this.setState({ userText: e.currentTarget.value });
    };

    onUpload = (accepted, rejected) => {
        if (accepted.length > 19 || this.state.uploadURLs.length > 19) {
            this.setState({
                tooLong: true
            });
        } else {
            this.setState({
                accepted,
                rejected,
                uploading: true,
                tooLong: false
            });
        }

        const uploadPromises = [];

        // upload file, append to `uploadURLs`
        // and then pass back to parent
        this.state.accepted.map((file, ind) => {
            console.log(`upload #${ind} of queue uploading: ${file}`);
            const url = `${process.env.INCA_SERVER_URL}/data/file/upload`;
            const formData = new FormData();
            formData.append('file', file);
            uploadPromises.push(
                axios
                    .post(url, formData)
                    .then((response) => {
                        console.log('resposne', response);
                        console.log(
                            `upload #${ind} of queue succeeded: ${file}`
                        );
                        /* this.setState({
              uploadURLs: [...this.state.uploadURLs, response.data]
            }); */
                        this.setUploadURLs([
                            ...this.state.uploadURLs,
                            response.data
                        ]);
                    })
                    .catch((error) => {
                        console.log(`upload #${ind} of queue FAILED: ${file}`);
                        console.log(error);
                    })
            );
        });

        Promise.all(uploadPromises).then(() => {
            console.log('DONE');
            this.setState({
                uploading: false
            });
        });
    };

    handleRemoveFile = (fileToBeRemoved) => {
        const newFiles = this.state.uploadURLs.filter((uploadedFile) => {
            return uploadedFile.thumb != fileToBeRemoved;
        });

        this.setState(
            {
                uploadURLs: [...newFiles]
            },
            () => {
                this.setUploadURLs(this.state.uploadURLs);
            }
        );
    };

    validateCustomLogic = () => {
        const { question } = this.props;
        const { userText } = this.state;
        if (question.customLogic?.inputValidators?.length) {
            const funcs = question.customLogic.inputValidators.map(
                (funcStr) => new Function('userText', funcStr)
            );
            const evals = funcs.map((f) => f(userText));
            const firstString = evals.find((e) => e != '');
            return firstString ? firstString : '';
        } else {
            return '';
        }
    };

    isValidInput = (text) => {
        const { question } = this.props;
        let regExp;
        if (text.length > 0) {
            if (question.showValidation) {
                switch (question.validationType) {
                    case 0: // Postal code(CA)
                        regExp = /^(?:[A-Z]\d[A-Z][ -]?\d[A-Z]\d)$/i;
                        break;
                    case 1: // Email
                        regExp =
                            /^[a-z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-z0-9-]+)*$/i;
                        break;
                    case 2: // Phone number
                        regExp =
                            /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
                        break;
                }
                return regExp.test(text);
            } else {
                regExp = /^[ !@#$%^&*()_\-~`’“;:\/?.>,<_=+\/\\\]\[]+$/;

                return !regExp.test(text);
                // regExp checks if a string doesn't contain non-alphanumeric characters
            }
        }
        return true;
    };
    getValidationText = () => {
        const { question, t } = this.props;
        let text = t('LABEL.VALIDATE.TEXT_INPUT');
        if (question.showValidation) {
            switch (question.validationType) {
                case 0:
                    text = t('LABEL.VALIDATE.POSTAL_CODE');
                    break;
                case 1:
                    text = t('LABEL.VALIDATE.EMAIL');
                    break;
                case 2:
                    text = t('LABEL.VALIDATE.PHONE_NUM');
                    break;
            }
        }
        return text;
    };
    render() {
        const { emojiPicker, userText, uploading, uploadURLs } = this.state;
        const { t, question, langId } = this.props;
        const { allowUserUpload, notApplicableTexts, showNotApplicableOption } =
            question;
        const allowEmojis = true;
        const allowMicrophone = false;

        const notApplicableText = getStringFromMultimediaTexts(
            notApplicableTexts,
            langId,
            getTranslation(this.props.t, 'BUTTON.NOT_APPLICABLE')
        );

        const dropzoneRef = React.createRef<any>();
        const openDialog = () => {
            if (dropzoneRef.current) {
                dropzoneRef.current.open();
            }
        };

        const inputHasAlphanumeric = this.isValidInput(userText);
        const customLogicValidationMsg = userText.length
            ? this.validateCustomLogic()
            : '';

        const inputIsValid =
            (inputHasAlphanumeric && !customLogicValidationMsg) ||
            (showNotApplicableOption && userText === notApplicableText);

        return (
            <form
                onSubmit={(e) => this.onSubmit(e, inputIsValid)}
                ref={(f) => (this.form = f)}
            >
                <UserInputArea
                    isEmbedded={this.props.isEmbedded}
                    className={`wc-prompt highlighted ${
                        inputIsValid ? '' : 'invalid'
                    }`}
                    style={{
                        animation: this.state.submitting
                            ? 'slideOut 0.5s'
                            : 'slideIn 0.5s'
                    }}
                    ref={(e) => (this.stickyFooter = e)}
                >
                    {this.state.uploading &&
                    this.state.uploadURLs.length === 0 ? (
                        <div className="line-loader" />
                    ) : null}
                    <PromptRow className="wc-prompt-row">
                        {this.state.uploadURLs.length > 0 ? (
                            <UploadPreview className="wc-upload-preview">
                                <h4>
                                    {getTranslation(t, 'LABEL.UPLOADS.HEADER')}
                                </h4>
                                {this.state.uploadURLs.map((i, idx) => {
                                    let fileExtension = i.thumb.substring(
                                        i.thumb.lastIndexOf('.') + 1,
                                        i.thumb.length
                                    );
                                    fileExtension = (
                                        fileExtension || ''
                                    ).toLowerCase();
                                    if (
                                        fileExtension.includes('png') ||
                                        fileExtension.includes('jpg') ||
                                        fileExtension.includes('jpeg') ||
                                        fileExtension.includes('gif')
                                    ) {
                                        return (
                                            <div
                                                className="uploaded-file img"
                                                key={`uploaded-file-img-${idx}`}
                                            >
                                                <span
                                                    className="remove-file"
                                                    onClick={() =>
                                                        this.handleRemoveFile(
                                                            i.thumb
                                                        )
                                                    }
                                                >
                                                    {' '}
                                                    &times;
                                                </span>
                                                <img
                                                    src={i.thumb}
                                                    key={i.thumb}
                                                />
                                            </div>
                                        );
                                    } else if (
                                        fileExtension.includes('pdf') ||
                                        fileExtension.includes('doc') ||
                                        fileExtension.includes('exe') ||
                                        fileExtension.includes('mp4') ||
                                        fileExtension.includes('mov') ||
                                        fileExtension.includes('flv') ||
                                        fileExtension.includes('webm') ||
                                        fileExtension.includes('mp3') ||
                                        fileExtension.includes('wav') ||
                                        fileExtension.includes('psd')
                                    ) {
                                        return (
                                            <div
                                                className="uploaded-file video"
                                                key={`uploaded-file-video-${idx}`}
                                            >
                                                <span
                                                    className="remove-file"
                                                    onClick={() =>
                                                        this.handleRemoveFile(
                                                            i.thumb
                                                        )
                                                    }
                                                >
                                                    &times;
                                                </span>
                                                <span>
                                                    <FontAwesomeIcon
                                                        icon={faPlayCircle}
                                                    />
                                                </span>
                                                <span>
                                                    {fileExtension.toUpperCase()}
                                                </span>
                                            </div>
                                        );
                                    }
                                })}
                                {uploading && uploadURLs.length > 0 ? (
                                    <div className="line-loader" />
                                ) : null}
                            </UploadPreview>
                        ) : null}
                    </PromptRow>
                    <PromptRow className="wc-prompt-row">
                        <Dropzone
                            ref={dropzoneRef}
                            onDrop={this.onUpload}
                            noClick={true}
                            // disableClick // replace with noClick???
                            // activeStyle={{
                            //     fontSize: '14px',
                            //     color: '#fff',
                            //     border: '1px dashed #0cc7b7',
                            //     borderRadius: '5px',
                            //     background: 'rgba(89, 189, 176, 0.2)',
                            //     content: 'Drag and drop files here',
                            //     position: 'relative',
                            //     zIndex: 1,
                            //     width: '100%',
                            //     height: '100%',
                            //     transition: 'all 0.2s linear'
                            // }}
                        >
                            {({
                                getRootProps,
                                getInputProps,
                                isDragActive
                            }) => (
                                <div className="Dropzone">
                                    <div className="drag-wrap">
                                        <TextareaAutosize
                                            data-testid={dataTestid.TEXTAREA}
                                            autoFocus
                                            inputRef={this.textInput}
                                            value={userText}
                                            onKeyUp={this.onKeyUp}
                                            onKeyDown={this.onKeyDown}
                                            onChange={this.textareaChange}
                                            placeholder={getTranslation(
                                                t,
                                                'PLACEHOLDER.PROMPT.TEXT'
                                            )}
                                            style={{ minHeight: 85 }}
                                            disabled={uploading ? true : false}
                                            onPaste={(event) => {
                                                !question.allowCopyPaste &&
                                                    event.preventDefault();
                                            }}
                                        />
                                    </div>
                                    <div className="icons">
                                        {true && ( // IMAGE UPLOAD ICON (show always, for now...! TODO)
                                            //allowUserUpload && ( // IMAGE UPLOAD ICON
                                            <div
                                                onClick={openDialog}
                                                onKeyDown={(e) => {
                                                    if (
                                                        e.key ===
                                                            KEY_NAME.SPACE ||
                                                        e.key === KEY_NAME.ENTER
                                                    ) {
                                                        openDialog();
                                                    }
                                                }}
                                                className="icons"
                                                tabIndex={0}
                                            >
                                                {/* Keep the following void input so the file upload works on mobile */}
                                                <input {...getInputProps()} />
                                                <FontAwesomeIcon
                                                    icon={faImage}
                                                />
                                            </div>
                                        )}

                                        <div className="emoji-wrapper">
                                            {allowEmojis && ( // EMOJI PICKER BUTTON
                                                <div
                                                    onClick={
                                                        this.toggleEmojiPicker
                                                    }
                                                    onKeyDown={(e) => {
                                                        if (
                                                            e.key ===
                                                                KEY_NAME.SPACE ||
                                                            e.key ===
                                                                KEY_NAME.ENTER
                                                        ) {
                                                            console.log(
                                                                'toggling'
                                                            );
                                                            this.toggleEmojiPicker();
                                                        }
                                                    }}
                                                    tabIndex={0}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faSmile}
                                                    />
                                                </div>
                                            )}
                                            {emojiPicker && ( // EMOJI PICKER MENU
                                                <EmojiPicker
                                                    addEmoji={this.addEmoji}
                                                />
                                            )}
                                        </div>

                                        {allowMicrophone && (
                                            <FontAwesomeIcon
                                                icon={faMicrophone}
                                            />
                                        )}

                                        {!inputIsValid && (
                                            <InvalidInputWarning>
                                                {!inputHasAlphanumeric
                                                    ? t(
                                                          'LABEL.VALIDATE.TEXT_INPUT'
                                                      )
                                                    : customLogicValidationMsg}
                                            </InvalidInputWarning>
                                        )}
                                        {/* send button */}

                                        {inputIsValid &&
                                        userText &&
                                        userText.length > 0 ? (
                                            <div
                                                className="msg-send"
                                                onClick={this.submit}
                                                onKeyDown={(e) => {
                                                    if (
                                                        e.key ===
                                                            KEY_NAME.SPACE ||
                                                        e.key === KEY_NAME.ENTER
                                                    ) {
                                                        this.submit();
                                                    }
                                                }}
                                                tabIndex={0}
                                                data-testid={
                                                    dataTestid.FABUTTON
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPaperPlane}
                                                    className="send"
                                                />
                                            </div>
                                        ) : (
                                            <div className="msg-send disabled">
                                                <FontAwesomeIcon
                                                    icon={faPaperPlane}
                                                    className="send"
                                                />
                                            </div>
                                        )}
                                        {showNotApplicableOption && (
                                            <span
                                                style={{
                                                    marginLeft: 'auto',
                                                    width: 'max-content',
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <NotApplicableButton
                                                    onClick={() => {
                                                        this.setState(
                                                            {
                                                                userText:
                                                                    notApplicableText
                                                            },
                                                            () => this.submit()
                                                        );
                                                    }}
                                                    text={notApplicableText}
                                                />
                                            </span>
                                        )}
                                    </div>
                                </div>
                            )}
                        </Dropzone>
                    </PromptRow>
                </UserInputArea>
                <button
                    type="submit"
                    ref={(b) => (this.submitButton = b)}
                    style={{ display: 'none' }}
                />
            </form>
        );
    }
}
