import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { last } from '@nexxt/common/utils/index';
import {
    LANGUAGE_QVALUE,
    RESUME_QVALUE,
    RESUME_OVALUE,
    NA_OPTION_VALUE,
    BUILTIN_OPTION_ID,
    CANADA_PROVINCE_OPTION_IDS,
    OTHER_OPTION_VALUE,
    OTHER_OPTION
} from '@nexxt/common/constants';
import {
    BMTYPES,
    QTYPES,
    SPECIAL_QIDS,
    UMTYPES,
    TTYPES,
    UserMessage,
    Task,
    TimestampCollection,
    OptionData,
    UploadURL,
    OPTION_TYPES,
    MULTIMEDIA_TYPES,
    ProjectInputSettings
} from '@nexxt/common/types';
import { getStringFromMultimediaTexts } from '@nexxt/common/services/TextService';
import {
    TextPrompt,
    Scale,
    SingleChoice,
    MultipleChoice,
    RankOrder,
    AdBuilder,
    Multimedia,
    RateImage,
    IconsRating,
    PopupCard,
    Numeric,
    // DynamicMap,
    VectorMap,
    VirtualQuestion,
    Journey_NEW,
    TradeOff,
    Hotspot,
    NPS,
    VideoAnnotation,
    TextHighlighter,
    RapidChoice,
    OEPrompt,
    PermissionPrompt
} from './questions';
import { JOURNEY_STEPS } from './questions/Journey_NEW/types';

interface QuestionSwitchProps {
    current: Task;
    submitAnswer: (val: UserMessage) => void;
    scrollToBottom: () => void;
    timestamps: TimestampCollection;
    langId: string; // TODO: typing
    setBottomOffset: (offset?: number, forceScroll?: boolean) => void;
    src: string;
    isEmbedded?: boolean;
    projectInputSettings?: ProjectInputSettings;
    isDelayed?: boolean;
}

const QuestionSwitch: React.FC<QuestionSwitchProps> = (props) => {
    const { current: task, isDelayed } = props;

    // disable question switch when _any_ of these conditions are met:
    if (
        isDelayed || // delay showing the question
        !task || // - no task yet [beginning]
        task.type === TTYPES.MESSAGE || // no question being asked
        task.messageLog.some(
            (m) => m.from === 'user' && m.type != UMTYPES.POPUP
        ) // user just answered; unless popup answer
    ) {
        return null;
    }

    const createModalRoot = () => {
        const parent = document.getElementById('bot-container');
        const existingRoot: any = parent.querySelector('#bot-modal-root');
        if (!existingRoot) {
            const newRoot = document.createElement('div');
            newRoot.className = 'modal-root';
            parent.style.position = 'relative';
            newRoot.id = 'bot-modal-root';
            parent.prepend(newRoot);
        }
    };

    // QUESTION SWITCH BOARD: render the appropriate question component
    const { question } = task;
    const lastMsg = last(task.messageLog);
    const previousAnswer = task.answer;
    const voicePlusEnabled = props.projectInputSettings?.voicePlusEnabled;
    /** Question-level inputSettings used if available, else project-level used. */
    const appliedInputSettings = question.inputSettings?.customInputTypes
        ? question.inputSettings
        : props.projectInputSettings;

    switch (question.type) {
        case QTYPES.OPEN:
        case QTYPES.PII:
            if (voicePlusEnabled) {
                return (
                    <OEPrompt
                        previousAnswer={previousAnswer}
                        setBottomOffset={props.setBottomOffset}
                        question={question}
                        isEmbedded={props.isEmbedded}
                        handleAnswer={(
                            text: string,
                            uploadURLs: UploadURL[],
                            transcriptTexts: string[],
                            isTranscriptionEdited: boolean
                        ) => {
                            const referenceOption =
                                question.options?.length && question.options[0];
                            props.submitAnswer({
                                text,
                                uploadURLs,
                                transcriptTexts,
                                isTranscriptionEdited,
                                uid: uuid(),
                                type: UMTYPES.TEXT,
                                pii: question.type == QTYPES.PII,
                                referencedOptionID:
                                    referenceOption.id > 0
                                        ? referenceOption.id
                                        : referenceOption.value,
                                from: 'user',
                                timestamps: {
                                    serverSend: lastMsg.timestamps.serverSend,
                                    clientReceive:
                                        props.timestamps.clientReceive,
                                    clientSend: new Date().toJSON()
                                },
                                parentType: question?.parentType,
                                questionId: question.id,
                                questionValue: question.value,
                                targetLabel: question.targetLabelToProbe,
                                allowUserEdit: question.allowUserEdit
                            });
                        }}
                        langId={props.langId}
                        inputSettings={appliedInputSettings}
                    />
                );
            }
            return (
                <TextPrompt
                    previousAnswer={previousAnswer}
                    setBottomOffset={props.setBottomOffset}
                    question={question}
                    isEmbedded={props.isEmbedded}
                    handleAnswer={(text: string, uploadURLs: UploadURL[]) => {
                        const referenceOption =
                            question.options?.length && question.options[0];
                        props.submitAnswer({
                            text,
                            uploadURLs,
                            uid: uuid(),
                            type: UMTYPES.TEXT,
                            pii: question.type == QTYPES.PII,
                            referencedOptionID:
                                referenceOption.id > 0
                                    ? referenceOption.id
                                    : referenceOption.value,
                            from: 'user',
                            timestamps: {
                                serverSend: lastMsg.timestamps.serverSend,
                                clientReceive: props.timestamps.clientReceive,
                                clientSend: new Date().toJSON()
                            },
                            parentType: question?.parentType,
                            questionId: question.id,
                            questionValue: question.value,
                            targetLabel: question.targetLabelToProbe,
                            allowUserEdit: question.allowUserEdit
                        });
                    }}
                    langId={props.langId}
                />
            );
            break;

        case QTYPES.TEXT_HIGHLIGHTER:
        case QTYPES.TEXT_HIGHLIGHTER_READONLY:
            createModalRoot();
            return (
                <TextHighlighter
                    question={question}
                    readonly={
                        question.type === QTYPES.TEXT_HIGHLIGHTER_READONLY
                    }
                    setBottomOffset={props.setBottomOffset}
                    langId={props.langId}
                    handleAnswer={(selectedOptions: OptionData[]) => {
                        props.submitAnswer({
                            uid: uuid(),
                            type: UMTYPES.MULTI_SELECTION,
                            selectedOptions,
                            unselectedOptions: [], // not used for text highlighter
                            from: 'user',
                            timestamps: {
                                serverSend: lastMsg.timestamps.serverSend,
                                clientReceive: props.timestamps.clientReceive,
                                clientSend: new Date().toJSON()
                            },
                            questionId: question.id,
                            questionValue: question.value,
                            allowUserEdit: question.allowUserEdit,
                            questionType: question.type
                        });
                    }}
                />
            );
            break;

        case QTYPES.SCALE:
            return (
                <Scale
                    question={question}
                    setBottomOffset={props.setBottomOffset}
                    langId={props.langId}
                    previousAnswer={previousAnswer}
                    handleAnswer={(selectedOptionVal, specify) => {
                        let selectedOption;
                        switch (selectedOptionVal) {
                            case OTHER_OPTION_VALUE:
                                const pipingOther =
                                    question?.piping &&
                                    question?.options?.find(
                                        (opt) =>
                                            opt.value === OTHER_OPTION_VALUE
                                    );
                                selectedOption = pipingOther || {
                                    id: BUILTIN_OPTION_ID,
                                    value: OTHER_OPTION_VALUE,
                                    texts:
                                        question?.optionSettings?.otherOption
                                            ?.texts || [],
                                    optionKey: `${question.id}_${OTHER_OPTION_VALUE}`,
                                    specify
                                };
                                break;
                            case NA_OPTION_VALUE:
                            default:
                                selectedOption = question.options.find(
                                    (o) => selectedOptionVal == o.value
                                ) || {
                                    id: BUILTIN_OPTION_ID,
                                    value: NA_OPTION_VALUE,
                                    texts: question.notApplicableTexts
                                        ? question.notApplicableTexts
                                        : [],
                                    optionKey: `${question.id}_${NA_OPTION_VALUE}`
                                };
                        }
                        const unselectedOptions = question.options.filter(
                            (opt) => opt.value != selectedOptionVal
                        );
                        props.submitAnswer({
                            uid: uuid(),
                            type: UMTYPES.SINGLE_SELECTION,
                            selectedOption,
                            unselectedOptions, // probably not necessary for scale, but included for completeness
                            from: 'user',
                            selectedOptionType:
                                question.selectedOptionType as OPTION_TYPES,
                            timestamps: {
                                serverSend: lastMsg.timestamps.serverSend,
                                clientReceive: props.timestamps.clientReceive,
                                clientSend: new Date().toJSON()
                            },
                            questionId: question.id,
                            questionValue: question.value,
                            allowUserEdit: question.allowUserEdit
                        });
                    }}
                />
            );
            break;

        case QTYPES.LANGUAGE: // language prompt: special case of SingleChoice
            return (
                <SingleChoice
                    question={question}
                    langId={props.langId}
                    handleAnswer={(languageValue) => {
                        const selectedLanguageOption = question.options.find(
                            (option) => option.value == languageValue
                        );

                        const selectedLanguageLabel =
                            getStringFromMultimediaTexts(
                                selectedLanguageOption.texts,
                                languageValue
                            );

                        props.submitAnswer({
                            uid: uuid(),
                            type: UMTYPES.LANGUAGE_SELECTION,
                            questionId: SPECIAL_QIDS.LANGUAGE,
                            questionValue: LANGUAGE_QVALUE,
                            allowUserEdit: false,
                            selectedLanguage: languageValue,
                            selectedLanguageLabel,
                            timestamps: {
                                serverSend: props.timestamps.serverSend,
                                clientReceive: props.timestamps.clientReceive,
                                clientSend: new Date().toJSON()
                            },
                            from: 'user'
                        });
                    }}
                />
            );
            break;

        case QTYPES.RESUME: // resume prompt: special case of SingleChoice
            return (
                <SingleChoice
                    question={question}
                    langId={props.langId}
                    handleAnswer={(val) => {
                        props.submitAnswer({
                            uid: uuid(),
                            type: UMTYPES.RESUME_SELECTION,
                            questionId: SPECIAL_QIDS.RESUME,
                            questionValue: RESUME_QVALUE,
                            allowUserEdit: false,
                            resume: val == RESUME_OVALUE, // bool: 1 for resume; 0 for restart
                            timestamps: {
                                serverSend: props.timestamps.serverSend,
                                clientReceive: props.timestamps.clientReceive,
                                clientSend: new Date().toJSON()
                            },
                            from: 'user'
                        });
                    }}
                />
            );
            break;

        case QTYPES.SINGLE_CHOICE:
            return (
                <SingleChoice
                    question={question}
                    langId={props.langId}
                    previousAnswer={previousAnswer}
                    handleAnswer={(
                        selectedOptionValue: string,
                        specify?: string
                    ) => {
                        let selectedOption;
                        switch (selectedOptionValue) {
                            case OTHER_OPTION_VALUE:
                                const pipingOther =
                                    question?.piping &&
                                    question?.options?.find(
                                        (opt) =>
                                            opt.value === OTHER_OPTION_VALUE
                                    );
                                selectedOption = pipingOther || {
                                    ...question.optionSettings?.otherOption,
                                    id: BUILTIN_OPTION_ID,
                                    value: OTHER_OPTION_VALUE,
                                    texts:
                                        question?.optionSettings?.otherOption
                                            ?.texts || [],
                                    optionKey: `${question.id}_${OTHER_OPTION_VALUE}`,
                                    specify
                                };
                                break;
                            case NA_OPTION_VALUE:
                            default:
                                selectedOption = question.options.find(
                                    (o) => selectedOptionValue == o.value
                                ) || {
                                    id: BUILTIN_OPTION_ID,
                                    value: NA_OPTION_VALUE,
                                    texts: question.notApplicableTexts
                                        ? question.notApplicableTexts
                                        : [],
                                    optionKey: `${question.id}_${NA_OPTION_VALUE}`
                                };
                        }
                        const unselectedOptions = question.options.filter(
                            (opt) => opt.value != selectedOptionValue
                        );
                        props.submitAnswer({
                            uid: uuid(),
                            type: UMTYPES.SINGLE_SELECTION,
                            selectedOption,
                            unselectedOptions,
                            from: 'user',
                            selectedOptionType:
                                question.selectedOptionType as OPTION_TYPES,
                            questionId: question.id,
                            questionValue: question.value,
                            allowUserEdit: question.allowUserEdit,
                            timestamps: {
                                serverSend: lastMsg.timestamps.serverSend,
                                clientReceive: props.timestamps.clientReceive,
                                clientSend: new Date().toJSON()
                            }
                        });
                    }}
                />
            );
            break;

        case QTYPES.NPS:
            return (
                <NPS
                    question={question}
                    langId={props.langId}
                    handleAnswer={(option) => {
                        props.submitAnswer({
                            uid: uuid(),
                            type: UMTYPES.SINGLE_SELECTION,
                            selectedOption: option,
                            unselectedOptions: [], // @TODO
                            from: 'user',
                            questionId: question.id,
                            questionValue: question.value,
                            allowUserEdit: question.allowUserEdit,
                            timestamps: {
                                serverSend: lastMsg.timestamps.serverSend,
                                clientReceive: props.timestamps.clientReceive,
                                clientSend: new Date().toJSON()
                            }
                        });
                    }}
                />
            );
            break;

        case QTYPES.RANK_ORDER:
            return (
                <RankOrder
                    question={question}
                    langId={props.langId}
                    previousAnswer={previousAnswer}
                    handleAnswer={(
                        selectedOptVals: string[],
                        specify: string
                    ) => {
                        // get selectedOptions in SORTED order [according to how user selected them]
                        const selectedOptions = selectedOptVals.map(
                            (choice) => {
                                if (choice === OTHER_OPTION_VALUE) {
                                    const pipingOther =
                                        question?.piping &&
                                        question?.options?.find(
                                            (opt) =>
                                                opt.value === OTHER_OPTION_VALUE
                                        );
                                    return (
                                        pipingOther || {
                                            ...question.optionSettings
                                                ?.otherOption,
                                            specify
                                        }
                                    );
                                }
                                return question.options.find(
                                    (o) => o.value == choice
                                );
                            }
                        );
                        // unselectedOptions are unsorted
                        const unselectedOptions = question.options.filter(
                            (opt) => !selectedOptVals.includes(opt.value)
                        );
                        props.submitAnswer({
                            uid: uuid(),
                            type: UMTYPES.MULTI_SELECTION,
                            selectedOptions: selectedOptions[0] // Not Applicable button handling
                                ? selectedOptions
                                : [
                                      {
                                          id: BUILTIN_OPTION_ID,
                                          value: NA_OPTION_VALUE,
                                          texts: question.notApplicableTexts
                                              ? question.notApplicableTexts
                                              : [],
                                          optionKey: `${question.id}_${NA_OPTION_VALUE}`
                                      }
                                  ],
                            selectedOptionType:
                                question.selectedOptionType as OPTION_TYPES,
                            unselectedOptions,
                            from: 'user',
                            questionId: question.id,
                            questionValue: question.value,
                            questionType: QTYPES.RANK_ORDER,
                            allowUserEdit: question.allowUserEdit,
                            timestamps: {
                                serverSend: lastMsg.timestamps.serverSend,
                                clientReceive: props.timestamps.clientReceive,
                                clientSend: new Date().toJSON()
                            }
                        });
                    }}
                />
            );
            break;

        case QTYPES.AD_BUILDER:
            return (
                <AdBuilder
                    question={question}
                    langId={props.langId}
                    handleAnswer={(choices, dataURL) => {
                        // get selectedOptions in SORTED order [according to how user selected them]
                        const selectedOptions = choices.map((choice) =>
                            question.options.find((o) => o.value == choice)
                        );
                        props.submitAnswer({
                            uid: uuid(),
                            type: UMTYPES.AD_BUILDER,
                            selectedOptions,
                            unselectedOptions: [], // @TODO
                            dataURL,
                            from: 'user',
                            questionId: question.id,
                            questionValue: question.value,
                            allowUserEdit: question.allowUserEdit,
                            timestamps: {
                                serverSend: lastMsg.timestamps.serverSend,
                                clientReceive: props.timestamps.clientReceive,
                                clientSend: new Date().toJSON()
                            }
                        });
                    }}
                />
            );
            break;

        case QTYPES.MULTIPLE_CHOICE:
            // Custom form for multiple choice question
            return (
                <MultipleChoice
                    question={question}
                    langId={props.langId}
                    previousAnswer={previousAnswer}
                    handleAnswer={(optionKeys: string[], specify: string) => {
                        const selectedOptions = question.options.filter((opt) =>
                            optionKeys.includes(opt.optionKey)
                        );
                        const unselectedOptions = question.options.filter(
                            (opt) => !optionKeys.includes(opt.optionKey)
                        );

                        // determine whether to use piped or unpiped other opt
                        if (
                            optionKeys.includes(
                                `${question.id}_${OTHER_OPTION_VALUE}`
                            )
                        ) {
                            const pipingOther =
                                question?.piping &&
                                question?.options?.find(
                                    (opt) => opt.value === OTHER_OPTION_VALUE
                                );

                            selectedOptions.push(
                                pipingOther || {
                                    ...question.optionSettings?.otherOption,
                                    specify
                                }
                            );
                        }

                        props.submitAnswer({
                            uid: uuid(),
                            type: UMTYPES.MULTI_SELECTION,
                            // special Not Applicable button handling
                            selectedOptions: selectedOptions[0] // Not Applicable button handling
                                ? selectedOptions
                                : [
                                      {
                                          id: BUILTIN_OPTION_ID,
                                          value: NA_OPTION_VALUE,
                                          texts: question.notApplicableTexts
                                              ? question.notApplicableTexts
                                              : [],
                                          optionKey: `${question.id}_${NA_OPTION_VALUE}`
                                      }
                                  ],
                            selectedOptionType:
                                question.selectedOptionType as OPTION_TYPES,
                            unselectedOptions,
                            from: 'user',
                            questionId: question.id,
                            questionValue: question.value,
                            questionType: QTYPES.MULTIPLE_CHOICE,
                            allowUserEdit: question.allowUserEdit,
                            timestamps: {
                                serverSend: lastMsg.timestamps.serverSend,
                                clientReceive: props.timestamps.clientReceive,
                                clientSend: new Date().toJSON()
                            }
                        });
                    }}
                />
            );
            break;

        case QTYPES.JOURNEY:
            createModalRoot();
            return (
                <Journey_NEW
                    question={question}
                    langId={props.langId}
                    mode={JOURNEY_STEPS.PREFILTER}
                    setBottomOffset={props.setBottomOffset}
                    scrollToBottom={props.scrollToBottom}
                    handleAnswer={(journeySteps, dataURL, journeyState) => {
                        props.submitAnswer({
                            uid: uuid(),
                            from: 'user',
                            type: UMTYPES.JOURNEY,
                            journeySteps,
                            journeyState,
                            dataURL,
                            questionId: question.id,
                            questionValue: question.value,
                            allowUserEdit: question.allowUserEdit,
                            timestamps: {
                                serverSend: lastMsg.timestamps.serverSend,
                                clientReceive: props.timestamps.clientReceive,
                                clientSend: new Date().toJSON()
                            }
                        });
                    }}
                />
            );

            break;
        // case QTYPES.JOURNEY_SELECT:
        //     return (
        //         <Journey_NEW
        //             question={question}
        //             langId={props.langId}
        //             mode={JOURNEY_STEPS.SELECT_STEPS}
        //             handleNotApplicableAnswer={() => {
        //                 props.submitAnswer({
        //                     uid: uuid(),
        //                     from: 'user',
        //                     type: UMTYPES.JOURNEY_SELECT,
        //                     journeySteps: [],
        //                     questionId: question.id,
        //                     questionValue: question.value,
        //                     allowUserEdit: question.allowUserEdit,
        //                     timestamps: {
        //                         serverSend: lastMsg.timestamps.serverSend,
        //                         clientReceive: props.timestamps.clientReceive,
        //                         clientSend: new Date().toJSON()
        //                     }
        //                 });
        //             }}
        //             handleAnswer={(journeySteps) => {
        //                 props.submitAnswer({
        //                     uid: uuid(),
        //                     from: 'user',
        //                     type: UMTYPES.JOURNEY_SELECT,
        //                     journeySteps,
        //                     questionId: question.id,
        //                     questionValue: question.value,
        //                     allowUserEdit: question.allowUserEdit,
        //                     timestamps: {
        //                         serverSend: lastMsg.timestamps.serverSend,
        //                         clientReceive: props.timestamps.clientReceive,
        //                         clientSend: new Date().toJSON()
        //                     }
        //                 });
        //             }}
        //         />
        //     );
        //     break;
        case QTYPES.JOURNEY_SELECT + '_multi':
            return (
                <Journey_NEW
                    question={question}
                    langId={props.langId}
                    mode={JOURNEY_STEPS.SELECT_STEPS}
                    maxSelect={20}
                    handleAnswer={(journeyAnswer) => {
                        props.submitAnswer({
                            uid: uuid(),
                            from: 'user',
                            type: UMTYPES.JOURNEY_SELECT,
                            journeySteps: journeyAnswer,
                            questionId: question.id,
                            questionValue: question.value,
                            allowUserEdit: question.allowUserEdit,
                            timestamps: {
                                serverSend: lastMsg.timestamps.serverSend,
                                clientReceive: props.timestamps.clientReceive,
                                clientSend: new Date().toJSON()
                            }
                        });
                    }}
                />
            );
            break;
        case QTYPES.VECTOR_MAP:
            const { langId } = props;
            const { notApplicableTexts } = question;
            const notApplicableText = getStringFromMultimediaTexts(
                notApplicableTexts,
                langId
            );
            return (
                <VectorMap
                    question={question}
                    langId={props.langId}
                    previousAnswer={previousAnswer}
                    handleAnswer={(region) => {
                        console.log(region);
                        const selectedOption: OptionData =
                            CANADA_PROVINCE_OPTION_IDS[region]
                                ? {
                                      // TODO: reserve unique option IDs for vector map!
                                      id: CANADA_PROVINCE_OPTION_IDS[region],
                                      value: region,
                                      texts: [
                                          {
                                              language: props.langId,
                                              contents: [
                                                  {
                                                      type: MULTIMEDIA_TYPES.TEXT,
                                                      text: region
                                                  }
                                              ]
                                          }
                                      ]
                                  }
                                : region === notApplicableText
                                ? {
                                      id: BUILTIN_OPTION_ID,
                                      value: NA_OPTION_VALUE,
                                      texts: [
                                          {
                                              language: props.langId,
                                              contents: [
                                                  {
                                                      type: MULTIMEDIA_TYPES.TEXT,
                                                      text: region
                                                  }
                                              ]
                                          }
                                      ],
                                      optionKey: `${question.id}_${NA_OPTION_VALUE}`
                                  }
                                : {
                                      id: -1,
                                      value: region,
                                      texts: [
                                          {
                                              language: props.langId,
                                              contents: [
                                                  {
                                                      type: MULTIMEDIA_TYPES.TEXT,
                                                      text: region
                                                  }
                                              ]
                                          }
                                      ]
                                  };
                        props.submitAnswer({
                            uid: uuid(),
                            from: 'user',
                            type: UMTYPES.VECTOR_MAP,
                            region: selectedOption,
                            questionId: question.id,
                            questionValue: question.value,
                            allowUserEdit: question.allowUserEdit,
                            timestamps: {
                                serverSend: lastMsg.timestamps.serverSend,
                                clientReceive: props.timestamps.clientReceive,
                                clientSend: new Date().toJSON()
                            }
                        });
                    }}
                />
            );
            break;
        case QTYPES.ICONS:
            return (
                <IconsRating
                    question={question}
                    langId={props.langId}
                    setBottomOffset={props.setBottomOffset}
                    isEmbedded={props.isEmbedded}
                    previousAnswer={previousAnswer}
                    handleAnswer={(val) => {
                        props.submitAnswer({
                            uid: uuid(),
                            type: UMTYPES.ICONS_RATING,
                            answer: val,
                            from: 'user',
                            questionId: question.id,
                            questionValue: question.value,
                            allowUserEdit: question.allowUserEdit,
                            timestamps: {
                                serverSend: lastMsg.timestamps.serverSend,
                                clientReceive: props.timestamps.clientReceive,
                                clientSend: new Date().toJSON()
                            }
                        });
                    }}
                />
            );
            break;
        case QTYPES.POPUP_CARD:
            return (
                <PopupCard
                    isMulti={false}
                    question={question}
                    langId={props.langId}
                    scrollToBottom={props.scrollToBottom}
                    setBottomOffset={props.setBottomOffset}
                    isEmbedded={props.isEmbedded}
                    handleAnswer={(option: OptionData) => {
                        const unselectedOptions = question.options.filter(
                            (opt) => opt.value != option?.value
                        );
                        props.submitAnswer({
                            uid: uuid(),
                            type: UMTYPES.POPUP,
                            selectedOption: option // Not Applicable button handling
                                ? option
                                : {
                                      id: BUILTIN_OPTION_ID,
                                      value: NA_OPTION_VALUE,
                                      texts: question.notApplicableTexts
                                          ? question.notApplicableTexts
                                          : [],
                                      optionKey: `${question.parent}_${NA_OPTION_VALUE}`
                                  },
                            unselectedOptions,
                            from: 'user',
                            questionId: question.id,
                            questionValue: question.value,
                            allowUserEdit: question.allowUserEdit,
                            timestamps: {
                                serverSend: lastMsg.timestamps.serverSend,
                                clientReceive: props.timestamps.clientReceive,
                                clientSend: new Date().toJSON()
                            }
                        });
                    }}
                />
            );
            break;
        case QTYPES.MULTI_POPUP_CARD:
            return (
                <PopupCard
                    isMulti={true}
                    question={question}
                    langId={props.langId}
                    scrollToBottom={props.scrollToBottom}
                    setBottomOffset={props.setBottomOffset}
                    isEmbedded={props.isEmbedded}
                    handleAnswer={(values: string[], specify?: string) => {
                        console.log(values);
                        let selectedOptions;
                        if (values?.length) {
                            selectedOptions = question.options.filter(
                                (o: OptionData) => values.includes(o.value)
                            );
                            values.includes(OTHER_OPTION_VALUE) &&
                                selectedOptions.push({
                                    ...question.optionSettings?.otherOption,
                                    specify
                                });
                        } else {
                            selectedOptions = [
                                {
                                    id: BUILTIN_OPTION_ID,
                                    value: NA_OPTION_VALUE,
                                    texts: question.notApplicableTexts
                                        ? question.notApplicableTexts
                                        : [],
                                    optionKey: `${question.parent}_${NA_OPTION_VALUE}`
                                }
                            ];
                        }
                        const unselectedOptions = question.options.filter(
                            (opt) => !values.includes(opt.value)
                        );

                        props.submitAnswer({
                            uid: uuid(),
                            type: UMTYPES.MULTIPLE_POPUP,
                            selectedOptions,
                            unselectedOptions,
                            from: 'user',
                            questionId: question.id,
                            questionValue: question.value,
                            allowUserEdit: question.allowUserEdit,
                            timestamps: {
                                serverSend: lastMsg.timestamps.serverSend,
                                clientReceive: props.timestamps.clientReceive,
                                clientSend: new Date().toJSON()
                            }
                        });
                    }}
                />
            );
        case QTYPES.TRADE_OFF:
            return (
                <TradeOff
                    question={question}
                    langId={props.langId}
                    scrollToBottom={props.scrollToBottom}
                    setBottomOffset={props.setBottomOffset}
                    isEmbedded={props.isEmbedded}
                    handleAnswer={(selectedOption, dislikes, log) => {
                        props.submitAnswer({
                            uid: uuid(),
                            type: UMTYPES.TRADE_OFF,
                            value: selectedOption
                                ? selectedOption
                                : {
                                      id: BUILTIN_OPTION_ID,
                                      value: NA_OPTION_VALUE,
                                      texts: [],
                                      optionKey: `${question.id}_${NA_OPTION_VALUE}`
                                  },
                            dislikes,
                            log,
                            from: 'user',
                            questionId: question.id,
                            questionValue: question.value,
                            allowUserEdit: question.allowUserEdit,
                            timestamps: {
                                serverSend: lastMsg.timestamps.serverSend,
                                clientReceive: props.timestamps.clientReceive,
                                clientSend: new Date().toJSON()
                            },
                            optionType: question.isRichText
                                ? OPTION_TYPES.TEXT_ONLY
                                : OPTION_TYPES.IMAGE_ONLY
                        });
                    }}
                />
            );
            break;

        case QTYPES.HOTSPOT:
            createModalRoot();

            return (
                <Hotspot
                    question={question}
                    langId={props.langId}
                    scrollToBottom={props.scrollToBottom}
                    setBottomOffset={props.setBottomOffset}
                    isEmbedded={props.isEmbedded}
                    handleAnswer={(
                        selectedOptions: OptionData[],
                        dataURL,
                        imageWidth
                    ) => {
                        const unselectedOptions = question.options.filter(
                            (opt) =>
                                !selectedOptions.find(
                                    (opt2) => opt2.value == opt.value
                                )
                        );
                        props.submitAnswer({
                            uid: uuid(),
                            from: 'user',
                            selectedOptions,
                            unselectedOptions,
                            type: UMTYPES.MULTI_SELECTION,
                            questionId: question.id,
                            questionValue: question.value,
                            questionType: QTYPES.HOTSPOT,
                            allowUserEdit: question.allowUserEdit,
                            timestamps: {
                                serverSend: lastMsg.timestamps.serverSend,
                                clientReceive: props.timestamps.clientReceive,
                                clientSend: new Date().toJSON()
                            },
                            dataURL,
                            imageWidth
                        });
                    }}
                />
            );
            break;
        case QTYPES.TREEMAN:
            createModalRoot();
            return (
                <Hotspot
                    question={question}
                    langId={props.langId}
                    scrollToBottom={props.scrollToBottom}
                    setBottomOffset={props.setBottomOffset}
                    isEmbedded={props.isEmbedded}
                    handleAnswer={(
                        selectedOption: OptionData,
                        dataURL: string,
                        imageWidth: number
                    ) => {
                        const unselectedOptions = question.options.filter(
                            (opt) => opt.value !== selectedOption.value
                        );
                        props.submitAnswer({
                            uid: uuid(),
                            from: 'user',
                            selectedOption,
                            unselectedOptions,
                            type: UMTYPES.SINGLE_SELECTION,
                            questionId: question.id,
                            questionValue: question.value,
                            questionType: QTYPES.TREEMAN,
                            allowUserEdit: question.allowUserEdit,
                            timestamps: {
                                serverSend: lastMsg.timestamps.serverSend,
                                clientReceive: props.timestamps.clientReceive,
                                clientSend: new Date().toJSON()
                            },
                            dataURL,
                            imageWidth
                        });
                    }}
                />
            );
            break;
        case QTYPES.DEPARTING_MESSAGE:
            const sendDeparting = () => {
                props.submitAnswer({
                    uid: uuid(),
                    type: UMTYPES.DEPARTING,
                    status: question.status, // QUOTA_STATUS
                    src: props.src,
                    from: 'user',
                    questionId: question.id,
                    questionValue: question.value,
                    allowUserEdit: question.allowUserEdit,
                    timestamps: {
                        serverSend: lastMsg.timestamps.serverSend,
                        clientReceive: props.timestamps.clientReceive,
                        clientSend: new Date().toJSON()
                    },
                    confirm: question.showContinueButton
                });
            };
            return (
                <Multimedia
                    question={question}
                    confirm={question.confirm}
                    onAck={sendDeparting}
                    langId={props.langId}
                />
            );
            break;

        case QTYPES.MULTIMEDIA:
            const handleMultimediaComplete = () => {
                // handle submit
                props.submitAnswer({
                    uid: uuid(),
                    type: UMTYPES.ACK,
                    from: 'user',
                    questionId: question.id,
                    questionValue: question.value,
                    allowUserEdit: question.allowUserEdit,
                    timestamps: {
                        serverSend: lastMsg.timestamps.serverSend,
                        clientReceive: props.timestamps.clientReceive,
                        clientSend: new Date().toJSON()
                    },
                    confirm: question.showContinueButton
                });
            };

            return (
                <Multimedia
                    question={question}
                    confirm={question.showContinueButton}
                    onAck={handleMultimediaComplete}
                    langId={props.langId}
                />
            );
            break;
        case QTYPES.PERMISSION_PROMPT:
            const handlePermissionPromptComplete = () => {
                // handle submit
                props.submitAnswer({
                    uid: uuid(),
                    type: UMTYPES.ACK,
                    from: 'user',
                    questionId: question.id,
                    questionValue: question.value,
                    allowUserEdit: question.allowUserEdit,
                    timestamps: {
                        serverSend: lastMsg.timestamps.serverSend,
                        clientReceive: props.timestamps.clientReceive,
                        clientSend: new Date().toJSON()
                    },
                    confirm: question.showContinueButton
                });
            };
            return (
                <PermissionPrompt
                    question={question}
                    confirm={question.showContinueButton}
                    onAck={handlePermissionPromptComplete}
                    langId={props.langId}
                    scrollToBottom={props.scrollToBottom}
                />
            );
            break;
        case QTYPES.VIDEO_ANNOTATION:
            return (
                <VideoAnnotation
                    question={question}
                    langId={props.langId}
                    handleAnswer={(annotations) => {
                        props.submitAnswer({
                            uid: uuid(),
                            type: UMTYPES.VIDEO_ANNOTATION,
                            from: 'user',
                            questionId: question.id,
                            questionValue: question.value,
                            allowUserEdit: question.allowUserEdit,
                            timestamps: {
                                serverSend: lastMsg.timestamps.serverSend,
                                clientReceive: props.timestamps.clientReceive,
                                clientSend: new Date().toJSON()
                            },
                            annotations: annotations
                        });
                    }}
                />
            );
        case QTYPES.VIRTUAL:
            const handleAnswer = () => {
                props.submitAnswer({
                    uid: uuid(),
                    type: UMTYPES.MULTI_SELECTION,
                    from: 'user',
                    questionId: question.id,
                    questionValue: question.value,
                    allowUserEdit: question.allowUserEdit,
                    // The answer for QTYPES.VIRTUAL is question.options which are already filtered by option conditions
                    selectedOptions: question.options[0] // Not Applicable button handling
                        ? question.options
                        : [
                              {
                                  id: BUILTIN_OPTION_ID,
                                  value: NA_OPTION_VALUE,
                                  texts: question?.notApplicableTexts
                                      ? question?.notApplicableTexts
                                      : [],
                                  optionKey: `${question.id}_${NA_OPTION_VALUE}`
                              }
                          ],
                    unselectedOptions: [], // by definition, virtual q cannot have any unselectedOptions
                    questionType: QTYPES.VIRTUAL,
                    timestamps: {
                        serverSend: lastMsg.timestamps.serverSend,
                        clientReceive: props.timestamps.clientReceive,
                        clientSend: new Date().toJSON()
                    }
                });
            };
            return (
                <VirtualQuestion
                    question={question}
                    handleAnswer={handleAnswer}
                    langId={props.langId}
                />
            );
            break;
        case QTYPES.RAPID_CHOICE:
            return (
                <RapidChoice
                    setBottomOffset={props.setBottomOffset}
                    scrollToBottom={props.scrollToBottom}
                    isEmbedded={props.isEmbedded}
                    handleAnswer={(optionIDs: number[]) => {
                        const selectedOptions = question.options.filter(
                            (o: OptionData) => optionIDs.includes(o.id)
                        );
                        const unselectedOptions = question.options.filter(
                            (opt) => !optionIDs.includes(opt.id)
                        );
                        props.submitAnswer({
                            uid: uuid(),
                            type: UMTYPES.MULTI_SELECTION,
                            selectedOptions: selectedOptions[0] // Not Applicable button handling
                                ? selectedOptions
                                : [
                                      {
                                          id: BUILTIN_OPTION_ID,
                                          value: NA_OPTION_VALUE,
                                          texts: question.notApplicableTexts
                                              ? question.notApplicableTexts
                                              : [],
                                          optionKey: `${question.id}_${NA_OPTION_VALUE}`
                                      }
                                  ],
                            selectedOptionType:
                                question.selectedOptionType as OPTION_TYPES,
                            from: 'user',
                            unselectedOptions,
                            questionId: question.id,
                            questionValue: question.value,
                            questionType: QTYPES.RAPID_CHOICE,
                            allowUserEdit: question.allowUserEdit,
                            timestamps: {
                                serverSend: lastMsg.timestamps.serverSend,
                                clientReceive: props.timestamps.clientReceive,
                                clientSend: new Date().toJSON()
                            }
                        });
                    }}
                    question={question}
                    langId={props.langId}
                />
            );
            break;
        case QTYPES.NUMERIC:
            return (
                <Numeric
                    handleAnswer={(
                        value: number | null,
                        formattedValue?: string
                    ) => {
                        props.submitAnswer({
                            uid: uuid(),
                            type: UMTYPES.NUMERIC,
                            value,
                            formattedValue,
                            from: 'user',
                            questionId: question.id,
                            questionValue: question.value,
                            questionType: QTYPES.NUMERIC,
                            allowUserEdit: question.allowUserEdit,
                            timestamps: {
                                serverSend: lastMsg.timestamps.serverSend,
                                clientReceive: props.timestamps.clientReceive,
                                clientSend: new Date().toJSON()
                            }
                        });
                    }}
                    question={question}
                    langId={props.langId}
                />
            );
            break;
        default:
            console.error(question);
            throw 'unsupport custom question input: ' + question.type;
    }
};

export default QuestionSwitch;
