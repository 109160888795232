import * as React from 'react';
import {
    InputWithOptionImages,
    ImageLoadState,
    PropsWithOptionQ
} from './_InputWithOptionImages';
import NotApplicableButton from '../components/NotApplicableButton';
import ContinueButton from '../components/ContinueButton';
import styled from 'styled-components';
import {
    getStringFromMultimediaTexts,
    getTranslation
} from '@nexxt/common/services/TextService';
const { translate } = require('react-i18next');
import * as cx from 'classnames';
import {
    KEY_NAME,
    NA_OPTION_VALUE,
    OTHER_OPTION_VALUE,
    RESUME_OVALUE
} from '@nexxt/common/constants';
import {
    OPTION_TYPES,
    MultipleOptionsAnswer,
    IMAGE_OPTION_SIZING
} from '@nexxt/common/types';
import { addOtherToOptions, calculateNewMaxWidth } from '../utils/questions';
import SpecifyEditor from '../components/SpecifyEditor';
import { isMobile } from 'react-device-detect';
import { getAutoOptionsPerRow } from '@nexxt/common/utils/question';
import OptionText from '../components/OptionText';

const RankOption = styled.span`
    position: absolute;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    width: 25px;
    height: 25px;
    background: ${(p) => p.theme.userBubble};
    display: block;
    text-align: center;
    transform: translate(-50%, -100%);
    -webkit-transform: translate(-50%, -100%);
    -moz-transform: translate(-50%, -100%);
    border-radius: 50%;
    border: 1px solid #fff;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    &.img {
        top: 35px;
        left: 25px;
    }
`;

type Selections = string[];

interface Props extends PropsWithOptionQ {
    handleAnswer: (s: Selections, other?: string) => void;
}

interface State extends ImageLoadState {
    selections: Selections;
    showSpecify: boolean;
    specifyText: string;
    submitting: boolean;
    showError: boolean;
}

@translate('translation')
export class RankOrder extends InputWithOptionImages<Props, State> {
    state: State = {
        selections: [],
        imageLoadCount: 0,
        specifyText: '',
        showSpecify: false,
        submitting: false,
        showError: false
    };

    componentDidMount() {
        if (this.props.previousAnswer) {
            const selections = (
                this.props.previousAnswer as MultipleOptionsAnswer
            ).values
                .filter((ans) => ans.value !== NA_OPTION_VALUE)
                .map((ans) => ans.value);

            this.setState({ selections });
        }
    }

    toggleChoice = (val: string) => {
        const isCurrOther = val === OTHER_OPTION_VALUE;
        const showSpecify =
            isCurrOther && this.props.question.optionSettings?.showSpecify
                ? !this.state.showSpecify
                : this.state.showSpecify;
        const index = this.state.selections.indexOf(val);
        if (index > -1) {
            // remove from selection
            const s = this.state.selections.slice();
            s.splice(index, 1);
            this.setState({ selections: s, showSpecify });
        } else {
            // only allow selection if max hasn't been reached
            if (
                this.state.selections.length <
                (this.props.question.max || this.props.question.options.length)
            ) {
                const s = this.state.selections.slice();
                s.push(val);
                this.setState({ selections: s, showSpecify });
            }
        }
    };

    handleSubmitAnswer = (selections, other?) => {
        const { showSpecify, specifyText, submitting } = this.state;
        if (showSpecify && !specifyText) {
            this.setState({ showError: true });
            return;
        }
        if (submitting) return;
        this.setState({ submitting: true });
        this.props.handleAnswer(selections, other);
        this.setState({ submitting: false });
    };

    render() {
        const { langId, t, question } = this.props;
        const {
            showNotApplicableOption,
            notApplicableTexts,
            continueTexts,
            min,
            max,
            imageDisplay,
            selectedOptionType,
            optionSettings
        } = question;
        const { showSpecify, specifyText, showError } = this.state;

        const notApplicableText = getStringFromMultimediaTexts(
            notApplicableTexts,
            langId
        );
        const continueText = getStringFromMultimediaTexts(
            continueTexts,
            langId
        );

        // get circle or not from question option
        const isCircle = imageDisplay === 'circle' ? true : false;
        const options = addOtherToOptions(question);

        const s = pickStatus(
            this.state.selections.length,
            min,
            max || options.length
        );

        if (!this.props.question) {
            return <div>Loading...</div>;
        } else {
            const isAnyMobile =
                isMobile ||
                window.innerWidth < 700 ||
                document.getElementById('bot-preview')?.scrollWidth < 700;

            const columnsFromOptionSettings = isAnyMobile
                ? optionSettings?.numColumnsMobile
                : optionSettings?.numColumns;

            const columns =
                columnsFromOptionSettings >= 1
                    ? columnsFromOptionSettings
                    : getAutoOptionsPerRow(
                          optionSettings?.size,
                          selectedOptionType,
                          isAnyMobile
                      );

            // check if options are text-only
            const textOnly = selectedOptionType === OPTION_TYPES.TEXT_ONLY;
            const hideText = selectedOptionType === OPTION_TYPES.IMAGE_ONLY;

            const disabled = s !== PERFECT; // TODO: what is happening here..?

            // Check if custom size is selected
            const isCustomSizing = IMAGE_OPTION_SIZING[
                question.optionSettings?.size
            ]
                ? true
                : false;
            // Checks if custom option size & custom number of columns
            const isFullCustomSizing =
                isCustomSizing && columnsFromOptionSettings >= 1;
            return (
                <div className="">
                    <ul
                        className={cx(
                            `multi-options-container multiple-options ranking-order`,
                            {
                                'two-columns': columns === 2,
                                [`pure-columns-${columns}`]:
                                    !textOnly && !isCustomSizing,
                                'custom-size': isCustomSizing

                                // [`with-image`]: !textOnly
                            }
                        )}
                        style={
                            isFullCustomSizing
                                ? {
                                      maxWidth: calculateNewMaxWidth(
                                          columnsFromOptionSettings,
                                          question.optionSettings?.size,
                                          // Border-> 4px, Padding outer options -> 4px
                                          8
                                      ),
                                      alignItems: 'center'
                                  }
                                : { alignItems: 'center' }
                        }
                    >
                        {options.map((option) => {
                            const index = this.state.selections.indexOf(
                                option.value
                            );
                            const selected = index > -1;
                            const optionDisabled = option.disabled || false;
                            return (
                                <li
                                    onClick={() =>
                                        !optionDisabled &&
                                        this.toggleChoice(option.value)
                                    }
                                    onKeyDown={(e) => {
                                        if (
                                            e.key === KEY_NAME.SPACE ||
                                            e.key === KEY_NAME.ENTER
                                        ) {
                                            this.toggleChoice(option.value);
                                        }
                                    }}
                                    tabIndex={0}
                                    key={option.id}
                                    className={cx(
                                        `multiple-option ${
                                            isCustomSizing
                                                ? ` custom-sizing custom-sizing--${question.optionSettings?.size}`
                                                : `pure-u-1-${columns}`
                                        }`,
                                        {
                                            'text-image': !textOnly,
                                            textOnly,
                                            'selected acitve': selected,
                                            disabled: optionDisabled
                                        }
                                    )}
                                >
                                    <div className="option">
                                        {!selected ? null : (
                                            <RankOption
                                                className={cx(
                                                    `${!textOnly ? 'img' : ''}`,
                                                    {
                                                        cirlce: isCircle
                                                    }
                                                )}
                                            >
                                                {index + 1}
                                            </RankOption>
                                        )}
                                        {!textOnly && (
                                            <span
                                                className={cx('option-img', {
                                                    circle: isCircle
                                                })}
                                            >
                                                <img src={option.pictureURL} />
                                            </span>
                                        )}

                                        {hideText ? (
                                            ''
                                        ) : option.texts ? (
                                            <OptionText
                                                isRichText={question.isRichText}
                                                className="option-text"
                                                option={option}
                                                langId={langId}
                                            />
                                        ) : (
                                            <p className="option-text">
                                                {t(
                                                    'PLACEHOLDER.PLACEHOLDER_TEXT'
                                                )}
                                            </p>
                                        )}
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                    {showSpecify ? (
                        <SpecifyEditor
                            onSubmit={() =>
                                this.handleSubmitAnswer(
                                    this.state.selections,
                                    specifyText
                                )
                            }
                            specifyText={specifyText}
                            handleChangeSpecifyText={(specifyText) =>
                                this.setState({ specifyText })
                            }
                            showError={showError}
                            t={this.props.t}
                        />
                    ) : null}
                    <Validator
                        n={this.state.selections.length}
                        min={min}
                        max={max || options.length}
                        t={t}
                    />

                    <div className="bottom-select-links ranking-order-btn bot-width">
                        {showNotApplicableOption && (
                            <NotApplicableButton
                                onClick={() =>
                                    this.handleSubmitAnswer([NA_OPTION_VALUE])
                                }
                                text={notApplicableText}
                            />
                        )}

                        <ContinueButton
                            onClick={() => {
                                this.handleSubmitAnswer(
                                    this.state.selections,
                                    specifyText
                                );
                            }}
                            disabled={disabled}
                            text={continueText}
                        />
                    </div>
                </div>
            );
        }
    }
}

const PERFECT = 0;

function Validator({
    n,
    min,
    max,
    t
}: {
    n: number;
    min: number;
    max: number;
    t: (text: string, options?: Record<string, number>) => string;
}) {
    if (n === 0) {
        if (min === max) {
            return (
                <div className="multi-choice-help">
                    {getTranslation(t, 'LABEL.VALIDATE.SELECT_EXACTLY', {
                        max
                    })}
                </div>
            );
        } else {
            return (
                <div className="multi-choice-help">
                    {getTranslation(t, 'LABEL.VALIDATE.SELECT_MIN_MAX', {
                        min,
                        max
                    })}
                </div>
            );
        }
    } else if (min <= n) {
        if (n === max) {
            return (
                <div className="multi-choice-help">
                    {getTranslation(t, 'LABEL.VALIDATE.SATISFIED')}
                </div>
            );
        } else {
            return (
                <div className="multi-choice-help">
                    {getTranslation(t, 'LABEL.VALIDATE.CONTINUE_OR_MORE', {
                        more: max - n
                    })}
                </div>
            );
        }
    } else {
        return (
            <div className="multi-choice-help">
                {getTranslation(t, 'LABEL.VALIDATE.MORE', { more: min - n })}
            </div>
        );
    }
}

function pickStatus(numPicks: number, min: number, max): number {
    if (numPicks < min) {
        return min - numPicks;
    } else if (numPicks > max) {
        return max - numPicks;
    } else {
        return PERFECT;
    }
}
