import * as React from 'react';
import {
    ConnectDragSource,
    DragSource,
    DragSourceConnector,
    DragSourceMonitor,
    DragSourceSpec,
    XYCoord
} from 'react-dnd';
import ItemTypes from '../constant';
import { Color } from 'csstype';

export interface IProps {
    id: any;
    text: string;
    index: number;
    color?: Color;
    bgColor?: Color;
    type?: string;
}

class JourneyOption extends React.Component<IProps & DragSourceCollectedProps> {
    public render() {
        const { text, isDragging, connectDragSource, sourceOffset } =
            this.props;
        // when dragging the item
        const opacity = isDragging ? 0 : 1;
        const otherStyle: React.CSSProperties = {
            opacity
        };
        if (this.props.color) {
            otherStyle.border = '1px solid ' + this.props.color;
        }

        return (
            <React.Fragment>
                {connectDragSource(
                    <div
                        className="journeyq-option"
                        ref={connectDragSource as any}
                        style={{ ...otherStyle }}
                    >
                        {text}
                    </div>
                )}
                <DragPreview
                    isDragging={isDragging}
                    sourceOffset={sourceOffset}
                    text={text}
                    style={otherStyle}
                />
            </React.Fragment>
        );
    }
}
const DragPreview = ({ isDragging, sourceOffset, text, style }: any) => {
    // If not dragging completely remove the element
    if (!isDragging) {
        return null;
    }
    const newStyle: React.CSSProperties = {
        ...style,
        opacity: 1,
        zIndex: 1000,
        position: 'fixed',
        top: 0,
        left: 0,
        transform: sourceOffset
            ? `translate(${sourceOffset.x}px, ${sourceOffset.y}px)`
            : ''
    };
    return (
        <div className="journeyq-option" style={newStyle}>
            {text}
        </div>
    );
};

const cardSource: DragSourceSpec<IProps, IJourneyOptionObject> = {
    beginDrag(props: IProps) {
        return {
            id: props.id,
            index: props.index,
            text: props.text,
            color: props.color,
            bgColor: props.color
        };
    }
};

export default DragSource<IProps, DragSourceCollectedProps>(
    (props: IProps) => props.type || ItemTypes.CARD, // ItemTypes.CARD,
    cardSource,
    (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
        sourceOffset: monitor.getClientOffset()
    })
)(JourneyOption as any) as any;

export interface IJourneyOptionObject {
    id: any;
    text: string;
    index: number;
    type?: string;
    color?: Color;
    bgColor?: Color;
}

interface DragSourceCollectedProps {
    isDragging: boolean;
    connectDragSource: ConnectDragSource;
    sourceOffset?: XYCoord;
}
