import * as React from 'react';
import { SlideInUpDiv } from '../../style/Animations';
import * as _ from 'lodash';

interface JourneyContinueProps {
    langId: string;
    instructionalTexts: string[];
    mediaURL?: string;
    buttons: {
        onClick: () => void;
        text: string | JSX.Element;
        disabled: boolean;
        className?: string;
    }[];
}

export const JourneyContinue: React.FunctionComponent<JourneyContinueProps> = ({
    instructionalTexts,
    mediaURL,
    buttons
}: JourneyContinueProps) => {
    const renderButtons = () => {
        return buttons.map((button, i) => {
            return (
                <button
                    key={`button-${i}`}
                    className={`journeyq-button button-hg ${
                        button.className ? button.className : 'button-continue'
                    }`}
                    onClick={button.onClick}
                    disabled={button.disabled}
                >
                    {button.text}
                </button>
            );
        });
    };
    return (
        <>
            <div className="content from-bot bot-width">
                <div
                    className="plain-text popup-wrapper"
                    style={{ textAlign: 'center' }}
                >
                    <div className="popup-container-wrapper">
                        <SlideInUpDiv
                            key={'bottomText-followup'}
                            className="buttons-container"
                        >
                            <div style={{ marginBottom: '10px' }}>
                                <div className="multi-choice-help">
                                    {instructionalTexts?.map((t, i) => (
                                        <p key={i}>{t}</p>
                                    ))}
                                </div>
                                {mediaURL && (
                                    <img
                                        src={mediaURL}
                                        style={{ width: '400px' }}
                                    />
                                )}
                            </div>
                            <div className="footer">{renderButtons()}</div>
                        </SlideInUpDiv>
                    </div>
                </div>
            </div>
        </>
    );
};
