import * as React from 'react';
import * as classnames from 'classnames';
import { isMobile } from 'react-device-detect';
import { DEFAULT_VIDEOJS_OPTIONS } from '@nexxt/common/constants';
import * as _ from 'lodash';
import videojs from 'video.js';
import '../style/Annotation.scss';
import { getQueryStringValue } from '@nexxt/common/services/TextService';
import { OPTION_SIZE } from '@nexxt/common/types';

interface VideoPlayerProps {
    videoURL: string;
    soundCheck?: boolean;
    mediaOptions?: { size?: OPTION_SIZE; autoplay?: boolean };
    customControlBar?: boolean;
    maxHeight?: number; // 100,75,50 ect -> will be / 100 - Max height (% relative to screen) for the video
    onEnded?: () => void;
    setVideoPlayer?: (v: videojs.Player) => void;
}
const VideoPlayer = ({
    soundCheck,
    videoURL,
    mediaOptions,
    customControlBar = true,
    maxHeight = 70,
    onEnded,
    setVideoPlayer
}: VideoPlayerProps) => {
    const TEST_MODE = getQueryStringValue('testMode');
    let playerRef: videojs.Player = React.useRef();
    const videoRef = React.useRef<HTMLVideoElement>();
    const [isLoaded, setIsLoaded] = React.useState(false);

    React.useEffect(() => {
        const player = createVideoPlayer();
        if (setVideoPlayer) {
            // Returns the player obj for use outside this component
            setVideoPlayer(player);
        }
        playerRef = player;
        return () => {
            if (playerRef) {
                playerRef.dispose();
            }
        };
    }, []);
    const createVideoPlayer = () => {
        const autoPlay = _.hasIn(mediaOptions, 'autoplay')
            ? mediaOptions?.autoplay
            : DEFAULT_VIDEOJS_OPTIONS.autoplay;

        const videoPlayerOptions = {
            ...DEFAULT_VIDEOJS_OPTIONS,
            autoPlay,
            fluid: true,
            fill: false,
            bigPlayButton: isMobile,
            sources: [{ src: videoURL, type: 'video/mp4' }],
            controlBar: {
                ...DEFAULT_VIDEOJS_OPTIONS.controlBar,
                progressControl: {}
            }
        };

        if (customControlBar) {
            videoPlayerOptions['controlBar'] = {
                ...DEFAULT_VIDEOJS_OPTIONS.controlBar,
                progressControl: {
                    seekBar: TEST_MODE === 'true' ? true : false
                },
                fullscreenToggle: true
            };
        }

        return videojs(videoRef.current, videoPlayerOptions);
    };

    const calculateWidth = () => {
        if (videoRef.current) {
            const heightLimit = window.innerHeight * (maxHeight / 100);
            const originalHeight = videoRef.current.clientHeight;

            // If original Height > maxHeght -> use maxHeight instead
            const newHeight =
                originalHeight > heightLimit ? heightLimit : originalHeight;

            return (
                newHeight *
                (videoRef.current.clientWidth / videoRef.current.clientHeight)
            );
        }
    };
    const mediaSize = mediaOptions?.size?.toLowerCase();
    return (
        <div
            className={classnames('video-annotation--player--container', {
                [`media-size--${mediaSize}`]: !!mediaSize
            })}
            style={{
                width: calculateWidth(),
                visibility: isLoaded ? 'visible' : 'hidden'
            }}
        >
            <div className="c-player__screen" data-vjs-player="true">
                <video
                    ref={videoRef}
                    className="video-js vjs-big-play-centered"
                    style={{
                        display: `${soundCheck ? 'none' : 'block'}`
                    }}
                    loop={soundCheck}
                    disablePictureInPicture
                    controlsList="nodownload"
                    onEnded={onEnded}
                    playsInline
                    onLoadedMetadata={() => {
                        setIsLoaded(true);
                    }}
                />
            </div>
        </div>
    );
};

export default VideoPlayer;
