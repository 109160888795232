import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import { ToastContainer } from 'react-toastify';
import Tracker from '@openreplay/tracker';

// const tracker = new Tracker({ projectKey: process.env.OPEN_REPLAY_KEY });

console.log('App Version: ', process.env.VERSION);

// Embed BotApp in template from server-side EJS view render.
document.addEventListener('DOMContentLoaded', () => {
    const botContainer = document.getElementById('bot-container');
    const botAppRoot = document.createElement('div');
    botContainer.appendChild(botAppRoot);

    ReactDOM.render(
        <React.Fragment>
            <ToastContainer
                position="bottom-center"
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                closeButton={false}
                autoClose={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover={false}
                limit={1}
            />
            <App
            // note: OpenReplay tracking currently disabled
            // openReplayTracker={tracker}
            />
        </React.Fragment>,
        botAppRoot
    );
});
