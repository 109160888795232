import * as React from 'react';
import * as PropTypes from 'prop-types';
import { BaseInput, BaseProps } from './_BaseInput';
import Modal from '../components/Modal';
import { Stage, Layer, Group, Line } from 'react-konva';
import styled from 'styled-components';
import { darken } from 'polished';
import { CanvasImage } from '../components/CanvasImage';
import { getStringFromMultimediaTexts } from '@nexxt/common/services/TextService';
import '../style/RateImage.scss';

import { STATIC_STORAGE_URL } from '@nexxt/common/constants';
import { STROKE_TYPES, Stroke } from '@nexxt/common/types';

interface Props extends BaseProps {
    handleAnswer: (strokes: Stroke[], dataURL: string) => void;
}

interface State {
    strokes: Stroke[];
    isDrawing: boolean;
    highlightedStroke: number | null;
    image: HTMLImageElement | null;
    isOpen: boolean;
    selection: STROKE_TYPES | 'erase' | any;
    strokeWidth: any;
}

export class RateImage extends BaseInput<Props, State> {
    state: State = {
        strokes: [],
        isDrawing: false,
        image: null,
        highlightedStroke: null,
        isOpen: false,
        selection: null,
        strokeWidth: 15
    };

    private stageRef;

    startDrawing = () => {
        const color =
            this.state.selection && this.state.selection !== 'erase'
                ? this.props.question.options.filter(
                      (o) => o.label === this.state.selection
                  )[0].color
                : '';
        if (
            this.state.selection === null ||
            (this.state.selection === 'erase' &&
                this.state.strokes.length === 0)
        ) {
            alert(this.props.t('LABEL.INSTRUCTIONS.RATE_IMAGE_ALERT'));
        } else if (this.state.selection === 'erase') {
            return;
        } else {
            const newStroke: Stroke = {
                type: this.state.selection,
                points: [],
                strokeWidth: this.state.strokeWidth,
                color
            };
            this.setState({
                isDrawing: true,
                strokes: [...this.state.strokes, newStroke]
            });
        }
    };

    stopDrawing = () => {
        this.setState({
            isDrawing: false
        });
    };

    maybeContinueDrawing = (e) => {
        if (this.state.isDrawing === true) {
            if (e.type === 'contentMousemove') {
                const pos = mousePos(e.evt);
                if (pos) {
                    const { mouseX, mouseY } = pos;
                    // Draw pixel if mouse is being pressed
                    this.addLine(mouseX, mouseY);
                }
            } else {
                const pos = touchPos(e.evt);
                if (pos) {
                    const { touchX, touchY } = pos;
                    this.addLine(touchX, touchY);
                }
            }
        }
    };

    addLine = (x: number, y: number) => {
        const currStroke = this.state.strokes[this.state.strokes.length - 1];
        const newPoint = { x, y };
        this.setState({
            strokes: [
                // all but last
                ...this.state.strokes.slice(0, -1),
                { ...currStroke, points: [...currStroke.points, newPoint] }
            ]
        });
    };

    highlightStroke = (idx: number) => {
        if (!this.state.isDrawing) {
            this.setState({
                highlightedStroke: idx
            });
        }
    };

    maybeUnhighlightStroke = (idx: number) => {
        if (this.state.highlightedStroke === idx) {
            this.setState({
                highlightedStroke: null
            });
        }
    };

    changeStroke = (idx: number) => {
        if (this.state.selection === 'erase') {
            this.deleteStroke(idx);
        } else if (this.state.selection) {
            this.changeStrokeType(idx, this.state.selection);
        }
    };

    deleteStroke = (idx: number) => {
        this.setState({
            strokes: [
                ...this.state.strokes.slice(0, idx),
                ...this.state.strokes.slice(idx + 1)
            ]
        });
    };

    changeStrokeType = (idx: number, selectedType: STROKE_TYPES) => {
        const color = this.props.question.options.filter(
            (o) => o.label === this.state.selection
        )[0].color;
        const stroke = this.state.strokes[idx];
        const updatedStroke = { ...stroke, type: selectedType, color };
        const updatedStrokes = [
            ...this.state.strokes.slice(0, idx),
            updatedStroke,
            ...this.state.strokes.slice(idx + 1)
        ];
        this.setState({
            strokes: updatedStrokes
        });
    };

    handleSelection = (e) => {
        this.setState({
            selection: e.target.value
        });
    };

    handleStrokeWidth = (e) => {
        this.setState({
            strokeWidth: e.target.value
        });
    };

    handleImage = (imageProps: HTMLImageElement) => {
        this.setState({
            image: imageProps
        });
    };

    strokeColour = (type: STROKE_TYPES, strokeI: number, color: string) => {
        const RGBA =
            'rgba(' +
            parseInt(color.substring(1, 3), 16) +
            ',' +
            parseInt(color.substring(3, 5), 16) +
            ',' +
            parseInt(color.substring(5, 7), 16) +
            ',' +
            0.4 +
            ')';
        if (this.state.highlightedStroke === strokeI) {
            return 'rgba(0,227,168, .2)';
        } else {
            return RGBA;
        }
    };

    cursorDisplay = (type: string) => {
        if (type === 'erase') {
            return `${STATIC_STORAGE_URL}/img/ratings/eraserCursor.png`;
        } else if (type) {
            return `${STATIC_STORAGE_URL}/img/ratings/highlighter@2x.png`;
        }
    };

    render() {
        const { langId, question, t } = this.props;
        const { continueTexts, options } = question;

        const continueText = getStringFromMultimediaTexts(
            continueTexts,
            langId
        );

        const disabled =
            this.state.strokes.length === 0 ||
            this.state.strokes.some((stroke) => stroke.type === 'neutral');

        const instructionsText =
            this.props.langId == '2'
                ? '选择并标注'
                : t('LABEL.INSTRUCTIONS.RATE_IMAGE_ALERT');

        const eraseButtonText =
            this.props.langId == '2' ? '擦去' : t('BUTTON.ERASE');
        const strokeText =
            this.props.langId == '2' ? '笔画' : t('BUTTON.STROKE');

        return (
            <Modal>
                <div className="canvas-container">
                    <div className="canvas-wrapper">
                        <div
                            className="canvas-img"
                            id="container"
                            style={{
                                cursor: `url(${this.cursorDisplay(
                                    this.state.selection
                                )}) -1 128, auto`
                            }}
                        >
                            <div
                                className="image-wrapper"
                                onMouseOut={this.stopDrawing}
                            >
                                <Stage
                                    width={
                                        this.state.image != null
                                            ? this.state.image.width
                                            : 0
                                    }
                                    height={
                                        this.state.image != null
                                            ? this.state.image.height
                                            : 0
                                    }
                                    ref={(node) => {
                                        this.stageRef = node;
                                    }}
                                    onContentMouseMove={
                                        this.maybeContinueDrawing
                                    }
                                    onMouseUp={this.stopDrawing}
                                    onTouchMove={this.maybeContinueDrawing}
                                    onTouchEnd={this.stopDrawing}
                                >
                                    <Layer
                                        onMouseDown={this.startDrawing}
                                        onTouchStart={this.startDrawing}
                                    >
                                        <CanvasImage
                                            url={this.props.question.mediaURL}
                                            onSelectImage={this.handleImage}
                                            width={780}
                                            height={585}
                                        />
                                    </Layer>
                                    <Layer>
                                        {this.state.strokes.map(
                                            (s, strokeI) => (
                                                <Group
                                                    key={strokeI}
                                                    onMouseOver={() =>
                                                        this.highlightStroke(
                                                            strokeI
                                                        )
                                                    }
                                                    onMouseOut={() =>
                                                        this.maybeUnhighlightStroke(
                                                            strokeI
                                                        )
                                                    }
                                                    onClick={() =>
                                                        this.changeStroke(
                                                            strokeI
                                                        )
                                                    }
                                                    onTap={() =>
                                                        this.changeStroke(
                                                            strokeI
                                                        )
                                                    }
                                                >
                                                    {consecutivePairs(
                                                        s.points
                                                    ).map(
                                                        (
                                                            [start, finish],
                                                            pointI
                                                        ) => (
                                                            <Line
                                                                key={pointI}
                                                                points={[
                                                                    (
                                                                        start as any
                                                                    ).x,
                                                                    (
                                                                        start as any
                                                                    ).y,
                                                                    (
                                                                        finish as any
                                                                    ).x,
                                                                    (
                                                                        finish as any
                                                                    ).y
                                                                ]}
                                                                stroke={this.strokeColour(
                                                                    s.type,
                                                                    strokeI,
                                                                    s.color
                                                                )}
                                                                strokeWidth={
                                                                    s.strokeWidth
                                                                }
                                                                lineCap="butt"
                                                                lineJoin="round"
                                                            />
                                                        )
                                                    )}
                                                </Group>
                                            )
                                        )}
                                    </Layer>
                                </Stage>
                            </div>
                        </div>
                    </div>
                    <div className="rate-image-selection">
                        <div className="selection-wrapper">
                            {/* <p>{instructionsText}</p>
              <div className="num-input">
                <label>{strokeText}</label>
                <input
                  type="number"
                  min="1"
                  max="80"
                  onChange={this.handleStrokeWidth}
                  value={this.state.strokeWidth}
                />
              </div> */}

                            <div className="options">
                                {options.map((option) => {
                                    return (
                                        <Button
                                            key={option.id}
                                            value={option.label}
                                            name={getStringFromMultimediaTexts(
                                                option.texts,
                                                langId
                                            )}
                                            color={option.color}
                                            handleSelection={
                                                this.handleSelection
                                            }
                                            btnClass={
                                                this.state.selection ===
                                                option.label
                                                    ? 'active'
                                                    : null
                                            }
                                        />
                                    );
                                })}
                                <Button
                                    value="erase"
                                    name={eraseButtonText}
                                    imageURL={`${STATIC_STORAGE_URL}/img/ratings/eraserbtn@2x.png`}
                                    handleSelection={this.handleSelection}
                                    btnClass={
                                        this.state.selection === 'erase'
                                            ? 'active'
                                            : null
                                    }
                                />
                            </div>
                            <div className="submit">
                                <button
                                    className="btn btn-primary "
                                    disabled={disabled}
                                    value="submit"
                                    onClick={() => {
                                        const dataURL = this.stageRef
                                            .getStage()
                                            .toDataURL();
                                        this.props.handleAnswer(
                                            this.state.strokes,
                                            dataURL
                                        );
                                    }}
                                >
                                    {continueText}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

const StrokeButton = styled.button`
  background-color: ${(props) => props.color};
  border: ${(props) => `2px solid  ${props.color}`}
  &:hover,
  &.active,
  &:focus {
    border-color: ${(props) => props.color && darken(0.1, props.color)};
    background-color: ${(props) => props.color && darken(0.1, props.color)};
    cursor: pointer;
    font-weight: bold;
  }
`;

interface ButtonProps {
    btnClass: string;
    name: string;
    value: string;
    handleSelection: (e: any) => void;
    color?: string;
    imageURL?: string;
}

const Button: React.FunctionComponent<ButtonProps> = (props) => {
    return (
        <StrokeButton
            color={props.color}
            type="button"
            name="select"
            value={props.value}
            onClick={props.handleSelection}
            className={`button-stroke ${props.btnClass}`}
            // If Button has a imageURL as a props
            style={
                props.imageURL
                    ? {
                          backgroundImage: `url(${props.imageURL})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center'
                      }
                    : null
            }
        >
            {props.imageURL ? '' : props.name}
        </StrokeButton>
    );
};

Button.propTypes = {
    btnClass: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    handleSelection: PropTypes.func.isRequired,
    color: PropTypes.string,
    imageURL: PropTypes.string
};

function consecutivePairs<T>(arr: T[]): T[][] {
    if (arr.length < 2) {
        return [];
    } else {
        const result = [];
        for (let i = 0; i < arr.length - 1; i++) {
            result.push([arr[i], arr[i + 1]]);
        }
        return result;
    }
}

function mousePos(e: MouseEvent) {
    if (e.offsetX) {
        return {
            mouseX: e.offsetX,
            mouseY: e.offsetY
        };
    } else {
        return null;
    }
}

function touchPos(e) {
    if (e.touches) {
        if (e.touches.length === 1) {
            // Only deal with 1 finger
            const touch = e.touches[0];
            console.log(touch);
            return {
                touchX: touch.pageX - touch.target.offsetLeft,
                touchY: touch.pageY - touch.target.offsetTop
            };
        }
    }
}
