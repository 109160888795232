import * as React from 'react';
export interface JourneyPathProps {
    startPointX: number;
    startPointY: number;
    chunkList: any[][];
    firstRowOffset: number;
}
import { YDiff } from '../constant';

// OPTIONS
const pathThickness = 24; // height (or width, when rotated) of path line
const dashThickness = 3; // height (or width, when rotated) of dashed line

const getRightCircle = (y: any, ydiff: any = 150, offsetX: number = 0) =>
    `C ${805 + offsetX} ${y}, ${805 + offsetX} ${y + ydiff}, ${675 + offsetX} ${
        y + ydiff
    }`;

const getRightHalfCircle = (y: any, ydiff: any = 70, offsetX: number) =>
    `C ${770 + offsetX} ${y}, ${775 + offsetX} ${y + ydiff}, ${772 + offsetX} ${
        y + ydiff
    }`;

const getLeftCircle = (y: any, ydiff: any = 150) =>
    `C 95 ${y}, 95 ${y + ydiff}, 225 ${y + ydiff}`;

const getLeftHalfCircle = (y: any, ydiff: any = 70) =>
    `C 133 ${y}, 141 ${y + ydiff}, 127 ${y + ydiff}`;

export const getRightLine = (
    currentChunkLength: number,
    isFirst: boolean,
    y: any,
    ydiff: any = 150,
    firstRowOffset: number
) => {
    if (isFirst) {
        switch (currentChunkLength) {
            case 0:
                return ` L${150 + firstRowOffset} ${y} `;
            case 1:
                return ` L${150 + firstRowOffset} ${y} `; // return ` L100 ${y} `;
            case 2:
                return ` L${300 + firstRowOffset} ${y} `; // return ` L200 ${y} `;
            case 3:
                return ` L${450 + firstRowOffset} ${y} `; // return ` L300 ${y} `;
            case 4:
                return ` L${600 + firstRowOffset} ${y} `; // return ` L400 ${y} `;
            case 5:
                return ` L${675 + firstRowOffset} ${y} `; // return ` L460 ${y} `;
            case 6:
                return ` L${675 + firstRowOffset} ${y} ${getRightCircle(
                    y,
                    ydiff,
                    firstRowOffset
                )} `; // return ` L460 ${y} ${getRightCircle(y, ydiff)} `;
            default:
                return '';
        }
    } else {
        switch (currentChunkLength) {
            case 0:
                return '';
            case 1:
                return ` L300 ${y} `;
            case 2:
                return ` L450 ${y} `;
            case 3:
                return ` L600 ${y} `;
            case 4:
                return ` L675 ${y} `;
            case 5:
                return ` L675 ${y} ${getRightCircle(y, ydiff)} `;
            default:
                return '';
        }
    }
};

export const getRightLast = (
    currentChunkLength: number,
    isFirst: boolean,
    y: any,
    ydiff: any = 150,
    firstRowOffset: number
) => {
    if (!isFirst) {
        currentChunkLength += 1;
    }
    const offsetX = isFirst ? firstRowOffset : 0;
    switch (currentChunkLength) {
        case 0:
            return ` L${225 + offsetX} ${y}`;
        case 1:
            return ` L${225 + offsetX} ${y} `; //return ` L150 ${y} `;
        case 2:
            return ` L${375 + offsetX} ${y} `; //return ` L250 ${y} `;
        case 3:
            return ` L${525 + offsetX} ${y} `; //return ` L350 ${y} `;
        case 4:
            return ` L${675 + offsetX} ${y} `; //return ` L460 ${y} `;
        case 5:
            return ` ${getRightHalfCircle(y, undefined, offsetX)}`; //return ` ${getRightHalfCircle(y)}`;
        case 6:
            return `  `;
        default:
            return '';
    }
};

export const getLeftLast = (
    currentChunkLength: number,
    y: any,
    ydiff: any = 150
) => {
    switch (currentChunkLength) {
        case 0:
            return '';
        case 5:
            return ` `;
        case 4:
            return ` L225 ${y} ${getLeftHalfCircle(y)}`;
        case 3:
            return ` L225 ${y} `;
        case 2:
            return ` L375 ${y} `;
        case 1:
            return ` L525 ${y} `;
        default:
            return '';
    }
};

export const getLeftLine = (
    currentChunkLength: number,
    y: any,
    ydiff: any = 150
) => {
    switch (currentChunkLength) {
        case 0:
            return '';
        case 6:
            return ` L225 ${y} ${getLeftCircle(y, ydiff)}`;
        case 5:
            return ` L225 ${y} ${getLeftCircle(y, ydiff)}`;
        case 4:
            return ` L225 ${y} `;
        case 3:
            return ` L225 ${y} `;
        case 2:
            return ` L375 ${y} `;
        case 1:
            return ` L525 ${y} `;
        default:
            return '';
    }
};

export const getLastLine = (
    chunkList: any[][],
    intialY: number,
    ydiff: number,
    firstRowOffset: number
) => {
    const lastIndex = chunkList.length - 1;
    const lastList = chunkList[lastIndex];
    const lastListLastIndex = lastList.length;
    const y = intialY + lastIndex * ydiff;
    if (lastIndex % 2 === 0) {
        return getRightLast(
            lastListLastIndex,
            lastIndex === 0,
            y,
            undefined,
            firstRowOffset
        );
    } else {
        return getLeftLast(lastListLastIndex, y);
    }
};

export const JourneyPath: React.SFC<JourneyPathProps> = (props) => {
    let d = `M ${props.startPointX} ${props.startPointY} `;
    let y = 0;
    if (props.chunkList.length > 1) {
        props.chunkList.forEach((list, index: number) => {
            if (index % 2 === 0) {
                d += getRightLine(
                    list.length,
                    index === 0,
                    y,
                    YDiff,
                    props.firstRowOffset
                );
            } else {
                d += getLeftLine(list.length, y, YDiff);
            }
            y += YDiff;
        });
    } else {
        d += getRightLine(
            props.chunkList[0].length,
            true,
            y,
            YDiff,
            props.firstRowOffset
        );
    }
    d += getLastLine(props.chunkList, 0, YDiff, props.firstRowOffset);

    return (
        <g className="path-main" transform="translate(24,80)">
            <path
                className="path-border"
                d={d}
                fill="none"
                strokeWidth={pathThickness}
                strokeLinecap="butt"
                // stroke="#999"
                stroke="#DAE7F6"
                strokeLinejoin="bevel"
            />
            <path
                className="path-line"
                d={d}
                fill="none"
                strokeWidth={dashThickness}
                // stroke="#eee"
                stroke="#4461FF"
                strokeDasharray="8"
                strokeLinejoin="bevel"
            />
        </g>
    );
};
