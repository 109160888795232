import * as React from 'react';
import {
    DropTarget,
    ConnectDropTarget,
    DropTargetConnector,
    DropTargetMonitor,
    DragSource,
    ConnectDragSource,
    DragSourceConnector,
    DragSourceMonitor,
    DragSourceSpec
} from 'react-dnd';

import ItemTypes from '../constant';
import { findDOMNode } from 'react-dom';
import { XYCoord } from 'dnd-core';
import { Color } from 'csstype';
import { TextDialogWrapper, BigCircle } from './SVGComponents';
import { JOURNEY_ELEMENT_TYPES, JOURNEY_STEPS } from '../types';

class JourneyElement extends React.Component<JourneyElementProps> {
    svg: SVGSVGElement | null = null;
    pt = null;
    componentDidMount() {
        this.svg = document.querySelector('svg');
        this.pt = this.svg.createSVGPoint();
    }
    cursorPoint(evt: XYCoord) {
        if (this.pt && this.svg && evt) {
            this.pt.x = evt.x;
            this.pt.y = evt.y;
            // console.log(this.pt.matrixTransform(this.svg.getCTM().inverse()),this.pt.matrixTransform(this.svg.getScreenCTM().inverse()))
            return this.pt.matrixTransform(this.svg.getScreenCTM().inverse());
        } else {
            return {
                x: 0,
                y: 0
            };
        }
    }
    render() {
        const {
            isDragging,
            sourceOffset,
            connectDropTarget,
            isSelected,
            isOver,
            canDrop,
            text,
            translateX,
            translateY,
            bgcolor,
            connectDragSource
        } = this.props;
        const isActive = canDrop && isOver;
        let fillColor = '#ccc';
        if (canDrop) {
            if (isOver) {
                fillColor = '#66cc00';
            } else {
                fillColor = 'blue';
            }
        }

        /* return (<g ref={connectDropTarget as any} transform={`translate(${this.props.translateX || 0}, ${this.props.translateY || 0})`}>
                <rect fill={fillColor} x="2" y="10" rx="3" ry="3" width="80" height="80" />
                <text x="9" y="42" font-family="Open Sans Condensed" font-size="12" stroke="none" fill="#fff" font-weight="900" >Workflow
                    <tspan x="9" dy="17">{text}</tspan>
                </text>
			</g>); */
        const sourceOffset2 = this.cursorPoint(sourceOffset);
        const ADDITEMS_MODE =
            this.props.currentState === JOURNEY_STEPS.ADD_ITEMS;
        const allowDragging = false; // @TODO: set equal to ADDITEMS_MODE to re-enable dragging of existing elements
        const cursorClass = '';
        // @TODO: re-enable dragging of existing elements
        // this.props.currentState === 'ADDITEMS'
        //     ? 'cursor-move'
        //     : this.props.currentState === 'SELECTSTEPS'
        //     ? 'cursor-pointer'
        //     : '';

        // render the appropriate kind of element
        if (
            // OPTION (STEP)
            this.props.type === JOURNEY_ELEMENT_TYPES.OPTION
        ) {
            // show OPTION element
            const optionElement = (
                <g className={cursorClass} id={`journey-step-${this.props.id}`}>
                    <TextDialogWrapper
                        {...this.props}
                        text={text}
                        isSelected={ADDITEMS_MODE ? isSelected : undefined}
                        translateX={translateX}
                        translateY={translateY}
                        bgcolor={bgcolor || '#F2AF00'}
                        followUpAnswers={this.props.followUpAnswers}
                        smiley={this.props.smiley}
                        banks={this.props.banks}
                        currentType={this.props.currentType}
                        hightLightCurrent={this.props.hightLightCurrent}
                        handleStepRemove={() =>
                            this.props.handleStepRemove(this.props.index)
                        }
                        currentState={this.props.currentState}
                        followUpSettings={this.props.followUpSettings}
                    />
                </g>
            );
            return allowDragging ? (
                <React.Fragment>
                    {connectDragSource(optionElement)}
                    {isDragging ? (
                        <g
                            style={{
                                transform: sourceOffset
                                    ? `translate(${
                                          sourceOffset2.x -
                                          24 -
                                          +translateX +
                                          10
                                      }px, ${
                                          sourceOffset2.y -
                                          80 -
                                          +translateY -
                                          10
                                      }px)`
                                    : ''
                            }}
                        >
                            <TextDialogWrapper
                                text={text}
                                translateX={0}
                                translateY={0}
                                bgcolor={bgcolor || '#F2AF00'}
                                {...this.props}
                                smiley={this.props.smiley}
                                banks={this.props.banks}
                                currentType={this.props.currentType}
                                hightLightCurrent={this.props.hightLightCurrent}
                                handleStepRemove={() => {}}
                                followUpAnswers={this.props.followUpAnswers}
                                currentState={null}
                                followUpSettings={this.props.followUpSettings}
                            />
                        </g>
                    ) : null}
                </React.Fragment>
            ) : (
                optionElement
            );
        } else if (
            // TIMEPOINTS
            [
                JOURNEY_ELEMENT_TYPES.STARTPOINT,
                JOURNEY_ELEMENT_TYPES.MIDPOINT,
                JOURNEY_ELEMENT_TYPES.ENDPOINT
            ].includes(this.props.type)
        ) {
            // show MIDPOINT element
            // (this can be combined with BANK case above..)
            return (
                <React.Fragment>
                    {connectDragSource(
                        <g className={cursorClass}>
                            <BigCircle
                                translateX={translateX}
                                translateY={translateY}
                                image={this.props.imgUrl}
                                text={this.props.text}
                                orientation="top"
                                radius={
                                    this.props.type ===
                                    JOURNEY_ELEMENT_TYPES.MIDPOINT
                                        ? 20
                                        : 40
                                }
                                startpointImageType={
                                    this.props.startpointImageType
                                }
                                currentState={this.props.currentState}
                            />
                        </g>
                    )}
                    {isDragging ? (
                        <g
                            style={{
                                transform: sourceOffset
                                    ? `translate(${
                                          sourceOffset2.x - 24 + 30
                                      }px, ${sourceOffset2.y - 80 - 30}px)`
                                    : ''
                            }}
                        >
                            <BigCircle
                                translateX={'0'}
                                translateY={'0'}
                                image={this.props.imgUrl}
                                orientation="top"
                                radius={20}
                                currentState={this.props.currentState}
                            />
                        </g>
                    ) : null}
                </React.Fragment>
            );
        } else {
            throw new Error(`no such journey element: ${this.props.type}`);
        }
    }
}

interface CardTargetCollectedProps {
    connectDropTarget: ConnectDropTarget;
}

const cardTarget = {
    hover(
        props: JourneyElementProps,
        monitor: DropTargetMonitor,
        component: React.Component | null
    ) {
        if (!component) {
            return null;
        }
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Determine rectangle on screen
        // eslint-disable-next-line react/no-find-dom-node
        const hoverBoundingRect = (
            findDOMNode(component) as Element
        ).getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY =
            (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY =
            (clientOffset as XYCoord).y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        /* if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
			return
		} */

        // Dragging upwards
        // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        // 	return
        // }

        console.log(dragIndex, hoverIndex);
        // props.moveCard(dragIndex, hoverIndex);

        // Time to actually perform the action
        // props.moveCard(dragIndex, hoverIndex)

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        // monitor.getItem().index = hoverIndex
        return {
            id: props.id,
            index: props.index,
            text: props.text
        };
    },
    drop(
        props: JourneyElementProps,
        monitor: DropTargetMonitor,
        component: JourneyElement | null
    ) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;
        props.moveCard(dragIndex, hoverIndex);
        console.log(
            'CARD TARGET(?) Dropped',
            dragIndex,
            hoverIndex,
            props,
            monitor.getItem(),
            monitor.getItemType(),
            component
        );
    }
};

const cardSource = {
    beginDrag(props: JourneyElementProps) {
        return {
            id: props.id,
            index: props.index,
            type: props.type
        };
    }
};

export interface DroppableProps {
    canDrop: boolean;
    isOver: boolean;
    isDragging: boolean;
    connectDropTarget: ConnectDropTarget;
}

interface DragSourceCollectedProps {
    isDragging: boolean;
    connectDragSource: ConnectDragSource;
    sourceOffset: XYCoord;
}

export interface JourneyElementProps
    extends DroppableProps,
        DragSourceCollectedProps {
    translateX: string;
    translateY?: string;
    bgcolor?: Color;
    hightLightComponent?: boolean;
    hightLightCurrent?: boolean;

    type?: JOURNEY_ELEMENT_TYPES;
    /* extend from base props for journey */
    id: any;
    text: string;
    isSelected: boolean;
    index: number;
    banks?: any[];
    smiley?: any;
    followUpAnswers?: any[];
    followUpSettings?: any[];
    currentType?: 'BANK' | 'EMOJI';
    // firstBank?: string;
    imgUrl: string;
    startpointImageType?: 'SQUARE' | 'CIRCLE';
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    handleStepRemove: (index: number) => void;
    currentState?: JOURNEY_STEPS;
}

export default DropTarget<JourneyElementProps, CardTargetCollectedProps>(
    [ItemTypes.DIALOG],
    cardTarget,
    (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
    })
)(
    DragSource<JourneyElementProps, DragSourceCollectedProps>(
        ItemTypes.DIALOG,
        cardSource,
        (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
            connectDragSource: connect.dragSource(),
            connectDragPreview: connect.dragPreview(),
            isDragging: monitor.isDragging(),
            sourceOffset: monitor.getClientOffset()
        })
    )(JourneyElement as any)
) as any;
