import * as React from 'react';
import * as _ from 'lodash';
import axios from 'axios';
import { DragDropContext, DropTargetMonitor } from 'react-dnd';
import TouchBackend from 'react-dnd-touch-backend';
import MultiBackend, { TouchTransition } from 'react-dnd-multi-backend';
import { UilArrowLeft, UilArrowRight } from '@iconscout/react-unicons';

import { getStringFromMultimediaTexts } from '@nexxt/common/services/TextService';
import { fixedShuffleIndex } from '@nexxt/common/utils';

import NotApplicableButton from '../../components/NotApplicableButton';
import { MessageLoader } from '../../components/MessageLoader';
import { dataURLtoBlob, svgToDataURL } from '../../utils/upload';

import { JourneyElementContainer } from './SVG/JourneyElementContainer';
import { JourneyElementDropspotProps } from './SVG/JourneyElementDropspot';
import JourneyOption from './SVG/JourneyOption';
import { JourneyPath } from './SVG/JourneyPath';
import { MarkersSVG } from './SVG/SVGComponents';
import AddEvent from './AddEvent';
import { JourneyFollowupQuestion } from './JourneyFollowupQuestion';

import {
    JourneyOptionData,
    JourneyElementData,
    JOURNEY_ELEMENT_TYPES,
    JOURNEY_STEPS
} from './types';

import {
    INITIAL_JOURNEY_QUESTION,
    ELEMENTS_PER_ROW,
    DEFAULT_JOURNEY_SETTINGS
} from './constant';

import { MultipleChoice } from '../../questions/MultipleChoice';
import { OPTION_TYPES } from '@nexxt/common/types';
import ContinueButton from '../../components/ContinueButton';
import { JourneyContinue } from './JourneyContinue';
import { JourneyElementDeleteModal } from './JourneyElementDeleteModal';
import '../../style/Journey.scss';

const SVG_ELEMENT_ID = 'svg-content-id';

const HTML5ToTouch = {
    backends: [
        {
            backend: TouchBackend({ enableMouseEvents: true }), // Note that you can call your backends with options
            preview: true,
            transition: TouchTransition
        }
    ]
};

interface Props {
    langId: string;
    setBottomOffset?: (offset: number) => void;
    scrollToBottom?: () => void;
    handleAnswer: (
        journeyAnswer: any,
        dataURL: string,
        journeyState: any
    ) => void;
    handleNotApplicableAnswer?: () => void;
    question: any;
    mode: JOURNEY_STEPS;
    maxSelect?: number;
}

interface State {
    journeyStepsBySegment: JourneyOptionData[][];
    currentBucketIndex: number;
    state: JOURNEY_STEPS;

    currentIndex: number;

    currentTypeAddEmoji: 'EMOJI' | 'TEXT' | 'BANK';
    showWarning: boolean;
    minSelect: number;
    maxSelect: number;
    uploading: boolean;
    warningShown: boolean;
    journeySettings: any; // TODO: typing
    filteredBuckets: any[];
    totalOptionNum: number;
    isValid: boolean;

    showDeleteModal: boolean;
    deleteJourneyElement: { bucketIndex: number; id: number };
}

class JourneyRaw extends React.Component<Props, State> {
    /**
     * CurrentState = SELECTSTEPS maxselect and minselect.
     * Can be set from question in the future
     *
     * @memberof Journey
     */

    constructor(props: any) {
        super(props);
        this.state = {
            minSelect: 1,
            maxSelect: 1,
            uploading: false,
            showWarning: false,
            journeyStepsBySegment: [],
            currentIndex: 0, // for follow up
            currentBucketIndex: 0, // bucket of options the user is currently on
            state: null,
            currentTypeAddEmoji: INITIAL_JOURNEY_QUESTION,
            warningShown: false,
            journeySettings: {},
            filteredBuckets: [],
            totalOptionNum: 0,
            isValid: false,
            showDeleteModal: false,
            deleteJourneyElement: null
        };
    }

    componentDidMount(): void {
        this.initJourney();
    }

    initJourney() {
        // default values, unless overridden by props.question.journeySettings
        const journeySettings = {
            ...DEFAULT_JOURNEY_SETTINGS,
            ...this.props.question.journeySettings,
            buckets: this.props.question.journeySettings?.buckets?.length
                ? this.props.question.journeySettings?.buckets
                : DEFAULT_JOURNEY_SETTINGS.buckets,
            midpoints: []
        };

        // randomize buckets + options based on randomizeBucket and randomizeOptions
        const bucketsWithRandomizedOptions = journeySettings.buckets.map((b) =>
            b.randomizeOptions ? { ...b, options: _.shuffle(b.options) } : b
        );
        const processedJourneySettings = {
            ...journeySettings,
            buckets: fixedShuffleIndex(
                bucketsWithRandomizedOptions,
                bucketsWithRandomizedOptions.map((b) => !b.randomizeBucket)
            )
        };
        const totalOptionNum = processedJourneySettings.buckets.reduce(
            (total, currentBucket) => {
                return (total += currentBucket?.options?.length || 0);
            },
            0
        );

        const state =
            totalOptionNum < 7 &&
            this.props.question.showNotApplicableOption && // @TODO this to be reversed
            this.props.mode === JOURNEY_STEPS.PREFILTER
                ? JOURNEY_STEPS.ADD_ITEMS
                : this.props.mode;

        if (this.props.question.journeyState) {
            // load journey select mode
            this.setState({
                ...this.props.question.journeyState,
                state,
                minSelect: 1,
                maxSelect: this.props.maxSelect ? this.props.maxSelect : 1,
                totalOptionNum,
                filteredBuckets:
                    this.props.question.journeyState?.buckets || [],
                showDeleteModal: false,
                deleteJourneyElement: null
            });
        } else {
            // load regular journey
            this.setState({
                minSelect: 1,
                maxSelect: 1,
                uploading: false,
                showWarning: false,
                journeyStepsBySegment: this.generateJourneyStepsByMidpoints(
                    journeySettings.midpoints.length
                ),
                currentIndex: 0, // for follow up
                currentBucketIndex: 0, // bucket of options the user is currently on
                state,
                currentTypeAddEmoji: INITIAL_JOURNEY_QUESTION,
                warningShown: false,
                journeySettings: processedJourneySettings,
                filteredBuckets: [],
                totalOptionNum,
                isValid: false,
                showDeleteModal: false,
                deleteJourneyElement: null
            });
        }
    }
    /*
     * Generates the initial struct for journeyStepsByMidpoints,
     * populating each of the numMidpoints + 1 segments as an empty array.
     */
    generateJourneyStepsByMidpoints = (numMidpoints: number): [][] => {
        return Array.from(Array(numMidpoints + 1).keys()).map((a) => []);
    };

    /* Drag dialogs to add button */
    /** NO USED  */
    handleDrag = (
        initialIndex: number, // drag start
        finalIndex: number, // drag end
        type: JOURNEY_ELEMENT_TYPES
    ) => {
        const { journeyStepsBySegment } = this.state;
        console.log('handleDrag:', type);
    };

    /* Creates a new option in the respective bucket. */
    createNewOption = (text: string, bucketIndex: number) => {
        if (!text) {
            return;
        }
        const { filteredBuckets: buckets, journeySettings } = this.state;
        const { color, bgcolor } =
            journeySettings.buckets[bucketIndex]?.options?.[0];

        // const { buckets } = this.state.journeySettings;
        const correspondingBucket = buckets[bucketIndex];
        const newOption = {
            id: Math.floor(Math.random() * +new Date()),
            text: text,
            color,
            bgcolor,
            editable: true,
            removable: true
        };
        const newBuckets = [
            ...buckets.filter((b, i) => i < bucketIndex),
            {
                ...correspondingBucket,
                options: [...correspondingBucket.options, newOption]
            },
            ...buckets.filter((b, i) => i > bucketIndex)
        ];
        this.setState(
            {
                filteredBuckets: newBuckets
            },
            this.validateJourneySteps
        );
    };

    editOption = (bucketIndex: number, id: number, editLabel: string) => {
        const { filteredBuckets: buckets } = this.state;
        const correspondingBucket = buckets[bucketIndex];
        const optionIndex = correspondingBucket.options.findIndex(
            (opt) => opt.id === id
        );
        const option = {
            ...correspondingBucket.options[optionIndex],
            text: editLabel
        };

        buckets[bucketIndex].options = [
            ...buckets[bucketIndex].options.slice(0, optionIndex),
            option,
            ...buckets[bucketIndex].options.slice(optionIndex + 1)
        ];

        this.setState({
            filteredBuckets: buckets
        });
    };

    deleteOption = (bucketIndex: number, id: number) => {
        const { filteredBuckets: buckets } = this.state;
        const correspondingBucket = buckets[bucketIndex];
        const optionIndex = correspondingBucket.options.findIndex(
            (opt) => opt.id === id
        );

        buckets[bucketIndex].options = [
            ...buckets[bucketIndex].options.slice(0, optionIndex),
            ...buckets[bucketIndex].options.slice(optionIndex + 1)
        ];

        this.setState(
            {
                filteredBuckets: buckets,
                deleteJourneyElement: null,
                showDeleteModal: false
            },
            this.validateJourneySteps
        );
    };

    /*
     * Returns data for an option element from option metadata.
     */
    generateJourneyElement = (
        text: string,
        color: string,
        bgcolor: string,
        bucketIndex: number,
        removable: boolean,
        editable: boolean
    ): JourneyOptionData => {
        return {
            id: `${Math.random() * +new Date()}`,
            smiley: null,
            bank: [],
            isSelected: false,
            listType: '',
            text,
            type: JOURNEY_ELEMENT_TYPES.OPTION,
            color,
            bgcolor,
            bucketIndex,
            removable,
            editable
        } as JourneyOptionData;
    };

    saveFollowUp = (currentIndex, followUpAnswers: any) => {
        const { selectedSegmentIndex, elemIndexWithinSegment, flatIndex } =
            this.state.journeyStepsBySegment.reduce(
                (acc, segment) => {
                    const flatIndex = acc.flatIndex + segment.length; // index of end of segment (@TODO: more clarity!)
                    console.log('inter flatIndex:', flatIndex);
                    // keep updating acc until segment is hit
                    return currentIndex >= flatIndex
                        ? {
                              // update progress vars while scanning through segments
                              ...acc,
                              selectedSegmentIndex:
                                  acc.selectedSegmentIndex + 1,
                              flatIndex: flatIndex,
                              elemIndexWithinSegment: currentIndex - flatIndex
                          }
                        : {
                              ...acc,
                              flatIndex: flatIndex
                          };
                },
                {
                    // initial vals
                    selectedSegmentIndex: 0,
                    elemIndexWithinSegment: currentIndex, // ensures validity for first segment
                    flatIndex: 0
                }
            );

        console.log('selectedSegmentIndex:', selectedSegmentIndex);
        console.log('elemIndexWithinSegment:', elemIndexWithinSegment);
        console.log('flatIndex:', flatIndex);
        console.log('currentIndex:', currentIndex);

        // here we add bank to specified element
        const updatedElem = {
            ...this.state.journeyStepsBySegment[selectedSegmentIndex][
                elemIndexWithinSegment
            ],
            followUpAnswers
        };
        const updatedSegment = [
            ...this.state.journeyStepsBySegment[selectedSegmentIndex].slice(
                0,
                elemIndexWithinSegment
            ),
            updatedElem,
            ...this.state.journeyStepsBySegment[selectedSegmentIndex].slice(
                elemIndexWithinSegment + 1
            )
        ];
        // and here we update existing segment struct
        this.setState({
            journeyStepsBySegment: [
                ...this.state.journeyStepsBySegment.slice(
                    0,
                    selectedSegmentIndex
                ),
                updatedSegment,
                ...this.state.journeyStepsBySegment.slice(
                    selectedSegmentIndex + 1
                )
            ]
        });
    };

    saveEverything = (isLastOption: boolean) => {
        if (isLastOption) this.goToFinalJourney();
    };

    handleAddItemsContinue = () => {
        if (this.state.state === JOURNEY_STEPS.ADD_ITEMS_EXTRA) {
            this.changeCurrentState(JOURNEY_STEPS.ADD_EMOJI);
            return;
        }

        // custom validation logic.. this can be replaced with customLogic!
        // if (this.state.warningShown) {
        this.changeCurrentState(JOURNEY_STEPS.JOURNEY_CONFIRM);
        // this.goToFollowUp();
        // }
    };

    goToPopup = () => {
        this.changeCurrentState(JOURNEY_STEPS.ADD_EMOJI);
        this.props.setBottomOffset(250);
    };

    beginJourneyExercise = () => {
        this.changeCurrentState(JOURNEY_STEPS.ADD_ITEMS);
        this.props.setBottomOffset(40);
    };

    proceedJourneyConfirm = () => {
        this.changeCurrentState(JOURNEY_STEPS.ADD_ITEMS_EXTRA);
    };

    goToFinalJourney = () => {
        this.changeCurrentState(JOURNEY_STEPS.FINAL_JOURNEY);
        this.props.setBottomOffset(10);
    };

    changeCurrentState = (value: any) => {
        this.setState(
            {
                state: value
            },
            () => {
                if (value === JOURNEY_STEPS.ADD_EMOJI) {
                    this.showEmoji();
                    this.hideFollowup();
                } else {
                    this.hideEmoji();
                    this.hideFollowup();
                }
            }
        );

        if (value !== JOURNEY_STEPS.ADD_EMOJI) {
            this.props.scrollToBottom();
        }
    };

    showEmoji = () => {
        const el = document.getElementById('wc-message-groups');
        el.classList.add('wc-message-groups-collapse-journey');
    };

    hideEmoji = () => {
        const el = document.getElementById('wc-message-groups');
        el.classList.remove('wc-message-groups-collapse-journey');
    };

    hideFollowup = () => {
        const el = document.getElementById('wc-message-groups');
        el.classList.remove('wc-message-groups-collapse-journey-followup');
    };

    /*
     * Remove journey steps that already dragged onto path
     */
    handleStepRemove = (currentIndex: number) => {
        // compute indices of new option, normalized to journeyStepsBySegment structure
        const { selectedSegmentIndex, elemIndexWithinSegment, flatIndex } =
            this.state.journeyStepsBySegment.reduce(
                (acc, segment) => {
                    const flatIndex = acc.flatIndex + segment.length + 1; // index of end of segment (@TODO: more clarity!)
                    // keep updating acc until segment is hit
                    return currentIndex >= flatIndex
                        ? {
                              // update progress vars while scanning through segments
                              ...acc,
                              selectedSegmentIndex:
                                  acc.selectedSegmentIndex + 1,
                              flatIndex: flatIndex,
                              elemIndexWithinSegment: currentIndex - flatIndex
                          }
                        : {
                              ...acc,
                              flatIndex: flatIndex
                          };
                },
                {
                    // initial vals
                    selectedSegmentIndex: 0,
                    elemIndexWithinSegment: currentIndex, // ensures validity for first segment
                    flatIndex: 0
                }
            );
        console.log('selectedSegmentIndex:', selectedSegmentIndex);
        console.log('elemIndexWithinSegment:', elemIndexWithinSegment);
        console.log('flatIndex:', flatIndex);
        console.log('currentIndex:', currentIndex);

        // remove element from segment
        // here we add new element to selected segment
        const removedElem =
            this.state.journeyStepsBySegment[selectedSegmentIndex][
                elemIndexWithinSegment
            ];
        console.log('removedElem:', removedElem);

        const updatedSegment = [
            ...this.state.journeyStepsBySegment[selectedSegmentIndex].slice(
                0,
                elemIndexWithinSegment
            ),
            ...this.state.journeyStepsBySegment[selectedSegmentIndex].slice(
                elemIndexWithinSegment + 1
            )
        ];
        // and here we update existing segment struct
        this.setState({
            journeyStepsBySegment: [
                ...this.state.journeyStepsBySegment.slice(
                    0,
                    selectedSegmentIndex
                ),
                updatedSegment,
                ...this.state.journeyStepsBySegment.slice(
                    selectedSegmentIndex + 1
                )
            ]
        });
        const flattenedJourneySteps = _.flatten(updatedSegment);
        // restore option to bucket, if it was removed before
        // @TODO: right now this re-adds to ALL buckets!!!
        const restoredOption = {
            color: removedElem.color,
            text: removedElem.text,
            id: removedElem.id,
            bgcolor: removedElem.bgcolor,
            removable: removedElem.removable,
            editable: removedElem.editable
        };
        this.setState(
            {
                filteredBuckets: this.state.filteredBuckets.map((b, index) => {
                    if (index === removedElem.bucketIndex)
                        return {
                            ...b,
                            options: b.persistOptions
                                ? b.options.map((o) => {
                                      if (
                                          o.text === restoredOption.text &&
                                          flattenedJourneySteps.findIndex(
                                              (step) =>
                                                  step.text ===
                                                  restoredOption.text
                                          ) === -1
                                      ) {
                                          return { ...o, used: false };
                                      }
                                      return o;
                                  })
                                : [...b.options, restoredOption]
                        };
                    return { ...b };
                })
            },
            this.validateJourneySteps
        );
    };

    /*
     * Triggered when a step is dropped onto the journey.
     * Creates an option element, and injects it into the journey.
     * TODO:
     *  - extend to work with dropping of existing option elements / midpoints?
     */
    handleDrop = (
        props: JourneyElementDropspotProps,
        monitor: DropTargetMonitor
    ) => {
        // create a new option element
        const { currentIndex } = props; // this is the 'flattened' index of dropspot (@TODO more clarity!)
        const monitorItem = monitor.getItem();
        const newElement = this.generateJourneyElement(
            monitorItem.text,
            monitorItem.color,
            monitorItem.bgColor,
            monitorItem.bucketIndex,
            monitorItem.removable,
            monitorItem.editable
        );
        // remove option from bucket, if not set to persist
        this.setState({
            filteredBuckets: this.state.filteredBuckets.map((b) => {
                return {
                    ...b,
                    options: b.persistOptions
                        ? b.options.map((o) => {
                              if (o.text === monitorItem.text) {
                                  return { ...o, used: true };
                              }
                              return o;
                          })
                        : b.options.filter((o) => o.text !== monitorItem.text)
                };
            })
        });

        // compute indices of new option, normalized to journeyStepsBySegment structure
        const { selectedSegmentIndex, elemIndexWithinSegment, flatIndex } =
            this.state.journeyStepsBySegment.reduce(
                (acc, segment) => {
                    const flatIndex = acc.flatIndex + segment.length + 1; // index of end of segment (@TODO: more clarity!)
                    console.log('inter flatIndex:', flatIndex);
                    // keep updating acc until segment is hit
                    return currentIndex >= flatIndex
                        ? {
                              // update progress vars while scanning through segments
                              ...acc,
                              selectedSegmentIndex:
                                  acc.selectedSegmentIndex + 1,
                              flatIndex: flatIndex,
                              elemIndexWithinSegment: currentIndex - flatIndex
                          }
                        : {
                              ...acc,
                              flatIndex: flatIndex
                          };
                },
                {
                    // initial vals
                    selectedSegmentIndex: 0,
                    elemIndexWithinSegment: currentIndex, // ensures validity for first segment
                    flatIndex: 0
                }
            );
        console.log('selectedSegmentIndex:', selectedSegmentIndex);
        console.log('elemIndexWithinSegment:', elemIndexWithinSegment);
        console.log('flatIndex:', flatIndex);
        console.log('currentIndex:', currentIndex);

        // inject new element into state
        // here we add new element to selected segment
        const updatedSegment = [
            ...this.state.journeyStepsBySegment[selectedSegmentIndex].slice(
                0,
                elemIndexWithinSegment
            ),
            newElement,
            ...this.state.journeyStepsBySegment[selectedSegmentIndex].slice(
                elemIndexWithinSegment
            )
        ];
        // and here we update existing segment struct
        this.setState(
            {
                journeyStepsBySegment: [
                    ...this.state.journeyStepsBySegment.slice(
                        0,
                        selectedSegmentIndex
                    ),
                    updatedSegment,
                    ...this.state.journeyStepsBySegment.slice(
                        selectedSegmentIndex + 1
                    )
                ]
            },
            this.validateJourneySteps
        );
    };

    // Upload dataURL to Cloud storage and return full image URL
    uploadJourneyAsImage = async () => {
        this.setState({ uploading: true });
        const journeySvg = document.getElementById(SVG_ELEMENT_ID);
        const dataURL = svgToDataURL(journeySvg);
        const blob = dataURLtoBlob(dataURL);
        const formData = new FormData();
        formData.append('file', blob, 'journey_image');

        const url = `${process.env.DATA_API}/file/upload`;

        const resp = await axios.post(url, formData);
        this.setState({ uploading: false });

        return resp.data.full;
    };

    handleAnswer = async () => {
        const flattenedJourneySteps = _.flatten(
            this.state.journeyStepsBySegment
        );
        this.props.handleAnswer(
            flattenedJourneySteps,
            flattenedJourneySteps?.length
                ? await this.uploadJourneyAsImage()
                : null,
            this.state
        );
    };

    restartJourney = () => {
        this.initJourney();
    };
    continueButton = (continueText: string) => {
        const { isValid, filteredBuckets } = this.state;
        if (
            [JOURNEY_STEPS.ADD_ITEMS, JOURNEY_STEPS.ADD_ITEMS_EXTRA].includes(
                this.state.state
            )
        ) {
            const labels = filteredBuckets
                .map((bucket) => `"${bucket.label}"`)
                .join(' and ');
            return (
                <div className="journey-bottom-container">
                    <div>
                        <div className="multi-choice-help">
                            <p>
                                {!isValid
                                    ? `Please drag all the options in ${labels} in order to continue.`
                                    : "Once you've added every event, click continue."}
                            </p>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '10px'
                            }}
                        >
                            <ContinueButton
                                disabled={!isValid}
                                onClick={this.handleAddItemsContinue}
                                text={continueText}
                            />
                        </div>
                    </div>
                </div>
            );

            /* else if (this.state.state === 'FOLLOWUP') {
                <button className={`journeyq-button button-hg button-continue`} onClick={this.goToPopup}>Continue</button>
            } */
        } else if (this.state.state === JOURNEY_STEPS.SELECT_STEPS) {
            const numSelectedSteps = _.flatten(
                this.state.journeyStepsBySegment
            ).length;
            {
                /*allList.length < this.state.maxSelect
            ? <span className="journey-warning">You can select upto {this.state.maxSelect} cards</span>
            : <span className="journey-warning">All {this.state.maxSelect} are selected</span>
            */
            }
            return this.state.maxSelect == 1 ? null : (
                <button
                    className={`journeyq-button button-hg button-continue ${
                        numSelectedSteps < this.state.minSelect
                            ? 'disabled'
                            : ''
                    }`}
                    disabled={numSelectedSteps < this.state.minSelect}
                    onClick={this.handleAnswer}
                >
                    {continueText}
                </button>
            );
        }
        return null;
    };

    updateCurrentIndex = (index, emoji?: 'EMOJI' | 'TEXT' | 'BANK') => {
        this.setState({
            currentIndex: index,
            currentTypeAddEmoji: emoji
        });
    };
    handlePrefilterBucketOptions = (optionKeys) => {
        const { currentBucketIndex, journeySettings, filteredBuckets } =
            this.state;
        const { buckets } = journeySettings;

        const currentBucket = buckets[currentBucketIndex];

        const filteredCurrentBucketOptions = currentBucket.options.filter(
            (opt) => optionKeys.includes(`${opt.id}`)
        );
        this.setState(
            {
                currentBucketIndex:
                    currentBucketIndex < buckets.length - 1
                        ? currentBucketIndex + 1
                        : 0,
                filteredBuckets: [
                    ...filteredBuckets,
                    {
                        ...currentBucket,
                        options: filteredCurrentBucketOptions
                    }
                ],
                journeySettings: {
                    ...journeySettings,
                    buckets: [
                        ...buckets.slice(0, currentBucketIndex),
                        {
                            ...journeySettings.buckets[currentBucketIndex],
                            filteredOptions: filteredCurrentBucketOptions
                        },
                        ...buckets.slice(currentBucketIndex + 1)
                    ]
                },
                state:
                    currentBucketIndex < buckets.length - 1
                        ? JOURNEY_STEPS.PREFILTER
                        : JOURNEY_STEPS.INSTRUCTION
            },
            () => {
                const flattenedOptions = _.flatten(
                    this.state.filteredBuckets.map((b) => b.options)
                );
                console.log('1111', flattenedOptions, this.state.state);
                if (
                    this.state.state === JOURNEY_STEPS.INSTRUCTION &&
                    !flattenedOptions.length
                ) {
                    this.handleAnswer();
                }
            }
        );
    };

    validateJourneySteps() {
        const { filteredBuckets } = this.state;
        let isValid = true;

        filteredBuckets.forEach((bucket) => {
            if (!bucket.persistOptions && bucket?.options?.length) {
                isValid = false;
            }

            if (
                bucket.persistOptions &&
                !bucket.options.every((opt) => opt?.used)
            ) {
                isValid = false;
            }
        });

        this.setState({ isValid });
    }

    renderJourney() {
        const {
            startpointImageUrl,
            startpointText,
            startpointImageType,
            endpointImageUrl,
            endpointText,
            maxSteps
        } = this.state.journeySettings;
        const { currentBucketIndex, journeyStepsBySegment, filteredBuckets } =
            this.state;

        // OPTIONS
        const svgWidth = 1024;
        let totalSegmentLength = 0;
        const journeySteps = _.flatten(this.state.journeyStepsBySegment);
        const numSteps = journeySteps.length;
        const showAddButton =
            this.state.state === JOURNEY_STEPS.ADD_ITEMS &&
            numSteps <= maxSteps + 1;
        let hightLightCurrent = false;
        if (this.state.state === JOURNEY_STEPS.ADD_EMOJI) {
            hightLightCurrent = true;
        }

        // 'chunkList' is a 2d array of journey elements, where the elements
        // can either be journey options OR midpoints. each chunk has a maximum
        // of ELEMENTS_PER_ROW elements, not including any of the dropspots (which are
        // populated dynamically in JourneyElementContainer). Each segment of journey steps
        // is followed by a single midpoint, except for the last segment, since there are
        // a total of numMidpoints + 1 segments.
        const chunkList: JourneyElementData[][] = [[]];
        let currentChunkIndex = 0;
        let currentElemIndex = 0;
        journeyStepsBySegment.map((segment, segmentIdx) => {
            segment.map((step) => {
                // check for next chunk
                if (currentElemIndex === ELEMENTS_PER_ROW) {
                    currentElemIndex = 0;
                    currentChunkIndex += 1;
                    chunkList.push([]);
                }
                // add step (option) to chunk
                chunkList[currentChunkIndex].push(step);
                currentElemIndex += 1;
            });

            // skip last segment
            if (segmentIdx + 1 !== journeyStepsBySegment.length) {
                // check for next chunk
                if (currentElemIndex === ELEMENTS_PER_ROW) {
                    currentElemIndex = 0;
                    currentChunkIndex += 1;
                    chunkList.push([]);
                }
                // add midpoint
                chunkList[currentChunkIndex].push({
                    type: JOURNEY_ELEMENT_TYPES.MIDPOINT,
                    ...this.state.journeySettings.midpoints[segmentIdx]
                });
                currentElemIndex += 1;
            }
            totalSegmentLength += segment.length;
        });
        console.log('chunkList:', chunkList);

        const svgHeight = Math.max(180 * chunkList.length + 40, 300);

        // TEXT LITERALS
        // this.props.langId == 'en'
        //     ? 'I was actively looking. I...'
        //     : '我主动寻找找信息...';
        // this.props.langId == 'en' ? 'I happened to...' : '我碰巧......';
        const continueText = this.props.langId == 'en' ? 'Continue' : '继续';
        const warningText =
            this.props.langId == 'en'
                ? 'I would think you\'d need to go to a branch to open an account? Please make sure that you add a "visited a branch“ step, then "continue".'
                : '第一次开户好像应该是要本人去银行的吧？请添加一个和“去分行”相关的步骤，然后 “继续”';

        // important params
        const showStartpoint = Boolean(startpointImageUrl || startpointText);
        const showEndpoint = Boolean(endpointImageUrl || endpointText);
        const firstRowOffset = showStartpoint ? 150 : 0;

        const showBucketNavigator = filteredBuckets.length > 2;
        const currentBucket = filteredBuckets[currentBucketIndex];
        const nextBucket = filteredBuckets?.[currentBucketIndex + 1];
        const onLastBucket = currentBucketIndex === filteredBuckets.length - 1;
        const optionsContainerCss =
            filteredBuckets.length > 1 ? { display: 'flex' } : {};

        return (
            <React.Fragment>
                {this.state.showDeleteModal && (
                    <JourneyElementDeleteModal
                        openModal={this.state.showDeleteModal}
                        toggleModal={(showDeleteModal) =>
                            this.setState({ showDeleteModal })
                        }
                        onConfirm={() =>
                            this.deleteOption(
                                this.state.deleteJourneyElement?.bucketIndex,
                                this.state.deleteJourneyElement?.id
                            )
                        }
                        onCancel={() =>
                            this.setState({
                                showDeleteModal: false,
                                deleteJourneyElement: null
                            })
                        }
                    />
                )}
                {/* JOURNEY BUCKETS CONTAINER */}
                {[
                    JOURNEY_STEPS.ADD_ITEMS,
                    JOURNEY_STEPS.ADD_ITEMS_EXTRA
                ].includes(this.state.state) ? (
                    <div className="content from-bot bot-width">
                        <div
                            className="journeyq-options-container"
                            style={optionsContainerCss}
                        >
                            <div className="journeyq-tabs">
                                <div className="journeyq-tabs-header">
                                    {/* JOURNEY BUCKET LABEL */}
                                    <div className="journeyq-tabs-header-label">
                                        {currentBucket?.label || ''}
                                    </div>
                                    {/* JOURNEY BUCKET INSTRUCTIONS */}
                                    <div className="journeyq-tabs-header-instructions">
                                        {currentBucket.instructions.map(
                                            (instruction, i) => (
                                                <span
                                                    key={i}
                                                    style={instruction.style}
                                                >
                                                    {instruction.text}
                                                </span>
                                            )
                                        )}
                                    </div>
                                </div>

                                {/* JOURNEY BUCKETS OPTIONS */}
                                <div className="journeyq-options">
                                    <div className="journeyq-option-container">
                                        {filteredBuckets[
                                            currentBucketIndex
                                        ].options.map((opt: any) => {
                                            return (
                                                <JourneyOption
                                                    key={opt.id}
                                                    text={opt.text}
                                                    bucketIndex={
                                                        currentBucketIndex
                                                    }
                                                    color={
                                                        filteredBuckets[
                                                            currentBucketIndex
                                                        ]?.color || '#FFFFFF'
                                                    }
                                                    bgColor={
                                                        filteredBuckets[
                                                            currentBucketIndex
                                                        ]?.bgcolor || '#2D67CB'
                                                    }
                                                    used={opt?.used}
                                                    id={opt.id}
                                                    editable={opt?.editable}
                                                    removable={opt?.removable}
                                                    editOption={(id, label) => {
                                                        this.editOption(
                                                            currentBucketIndex,
                                                            id,
                                                            label
                                                        );
                                                    }}
                                                    deleteOption={(id) => {
                                                        this.setState({
                                                            showDeleteModal:
                                                                true,
                                                            deleteJourneyElement:
                                                                {
                                                                    bucketIndex:
                                                                        currentBucketIndex,
                                                                    id
                                                                }
                                                        });
                                                    }}
                                                />
                                            );
                                        })}
                                        {currentBucket.allowNewOptions ? (
                                            <AddEvent
                                                bgcolor={'#FFFFFF'}
                                                color={
                                                    filteredBuckets[
                                                        currentBucketIndex
                                                    ]?.bgcolor || '#2D67CB'
                                                }
                                                bucketIndex={currentBucketIndex}
                                                onSave={this.createNewOption}
                                                langId={this.props.langId}
                                            />
                                        ) : null}
                                    </div>
                                </div>

                                {/* JOURNEY BUCKETS BUTTONS */}
                                <div className="journeyq-tabs-buckets-buttons">
                                    {showBucketNavigator && (
                                        <>
                                            <button
                                                className={`journeyq-button-arrow button-arrow-previous`}
                                                onClick={() =>
                                                    this.setState({
                                                        currentBucketIndex:
                                                            currentBucketIndex -
                                                            1
                                                    })
                                                }
                                            >
                                                <span className="button-arrow-vector">
                                                    <UilArrowLeft size={32} />
                                                </span>
                                                <span>
                                                    {
                                                        filteredBuckets[
                                                            currentBucketIndex -
                                                                1
                                                        ].label
                                                    }
                                                </span>
                                            </button>
                                            <button
                                                className={`journeyq-button-arrow button-arrow-continue`}
                                                onClick={() =>
                                                    this.setState(
                                                        (prevState) => ({
                                                            ...prevState,
                                                            currentBucketIndex:
                                                                currentBucketIndex +
                                                                1
                                                        })
                                                    )
                                                }
                                                disabled={onLastBucket}
                                            >
                                                <span>
                                                    {
                                                        filteredBuckets[
                                                            currentBucketIndex +
                                                                1
                                                        ].label
                                                    }
                                                </span>
                                                <span className="button-arrow-vector">
                                                    <UilArrowRight size={32} />
                                                </span>
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                            {filteredBuckets.length > 1 && nextBucket && (
                                <div className="journeyq-tabs">
                                    <div className="journeyq-tabs-header">
                                        {/* JOURNEY BUCKET LABEL */}
                                        <div className="journeyq-tabs-header-label">
                                            {nextBucket.label}
                                        </div>
                                        {/* JOURNEY BUCKET INSTRUCTIONS */}
                                        <div className="journeyq-tabs-header-instructions">
                                            {nextBucket.instructions.map(
                                                (instruction, i) => (
                                                    <span
                                                        key={i}
                                                        style={
                                                            instruction.style
                                                        }
                                                    >
                                                        {instruction.text}
                                                    </span>
                                                )
                                            )}
                                        </div>
                                    </div>

                                    {/* JOURNEY BUCKETS OPTIONS */}
                                    <div className="journeyq-options">
                                        <div className="journeyq-option-container">
                                            {nextBucket.options.map(
                                                (opt: any) => {
                                                    return (
                                                        <JourneyOption
                                                            key={opt.id}
                                                            bucketIndex={
                                                                currentBucketIndex +
                                                                1
                                                            }
                                                            text={opt.text}
                                                            color={
                                                                nextBucket?.color ||
                                                                '#FFFFFF'
                                                            }
                                                            bgColor={
                                                                nextBucket?.bgcolor ||
                                                                '#2D67CB'
                                                            }
                                                            used={opt?.used}
                                                            id={opt.id}
                                                            editable={
                                                                opt?.editable
                                                            }
                                                            removable={
                                                                opt?.removable
                                                            }
                                                            editOption={(
                                                                id,
                                                                label
                                                            ) => {
                                                                this.editOption(
                                                                    currentBucketIndex +
                                                                        1,
                                                                    id,
                                                                    label
                                                                );
                                                            }}
                                                            deleteOption={(
                                                                id
                                                            ) => {
                                                                this.setState({
                                                                    showDeleteModal:
                                                                        true,
                                                                    deleteJourneyElement:
                                                                        {
                                                                            bucketIndex:
                                                                                currentBucketIndex +
                                                                                1,
                                                                            id
                                                                        }
                                                                });
                                                            }}
                                                        />
                                                    );
                                                }
                                            )}
                                            {nextBucket.allowNewOptions ? (
                                                <AddEvent
                                                    bgcolor={
                                                        nextBucket?.color ||
                                                        '#2D67CB'
                                                    }
                                                    color={
                                                        nextBucket.bgcolor ||
                                                        '#FFFFFF'
                                                    }
                                                    bucketIndex={
                                                        currentBucketIndex + 1
                                                    }
                                                    onSave={
                                                        this.createNewOption
                                                    }
                                                    langId={this.props.langId}
                                                />
                                            ) : null}
                                        </div>
                                    </div>

                                    {/* JOURNEY BUCKETS BUTTONS */}
                                    <div className="journeyq-tabs-buckets-buttons">
                                        {showBucketNavigator && (
                                            <>
                                                <button
                                                    className={`journeyq-button-arrow button-arrow-previous`}
                                                    onClick={() =>
                                                        this.setState({
                                                            currentBucketIndex:
                                                                currentBucketIndex -
                                                                1
                                                        })
                                                    }
                                                >
                                                    <span className="button-arrow-vector">
                                                        <UilArrowLeft
                                                            size={32}
                                                        />
                                                    </span>
                                                    <span>
                                                        {
                                                            filteredBuckets[
                                                                currentBucketIndex -
                                                                    1
                                                            ].label
                                                        }
                                                    </span>
                                                </button>
                                                <button
                                                    className={`journeyq-button-arrow button-arrow-continue`}
                                                    onClick={() =>
                                                        this.setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                currentBucketIndex:
                                                                    currentBucketIndex +
                                                                    1
                                                            })
                                                        )
                                                    }
                                                    disabled={onLastBucket}
                                                >
                                                    <span>
                                                        {
                                                            filteredBuckets[
                                                                currentBucketIndex +
                                                                    1
                                                            ].label
                                                        }
                                                    </span>
                                                    <span className="button-arrow-vector">
                                                        <UilArrowRight
                                                            size={32}
                                                        />
                                                    </span>
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : null}

                {/* JOURNEY PATH CONTAINER */}
                <div className="content from-bot bot-width">
                    <div
                        className="svg-container"
                        style={{ textAlign: 'center' }}
                    >
                        {/* JOURNEY PATH SVG */}
                        <svg
                            id={SVG_ELEMENT_ID}
                            version="1.1"
                            viewBox={`0 0 ${svgWidth} ${svgHeight}`}
                            preserveAspectRatio="xMinYMin meet"
                            className="svg-content"
                        >
                            {/* NOT SURE WHAT THIS DOES?? */}
                            <MarkersSVG />

                            {/* JOURNEY PATH LINE COMPONENT */}
                            <JourneyPath
                                // startPointX={firstRowOffset}
                                startPointX={0}
                                startPointY={0}
                                chunkList={chunkList}
                                firstRowOffset={firstRowOffset + 50}
                            />

                            {/* JOURNEY ELEMENT CONTAINER (OPTIONS, PLACEHOLDERS, & DROP ADD BUTTONS) */}
                            <JourneyElementContainer
                                chunkList={chunkList}
                                currentType={this.state.currentTypeAddEmoji}
                                handleDrop={this.handleDrop}
                                hightLightCurrent={hightLightCurrent}
                                currentIndex={this.state.currentIndex}
                                startpointText={startpointText}
                                startpointImageUrl={startpointImageUrl}
                                startpointImageType={startpointImageType}
                                endpointText={endpointText}
                                endpointImageUrl={endpointImageUrl}
                                currentState={this.state.state}
                                showAddButton={showAddButton}
                                handleDrag={this.handleDrag}
                                handleStepRemove={this.handleStepRemove}
                                showStartpoint={showStartpoint}
                                showEndpoint={showEndpoint}
                                firstRowOffset={firstRowOffset}
                                totalSegmentLength={totalSegmentLength}
                                followUpSettings={
                                    this.state.journeySettings?.followUpSettings
                                }
                            />
                        </svg>

                        {
                            /* JOURNEY VALIDATION UI */
                            this.state.showWarning ? (
                                <span className="journey-warning">
                                    {warningText}
                                </span>
                            ) : null
                        }
                        {
                            /* JOURNEY CONTINUE BUTTON */
                            this.continueButton(continueText)
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    render() {
        const {
            journeyConfirmTexts,
            journeyInstructionalTexts,
            instructionalMediaURL
        } = this.state.journeySettings;
        const { journeyStepsBySegment, currentBucketIndex } = this.state;
        return (
            <React.Fragment>
                {this.state.state === JOURNEY_STEPS.PREFILTER ? (
                    <>
                        {this.state.journeySettings.buckets[
                            currentBucketIndex
                        ]?.questionTexts.map((text, i) => (
                            <p
                                style={{ margin: '30px 0 15px 100px' }}
                                key={`${currentBucketIndex}-${i}`}
                            >
                                {text}
                            </p>
                        ))}
                        <MultipleChoice
                            key={this.state.currentBucketIndex}
                            question={{
                                ...this.props.question,
                                showNotApplicableOption: !!currentBucketIndex, // to hide NA from the first bucket mcq
                                selectedOptionType: OPTION_TYPES.TEXT_ONLY,
                                min: 1,
                                max: this.state.journeySettings.buckets[
                                    currentBucketIndex
                                ]?.options.length,
                                options: this.state.journeySettings.buckets[
                                    currentBucketIndex
                                ]?.options.map((opt) => ({
                                    ...opt,
                                    label: opt.text,
                                    texts: [
                                        {
                                            language: this.props.langId,
                                            text: opt.text
                                        }
                                    ],
                                    value: opt.id,
                                    optionKey: opt.id
                                }))
                            }}
                            langId={this.props.langId}
                            handleAnswer={(optionKeys) =>
                                this.handlePrefilterBucketOptions(optionKeys)
                            }
                        />
                    </>
                ) : this.state.state === JOURNEY_STEPS.INSTRUCTION ? (
                    <JourneyContinue
                        langId={this.props.langId}
                        instructionalTexts={journeyInstructionalTexts}
                        mediaURL={instructionalMediaURL}
                        buttons={[
                            {
                                text: 'Continue',
                                onClick: this.beginJourneyExercise,
                                disabled: false
                            }
                        ]}
                    />
                ) : (
                    /* JOURNEY PATH UI */
                    this.renderJourney()
                )}
                {this.state.state === JOURNEY_STEPS.FINAL_JOURNEY ? (
                    <JourneyContinue
                        langId={this.props.langId}
                        instructionalTexts={[
                            "Thanks for completing the exercise. If you are happy with what you've completed please continue. Or, you could click on ‘Re-do’ to restart from scratch."
                        ]}
                        buttons={[
                            {
                                text: 'Re-do',
                                onClick: this.restartJourney,
                                disabled: this.state.uploading,
                                className: 'button-cancel'
                            },
                            {
                                text: this.state.uploading ? (
                                    <MessageLoader />
                                ) : (
                                    'Continue'
                                ),
                                onClick: this.handleAnswer,
                                disabled: this.state.uploading
                            }
                        ]}
                    />
                ) : null}
                {
                    /* JOURNEY CONFIRM BUTTON */
                    this.state.state === JOURNEY_STEPS.JOURNEY_CONFIRM ? (
                        <JourneyContinue
                            langId={this.props.langId}
                            instructionalTexts={journeyConfirmTexts}
                            buttons={[
                                {
                                    text: 'Add more',
                                    onClick: this.proceedJourneyConfirm,
                                    disabled: false
                                },
                                {
                                    text: 'Continue',
                                    onClick: this.goToPopup,
                                    disabled: false
                                }
                            ]}
                        />
                    ) : null
                }
                {
                    /* JOURNEY FOLLOWUP QUESTION UI */
                    this.state.state === JOURNEY_STEPS.ADD_EMOJI ? (
                        <JourneyFollowupQuestion
                            journeyStepsBySegment={journeyStepsBySegment}
                            followUpSettings={
                                this.state.journeySettings?.followUpSettings
                            }
                            langId={this.props.langId}
                            currentIndex={this.state.currentIndex}
                            saveFollowUp={this.saveFollowUp}
                            updateCurrentIndex={this.updateCurrentIndex}
                            saveEverything={this.saveEverything}
                            question={this.props.question}
                        />
                    ) : null
                }

                {
                    /* NOT APPLICABLE BUTTON */
                    this.props.mode === JOURNEY_STEPS.SELECT_STEPS &&
                    this.props.question.showNotApplicableOption ? (
                        <div className="bottom-select-links bot-width">
                            <NotApplicableButton
                                onClick={this.props.handleNotApplicableAnswer}
                                text={getStringFromMultimediaTexts(
                                    this.props.question.notApplicableTexts,
                                    'en'
                                )}
                            />
                        </div>
                    ) : null
                }
            </React.Fragment>
        );
    }
}

export const Journey_NEW = DragDropContext(MultiBackend(HTML5ToTouch))(
    JourneyRaw
);
