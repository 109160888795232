import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import {
    Message,
    BMTYPES,
    ProjectInputSettings,
    AVATAR_TYPE,
    DIGITAL_AVATAR_PROVIDER
} from '@nexxt/common/types';
import emojiRegex from 'emoji-regex';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import {
    FontAwesomeIcon,
    FontAwesomeIconProps
} from '@fortawesome/react-fontawesome';
import {
    faVolumeXmark,
    faVolumeHigh,
    faUserSlash,
    faUser
} from '@fortawesome/free-solid-svg-icons';
import {
    Configuration,
    NewSessionData,
    StreamingAvatarApi
} from '@heygen/streaming-avatar';
import { AudioVisualizer } from './AudioVisualizer';
import * as AWS from 'aws-sdk';
import { loadModule } from 'cld3-asm';
import axios from 'axios';
import { AWS_POLLY_AVATARS } from '@nexxt/common/constants';

interface DigitalAvatarProps {
    messageLog: Message[];
    setMessageBubbleBlock: (blockIdx: number) => void;
    inputSettings: ProjectInputSettings;
    setResponseInputDelay: (isDelayed: boolean) => void;
    lang: string;
}

const HEYGEN_API_KEY = process.env.HEYGEN_API_KEY;
const AWS_POLLY_ACCESS_KEY_ID = process.env.AWS_POLLY_ACCESS_KEY_ID;
const AWS_POLLY_SECRET_ACCESS_KEY = process.env.AWS_POLLY_SECRET_ACCESS_KEY;

const HEYGEN_QUALITY = 'high';
const DELAY_BETWEEN_SYNC_MSGS_MS = 1000;

const EMOJI_REGEX = emojiRegex();

// Add a mapping of detected languages to Polly voice IDs
const languageVoiceMap = {
    en: 'Joanna',
    zh: 'Zhiyu', // Chinese (Simplified)
    pt: 'Camila', // Portuguese (Brazilian) (as Brazilian Portuguese is more common in Polly)
    fr: 'Celine', // French
    de: 'Marlene', // German
    da: 'Naja', // Danish
    nl: 'Lotte', // Dutch
    sv: 'Astrid', // Swedish
    fi: 'Suvi', // Finnish
    nn: 'Liv', // Norwegian
    ja: 'Mizuki', // Japanese
    ko: 'Seoyeon', // Korean
    es: 'Lucia', // Spanish (European)
    ar: 'Zeina', // Arabic
    hi: 'Aditi', // Hindi
    it: 'Carla', // Italian
    tr: 'Filiz', // Turkish
    pl: 'Maja', // Polish
    ru: 'Tatyana' // Russian
};

const polly = new AWS.Polly({
    region: 'us-east-1', // Change this to your region
    accessKeyId: AWS_POLLY_ACCESS_KEY_ID,
    secretAccessKey: AWS_POLLY_SECRET_ACCESS_KEY
});

interface StyledMuteButtonProps extends FontAwesomeIconProps {
    muted?: boolean;
}

const DigitalAvatarWrapper = styled.div`
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 50%;
    @media (max-width: 768px) {
        width: 110px;
        height: 110px;
    }
`;
const Video = styled.video`
    height: 300px;
    width: 300px;
    margin-left: -85px;
    margin-top: -66px;
    @media (max-width: 768px) {
        height: 250px;
        width: 250px;
        margin-top: -55px;
    }
`;
const DigitalLoadingContainer = styled.div`
    position: fixed;
    top: 75;
    right: 75;
    z-index: 10;
`;
const MuteIconContainer = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 99;
    border-radius: 50%;
    cursor: pointer;
`;
const VideoIconContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
    border-radius: 50%;
    cursor: pointer;
`;
const MuteIcon = styled(FontAwesomeIcon)<StyledMuteButtonProps>`
    padding: 9px;
    border-radius: 50%;
    aspect-ratio: 1;
    font-size: 1.25em;
    color: ${(props) => (props.muted ? '#c54e76' : '#2d67cb')};
    background-color: ${(props) =>
        props.muted ? 'rgba(249, 232, 249, 1)' : '#e3edff'};
`;

function useTaskQueue(params: { shouldProcess: boolean }): {
    tasks: ReadonlyArray<Task>;
    isProcessing: boolean;
    addTask: (task: Task) => void;
} {
    const [queue, setQueue] = React.useState<{
        isProcessing: boolean;
        tasks: Array<Task>;
    }>({ isProcessing: false, tasks: [] });

    React.useEffect(() => {
        if (!params.shouldProcess) return;
        if (queue.tasks.length === 0) return;
        if (queue.isProcessing) return;

        const processQueue = async () => {
            const task = queue.tasks[0];
            setQueue((prev) => ({
                isProcessing: true,
                tasks: prev.tasks.slice(1)
            }));

            // Set message delay to sync digital avatar speaking with message bubbles
            try {
                const result = await task();
                if (result?.durationMs) {
                    await new Promise((resolve) => {
                        setTimeout(resolve, result.durationMs);
                    });
                }
            } finally {
                setQueue((prev) => ({
                    isProcessing: false,
                    tasks: prev.tasks
                }));
            }
        };

        processQueue();
    }, [queue, params.shouldProcess]);

    return {
        tasks: queue.tasks,
        isProcessing: queue.isProcessing,
        addTask: React.useCallback((task) => {
            setQueue((prev) => ({
                isProcessing: prev.isProcessing,
                tasks: [...prev.tasks, task]
            }));
        }, [])
    };
}

type Task = () => Promise<{ durationMs?: number }>;

const DigitalAvatar: React.FunctionComponent<DigitalAvatarProps> = ({
    messageLog,
    setMessageBubbleBlock,
    inputSettings,
    setResponseInputDelay,
    lang
}: DigitalAvatarProps) => {
    const [data, setData] = useState<NewSessionData>();
    const [stream, setStream] = useState<MediaStream>();
    const [isAvatarMuted, setIsAvatarMuted] = useState(
        !inputSettings.digitalAvatar.unmuteAudioOnStart
    );
    const [isAvatarSpeaking, setIsAvatarSpeaking] = useState(false);
    const [isAvatarDisplayed, setIsAvatarDisplayed] = useState(
        inputSettings.digitalAvatar.type == AVATAR_TYPE.AUDIO &&
            !inputSettings.digitalAvatar.disableVideoOnStart
    );
    const [hasVideoPlayed, setHasVideoPlayed] = useState<boolean>(
        inputSettings.digitalAvatar.type == AVATAR_TYPE.AUDIO
    );
    const [hasCompletedMessageSync, setHasCompletedMessageSync] =
        useState<boolean>(false);
    const [debug, setDebug] = useState<string>();
    const taskQueue = useTaskQueue({ shouldProcess: true });
    const mediaStream = useRef<HTMLVideoElement>(null);
    const avatar = useRef(
        new StreamingAvatarApi(
            new Configuration({
                headers: {
                    'Content-Type': 'application/json',
                    'X-Api-Key': HEYGEN_API_KEY
                }
            })
        )
    );
    const speakingTimeOut = useRef(undefined);
    const [isHygenActive, setIsHygenActive] = useState<boolean>(
        inputSettings.digitalAvatar.type == AVATAR_TYPE.VIDEO
    );
    const [cldFactory, setCldFactory] = useState<any>(null);
    const audioQueueRef = useRef(Promise.resolve());

    const audio = useRef<HTMLAudioElement>(null);

    useEffect(() => {
        const initializeCld = async () => {
            const factory = await loadModule();
            setCldFactory(factory);
        };

        initializeCld();
    }, []);

    useEffect(() => {
        if (taskQueue.isProcessing) {
            setResponseInputDelay(true);
        } else {
            setResponseInputDelay(false);
        }
    }, [taskQueue.isProcessing]);

    useEffect(() => {
        if (isHygenActive) {
            initializeAvatar();
        }
    }, []);

    useEffect(() => {
        if (stream && mediaStream.current) {
            mediaStream.current.srcObject = stream;
            mediaStream.current.onloadedmetadata = () => {
                mediaStream.current.play().catch((error) => {
                    console.warn(
                        'Autoplay not allowed, playing muted video:',
                        error
                    );
                    setIsAvatarMuted(true);
                    mediaStream.current.play();
                });
                if (!inputSettings.digitalAvatar.disableVideoOnStart) {
                    setIsAvatarDisplayed(true);
                }
                setHasVideoPlayed(true);
            };
        }
    }, [stream, mediaStream]);

    useEffect(() => {
        if (audio.current) {
            audio.current.muted = isAvatarMuted;
        }
        if (mediaStream.current) {
            mediaStream.current.muted = isAvatarMuted;
        }
    }, [isAvatarMuted]);

    useEffect(() => {
        if (!hasVideoPlayed || !messageLog?.length) return;

        const addTriggerTaskForMsg = (msg: Message, msgIdx: number) => {
            const msgType = msg?.type;
            const text =
                msgType == BMTYPES.RICH_TEXT
                    ? msg.text.blocks.map((block) => block.text).join('\n')
                    : msgType == BMTYPES.TEXT
                    ? msg.text
                    : '';
            const processedText = text?.trim().replaceAll(EMOJI_REGEX, '');
            if (!processedText?.length) return;

            taskQueue.addTask(async () => {
                // TODO: better handling of muted state
                const { durationMs } = await triggerTask(
                    processedText,
                    msgIdx,
                    audio.current.muted
                );
                return { durationMs };
            });
        };

        if (hasCompletedMessageSync) {
            const msgIdx = messageLog.length - 1;
            const latestMsg = messageLog[msgIdx];
            addTriggerTaskForMsg(latestMsg, msgIdx);
        } else {
            messageLog.forEach((msg, index) => {
                addTriggerTaskForMsg(msg, index);
            });
            setHasCompletedMessageSync(true);
        }
    }, [messageLog, hasVideoPlayed]);

    const detectLanguage = (inputText: string) => {
        if (cldFactory) {
            const identifier = cldFactory.create(0, 1000);
            const result = identifier.findLanguage(inputText);
            return result.language || 'eng';
        }
    };

    async function initializeAvatar() {
        async function tryCreateAvatar(retries = 3) {
            try {
                const result = await avatar.current.createStartAvatar(
                    {
                        newSessionRequest: {
                            quality: HEYGEN_QUALITY,
                            avatarName:
                                inputSettings?.digitalAvatar
                                    ?.streamingAvatarId ||
                                'josh_lite3_20230714',
                            voice: {
                                voiceId: '433c48a6c8944d89b3b76d2ddcc7176a'
                            }
                        }
                    },
                    setDebug
                );
                return result;
            } catch (error) {
                if (retries > 0) {
                    console.log(`Retrying... (${retries} attempts left)`);
                    return tryCreateAvatar(retries - 1);
                } else {
                    console.log('Unable to connect to Heygen', error);
                    setHasVideoPlayed(true);
                    return;
                }
            }
        }

        try {
            const result = await tryCreateAvatar();
            if (result) {
                setData(result);
                setStream(avatar.current.mediaStream);
            }
        } catch (error) {
            console.error(
                'Unable to connect to HeyGen after 3 attempts',
                error.message
            );
        }
    }

    const getDeepgramAudio = async (
        text: string,
        detectedLang: string
    ): Promise<number> => {
        const selectedModel =
            inputSettings.digitalAvatar.streamingAvatarIds?.[
                detectedLang || lang
            ].value || inputSettings.digitalAvatar.streamingAvatarId;
        try {
            const response = await axios.post(
                `https://api.deepgram.com/v1/speak?model=${selectedModel}`,
                { text: text },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${process.env.DEEPGRAM_API_KEY}`
                    },
                    responseType: 'arraybuffer'
                }
            );

            const buffer = response.data;
            const blob = new Blob([buffer], { type: 'audio/mp3' });
            if (audio.current) {
                const url = window.URL.createObjectURL(blob);
                audio.current.src = url;

                return new Promise<number>((resolve, reject) => {
                    const handleLoadedMetadata = () => {
                        if (audio.current) {
                            const duration = audio.current.duration;
                            resolve(duration);
                        } else {
                            resolve(0);
                        }
                    };

                    const handleError = () => {
                        reject(new Error('Error loading audio metadata'));
                    };

                    if (
                        audio.current.readyState >=
                        HTMLMediaElement.HAVE_METADATA
                    ) {
                        handleLoadedMetadata();
                    } else {
                        audio.current.addEventListener(
                            'loadedmetadata',
                            handleLoadedMetadata
                        );
                        audio.current.addEventListener('error', handleError);
                    }
                });
            } else {
                throw new Error('Audio element is not available');
            }
        } catch (error) {
            console.error(
                'There was a problem with your fetch operation:',
                error
            );
            throw error;
        }
    };

    async function triggerTask(
        text: string,
        msgIdx: number,
        isMuted = isAvatarMuted,
        retries = 2
    ): Promise<{ durationMs: number }> {
        if (isHygenActive && stream) {
            try {
                const result = await handleAvatarSpeech(text, msgIdx);
                return result;
            } catch (error) {
                console.error('Heygen TTS failed', error);
            }
        }
        // TODO: detect language from message language
        let detectedLang;

        if (!detectedLang) {
            detectedLang = detectLanguage(text);
        }

        const useDeepgram =
            inputSettings.digitalAvatar.streamingAvatarIds?.[detectedLang]
                ?.provider === DIGITAL_AVATAR_PROVIDER.DEEPGRAM;

        if (useDeepgram) {
            try {
                clearTimeout(speakingTimeOut.current);
                const durationMs = await getDeepgramAudio(text, detectedLang);
                await queueAudioPlayback(
                    audio.current.src,
                    durationMs - 1000,
                    msgIdx,
                    isMuted
                );
                return { durationMs };
            } catch (error) {
                console.error(
                    'Deepgram TTS failed, falling back to Polly',
                    error
                );
                const result = await handlePollyFallback(
                    text,
                    msgIdx,
                    retries,
                    isMuted
                );
                return result;
            }
        } else {
            const result = await handlePollyFallback(
                text,
                msgIdx,
                retries,
                isMuted
            );
            return result;
        }
    }

    async function queueAudioPlayback(audioUrl, durationMs, msgIdx, isMuted) {
        audioQueueRef.current = audioQueueRef.current.then(
            () =>
                new Promise<void>((resolve) => {
                    const audioElement = new Audio(audioUrl);
                    audioElement.muted = isMuted;
                    audio.current = audioElement;

                    setIsAvatarSpeaking(true);
                    setMessageBubbleBlock(msgIdx);

                    audioElement.onended = () => {
                        setIsAvatarSpeaking(false);
                        resolve();
                    };

                    audioElement.onerror = (error) => {
                        console.error('Error loading audio:', error);
                        setIsAvatarSpeaking(false);
                        resolve();
                    };

                    audioElement.play().catch((error) => {
                        console.warn(
                            'Autoplay not allowed, handling error:',
                            error
                        );
                        audioElement.muted = true;
                        setIsAvatarSpeaking(false);
                        setIsAvatarMuted(true);
                        resolve();
                    });
                })
        );

        await audioQueueRef.current;
    }

    async function handlePollyFallback(
        text: string,
        msgIdx: number,
        retries: number,
        isMuted: boolean
    ): Promise<{ durationMs: number }> {
        // TODO: detect language from message language
        let detectedLang;

        if (!detectedLang) {
            detectedLang = detectLanguage(text);
        }

        const voiceId =
            inputSettings.digitalAvatar.streamingAvatarIds?.[detectedLang]
                ?.value ||
            languageVoiceMap[detectedLang] ||
            'Joanna';

        const validVoiceId = AWS_POLLY_AVATARS.find(
            (avatar) => avatar.value == voiceId
        );

        const selectedModel = validVoiceId ? voiceId : 'Joanna';
        console.log('Language ' + detectedLang + ' Speak by ' + voiceId);

        try {
            clearTimeout(speakingTimeOut.current);
            const params = {
                OutputFormat: 'mp3',
                Text: text,
                VoiceId: selectedModel
            };
            const response = await polly.synthesizeSpeech(params).promise();

            const audioBuffer = await new Response(
                response.AudioStream as Blob
            ).arrayBuffer();
            const blob = new Blob([audioBuffer], { type: 'audio/mp3' });
            const audioUrl = URL.createObjectURL(blob);
            audio.current.src = audioUrl;

            const durationMs = audio.current.duration;

            await queueAudioPlayback(
                audioUrl,
                durationMs - 1000,
                msgIdx,
                isMuted
            );
            return { durationMs };
        } catch (error) {
            if (retries > 0) {
                console.log(`Retrying Polly... (${retries} attempts left)`);
                return triggerTask(text, msgIdx, isMuted, retries - 1);
            } else {
                console.error(
                    'Failed to send text to Polly after retries',
                    error.message
                );
                return { durationMs: 0 };
            }
        }
    }

    async function handleAvatarSpeech(
        text: string,
        msgIdx: number
    ): Promise<{ durationMs: number }> {
        try {
            clearTimeout(speakingTimeOut.current);
            const response = await avatar.current.speak({
                taskRequest: { text: text, sessionId: data?.sessionId }
            });
            setIsAvatarSpeaking(true);
            setMessageBubbleBlock(msgIdx);
            const durationMs = response.data.durationMs - 1000;
            speakingTimeOut.current = setTimeout(() => {
                setIsAvatarSpeaking(false);
            }, response.data.durationMs);
            return { durationMs: Math.max(durationMs, 0) };
        } catch (error) {
            console.error(
                'Failed to send text to avatar after retries',
                error.message
            );
            return { durationMs: 0 };
        }
    }

    const toggleMute = () => {
        setIsAvatarMuted((prevMuted) => {
            if (audio.current) {
                audio.current.muted = !prevMuted;
            }
            if (mediaStream.current) {
                mediaStream.current.muted = !prevMuted;
            }
            return !prevMuted;
        });
    };

    const toggleVideo = () => {
        setIsAvatarDisplayed((prev) => !prev);
    };

    return (
        <>
            <DigitalAvatarWrapper>
                {!hasVideoPlayed && (
                    <DigitalLoadingContainer>
                        <CircularProgress />
                    </DigitalLoadingContainer>
                )}
                <div
                    style={{
                        display:
                            hasVideoPlayed &&
                            inputSettings.digitalAvatar
                                .enableAudioAvatarAnimation &&
                            isAvatarDisplayed
                                ? 'block'
                                : 'none'
                    }}
                >
                    <AudioVisualizer
                        isActive={isAvatarSpeaking}
                        background={'#e7e7e7'}
                    />
                </div>
                <div
                    style={{
                        display:
                            isAvatarDisplayed &&
                            inputSettings.digitalAvatar.type !==
                                AVATAR_TYPE.AUDIO
                                ? 'block'
                                : 'none'
                    }}
                >
                    <Video
                        id="mediaElement"
                        className="videoEle show"
                        autoPlay
                        ref={mediaStream}
                        playsInline
                        muted={isAvatarMuted}
                    />
                </div>
            </DigitalAvatarWrapper>
            <audio ref={audio} />
            {hasVideoPlayed && (
                <MuteIconContainer onClick={toggleMute}>
                    <MuteIcon
                        icon={isAvatarMuted ? faVolumeXmark : faVolumeHigh}
                        muted={isAvatarMuted}
                    />
                </MuteIconContainer>
            )}
            {hasVideoPlayed &&
                (inputSettings?.digitalAvatar?.enableAudioAvatarAnimation ||
                    inputSettings?.digitalAvatar?.type !==
                        AVATAR_TYPE.AUDIO) && (
                    <VideoIconContainer onClick={toggleVideo}>
                        <MuteIcon
                            icon={!isAvatarDisplayed ? faUserSlash : faUser}
                            muted={!isAvatarDisplayed}
                        />
                    </VideoIconContainer>
                )}
        </>
    );
};

export default DigitalAvatar;
