import * as React from 'react';
import {
    DropTarget,
    ConnectDropTarget,
    DropTargetConnector,
    DropTargetMonitor,
    DragSource,
    ConnectDragSource,
    DragSourceConnector,
    DragSourceMonitor,
    DragSourceSpec
} from 'react-dnd';

import ItemTypes from '../constant';
import { findDOMNode } from 'react-dom';
import { XYCoord } from 'dnd-core';
import { Color } from 'csstype';
import { TextDialogWrapper, BigCircle } from './SVGComponents';
import { BANK_PIC_URLS } from '../constant';

class JourneyElement extends React.Component<JourneyElementProps> {
    svg: SVGSVGElement | null = null;
    pt = null;
    componentDidMount() {
        this.svg = document.querySelector('svg');
        this.pt = this.svg.createSVGPoint();
    }
    cursorPoint(evt: XYCoord) {
        if (this.pt && this.svg && evt) {
            this.pt.x = evt.x;
            this.pt.y = evt.y;
            // console.log(this.pt.matrixTransform(this.svg.getCTM().inverse()),this.pt.matrixTransform(this.svg.getScreenCTM().inverse()))
            return this.pt.matrixTransform(this.svg.getScreenCTM().inverse());
        } else {
            return {
                x: 0,
                y: 0
            };
        }
    }
    render() {
        // const bankImageURL =
        //     this.props.firstBank in BANK_PIC_URLS
        //         ? BANK_PIC_URLS[this.props.firstBank]
        //         : BANK_PIC_URLS['DEFAULT'];
        const {
            isDragging,
            sourceOffset,
            connectDropTarget,
            isSelected,
            isOver,
            canDrop,
            text,
            translateX,
            translateY,
            bgcolor,
            connectDragSource
        } = this.props;
        const isActive = canDrop && isOver;
        let fillColor = '#ccc';
        if (canDrop) {
            if (isOver) {
                fillColor = '#66cc00';
            } else {
                fillColor = 'blue';
            }
        }

        /* return (<g ref={connectDropTarget as any} transform={`translate(${this.props.translateX || 0}, ${this.props.translateY || 0})`}>
                <rect fill={fillColor} x="2" y="10" rx="3" ry="3" width="80" height="80" />
                <text x="9" y="42" font-family="Open Sans Condensed" font-size="12" stroke="none" fill="#fff" font-weight="900" >Workflow
                    <tspan x="9" dy="17">{text}</tspan>
                </text>
			</g>); */
        const sourceOffset2 = this.cursorPoint(sourceOffset);
        const ADDITEMS_MODE = this.props.currentState === 'ADDITEMS';
        const cursorClass =
            this.props.currentState === 'ADDITEMS'
                ? 'cursor-move'
                : this.props.currentState === 'SELECTSTEPS'
                ? 'cursor-pointer'
                : '';

        // render the appropriate kind of element
        if (!this.props.type || this.props.type === 'TEXT') {
            // show TEXT element
            const textElement = (
                <g className={cursorClass}>
                    <TextDialogWrapper
                        {...this.props}
                        text={text}
                        isSelected={ADDITEMS_MODE ? isSelected : undefined}
                        translateX={translateX}
                        translateY={translateY}
                        bgcolor={bgcolor || '#F2AF00'}
                        smiley={this.props.smiley}
                        banks={this.props.banks}
                        currentType={this.props.currentType}
                        hightLightCurrent={this.props.hightLightCurrent}
                        removeDialog={() =>
                            this.props.removeDialog(this.props.index)
                        }
                        selectCurrentDialog={() =>
                            this.props.selectCurrentDialog(this.props.index)
                        }
                        currentState={this.props.currentState}
                    />
                </g>
            );
            return ADDITEMS_MODE ? (
                <React.Fragment>
                    {connectDragSource(textElement)}
                    {isDragging ? (
                        <g
                            style={{
                                transform: sourceOffset
                                    ? `translate(${
                                          sourceOffset2.x -
                                          24 -
                                          +translateX +
                                          10
                                      }px, ${
                                          sourceOffset2.y -
                                          80 -
                                          +translateY -
                                          10
                                      }px)`
                                    : ''
                            }}
                        >
                            <TextDialogWrapper
                                text={text}
                                translateX={0}
                                translateY={0}
                                bgcolor={bgcolor || '#F2AF00'}
                                {...this.props}
                                smiley={this.props.smiley}
                                banks={this.props.banks}
                                currentType={this.props.currentType}
                                hightLightCurrent={this.props.hightLightCurrent}
                                removeDialog={() => {}}
                                selectCurrentDialog={() => {}}
                                currentState={null}
                            />
                        </g>
                    ) : null}
                </React.Fragment>
            ) : (
                textElement
            );
        } else if (this.props.type === 'BANK') {
            // show BANK image as element
            return (
                <BigCircle
                    translateX={translateX}
                    translateY={translateY}
                    image={this.props.imgUrl}
                    text={this.props.text}
                    orientation="bottom"
                    radius={40}
                />
            );
        } else if (this.props.type === 'MIDPOINT') {
            // show MIDPOINT element
            // (this can be combined with BANK case above..)
            return (
                <React.Fragment>
                    {connectDragSource(
                        <g className="cursor-move">
                            <BigCircle
                                translateX={translateX}
                                translateY={translateY}
                                image={this.props.imgUrl}
                                text={this.props.text}
                                orientation="top"
                                radius={30}
                            />
                        </g>
                    )}
                    {isDragging ? (
                        <g
                            style={{
                                transform: sourceOffset
                                    ? `translate(${
                                          sourceOffset2.x - 24 + 30
                                      }px, ${sourceOffset2.y - 80 - 30}px)`
                                    : ''
                            }}
                        >
                            <BigCircle
                                translateX={'0'}
                                translateY={'0'}
                                image={this.props.imgUrl}
                                orientation="top"
                                radius={30}
                            />
                        </g>
                    ) : null}
                </React.Fragment>
            );
        }
    }
}

interface CardTargetCollectedProps {
    connectDropTarget: ConnectDropTarget;
}

const cardTarget = {
    hover(
        props: JourneyElementProps,
        monitor: DropTargetMonitor,
        component: React.Component | null
    ) {
        if (!component) {
            return null;
        }
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Determine rectangle on screen
        // eslint-disable-next-line react/no-find-dom-node
        const hoverBoundingRect = (
            findDOMNode(component) as Element
        ).getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY =
            (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY =
            (clientOffset as XYCoord).y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        /* if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
			return
		} */

        // Dragging upwards
        // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        // 	return
        // }

        console.log(dragIndex, hoverIndex);
        // props.moveCard(dragIndex, hoverIndex);

        // Time to actually perform the action
        // props.moveCard(dragIndex, hoverIndex)

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        // monitor.getItem().index = hoverIndex
        return {
            id: props.id,
            index: props.index,
            text: props.text
        };
    },
    drop(
        props: JourneyElementProps,
        monitor: DropTargetMonitor,
        component: JourneyElement | null
    ) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;
        props.moveCard(dragIndex, hoverIndex);
        console.log(
            'Dropped',
            dragIndex,
            hoverIndex,
            props,
            monitor.getItem(),
            monitor.getItemType(),
            component
        );
    }
};

const cardSource = {
    beginDrag(props: JourneyElementProps) {
        return {
            id: props.id,
            index: props.index,
            type: props.type
        };
    }
};

export interface DroppableProps {
    canDrop: boolean;
    isOver: boolean;
    isDragging: boolean;
    connectDropTarget: ConnectDropTarget;
}

interface DragSourceCollectedProps {
    isDragging: boolean;
    connectDragSource: ConnectDragSource;
    sourceOffset: XYCoord;
}

export interface JourneyElementProps
    extends DroppableProps,
        DragSourceCollectedProps {
    translateX: string;
    translateY?: string;
    bgcolor?: Color;
    hightLightComponent?: boolean;
    hightLightCurrent?: boolean;

    type?: 'TEXT' | 'ADDNEW' | 'BANK' | 'MIDPOINT';
    /* extend from base props for journey */
    id: any;
    text: string;
    isSelected: boolean;
    index: number;
    banks?: any[];
    smiley?: any;
    currentType?: 'BANK' | 'EMOJI';
    // firstBank?: string;
    imgUrl: string;

    moveCard: (dragIndex: number, hoverIndex: number) => void;
    removeDialog: (index: number) => void;
    currentState?:
        | 'ADDITEMS'
        | 'ADDEMOJIS'
        | 'FINALJOURNEY'
        | 'FOLLOWUP'
        | 'SELECTSTEPS';
    selectCurrentDialog: (index: number) => void;
}

export default DropTarget<JourneyElementProps, CardTargetCollectedProps>(
    [ItemTypes.DIALOG],
    cardTarget,
    (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
    })
)(
    DragSource<JourneyElementProps, DragSourceCollectedProps>(
        ItemTypes.DIALOG,
        cardSource,
        (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
            connectDragSource: connect.dragSource(),
            connectDragPreview: connect.dragPreview(),
            isDragging: monitor.isDragging(),
            sourceOffset: monitor.getClientOffset()
        })
    )(JourneyElement as any)
) as any;
