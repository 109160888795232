import * as React from 'react';
import {
    DOCFileIcon,
    PDFFileIcon,
    TXTFileIcon,
    VideoIcon as VIcon
} from '../style/SVG';

export interface IProps {
    type: string;
    link?: string;
}

export const FileIcon: React.SFC<IProps> = (props) => {
    const { type, link } = props;
    let Icon: React.ElementType = null;
    switch (type.toLowerCase()) {
        case 'pdf':
            Icon = PDFFileIcon;
            break;
        case 'doc':
        case 'docx':
            Icon = DOCFileIcon;
            break;
        case 'txt':
        default:
            Icon = TXTFileIcon;
    }
    if (link) {
        <a className="link" href={link} download>
            <Icon />
        </a>;
    }
    return <Icon />;
};

export const VideoIcon: React.SFC<any> = (props) => {
    const { type, link } = props;
    const Icon: React.ElementType = VIcon;

    if (link) {
        <a className="" href={link} download>
            <Icon />
        </a>;
    }
    return <Icon />;
};
