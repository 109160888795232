import * as React from 'react';
import * as PropTypes from 'prop-types';
import '../../style/PopupCard.scss';
import { IPopupOptions } from './types';
import { SlideInUpDiv } from '../../style/Animations';

interface JourneyFollowupContinueProps {
    langId: string;
    goToPopup: () => void;
    text: string;
}

export const JourneyFollowupContinue: React.FunctionComponent<JourneyFollowupContinueProps> =
    (props) => {
        const { langId, text } = props;

        const continueText = langId == 'en' ? 'Continue' : '继续';

        return (
            <React.Fragment>
                <div className="content from-bot bot-width">
                    <div
                        className="plain-text popup-wrapper"
                        style={{ textAlign: 'center' }}
                    >
                        <div className="popup-container-wrapper">
                            <SlideInUpDiv
                                key={'bottomText-followup'}
                                className="buttons-container"
                            >
                                <div className="question m">{text}</div>

                                <div className="footer">
                                    <button
                                        className={`journeyq-button button-hg button-continue`}
                                        onClick={props.goToPopup}
                                    >
                                        {continueText}
                                    </button>
                                </div>
                            </SlideInUpDiv>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

JourneyFollowupContinue.propTypes = {
    langId: PropTypes.string,
    goToPopup: PropTypes.func
};

/* Todo: Separate Folder */
export interface IPopupButtonsProps {
    buttonOptions: IPopupOptions;
    value: string | string[];
    langId: any;
    isSelected: boolean;
    onClick: (option: IPopupOptions) => void;
}

/* Use Flex design */
export const PopupButtons: React.FunctionComponent<IPopupButtonsProps> = (
    props
) => {
    const buttonText = props.buttonOptions.texts.filter(
        (t) => (t.language = props.langId)
    )[0];
    if (props.buttonOptions.imgUrl) {
        return (
            <div
                className={`popup-button  popup journey ${
                    props.isSelected ? ' active ' : ''
                }`}
                style={{ width: '80px', height: '80px', margin: '5px' }}
                onClick={() => props.onClick(props.buttonOptions)}
            >
                <span className="checkmark">
                    <span className="checkmark_stem"></span>
                    <span className="checkmark_kick"></span>
                </span>
                <span className="p-icon" style={{ marginTop: '0px' }}>
                    <img
                        src={`${props.buttonOptions.imgUrl}`}
                        className="img"
                    />
                </span>
                <span className="p-text">{buttonText.text}</span>
            </div>
        );
    }
    return (
        <div
            className="popup-button"
            onClick={() => props.onClick(props.buttonOptions)}
        >
            <span className="p-text">{buttonText.text}</span>
        </div>
    );
};

PopupButtons.propTypes = {
    buttonOptions: PropTypes.any,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    langId: PropTypes.any,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func
};
