import * as React from 'react';
import * as PropTypes from 'prop-types';
import { BaseInput, BaseProps } from './_BaseInput';
import styled from 'styled-components';
import NotApplicableButton from '../components/NotApplicableButton';
import ContinueButton from '../components/ContinueButton';
import {
    STATIC_STORAGE_URL,
    NA_OPTION_VALUE,
    iconURLs,
    ICON_OPTION_VALUE_PREFIX
} from '@nexxt/common/constants';
import { getStringFromMultimediaTexts } from '@nexxt/common/services/TextService';
import { IconAnswer } from '@nexxt/common/types';

const IconPic = styled.input`
    width: 60px;
    height: 60px;
    margin: 0 1%;
`;

interface Props extends BaseProps {
    handleAnswer: (answer: {
        label: string | number;
        value: string;
        optionKey: string;
    }) => void;
    isEmbedded: boolean;
}

interface State {
    rating: number | null;
    hover: number | null;
}

export class IconsRating extends BaseInput<Props, State> {
    state: State = {
        rating: null,
        hover: null
    };

    async componentDidMount() {
        if (this.props.previousAnswer) {
            this.setState({
                rating: parseInt(
                    (this.props.previousAnswer as IconAnswer).option.value
                )
            });
        }
    }

    handleHover = (e) => {
        this.setState({
            hover: e.target.value
        });
    };

    handleLeave = () => {
        this.setState({
            hover: null
        });
    };

    handleClick = (e) => {
        this.setState({
            rating: e.target.value
        });
    };

    selectedIcon = (i: number) => {
        if (this.state.hover > i || this.state.rating > i) {
            return true;
        } else {
            return false;
        }
    };

    submitRating = () => {
        const { rating } = this.state;

        this.props.handleAnswer({
            label: rating as number,
            value: `${rating}`,
            optionKey: `${this.props.question.id}_${ICON_OPTION_VALUE_PREFIX}${rating}`
        });
    };

    render() {
        const disabled = this.state.rating === null;
        const { langId, question } = this.props;
        const {
            showNotApplicableOption,
            notApplicableTexts,
            continueTexts,
            customStyle,
            iconType,
            iconNumber
        } = question;

        const notApplicableText = getStringFromMultimediaTexts(
            notApplicableTexts,
            langId
        );

        const continueText = getStringFromMultimediaTexts(
            continueTexts,
            langId
        );

        const icons: any[] = [];
        for (let i = 0; i < iconNumber; i++) {
            icons.push(
                <Icon
                    value={i}
                    key={i}
                    registerSelection={this.handleHover}
                    onClick={this.handleClick}
                    leave={this.handleLeave}
                    selected={this.selectedIcon(i)}
                    iconType={iconType}
                />
            );
        }

        return (
            <div className={customStyle}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    {icons}
                </div>

                <div className="bottom-select-links bot-width">
                    {showNotApplicableOption && (
                        <NotApplicableButton
                            onClick={() =>
                                this.props.handleAnswer({
                                    label: notApplicableText,
                                    value: NA_OPTION_VALUE,
                                    optionKey: `${this.props.question.id}_${NA_OPTION_VALUE}`
                                })
                            }
                            selected={this.state.rating === 0}
                            text={notApplicableText}
                        />
                    )}

                    <ContinueButton
                        onClick={() => {
                            this.submitRating();
                        }}
                        disabled={disabled}
                        text={continueText}
                    />
                </div>
            </div>
        );
    }
}

interface IconProps {
    iconType: string;
    selected: boolean;
    registerSelection: (e: any) => void;
    onClick: (e: any) => void;
    value: number;
    leave: () => void;
}

const Icon: React.FC<IconProps> = (props) => {
    const unselectedIconURL = iconURLs[props.iconType].unselected;
    const selectedIconURL = iconURLs[props.iconType].selected;

    return (
        <IconPic
            type="image"
            src={props.selected ? selectedIconURL : unselectedIconURL}
            onMouseEnter={props.registerSelection}
            onClick={props.onClick}
            value={props.value + 1}
            onMouseLeave={props.leave}
        />
    );
};

Icon.propTypes = {
    iconType: PropTypes.string,
    selected: PropTypes.bool,
    registerSelection: PropTypes.func,
    onClick: PropTypes.func,
    value: PropTypes.number,
    leave: PropTypes.func
};
