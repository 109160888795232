import * as React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import '../style/BottomModal.scss';

interface BottomModalProps {
    onClose?: any;
    children: React.ReactNode;
}

const handleOutsideClick = (ref, handler, eventType = 'click') => {
    React.useEffect(() => {
        const clicklistener = (event) => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }

            handler();
        };

        const esclistener = (event) => {
            if (event.key === 'Escape') {
                handler();
            }
        };

        document.addEventListener('keyup', esclistener);
        document.addEventListener(eventType, clicklistener);

        return () => {
            document.removeEventListener('keyup', esclistener);
            document.removeEventListener(eventType, clicklistener);
        };
    }, []);
};
export function BottomModal({ onClose, children }: BottomModalProps) {
    const modalRef = React.useRef();
    const divRef = React.useRef();
    const [isClosing, setIsClosing] = React.useState(false);

    const handleClose = () => {
        setIsClosing(true);

        setTimeout(() => {
            onClose();
        }, 200);
    };

    const preventDefault = (e) => e.preventDefault();

    React.useEffect(() => {
        divRef &&
            divRef.current &&
            (divRef.current as any).addEventListener(
                'mousewheel',
                preventDefault,
                {
                    passive: false
                }
            );

        return () => {
            divRef &&
                divRef.current &&
                (divRef.current as any).removeEventListener(
                    'mousewheel',
                    preventDefault,
                    {
                        passive: false
                    }
                );
        };
    }, [divRef]);

    handleOutsideClick(modalRef, handleClose);

    return (
        <div
            className={`bottom-modal--container ${isClosing ? '--close' : ''}`}
            ref={divRef}
            style={isMobile || isTablet ? { height: window.innerHeight } : {}}
        >
            <div
                ref={modalRef}
                className={`bottom-modal ${isClosing ? '--close' : ''}`}
            >
                <div className="bottom-modal--body">{children}</div>
            </div>
        </div>
    );
}

export default BottomModal;
