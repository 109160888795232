import * as React from 'react';
import * as _ from 'lodash';
import { BaseInput, BaseProps } from './_BaseInput';
import { Stage, Layer, Rect } from 'react-konva';
import { CanvasImage } from '../components/CanvasImage';
import {
    getStringFromMultimediaTexts,
    getTranslation
} from '@nexxt/common/services/TextService';
import {
    formatBoxes,
    isKonvaTouchEnabled,
    handleKonvaTouch,
    handleKonvaTouchEnd,
    zoomKonvaStage,
    getAnnotationEmoji,
    orderByOverlap,
    getAnnotationColorByButton
} from '@nexxt/common/utils/question';
import { OptionButton, OptionData, QTYPES } from '@nexxt/common/types';
import '../style/Hotspot.scss';
import ContinueButton from '../components/ContinueButton';
import {
    BUILTIN_OPTION_ID,
    KEY_NAME,
    NA_OPTION_VALUE
} from '@nexxt/common/constants';
import { PERFECT, pickStatus } from '../utils/questions';
import axios from 'axios';
import { dataURLtoBlob } from '../utils/upload';
import NotApplicableButton from '../components/NotApplicableButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { UilTrashAlt, UilTimes } from '@iconscout/react-unicons';

const { translate } = require('react-i18next');

//package ID 1137
interface HotspotProps extends BaseProps {
    handleAnswer: (
        options: OptionData[] | OptionData,
        dataURL: string,
        width: number
    ) => void;
    isEmbedded: boolean;
}

interface HotspotState {
    image: HTMLImageElement | null;
    ratio: number;
    originalWidth: number;
    width: number;
    height: number;
    boxAreas: any[];
    currentHover: any;
    currentSelected: any;
    currentAnnotation: CurrentAnnotation;
    disabled: boolean;
    showModal: boolean;
    mode: string;
    uploading: boolean;
    error: string;
    showComment: boolean;
    editing: boolean;
    containerSize: { width: number; height: number };
    isValid: boolean;
    isSlowLoading: boolean;
}

interface CurrentAnnotation extends OptionButton {
    verbatim: string;
}

enum HOTSPOT_MODE {
    SINGLE = 'SINGLE',
    MULTIPLE = 'MULTIPLE'
}

@translate('translation')
export class Hotspot extends BaseInput<HotspotProps, HotspotState> {
    state: HotspotState = {
        image: null,
        boxAreas: [],
        originalWidth: 0,
        ratio: null,
        width: 0,
        height: 0,
        currentHover: null,
        currentSelected: null,
        currentAnnotation: null,
        disabled: true,
        showModal: false,
        mode:
            this.props.question.buttons.length === 1
                ? HOTSPOT_MODE.SINGLE
                : HOTSPOT_MODE.MULTIPLE,
        uploading: false,
        error: null,
        showComment: false,
        editing: false,
        containerSize: { width: 0, height: 0 },
        isValid: false,
        isSlowLoading: false
    };

    private stageRef;
    stickyFooter: HTMLDivElement;
    lastCenter = null;
    lastDist = 0;
    hotspotWrapper: HTMLDivElement;

    componentDidMount() {
        const STICKY_FOOTER_HEIGHT = this.stickyFooter?.clientHeight || 40;
        this.props.setBottomOffset(STICKY_FOOTER_HEIGHT);

        let containerWidth;

        const containerHeight =
            this.props.question.type === QTYPES.HOTSPOT
                ? window.innerHeight > 800
                    ? window.innerHeight - 390
                    : window.innerHeight - 240
                : window.innerHeight - 100;

        if (this.props.isEmbedded && this.hotspotWrapper.clientWidth <= 480) {
            containerWidth = this.hotspotWrapper.clientWidth;
        } else {
            containerWidth = window.innerWidth > 750 ? 750 : window.innerWidth;
        }

        this.setState({
            containerSize: {
                width: containerWidth,
                height: containerHeight
            }
        });

        document.addEventListener('keydown', this.handlePressEscape, false);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.ratio !== prevState.ratio) {
            this.setState({
                boxAreas: formatBoxes(
                    this.props.question.options,
                    this.state.ratio
                )
            });
        }

        if (this.state.height !== prevState.height) {
            const containerHeight =
                this.props.question.type === QTYPES.HOTSPOT
                    ? window.innerHeight > 800
                        ? window.innerHeight - 390
                        : window.innerHeight - 240
                    : window.innerHeight - 100;
            this.setState({
                containerSize: {
                    width: this.state.containerSize.width,
                    height:
                        this.state.height < containerHeight
                            ? this.state.height
                            : containerHeight
                }
            });
        }

        if (this.state.showModal !== prevState.showModal) {
            // disable `new message` notification
            const STICKY_FOOTER_HEIGHT = this.stickyFooter?.clientHeight || 40;
            this.props.setBottomOffset(STICKY_FOOTER_HEIGHT);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handlePressEscape, false);
    }
    /**
     * load an image from CanvasImage.tsx along with the resized ratio
     * @param imageProps
     * @param ratio
     */
    handleImage = (
        imageProps: HTMLImageElement,
        originalWidth: number,
        ratio: number,
        width?: number,
        height?: number
    ) => {
        this.setState(
            {
                image: imageProps,
                originalWidth,
                ratio,
                width,
                height,
                isSlowLoading: false
            },
            () => {
                this.props.scrollToBottom();
            }
        );
    };

    handleSlowLoading = () => {
        setTimeout(() => {
            if (!this.state.image) {
                this.setState({ isSlowLoading: true });
            }
        }, 2000);
    };

    validateNumSelections = (boxAreas = this.state.boxAreas) => {
        let disabled = true;
        const { question } = this.props;

        const status = pickStatus(
            boxAreas.filter((box) => box.selection).length,
            question.min,
            question.max
        );

        if (status === 100 || status === PERFECT) {
            disabled = false;
        }

        return disabled;
    };

    /**
     * Add selection field in the boxArea state
     * @param area
     * @param selection
     */
    handleChoice = (area, selection) => {
        const { question } = this.props;
        const { boxAreas, mode } = this.state;
        let disabled = true;
        let currentSelected = area;
        let currentAnnotation = selection;
        let error = null;
        const newSelection = {
            label: selection.label,
            value: selection.value,
            verbatim: selection.verbatim || ''
        };
        const selectedIndex = boxAreas.findIndex((x) => x.id === area.id);
        if (
            question.type === QTYPES.HOTSPOT &&
            !(
                question.max == 1 &&
                question.buttons.length == 1 &&
                !question.showFollowUp
            )
        ) {
            if (
                this.props.question.showFollowUp &&
                (!selection?.verbatim || selection?.verbatim.trim() === '')
            ) {
                error = this.props.t('LABEL.INSTRUCTIONS.ANNOTATION.COMMENT');
            } else if (!selection?.label) {
                error = this.props.t('LABEL.VALIDATE.SELECT');
            } else {
                if (
                    mode === HOTSPOT_MODE.SINGLE &&
                    !question.showFollowUp &&
                    currentSelected?.selection
                ) {
                    // remove selection without showing modal when a hotspot question has a single button and no follow up
                    delete area.selection;
                } else {
                    area = {
                        ...area,
                        selection: newSelection
                    };
                }
                currentSelected = null;
                currentAnnotation = null;

                boxAreas.splice(selectedIndex, 1, area);
            }

            disabled = this.validateNumSelections();
        } else {
            // QTYPES === TREEMAN -> reset the previous selections(SINGLE CHOICE)
            currentSelected = null;
            currentAnnotation = null;
            disabled = false;
            boxAreas.map((box) => {
                delete box.selection;
                return box;
            });

            area = {
                ...area,
                selection: {
                    label: selection.label,
                    value: selection.value
                }
            };
            boxAreas.splice(selectedIndex, 1, area);
        }

        this.setState((prevState) => ({
            ...prevState,
            boxAreas,
            currentSelected,
            currentAnnotation,
            error,
            disabled
        }));
    };

    handleDeleteSelection = (id) => {
        const newBoxAreas = [...this.state.boxAreas];
        const selectedIndex = newBoxAreas.findIndex((x) => x.id === id);
        newBoxAreas[selectedIndex].selection = null;

        const disabled = this.validateNumSelections(newBoxAreas);

        this.setState({
            boxAreas: newBoxAreas,
            currentSelected: null,
            disabled
        });
    };

    /**
     * Clear currentSelected field by pressing escape key
     * @param event
     */
    handlePressEscape = (event) => {
        if (event.key === KEY_NAME.ESCAPE) {
            this.setState({
                currentSelected: null
            });
        }
    };

    /**
     * onMouseOver handler in Rect Konva component
     * @param event
     * @param area
     */
    handleMouseOverRect = (event, area?) => {
        event.target.getStage().container().style.cursor = 'pointer';

        this.setState({
            currentHover: area
        });
    };

    /**
     * onMouseOut handler in Rect Konva component
     * @param event
     * @param area
     */
    handleMouseOutRect = (event) => {
        event.target.getStage().container().style.cursor = 'default';
        this.setState({
            currentHover: null
        });
    };

    /**
     * onClick handler in Rect Konva component and show buttons
     * @param area
     */
    handleClickRect = (area) => {
        this.clearReaction();
        // Prompt selection bottom modal when there are a multiple buttons or hotspot question with follow up enabled.
        if (
            this.state.mode === HOTSPOT_MODE.MULTIPLE ||
            (this.props.question.showFollowUp &&
                this.props.question.type === QTYPES.HOTSPOT)
        ) {
            const defaultAnnotation =
                this.state.mode === HOTSPOT_MODE.SINGLE
                    ? this.props.question.buttons[0]
                    : null;

            const showComment = this.state.mode === HOTSPOT_MODE.SINGLE;
            const editing = Boolean(area.selection);

            this.setState({
                currentSelected: area,
                currentAnnotation: area.selection || defaultAnnotation,
                showComment,
                editing
            });
        } else {
            this.handleChoice(area, this.props.question.buttons[0]);
        }
    };

    /**
     * Get color name based on a user's selection
     * @param value
     */
    getAreaColor = (value) => {
        const { buttons } = this.props.question;
        const selectedButton = buttons.find((b) => b.value === value);

        if (selectedButton && 'color' in selectedButton) {
            return selectedButton.color;
        }

        /**  @BACKWARD_COMPATIBILITY */
        switch (value) {
            case '1':
                return 'green';
            case '3':
                return 'red';
            default:
                return 'yellow';
        }
    };
    toggleImageModal = () => {
        this.setState({
            showModal: !this.state.showModal
        });
    };

    validatorText = (max: number, min: number) => {
        const { t } = this.props;
        const { boxAreas } = this.state;
        const selected = boxAreas.filter((area) => area.selection).length;

        if (selected === 0) {
            return (
                <div className="multi-choice-help">
                    <p>{getTranslation(t, 'LABEL.VALIDATE.HOTSPOT_SELECT')}</p>
                </div>
            );
        } else if (selected > max) {
            return (
                <div className="multi-choice-help warning">
                    <p>
                        {getTranslation(t, 'LABEL.VALIDATE.SELECT_MIN_MAX', {
                            min,
                            max
                        })}
                    </p>
                </div>
            );
        } else if (selected < min) {
            return (
                <div className="multi-choice-help">
                    <p>
                        {getTranslation(t, 'LABEL.VALIDATE.MORE', {
                            more: min - selected
                        })}
                    </p>
                </div>
            );
        } else if (selected === max) {
            return (
                <div className="multi-choice-help">
                    <p>{getTranslation(t, 'LABEL.VALIDATE.SATISFIED')}</p>
                </div>
            );
        } else {
            return (
                <div className="multi-choice-help">
                    <p>
                        <span className="multi-choice-help--header">
                            {getTranslation(
                                t,
                                'LABEL.VALIDATE.CONTINUE_OR_MORE_HEADER_HOTSPOT'
                            )}
                        </span>
                        {getTranslation(
                            t,
                            'LABEL.VALIDATE.CONTINUE_OR_MORE_SPECIALTY'
                        )}
                    </p>
                </div>
            );
        }
    };

    // Upload dataURL to Cloud storage and return full image URL
    uploadPictureWithHighlights = async () => {
        this.setState({ uploading: true });
        const blob = dataURLtoBlob(this.stageRef.getStage().toDataURL());
        const formData = new FormData();
        formData.append('file', blob, 'highlighed_hotspot_image');

        const url = `${process.env.INCA_SERVER_URL}/data/file/upload`;

        const resp = await axios.post(url, formData);
        this.setState({ uploading: false });

        return resp.data.full;
    };

    /**
     * Submit the answer in (OptionData + selection)
     */
    handleSubmit = async (notApplicable = false) => {
        const { question } = this.props;
        const { boxAreas } = this.state;
        let selectedOptions;
        let dataURL;
        if (notApplicable) {
            selectedOptions = [
                {
                    id: BUILTIN_OPTION_ID,
                    value: NA_OPTION_VALUE,
                    texts: question?.notApplicableTexts
                }
            ];
        } else {
            dataURL = await this.uploadPictureWithHighlights();
            let selectedAreas = {};
            boxAreas.forEach((area) => {
                if (area.selection) {
                    selectedAreas = {
                        ...selectedAreas,
                        [area.id]: area.selection
                    };
                }
            });
            const selectedOptionIDs = Object.keys(selectedAreas);
            selectedOptions = this.props.question.options
                .filter((option) =>
                    selectedOptionIDs.includes(option.id.toString())
                )
                .map((selected) => {
                    return {
                        ...selected,
                        selection: selectedAreas[selected.id]
                    };
                });
        }

        this.props.handleAnswer(
            question.type === QTYPES.HOTSPOT
                ? selectedOptions
                : selectedOptions[0],
            dataURL,
            this.state.originalWidth
        );
    };

    handleModalClose = () => {
        this.setState({
            currentSelected: null,
            currentAnnotation: null,
            error: null
        });
    };

    handleCurrentAnnotationUpdate = (type, value) => {
        const newAnnotation: CurrentAnnotation = {
            ...this.state.currentAnnotation
        };
        let isValid;
        switch (type) {
            case 'verbatim':
                newAnnotation.verbatim = value;
                isValid = this.isValid(value);
                break;
            case 'selection':
                newAnnotation.label = value.label;
                newAnnotation.value = value.value;
                if (this.state.currentAnnotation?.verbatim) {
                    isValid = this.isValid(
                        this.state.currentAnnotation.verbatim
                    );
                }
                break;
            default:
                break;
        }
        if (type === 'selection' && !this.props.question.showFollowUp) {
            this.handleChoice(this.state.currentSelected, newAnnotation);
            this.clearReaction();
        } else {
            this.setState({ isValid, currentAnnotation: newAnnotation });
        }
    };

    clearReaction = () => {
        this.setState({
            editing: false,
            showComment: false,
            currentAnnotation: null,
            currentSelected: null,
            currentHover: null,
            isValid: false
        });
    };

    isValid = (value) => {
        if (value.trim() !== '') {
            return true;
        }

        return false;
    };

    render() {
        const { langId, question, t } = this.props;
        const { continueTexts, notApplicableTexts, buttons } = question;
        const {
            boxAreas,
            image,
            currentHover,
            currentSelected,
            currentAnnotation,
            disabled,
            uploading,
            showComment,
            editing
        } = this.state;
        const continueText = getStringFromMultimediaTexts(
            continueTexts,
            langId
        );
        const notapplicableText = getStringFromMultimediaTexts(
            notApplicableTexts,
            langId
        );

        const isHotspot = question.type === QTYPES.HOTSPOT;

        const orderedBoxAreas = orderByOverlap(boxAreas);

        return (
            <div
                className="video-annotation--container"
                ref={(e) => (this.hotspotWrapper = e)}
            >
                <div
                    className={`hotspot--image-wrapper ${
                        isHotspot ? 'video-annotation--player--container' : ''
                    }`}
                >
                    <Stage
                        height={image != null ? image.height : 0}
                        width={this.state.containerSize.width}
                        draggable={!isKonvaTouchEnabled()}
                        // onWheel={(e) => {
                        //     if (isHotspot) {
                        //         zoomKonvaStage(e, null, this.stageRef);
                        //     }
                        // }}
                        onTouchMove={(e) => {
                            if (isHotspot) {
                                handleKonvaTouch(
                                    e,
                                    this.stageRef,
                                    this.lastCenter,
                                    this.lastDist
                                );
                            }
                        }}
                        onTouchEnd={(e) => {
                            if (isHotspot) {
                                handleKonvaTouchEnd(
                                    e,
                                    this.lastCenter,
                                    this.lastDist
                                );
                            }
                        }}
                        x={
                            this.state.containerSize.width / 2 -
                            this.state.width / 2
                        }
                        ref={(node) => (this.stageRef = node)}
                    >
                        <Layer>
                            <CanvasImage
                                url={`${
                                    question.mediaURL
                                }?timestamp=${new Date()}`}
                                onSelectImage={this.handleImage}
                                maxHeight={this.state.containerSize.height}
                                maxWidth={this.state.containerSize.width}
                                handleSlowLoading={this.handleSlowLoading}
                            />
                            {orderedBoxAreas.map((area) => {
                                const hovered =
                                    currentHover && currentHover.id === area.id;
                                const selected =
                                    currentSelected &&
                                    currentSelected.id === area.id;
                                const active = hovered || selected;
                                return (
                                    <Rect
                                        key={area.id}
                                        name={`rect-${area.id}`}
                                        stroke={
                                            active || question.isVisible
                                                ? selected && area.selection
                                                    ? '#ffffff'
                                                    : '#2d67cb'
                                                : 'transparent'
                                        }
                                        shadowForStrokeEnabled={true}
                                        strokeWidth={
                                            selected && area.selection ? 4 : 2
                                        }
                                        rotation={area.rotation}
                                        x={area.x}
                                        y={area.y}
                                        width={area.width}
                                        height={area.height}
                                        onTouchStart={(e) =>
                                            this.handleClickRect(area)
                                        }
                                        onMouseOver={(e) =>
                                            this.handleMouseOverRect(e, area)
                                        }
                                        onMouseOut={(e) =>
                                            this.handleMouseOutRect(e)
                                        }
                                        fill={
                                            area.selection
                                                ? this.getAreaColor(
                                                      area.selection.value
                                                  )
                                                : active
                                                ? '#2d67cb'
                                                : ''
                                        }
                                        onClick={(e) => {
                                            e.evt.stopPropagation();
                                            e.evt.preventDefault();
                                            this.handleClickRect(area);
                                        }}
                                        opacity={
                                            active || area.selection ? 0.3 : 1
                                        }
                                    />
                                );
                            })}
                        </Layer>
                    </Stage>
                    {isHotspot && (
                        <div className="hotspot--image-buttons">
                            <div
                                onClick={(e) =>
                                    zoomKonvaStage(e, 'zoom-in', this.stageRef)
                                }
                            >
                                +
                            </div>
                            <div
                                onClick={(e) =>
                                    zoomKonvaStage(e, 'zoom-out', this.stageRef)
                                }
                            >
                                -
                            </div>
                        </div>
                    )}
                </div>
                <div
                    className={`video-annotation--reactions--container --hotspot ${
                        currentSelected ? '--active' : ''
                    }`}
                >
                    {!showComment ? (
                        <div className="video-annotation--reactions">
                            <div className="video-annotation--reactions--header">
                                {boxAreas.filter(
                                    (box) =>
                                        box.selection &&
                                        currentSelected?.boxID === box.boxID
                                ).length > 0
                                    ? t('LABEL.INSTRUCTIONS.ANNOTATION.UPDATE')
                                    : t(
                                          'LABEL.INSTRUCTIONS.ANNOTATION.REACTION'
                                      )}
                            </div>
                            <div className="video-annotation--reactions--options">
                                {buttons.map((button, i) => {
                                    const text = getStringFromMultimediaTexts(
                                        button.texts,
                                        langId
                                    );
                                    const emoji = getAnnotationEmoji(
                                        button.mediaURL,
                                        button.value
                                    );
                                    const selected =
                                        this.state.currentAnnotation &&
                                        this.state.currentAnnotation.value ===
                                            button.value;

                                    return (
                                        <div
                                            className={`video-annotation--reactions--options--button ${
                                                selected ? '--selected' : ''
                                            }`}
                                            key={i}
                                            onClick={() => {
                                                this.handleCurrentAnnotationUpdate(
                                                    'selection',
                                                    button
                                                );
                                                if (
                                                    this.props.question
                                                        .showFollowUp
                                                ) {
                                                    this.setState({
                                                        showComment: true
                                                    });
                                                }
                                            }}
                                        >
                                            <img
                                                className="video-annotation--reactions--options--button--img"
                                                src={emoji}
                                            />
                                            <span className="video-annotation--reactions--options--button--label">
                                                {text}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : (
                        <div className="video-annotation--reactions">
                            <div className="video-annotation--reactions--header">
                                <span>
                                    {t('LABEL.INSTRUCTIONS.ANNOTATION.COMMENT')}{' '}
                                    {buttons.length > 1 ? (
                                        <>
                                            <img
                                                className="video-annotation--reactions--options--button--img--small"
                                                src={getAnnotationEmoji(
                                                    buttons.find(
                                                        (b) =>
                                                            b.value ==
                                                            currentAnnotation?.value
                                                    )?.mediaURL,
                                                    currentAnnotation?.value
                                                )}
                                            />
                                            <span> </span>
                                        </>
                                    ) : (
                                        // show button text instead of image if only one button available
                                        <span>
                                            {getStringFromMultimediaTexts(
                                                buttons?.[0].texts,
                                                langId
                                            )}
                                        </span>
                                    )}
                                    ?
                                </span>
                            </div>
                            <div className="video-annotation--reactions--options">
                                <div className="video-annotation--reactions--options--text">
                                    <textarea
                                        autoFocus
                                        value={
                                            this.state.currentAnnotation
                                                ?.verbatim
                                        }
                                        onChange={(e) =>
                                            this.handleCurrentAnnotationUpdate(
                                                'verbatim',
                                                e.target.value
                                            )
                                        }
                                        onKeyPress={(e) => {
                                            if (
                                                e.key === KEY_NAME.ENTER &&
                                                !e.shiftKey
                                            ) {
                                                this.state.isValid &&
                                                    this.handleChoice(
                                                        currentSelected,
                                                        currentAnnotation
                                                    );
                                                setTimeout(() => {
                                                    this.clearReaction();
                                                }, 200);
                                            }
                                        }}
                                        onPaste={(event) => {
                                            event.preventDefault();
                                        }}
                                    />
                                    <div className="video-annotation--reactions--options--text--actions">
                                        <div
                                            className="video-annotation--reactions--options--text--actions--item"
                                            onClick={this.clearReaction}
                                        >
                                            <UilTimes />
                                        </div>
                                        <div
                                            className={`video-annotation--reactions--options--text--actions--item ${
                                                this.state.isValid
                                                    ? '--valid'
                                                    : ''
                                            }`}
                                            onClick={() => {
                                                if (this.state.isValid) {
                                                    this.handleChoice(
                                                        currentSelected,
                                                        currentAnnotation
                                                    );
                                                    setTimeout(() => {
                                                        this.clearReaction();
                                                    }, 200);
                                                }
                                            }}
                                            style={{ paddingRight: 2 }}
                                            tabIndex={0}
                                        >
                                            <FontAwesomeIcon
                                                icon={faPaperPlane}
                                                className="send"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {isHotspot &&
                    this.state.image &&
                    !(
                        question.max == 1 &&
                        question.buttons.length == 1 &&
                        !question.showFollowUp
                    ) && (
                        <div className="video-annotation--comments">
                            <div className="video-annotation--comments--header">
                                {t(
                                    'LABEL.INSTRUCTIONS.ANNOTATION.REACTION_HEADER'
                                )}
                            </div>
                            <div className="video-annotation--comments--list">
                                {this.state.boxAreas.filter(
                                    (box) => box.selection
                                ).length > 0
                                    ? this.state.boxAreas
                                          .filter((box) => box.selection)
                                          .map((boxArea, i) => {
                                              const selectedButton =
                                                  buttons.find(
                                                      (b) =>
                                                          b.value ===
                                                          boxArea.selection
                                                              .value
                                                  );
                                              return (
                                                  <div
                                                      className="video-annotation--comment"
                                                      key={i}
                                                  >
                                                      <div
                                                          className="video-annotation--comment--reaction"
                                                          style={{
                                                              backgroundColor:
                                                                  getAnnotationColorByButton(
                                                                      selectedButton,
                                                                      true
                                                                  )
                                                          }}
                                                      ></div>
                                                      <div
                                                          className="video-annotation--comment--body --disabled"
                                                          //   onClick={() =>
                                                          //       this.handleClickRect(
                                                          //           boxArea
                                                          //       )
                                                          //   }
                                                      >
                                                          {this.props.question
                                                              .showFollowUp
                                                              ? boxArea
                                                                    .selection
                                                                    .verbatim
                                                              : boxArea.text}
                                                      </div>
                                                      {!editing ? (
                                                          <div className="video-annotation--comment--actions">
                                                              <UilTrashAlt
                                                                  size={18}
                                                                  onClick={() =>
                                                                      this.handleDeleteSelection(
                                                                          boxArea.id
                                                                      )
                                                                  }
                                                              />
                                                          </div>
                                                      ) : this.state
                                                            .currentSelected &&
                                                        this.state
                                                            .currentSelected
                                                            .boxID ===
                                                            boxArea.boxID ? (
                                                          <div className="video-annotation--comment--actions">
                                                              <UilTimes
                                                                  size={18}
                                                                  onClick={
                                                                      this
                                                                          .clearReaction
                                                                  }
                                                              />
                                                          </div>
                                                      ) : null}
                                                  </div>
                                              );
                                          })
                                    : t(
                                          'LABEL.INSTRUCTIONS.ANNOTATION.REACTION_EMPTY'
                                      )}
                            </div>
                        </div>
                    )}
                {this.state.image ? (
                    <div
                        className="bottom-select-links bot-width"
                        style={{ marginTop: 0 }}
                    >
                        {isHotspot &&
                            this.validatorText(question.max, question.min)}
                        <div className="hotspot--submit">
                            {question.showNotApplicableOption && (
                                <NotApplicableButton
                                    disabled={uploading}
                                    text={notapplicableText}
                                    onClick={() => this.handleSubmit(true)}
                                />
                            )}
                            <ContinueButton
                                disabled={disabled || uploading}
                                onClick={() => this.handleSubmit(false)}
                                text={continueText}
                            />
                        </div>
                    </div>
                ) : (
                    <div
                        className="bottom-select-links bot-width"
                        style={{ marginTop: 0 }}
                    >
                        {this.state.isSlowLoading
                            ? t('PLACEHOLDER.LOADING')
                            : ''}
                    </div>
                )}
            </div>
        );
    }
}
