import * as React from 'react';

function FloatingSquaresTheme() {
    return (
        <ul className="squares">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
    );
}

export default FloatingSquaresTheme;
