import * as React from 'react';
import { Color } from 'csstype';

const ESCAPE = 27;
const ENTER = 13;

export interface IState {
    text: string;

    isAdd: boolean;
    isSaving: boolean;
}

export interface IProps {
    bgcolor: Color;
    color?: Color;
    onSave: (text: string, bucketIndex: number) => void;
    langId: string;
}

export default class AddEvent extends React.Component<any, IState> {
    inputRef: any;
    state = {
        isAdd: false,
        text: '',
        isSaving: false // if value is saved to backend
    };

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            text: e.currentTarget.value
        });
    };

    resetValues = () => {
        this.setState({
            isAdd: false,
            text: ''
        });
    };

    toggleView = () => {
        this.setState({
            isAdd: true
        });
    };

    onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const keyCode = e.keyCode;
        if (keyCode === ESCAPE) {
            // Exit without saving
            this.resetValues();
        } else if (keyCode === ENTER) {
            // Save and exit
            const text = this.state.text;
            this.props.onSave(text, this.props.bucketIndex);
            this.resetValues();
        }
    };

    onBlur = () => {
        const text = this.state.text;
        this.props.onSave(text, this.props.bucketIndex);
        this.resetValues();
    };

    render() {
        const { isAdd, text } = this.state;
        const { bgcolor, color } = this.props;
        const placeholderText =
            this.props.langId == 'en' ? 'Please type here...' : '输入';
        const addText =
            this.props.langId == 'en' ? 'Other, specify:' : '其它，请输入';
        if (isAdd) {
            return (
                <div
                    className={`journeyq-option option-input`}
                    style={{
                        backgroundColor: color
                    }}
                    onClick={() => this.inputRef.focus()}
                >
                    <input
                        ref={(ref) => {
                            this.inputRef = ref;
                        }}
                        autoFocus
                        type="text"
                        value={text}
                        placeholder={placeholderText}
                        onChange={this.onChange}
                        onKeyUp={this.onKeyUp}
                        onBlur={this.onBlur}
                        style={{
                            color: bgcolor || '#FFFF'
                        }}
                    />
                </div>
            );
        } else {
            return (
                <div
                    className={`journeyq-option option-button`}
                    onClick={this.toggleView}
                    style={{
                        border: `1px solid ${color}`,
                        backgroundColor: '#FFFF'
                    }}
                >
                    <span>{addText}</span>
                </div>
            );
        }
    }
}
