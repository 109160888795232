import { STATIC_STORAGE_URL } from '@nexxt/common/constants';
import * as i18next from 'i18next';
import HttpApi from 'i18next-http-backend';
// import * as LanguageDetector from 'i18next-browser-languagedetector';
// import { reactI18nextModule } from 'react-i18next';
declare let __DEV__: boolean;

export const i18n = i18next
    .use(HttpApi)
    // .use(LanguageDetector)
    // .use(reactI18nextModule) // if not using I18nextProvider
    .init({
        fallbackLng: 'en',
        defaultNS: 'translation',
        interpolation: {
            escapeValue: false // not needed for react!!
        },
        // react i18next special options (optional)
        react: {
            wait: false,
            nsMode: 'default'
        },

        backend: {
            loadPath: `${STATIC_STORAGE_URL}/locales/{{lng}}/{{ns}}.json`,
            crossDomain: true
        }
    });
