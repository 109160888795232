import * as React from 'react';
import * as PropTypes from 'prop-types';
import { DroppableProps } from './JourneyElement';
import * as _ from 'lodash';
import { Color } from 'csstype';
import { JOURNEY_STEPS } from '../types';
import { QTYPES } from '@nexxt/common/types';
import { UilAlignLeft, UilExternalLinkAlt } from '@iconscout/react-unicons';
import Modal from '../../../components/Modal';
import JourneyModal from './JourneyModal';

export const MarkersSVG: React.SFC = () => (
    <defs>
        <marker
            id="arrow"
            markerWidth="4"
            markerHeight="10"
            viewBox="-2 -4 4 4"
            refX="0"
            refY="0"
            markerUnits="strokeWidth"
            orient="auto"
        >
            <polyline
                points="-2,-2 0,0 -2,2"
                stroke="#443c3d"
                strokeWidth="0.75px"
                fill="#000"
            />
        </marker>
        <marker id="plus" viewBox="-2 -2 2 2">
            <path fill="white" d="M0,-1 V1 M-1,0 H1" strokeWidth="1" />
        </marker>
        {/* Drop Shadow Code. Tailored for dialog box */}
        <filter id="dropshadow" height="130%">
            <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="-1 0 0 0 1, 0 -1 0 0 1, 0 0 -1 0 1, 0 0 0 1 0"
                result="matrix"
            />
            <feGaussianBlur in="matrix" stdDeviation="6">
                <animate
                    attributeType="XML"
                    attributeName="stdDeviation"
                    values="6;5;4;3;2;1;2;3;4;5;"
                    dur="2000ms"
                    repeatCount="indefinite"
                />
            </feGaussianBlur>
            <feOffset dx="0" dy="0" result="offsetblur" />
            <feComponentTransfer>
                <feFuncA type="linear" slope="1.4" />
            </feComponentTransfer>
            <feMerge>
                <feMergeNode />
                <feMergeNode in="SourceGraphic" />
            </feMerge>
        </filter>
    </defs>
); /* Add additional marker if needed. No props allowed */

export interface TranslateCoreProps {
    translateX?: any;
    translateY?: any;
}

export interface JourneyElementDropspotSVGProps
    extends TranslateCoreProps,
        DroppableProps {
    connectDropTarget: any;
    text?: string;
    showAddIndicator?: boolean;
}
export class JourneyElementDropspotSVG extends React.Component<JourneyElementDropspotSVGProps> {
    render() {
        // OPTIONS
        const plusLength = 6; // length of each segment (quadrant) of plus icon
        const circleRadius = 12; // radius of circle containing plus icon
        const rectWidth = 200; // invisible rectangle around the circle determines where option can be dropped
        const rectHeight = 100; // ensure that these dimensions do not cause any overlap between the regions!
        const labelOffset = [-60, 10];
        const dragLabelOffset = [-60, -50];
        const labelDims = [120, 50];
        const labelFontSize = 8;

        const {
            translateX,
            translateY,
            connectDropTarget,
            canDrop,
            isOver,
            text,
            showAddIndicator
        } = this.props;
        let className = '';
        if (canDrop) {
            className = 'zoom-in';
        }
        if (isOver) {
            className = 'zoom-repeat';
        }
        return (
            <g
                ref={connectDropTarget}
                transform={`translate(${translateX || 0},${translateY || 0})`}
            >
                {canDrop && showAddIndicator ? (
                    <foreignObject
                        x={dragLabelOffset[0]}
                        y={dragLabelOffset[1]}
                        width={labelDims[0]}
                        height={labelDims[1]}
                    >
                        <span
                            style={{
                                display: 'flex',
                                fontSize: `${labelFontSize}px`,
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                color: '#2D67CB',
                                margin: '10px auto'
                            }}
                        >
                            <span>{'Drag onto Here'}</span>
                        </span>
                    </foreignObject>
                ) : null}
                <circle
                    className={className}
                    cx="0"
                    cy="0"
                    r={circleRadius}
                    stroke="#C4C4C4"
                    strokeWidth="2"
                    fill="#434343"
                />
                <path
                    className={className}
                    stroke="#FFFFFF"
                    d={`M0,-${plusLength} V${plusLength} M-${plusLength},0 H${plusLength}`}
                    strokeWidth="3px"
                    strokeLinejoin="round"
                />
                {text ? (
                    <foreignObject
                        x={labelOffset[0]}
                        y={labelOffset[1]}
                        width={labelDims[0]}
                        height={labelDims[1]}
                    >
                        <span
                            style={{
                                display: 'flex',
                                fontSize: `${labelFontSize}px`,
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                color: 'white',
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                width: '70px',
                                height: '15px',
                                margin: '10px auto',
                                borderRadius: '10px'
                            }}
                        >
                            <span>{text}</span>
                        </span>
                    </foreignObject>
                ) : null}
                {/* {this.props.isDragging && ( */}
                <rect
                    width={rectWidth}
                    height={rectHeight}
                    stroke="rgba(0,0,0,0)"
                    strokeWidth="1"
                    fill="rgba(0,0,0,0)"
                    transform={`translate(-${rectWidth / 2},-${
                        rectHeight / 2
                    })`}
                ></rect>
                {/* )} */}
            </g>
        );
    }
}

export interface TextDialogProps extends TranslateCoreProps, DroppableProps {
    text: string;
    isSelected: boolean;
    bgcolor?: Color;
    color?: Color;
    smiley?: any;
    banks?: any[];
    followUpAnswers: any[];
    followUpSettings: any[];
    currentType?: 'BANK' | 'EMOJI';
    ref?: any;
    currentState?: JOURNEY_STEPS;
    hightLightComponent?: boolean;
    hightLightCurrent?: boolean;
    onDrop?: () => void;
    handleStepRemove: () => void;
    emojiPlaceholderImageUrl?: string;
    bankPlaceholderImageUrl?: string;
}

function getImageCoordinate(
    x: number,
    y: number,
    width: number,
    height: number
) {
    return {
        x: x + width + 2,
        y: y - height - 2
    };
}

function getDialogCloseCoordinate(
    x: number,
    y: number,
    width: number,
    height: number
) {
    return {
        x: x * -1,
        y: y - height - 2
    };
}

function getTextInitalCoordinate(
    x: number,
    y: number,
    width: number,
    height: number
) {
    return {
        x: x - 2,
        y: y - 2 - height
    };
}

export const TextDialogWrapper: React.FC<TextDialogProps> = (props) => {
    const [openModal, setOpenModal] = React.useState(false);
    const hasMultipleFollowUp =
        props.followUpAnswers?.length > 1 ||
        props.followUpAnswers?.[0]?.length > 1;
    const isFirstFollowUpAnswerText =
        props.followUpAnswers?.[0]?.type === QTYPES.OPEN;

    const banks = props.banks;
    const bankHeight1 = 20;
    const bankHeight2 = 25;
    const startX = -50;
    const startY = -13;

    let emptyBank: any;
    let emptyEmoji: any;

    const xWidth = 100;
    let yHeight = 40;

    if (banks && banks.length && banks.length < 5) {
        yHeight += bankHeight1;
    } else if (banks && banks.length > 4) {
        yHeight += bankHeight1 + bankHeight2;
    } else if (!banks || banks.length === 0) {
        if (props.hightLightComponent && props.currentType === 'BANK') {
            yHeight += bankHeight1;
            emptyBank = props.bankPlaceholderImageUrl ? (
                <img
                    className="jo-bank"
                    width="20"
                    height="20"
                    style={{
                        borderRadius: '50%',
                        marginRight: '2px',
                        marginBottom: '2px'
                    }}
                    src={props.bankPlaceholderImageUrl}
                />
            ) : null;
        }
    }

    if (props.hightLightComponent && props.currentType === 'EMOJI') {
        emptyEmoji = props.emojiPlaceholderImageUrl ? (
            <img
                width="20"
                height="20"
                style={{ borderRadius: '50%' }}
                src={props.emojiPlaceholderImageUrl}
            />
        ) : null;
    }

    const imageCoordinates = getImageCoordinate(
        startX,
        startY,
        xWidth,
        yHeight
    );
    const closeButtonCoordinates = getDialogCloseCoordinate(
        startX,
        startY,
        xWidth,
        yHeight
    );
    const textCoordinates = getTextInitalCoordinate(
        startX,
        startY,
        xWidth,
        yHeight
    );
    // M -50 -13 L 3 -13 L 0 -10 L -3 -13 L 50 -13 L 52 -15 L 52 -50 L 50 -52 L -50 -52 L -52 -50 L -52 -15 L -50 -13
    const d1 = `L ${startX + xWidth} ${startY}`;
    const d2 = `L ${startX + xWidth + 2} ${startY - 2}`;
    const d3 = `L ${startX + xWidth + 2} ${startY - yHeight - 2}`;
    const d4 = `L ${startX + xWidth} ${startY - yHeight - 4}`;
    const d5 = `L ${startX} ${startY - yHeight - 4}`;
    const d6 = `L ${startX - 2} ${startY - yHeight - 2}`;
    const d7 = `L ${startX - 2} ${startY - 2}`;
    const d8 = `L ${startX} ${startY}`;
    const d = `M ${startX} ${startY} L -3 -13 L 0 -10 L 3 -13 ${d1} ${d2} ${d3} ${d4} ${d5} ${d6} ${d7} ${d8}`;

    let smileyCircleColor = {};
    const dialogBgColor = props.bgcolor;
    const dialogFontColor = props.color;
    const dialogFontWeight =
        props.hightLightComponent ||
        (props.currentState === JOURNEY_STEPS.SELECT_STEPS && props.isSelected)
            ? 800
            : 100;
    const dialogFontSize = 8;
    let dialogDropDown = {};
    if (props.hightLightComponent) {
        if (
            props.currentType === 'EMOJI' &&
            !(props.smiley && props.smiley.imgUrl)
        ) {
            smileyCircleColor = {
                stroke: '#00BDB0',
                strokeWidth: '1',
                strokeDasharray: '1.5'
            };
        } else {
            smileyCircleColor = {
                stroke: '#fff',
                strokeWidth: '1'
            };
        }
        dialogDropDown = {
            filter: 'url(#dropshadow)'
        };
    } else {
        smileyCircleColor = {
            stroke: '#fff',
            strokeWidth: '1'
        };
    }

    let opacity = 1;
    if (props.hightLightCurrent && !props.hightLightComponent) {
        opacity = 0.3;
    }

    return (
        <g
            className="user-select-none"
            ref={props.ref}
            transform={`translate(${props.translateX || 0}, ${
                props.translateY || 0
            }) scale(${
                props.hightLightComponent ||
                (props.currentState === JOURNEY_STEPS.SELECT_STEPS &&
                    props.isSelected)
                    ? 1.15
                    : 1
            })`}
        >
            {openModal && (
                <JourneyModal
                    openModal={openModal}
                    toggleModal={(toggle) => setOpenModal(toggle)}
                    followUpAnswers={props.followUpAnswers}
                    followUpSettings={props.followUpSettings}
                />
            )}
            <g {...dialogDropDown}>
                <g className="dialog" opacity={opacity}>
                    {/* <polygon points="0,-10 3,-13 -3,-13" fill={polygonColor} stroke={polygonColor} strokeWidth="1" /> */}
                    {props.canDrop && props.isOver && !props.isDragging ? (
                        <path
                            d={d}
                            fill={'#E8F9F7'}
                            stroke={'#00BDB0'}
                            strokeWidth="1.5"
                            strokeDasharray="5"
                        />
                    ) : (
                        <path
                            d={d}
                            fill={
                                props.isDragging && props.currentState !== null
                                    ? '#DBDBDB'
                                    : dialogBgColor
                            }
                            stroke={
                                props.isDragging && props.currentState !== null
                                    ? '#DBDBDB'
                                    : '#fff'
                            }
                            strokeWidth="2"
                        />
                    )}
                </g>
                {props.isDragging && props.currentState === null ? (
                    <path
                        d={`M ${startX} ${startY} L ${startX} ${
                            startY - yHeight - 4
                        } L ${startX - 2} ${startY - yHeight - 2} L ${
                            startX - 2
                        } ${startY - 2}`}
                        fill={props.bgcolor || '#0086FF'}
                        stroke={props.bgcolor || '#0086FF'}
                        strokeWidth="2"
                    />
                ) : null}
                {
                    // props.followUpAnswers?.length && !hasMultipleFollowUp ? (
                    //     <g
                    //         transform={`translate(${imageCoordinates.x},${imageCoordinates.y})`}
                    //         opacity={opacity}
                    //     >
                    //         <circle
                    //             cx="0"
                    //             cy="0"
                    //             r="12"
                    //             fill={'#fff'}
                    //             {...smileyCircleColor}
                    //         />
                    //         <foreignObject x="-10" y="-10" width="20" height="20">
                    //             {isFirstFollowUpAnswerText ? (
                    //                 <UilAlignLeft size={15} />
                    //             ) : (
                    //                 <img
                    //                     width="20"
                    //                     height="20"
                    //                     style={{ borderRadius: '50%' }}
                    //                     src={props.followUpAnswers?.[0]?.[0].imgUrl}
                    //                 />
                    //             )}
                    //         </foreignObject>
                    //     </g>
                    // ) :
                    props.followUpAnswers?.length ? (
                        <g
                            transform={`translate(${imageCoordinates.x},${imageCoordinates.y})`}
                            opacity={opacity}
                        >
                            <rect
                                width="50"
                                height="20"
                                x="-35"
                                y="-10"
                                rx="10"
                                fill={'#fff'}
                                {...smileyCircleColor}
                            />
                            <foreignObject
                                x="-30"
                                y="-10"
                                width="20"
                                height="20"
                            >
                                {isFirstFollowUpAnswerText ? (
                                    <UilAlignLeft size={15} />
                                ) : (
                                    <img
                                        width="20"
                                        height="20"
                                        style={{ borderRadius: '50%' }}
                                        src={
                                            props.followUpAnswers?.[0]?.[0]
                                                .imgUrl
                                        }
                                    />
                                )}
                            </foreignObject>
                            <foreignObject
                                x="-10"
                                y="-10"
                                width="20"
                                height="20"
                                cursor={'pointer'}
                                onClick={() => setOpenModal(true)}
                            >
                                <UilExternalLinkAlt size={17} />
                            </foreignObject>
                        </g>
                    ) : emptyEmoji ? (
                        <g
                            transform={`translate(${imageCoordinates.x},${imageCoordinates.y})`}
                        >
                            <circle
                                cx="0"
                                cy="0"
                                r="12"
                                fill={'#E8F9F7'}
                                {...smileyCircleColor}
                            />
                            <foreignObject
                                x="-10"
                                y="-10"
                                width="20"
                                height="20"
                            >
                                {emptyEmoji}
                            </foreignObject>
                        </g>
                    ) : null
                }
            </g>
            <foreignObject
                x={textCoordinates.x + 2}
                y={textCoordinates.y}
                width={xWidth - 4}
                height={36 + 3}
            >
                <span
                    style={{
                        display: 'flex',
                        fontSize: `${dialogFontSize}px`,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: `${xWidth - 4}px`,
                        height: '39px',
                        textAlign: 'center',
                        color: dialogFontColor,
                        fontWeight: dialogFontWeight,
                        opacity
                    }}
                >
                    <span>
                        {props.canDrop && props.isOver && !props.isDragging
                            ? 'Drop Here'
                            : props.text}
                    </span>
                </span>
            </foreignObject>

            {[JOURNEY_STEPS.ADD_ITEMS, JOURNEY_STEPS.ADD_ITEMS_EXTRA].includes(
                props.currentState
            ) && !(props.canDrop && props.isOver && !props.isDragging) ? (
                <g
                    {...dialogDropDown}
                    onClick={props.handleStepRemove}
                    className="cursor-pointer"
                >
                    {/* Step Remove Button */}
                    <g
                        transform={`translate(${closeButtonCoordinates.x},${closeButtonCoordinates.y})`}
                        opacity={opacity}
                    >
                        <g className="SVGDialogClose">
                            <circle
                                cx="0"
                                cy="0"
                                r="7"
                                fill={'#fff'}
                                stroke="#c4c4c4"
                            />
                            <path
                                style={{
                                    transform: `rotate(45deg)`
                                }}
                                stroke="#000"
                                d="M0,-3 V3 M-3,0 H3"
                                strokeWidth="1px"
                            />
                        </g>
                    </g>
                </g>
            ) : null}
            <circle
                cx="0"
                cy="0"
                r="8"
                stroke={props.bgcolor || '#053EA0'}
                strokeWidth="1"
                fill={props.bgcolor || '#053EA0'}
                opacity={opacity}
            />
            {props.canDrop && props.isOver && !props.isDragging ? null : (
                <circle
                    cx="0"
                    cy="0"
                    r="6"
                    fill={props.bgcolor || '#0086FF'}
                    opacity={opacity}
                />
            )}
        </g>
    );
};

TextDialogWrapper.propTypes = {
    text: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    bgcolor: PropTypes.string,
    smiley: PropTypes.any,
    banks: PropTypes.arrayOf(PropTypes.any),
    currentType: PropTypes.oneOf(['BANK', 'EMOJI']),
    ref: PropTypes.any,
    currentState: PropTypes.any,
    hightLightComponent: PropTypes.bool,
    hightLightCurrent: PropTypes.bool,
    onDrop: PropTypes.func,
    handleStepRemove: PropTypes.func,
    translateX: PropTypes.any,
    translateY: PropTypes.any,
    canDrop: PropTypes.bool.isRequired,
    isOver: PropTypes.bool.isRequired,
    isDragging: PropTypes.bool.isRequired,
    connectDropTarget: PropTypes.any,
    emojiPlaceholderImageUrl: PropTypes.string
};

export interface IBigCircleProps {
    radius: number;
    orientation: 'top' | 'bottom';
    translateX?: string;
    translateY?: string;
    image?: string;
    text?: string;
    currentState?: JOURNEY_STEPS;
    startpointImageType?: 'CIRCLE' | 'SQUARE';
}

export const BigCircle: React.SFC<IBigCircleProps> = (props) => {
    const random = Math.random() * +new Date();
    const labelOffsetX = -60;
    const labelOffsetY = 30;
    // const labelOffsetY = props.orientation === 'bottom' ? 20 : -(props.radius * 2 + 30);
    const labelWidth = 120;
    const labelHeight = 50;

    const imgUrl = props.image || ' ';
    let opacity = 1;
    if ([JOURNEY_STEPS.ADD_EMOJI].includes(props.currentState)) {
        opacity = 0.8;
    }
    return (
        <React.Fragment>
            <defs>
                <pattern
                    id={'image' + random}
                    x="0%"
                    y="0%"
                    height="100%"
                    width="100%"
                    viewBox="0 0 512 512"
                >
                    <image
                        x="0%"
                        y="0%"
                        width="512"
                        height="512"
                        href={imgUrl}
                    ></image>
                </pattern>
            </defs>
            <g
                className="user-select-none"
                transform={`translate(${props.translateX || '250'}, ${
                    props.translateY || '0'
                })`}
                opacity={opacity}
            >
                {props?.startpointImageType === 'SQUARE' ? (
                    <image
                        y={-props.radius}
                        width={props.radius * 2}
                        height={props.radius * 2}
                        href={imgUrl}
                    ></image>
                ) : (
                    <circle
                        cx="0"
                        cy="0"
                        r={props.radius}
                        fill={`url(#image${random})`}
                    />
                )}

                {props.text ? (
                    <foreignObject
                        x={labelOffsetX}
                        y={labelOffsetY}
                        width={labelWidth}
                        height={labelHeight}
                    >
                        <span
                            style={{
                                display: 'flex',
                                fontSize: '10px',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '120px',
                                textAlign: 'center',
                                color: '#4c4c4c',
                                fontWeight: 400
                            }}
                        >
                            <span>{props.text}</span>
                        </span>
                    </foreignObject>
                ) : null}
            </g>
        </React.Fragment>
    );
};

BigCircle.propTypes = {
    translateX: PropTypes.string,
    translateY: PropTypes.string,
    image: PropTypes.string,
    text: PropTypes.string,
    orientation: PropTypes.oneOf(['top', 'bottom']),
    currentState: PropTypes.oneOf(Object.values(JOURNEY_STEPS))
};
