import * as React from 'react';
import styled from 'styled-components';
import { BASE_THEME } from '../style/BotThemes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { dataTestid } from '../constants/dataTestid';

const Button = styled.button`
    text-align: center;
    font-weight: bold;
    display: flex;
    align-items: center;
    border-radius: ${BASE_THEME.borderRadius};
    padding: 0.5em 0.75em 0.5em 0.55em;
    border: none;
    color: white;
    background: ${(p) => p.theme.continueBtn};
    &:hover,
    &:active,
    &:focus {
        background: ${(p) => p.theme.buttonBgHover || BASE_THEME.teal};
    }
    &:disabled,
    &:disabled:hover,
    &:disabled:active {
        background-color: #bbb;
        color: #888;
        cursor: mouse;
    }
    svg {
        margin-right: 0.35em;
    }
    span {
        margin: auto;
    }
`;

interface Props {
    onClick: () => void;
    text: string;
    disabled?: boolean;
}

const ContinueButton: React.SFC<Props> = (props) => {
    return (
        <Button
            data-testid={dataTestid.CONTINUEBTN}
            disabled={props.disabled}
            onClick={props.onClick}
        >
            {/* uncomment to enable icon
       *
       <FontAwesomeIcon icon={faCheck} />
       */}
            <span>{props.text}</span>
        </Button>
    );
};
export default ContinueButton;
