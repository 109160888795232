import * as React from 'react';
import { BaseInput, BaseProps } from './_BaseInput';
import { Question } from '@nexxt/common/types';

const { translate } = require('react-i18next');

// @TODO: remove this component?

export interface ImageLoadState {
    imageLoadCount: number;
}

export type PropsWithOptionQ = BaseProps;

@translate('translation')
export class InputWithOptionImages<
    P extends PropsWithOptionQ,
    S extends ImageLoadState
> extends BaseInput<P, S> {
    constructor(p, c?) {
        super(p, c);
    }
}
