import * as React from 'react';

import { BaseInput, BaseProps } from './_BaseInput';
import { PopupButtons } from './_PopupInterface';
import { OPTION_TYPES, OptionData } from '@nexxt/common/types';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw } from 'draft-js';
import '../style/PopupCard.scss';
import { delay } from '@nexxt/common/utils/index';
import { getStringFromMultimediaTexts } from '@nexxt/common/services/TextService';
import styled, { keyframes } from 'styled-components';
import { slideInLeft } from 'react-animations';
import { KEY_NAME, OTHER_OPTION_VALUE } from '@nexxt/common/constants';
import { addOtherToOptions, PERFECT, pickStatus } from '../utils/questions';
import NotApplicableButton from '../components/NotApplicableButton';
import ContinueButton from '../components/ContinueButton';
import SpecifyEditor from '../components/SpecifyEditor';

const { translate } = require('react-i18next');
const slideInAnimation = keyframes`${slideInLeft}`;

const SlideInDiv = styled.div`
    animation: 1s ${slideInAnimation};
`;

interface Props extends BaseProps {
    isMulti: boolean;
    handleAnswer: (o: OptionData | string[], other?: string) => void;
    isEmbedded: boolean;
}

interface Selections {
    [value: string]: true;
}

interface State {
    selectedValue: string;
    submitting: boolean;
    fadeOut: boolean;
    disableButtons: boolean;
    selections: Selections;
    showSpecify: boolean;
    specifyText: string;
    showError: boolean;
    stickyFooterHeight: number;
    breakpoint: number;
    size: string;
}

interface Card {
    questionValue: string;
}

interface Stack extends Object {
    on: (eventName: string, fn: () => void) => void;
    createCard: (el: HTMLElement, prepend?: boolean) => Card;
}

@translate('translation')
export class PopupCard extends BaseInput<Props, State> {
    constructor(props, context) {
        super(props, context);
        this.state = {
            selectedValue: '',
            submitting: false,
            fadeOut: false,
            disableButtons: true,
            selections: {},
            showSpecify: false,
            specifyText: '',
            showError: false,
            stickyFooterHeight: 0,
            breakpoint: 12,
            size: 'desktop'
        };
    }
    stickyFooter: HTMLDivElement;
    popupWrapper: HTMLDivElement;

    updateBreakpoint = () => {
        if (this.popupWrapper.clientWidth <= 480) {
            this.setState({ size: 'mobile', breakpoint: 5 });
        } else if (this.popupWrapper.clientWidth <= 768) {
            this.setState({ size: 'tablet', breakpoint: 6 });
        } else {
            this.setState({ size: 'desktop', breakpoint: 12 });
        }
        // update sticky footer after number of options per row changes
        this.setStickyFooter();
    };

    async componentDidMount() {
        // reserve space at bottom for sticky footer
        this.setStickyFooter();
        // wait one second for animation to load
        // before calling scrollToBottom
        // TODO: i don't like this and it should be dealt with more cleanly :(
        this.updateDisabled();
        await delay(1000);
        this.props.scrollToBottom();
        this.updateBreakpoint();
        window.addEventListener('resize', this.updateBreakpoint);
    }

    shouldComponentUpdate(nextProps: Props) {
        if (nextProps.question.id !== this.props.question.id) {
            this.setState({
                fadeOut: false,
                selectedValue: '',
                disableButtons: false,
                submitting: false,
                showError: false,
                specifyText: '',
                showSpecify: false,
                selections: {}
            });
        }
        return true;
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateBreakpoint);
    }

    setStickyFooter(): void {
        const STICKY_FOOTER_HEIGHT = this.stickyFooter.clientHeight + 20;
        !this.props.isEmbedded &&
            this.props.setBottomOffset(STICKY_FOOTER_HEIGHT);
        this.setState({
            stickyFooterHeight: STICKY_FOOTER_HEIGHT
        });
    }

    handleSubmit = async (button, other?) => {
        if (this.state.submitting) {
            return; // solution for now. Need to implement debounce to select again or disable
        }
        // show specify text input warning when the input box shown but empty
        if (
            (button?.value === OTHER_OPTION_VALUE ||
                (button && Object.keys(button).includes(OTHER_OPTION_VALUE))) &&
            this.state.showSpecify &&
            !this.state.specifyText.length
        ) {
            this.setState({ showError: true });
            return;
        }

        // trigger to show specify input box for single choice popup question
        if (
            button?.value === OTHER_OPTION_VALUE &&
            this.props.question.optionSettings?.showSpecify &&
            !other
        ) {
            this.setState({ showSpecify: true });
            return;
        }

        let answer;
        let { selections, showSpecify } = this.state;
        if (!button) {
            // handle NA
            answer = this.props.isMulti ? [] : undefined;
        } else if (this.props.isMulti) {
            // handle multiple selection
            answer = Object.keys(button);
        } else {
            // handle single selection
            answer = {
                ...button,
                value: button.value,
                id: button.id,
                texts: button.texts
            };
            // For single selection, displays check mark for the selected answer
            selections = Object.assign({}, this.state.selections, {
                [button.value]: true as const
            });
            showSpecify = false;
        }

        this.setState({
            selectedValue: button?.text,
            submitting: true,
            fadeOut: true,
            disableButtons: true,
            selections,
            showSpecify
        });

        this.props.isMulti
            ? (async () => {
                  this.props.handleAnswer(answer, other);
                  await Promise.resolve(delay(1000));
              })()
            : (async () => {
                  await Promise.resolve(delay(1000));
                  this.props.handleAnswer(answer, other);
              })();
    };

    toggleChoice = (value: string) => {
        const isCurrOther = value === OTHER_OPTION_VALUE;
        const showSpecify =
            isCurrOther && this.props.question?.optionSettings?.showSpecify
                ? !this.state.showSpecify
                : this.state.showSpecify;

        if (this.state.selections[value]) {
            const s = Object.assign({}, this.state.selections);
            delete s[value];
            this.setState({ selections: s, showSpecify });
        } else {
            this.setState({
                selections: Object.assign({}, this.state.selections, {
                    [value]: true as const
                }),
                showSpecify
            });
        }
    };

    updateDisabled = () => {
        setTimeout(() => {
            this.setState({ disableButtons: false });
        }, 1000);
    };

    validatorText = (max: number, min: number) => {
        const { t } = this.props;
        const selected = Object.keys(this.state.selections).length;

        if (selected === 0) {
            return (
                <div className="multi-choice-help">
                    <p>{t('LABEL.VALIDATE.SELECT')}</p>
                </div>
            );
        } else if (selected > max) {
            return (
                <div className="multi-choice-help warning">
                    <p>{t('LABEL.VALIDATE.SELECT_MIN_MAX', { min, max })}</p>
                </div>
            );
        } else if (selected < min) {
            return (
                <div className="multi-choice-help">
                    <p>{t('LABEL.VALIDATE.MORE', { more: min - selected })}</p>
                </div>
            );
        } else if (selected === max) {
            return (
                <div className="multi-choice-help">
                    <p>{t('LABEL.VALIDATE.SATISFIED')}</p>
                </div>
            );
        } else {
            return (
                <div className="multi-choice-help">
                    <p>{t('LABEL.VALIDATE.CONTINUE_OR_MORE')}</p>
                </div>
            );
        }
    };

    render() {
        const { question, langId, isMulti } = this.props;
        const {
            showNotApplicableOption,
            notApplicableTexts,
            continueTexts,
            max,
            min
        } = question;
        const { showSpecify, specifyText, showError } = this.state;
        let onClickHandler;
        if (isMulti) {
            onClickHandler = (option) => this.toggleChoice(option.value);
        } else {
            onClickHandler = (option) => this.handleSubmit(option);
        }
        const options = addOtherToOptions(question);
        const buttonRows = Math.ceil(options.length / this.state.breakpoint);
        const buttonsPerRow = Math.ceil(options.length / buttonRows);
        const buttonWidth = 100 / buttonsPerRow;

        const buttonOptions = options.map((option, index) => (
            <PopupButtons
                key={index}
                buttonType={this.props.question.selectedOptionType}
                selected={
                    Object.keys(this.state.selections).includes(option.value) ||
                    (showSpecify && option.value === OTHER_OPTION_VALUE)
                }
                hasIcon={true}
                disableButtons={this.state.disableButtons || option.disabled}
                buttonText={getStringFromMultimediaTexts(
                    option.texts,
                    langId,
                    option.label
                )}
                buttonSet={option}
                onClick={() => onClickHandler(option)}
                onKeyDown={(e) => {
                    if (e.key === KEY_NAME.SPACE || e.key === KEY_NAME.ENTER) {
                        onClickHandler(option);
                    }
                }}
                value={this.state.selectedValue}
                width={buttonWidth}
                size={this.state.size}
                numOptions={options.length}
            />
        ));

        const notApplicableText = getStringFromMultimediaTexts(
            notApplicableTexts,
            langId
        );
        const continueText =
            getStringFromMultimediaTexts(continueTexts, langId) ||
            this.props.t('BUTTON.CONTINUE');

        const AnimationClass = this.state.fadeOut ? 'fadeOut' : '';
        const isImage =
            ((question.mediaURL || question.pictureURL) &&
                OPTION_TYPES.IMAGE_ONLY ===
                    question.popupSettings?.popupCardTypes)

        const questionTextsObject = {};
        question.texts.forEach((questionText) => {
            questionTextsObject[questionText.language] = questionText;
        });

        const currentQuestionText = questionTextsObject[langId] || {};
        const s = pickStatus(
            Object.getOwnPropertyNames(this.state.selections).length,
            min,
            max
        );
        let disabledButton = true;
        if (s === 100 || s === PERFECT) {
            disabledButton = false;
        }

        this.stickyFooter?.clientHeight + 20 > this.state.stickyFooterHeight &&
            this.setStickyFooter();

        return (
            <React.Fragment>
                <div
                    className="content from-bot bot-width"
                    ref={(e) => (this.popupWrapper = e)}
                >
                    <div className="plain-text popup-wrapper">
                        {isImage ? (
                            <SlideInDiv
                                className={`image-wrapper ${AnimationClass}`}
                                key={question.value}
                            >
                                <div className="progress-bar">
                                    <div
                                        className="progress-percentage"
                                        style={{ width: '10%' }}
                                    ></div>
                                </div>
                                <div className="image-contents">
                                    <img
                                        src={
                                            question.mediaURL ||
                                            question.pictureURL
                                        }
                                    />
                                    {/* <img src="https://images-na.ssl-images-amazon.com/images/I/41jQuY2C9bL._AC_SY400_.jpg" alt=""/> */}
                                </div>
                            </SlideInDiv>
                        ) : (
                            <SlideInDiv
                                className={`text-wrapper ${AnimationClass}`}
                                key={question.value}
                            >
                                <Editor
                                    editorState={EditorState.createWithContent(
                                        convertFromRaw(
                                            currentQuestionText?.contents?.[0]
                                                ?.richText
                                        )
                                    )}
                                    toolbar={{ options: [], inline: [] }}
                                    readOnly={true}
                                />
                            </SlideInDiv>
                        )}
                        <div
                            className={`popup-container-wrapper`}
                            ref={(e) => (this.stickyFooter = e)}
                        >
                            <div
                                className={`buttons-container ${
                                    Object.keys(this.state.selections).length >
                                    0
                                        ? 'ticked'
                                        : ''
                                }`}
                            >
                                <div className="buttons-container-flex bot-width">
                                    {buttonOptions}
                                </div>
                            </div>
                            {showSpecify ? (
                                <SpecifyEditor
                                    onSubmit={() =>
                                        this.handleSubmit(
                                            isMulti
                                                ? this.state.selections
                                                : {
                                                      ...question.optionSettings
                                                          ?.otherOption,
                                                      specify: specifyText
                                                  },
                                            specifyText
                                        )
                                    }
                                    showError={showError}
                                    specifyText={specifyText}
                                    handleChangeSpecifyText={(specifyText) =>
                                        this.setState({ specifyText })
                                    }
                                    t={this.props.t}
                                />
                            ) : null}
                            <div
                                className="bottom-select-links bot-width"
                                style={{ marginTop: 0 }}
                            >
                                {isMulti ? this.validatorText(max, min) : null}
                                {showNotApplicableOption && (
                                    <NotApplicableButton
                                        onClick={() =>
                                            this.handleSubmit(undefined)
                                        }
                                        text={notApplicableText}
                                        disabled={this.state.disableButtons}
                                    />
                                )}
                                {(isMulti || showSpecify) && (
                                    <ContinueButton
                                        onClick={() => {
                                            this.handleSubmit(
                                                isMulti
                                                    ? this.state.selections
                                                    : {
                                                          ...question
                                                              .optionSettings
                                                              ?.otherOption,
                                                          specify: specifyText
                                                      },
                                                specifyText
                                            );
                                        }}
                                        disabled={isMulti && disabledButton}
                                        text={continueText}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
