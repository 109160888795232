import * as React from 'react';
import * as _ from 'lodash';
import { BaseInput, BaseProps } from './_BaseInput';
import NotApplicableButton from '../components/NotApplicableButton';
import ContinueButton from '../components/ContinueButton';
import { Region, VectorMapAnswer } from '@nexxt/common/types';
import { getStringFromMultimediaTexts } from '@nexxt/common/services/TextService';
import { last } from '@nexxt/common/utils/index';
const { translate } = require('react-i18next');
import Select from 'react-select';
import { VectorMap as JVectormap } from '@nexxt-intelligence/react-jvectormap';
import {
    VECTOR_MAP_REGION_CODES,
    VECTOR_MAP_ENGLISH_TO_CHINESE,
    VECTOR_MAP_ENGLISH_TO_FRENCH,
    VMAP_OVALUE,
    FILTER_VECTOR_MAP_CODES
} from '@nexxt/common/constants';
import '../style/VectorMap.scss';

interface Props extends BaseProps {
    handleAnswer: (s: string) => void;
}

interface State {
    enabled: boolean;
    region: any; // @TODO: typing
    enableShowRegion: boolean;
}

@translate('translation')
export class VectorMap extends BaseInput<Props, State> {
    state: State = {
        enabled: true,
        region: null,
        enableShowRegion: true
    };
    componentDidCatch(er, info) {
        this.setState({ enableShowRegion: false });
    }

    componentDidMount() {
        const { previousAnswer, question } = this.props;

        if (previousAnswer) {
            const selectedRegion = this.props.question.selectedRegion as Region;

            const selectedValue = (previousAnswer as VectorMapAnswer).option
                .value;
            const regionKey = _.findKey(
                VECTOR_MAP_REGION_CODES,
                (value, key) =>
                    value === selectedValue &&
                    key.split('/')[3] === selectedRegion.identifier
            );
            regionKey &&
                this.setState({
                    region: {
                        code: regionKey.split('/')[4],
                        label: selectedValue,
                        value: regionKey
                    }
                });
            // const formattedCode = `${VMAP_OVALUE}${selectedRegion.identifier}/${code}`;
            // const label = VECTOR_MAP_REGION_CODES[formattedCode];
        }
    }
    clearTooltips = (activeRegion: string = '') => {
        // @TODO: country name type
        // HACKY SOLUTION:
        // force-delete all the jvectormap tips [popups] to prevent
        // bug where the tips persist after the question input is done
        // https://stackoverflow.com/a/10842510
        let activeRegionInstances = 0;
        [].forEach.call(
            document.querySelectorAll('.jvectormap-tip'),
            function (e) {
                if (e.textContent !== activeRegion || activeRegionInstances) {
                    // only one activeRegion persists
                    e.parentNode.removeChild(e);
                } else {
                    activeRegionInstances++;
                }
            }
        );
    };

    submit = () => {
        this.clearTooltips(); // clear tooltips
        this.setState({ enabled: false });
        this.props.handleAnswer(this.state.region.label);
    };

    onMapClick = (e, code) => {
        const selectedRegion = this.props.question.selectedRegion as Region;
        const formattedCode = `${VMAP_OVALUE}${selectedRegion.identifier}/${code}`;
        const label = VECTOR_MAP_REGION_CODES[formattedCode];
        this.clearTooltips(label);
        this.setState({ region: { value: formattedCode, label, code } }, () => {
            if (!this.props.question.showContinueButton) {
                this.submit();
            }
        });
    };

    onSelect = (region) => {
        this.clearTooltips();
        const code = last(region.value.split('/'));
        this.setState({
            region: { ...region, code }
        });
    };

    unformatCode = (formattedCode: string) => {
        return formattedCode.split('/')[-1];
    };

    formatRegionTip = (e, tip, code) => {
        // convert from english --> chinese if applicable
        switch (this.props.langId) {
            case 'fr':
                tip.html(VECTOR_MAP_ENGLISH_TO_FRENCH[code]);
                break;
            case 'zh_TW':
                tip.html(VECTOR_MAP_ENGLISH_TO_CHINESE[code]);
                break;
            default:
                break;
        }
    };

    // Based on the region `code`, return the string that will be shown
    // as the label for that region. Currently only the USA map is supported,
    // and certain of the US states are hidden.
    renderLabels = (code) => {
        const noShowStates = ['US-RI', 'US-DC', 'US-DE', 'US-MD'];
        const { question } = this.props;
        const region = question.selectedRegion as Region;
        if (region.title === 'USA' || region.title === 'Canada') {
            if (!noShowStates.includes(code)) {
                // return VECTOR_MAP_REGION_CODES[code]
                return code.split('-')[1]; // remove 'US-' prefix
            }
        }
    };

    // If applicable, specifies the offset for the label for any particular region code.
    renderLabelOffsets = (code) => {
        return {
            CA: [-10, 10],
            ID: [0, 40],
            OK: [25, 0],
            LA: [-20, 0],
            FL: [45, 0],
            KY: [10, 5],
            VA: [15, 5],
            MI: [30, 30],
            AK: [50, -25],
            HI: [25, 50]
        }[code.split('-')[1]];
    };

    render() {
        const { langId, question } = this.props;
        const { enableShowRegion } = this.state;
        const region = question.selectedRegion as Region;
        const selectedMapCode = region.value;

        const { notApplicableTexts, continueTexts } = question;
        const notApplicableText = getStringFromMultimediaTexts(
            notApplicableTexts,
            langId
        );
        const continueText = getStringFromMultimediaTexts(
            continueTexts,
            langId
        );
        // generate list of options for Select
        const optionsObj = FILTER_VECTOR_MAP_CODES(
            this.props.question.selectedRegion as Region
        );
        const options = Object.keys(optionsObj).map((key) => ({
            value: key,
            label: optionsObj[key]
        }));
        const sortedOptions = options.sort((a, b) =>
            a.label.localeCompare(b.label)
        );

        return this.state.enabled ? (
            <>
                <div className="vector-map-ui">
                    {selectedMapCode === 'world_mill' && ( // Select only enabled for world map (for now)
                        <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="accessType"
                            value={this.state.region}
                            options={sortedOptions}
                            onChange={this.onSelect}
                            placeholder="Search for an area, or click on the map"
                        />
                    )}
                    <JVectormap
                        map={selectedMapCode}
                        backgroundColor="rgb(117, 181, 226)"
                        containerStyle={{
                            width: '100%',
                            height: '100%',
                            margin: '0 auto'
                        }}
                        onRegionClick={this.onMapClick}
                        onRegionTipShow={this.formatRegionTip}
                        containerClassName="map ripple"
                        labels={{
                            regions: {
                                render: this.renderLabels,
                                offsets: this.renderLabelOffsets
                            }
                        }}
                        selectedRegions={
                            enableShowRegion && this.state.region
                                ? [this.state.region.code]
                                : []
                        }
                        regionLabelStyle={{
                            initial: {
                                'font-family': 'Verdana',
                                'font-size': '10',
                                'font-weight': 'bold',
                                cursor: 'default',
                                fill: 'black'
                            },
                            hover: {
                                cursor: 'pointer'
                            }
                        }}
                        regionStyle={{
                            selected: {
                                fill: '#349a71'
                            }
                        }}
                    />
                </div>
                <div className="bottom-select-links bot-width">
                    {this.props.question.showNotApplicableOption && (
                        <NotApplicableButton
                            onClick={() =>
                                this.props.handleAnswer(notApplicableText)
                            }
                            text={notApplicableText}
                        />
                    )}
                    {this.props.question.showContinueButton && (
                        <ContinueButton
                            onClick={this.submit}
                            disabled={!this.state.region}
                            text={continueText}
                        />
                    )}
                </div>
            </>
        ) : null;
    }
}
