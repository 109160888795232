import * as React from 'react';
import RollbarHOC from './HOC/Rollbar';
import { STATIC_STORAGE_URL } from '@nexxt/common/constants';

interface BotErrorProps {
    rollbar: any;
}

class BotError extends React.Component<BotErrorProps, { hasError: boolean }> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        this.props.rollbar.error(`[BOT]: ${error}`);
        // TODO: Log error to the back end
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="bot-error">
                    <h2>Something went wrong...</h2>
                    <h3>Reload page and try again!</h3>
                    <img
                        src={`${STATIC_STORAGE_URL}/img/portal/illustrates/log-in_failed_white.gif`}
                        alt="robot-greeting"
                        width="200px"
                    />
                </div>
            );
        }

        return this.props.children;
    }
}

export default RollbarHOC(BotError);
