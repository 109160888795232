import * as React from 'react';
import * as _ from 'lodash';
import { BaseInput, BaseProps } from './_BaseInput';
import * as cx from 'classnames';
import NotApplicableButton from '../components/NotApplicableButton';
import ContinueButton from '../components/ContinueButton';
import {
    getStringFromMultimediaTexts,
    getTranslation,
    formatNumber,
    formatThousandSeparator
} from '@nexxt/common/services/TextService';
import styled from 'styled-components';
import { KEY_NAME, NA_OPTION_VALUE } from '@nexxt/common/constants';
const { translate } = require('react-i18next');

const Outer = styled.div`
    .numeric--input--container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        margin: 0 auto;
        > span {
            font-size: 20px;
        }
    }
    input {
        width: 40ch;
        min-width: 20ch;
        max-width: calc(100% - 30px);
        // width: 20em;
        font-size: 16px;
        padding: 0.5em;
        text-align: center;
        display: block;
        z-index: 16;
        position: relative;
        border-bottom: 1px solid #595959;
        outline: 0;
        border-width: 0 0 1px;
    }
    button {
        z-index: 16;
    }
    p {
        min-height: 2em;
    }
    .container {
        position: relative;
        right: 95px;
    }
`;

interface Props extends BaseProps {
    handleAnswer: (
        numericAnswer: number | string,
        formattedAnswer?: string
    ) => void;
}

interface State {
    numericAnswer: number;
    formattedAnswer: string;
    validationText: string;
    showError: boolean;
    isValid: boolean;
}

@translate('translation')
export class Numeric extends BaseInput<Props, State> {
    state: State = {
        numericAnswer: null,
        formattedAnswer: '',
        validationText: getTranslation(
            this.props.t,
            'LABEL.VALIDATE.NUMERIC_INPUT'
        ),
        showError: false,
        isValid: false
    };

    /**
     * Remove commas from value and returns numeric value without formatting
     * i.e. 1,000 to 1000
     */
    removeSeparator = (value: string) => {
        return _.replace(value, /,/g, '');
    };

    /**
     *  Get string input value and returns pure numeric value in array.
     * i.e. 1,000 -> ['1','0','0','0'], 2.34 -> ['2','.','3','4']
     */
    getNumberAsArray = (stringInput: string): string[] => {
        const arr = this.removeSeparator(stringInput).split('');

        const numericArray = [];
        arr.forEach((char) => {
            const charToNumber = _.toNumber(char);
            if (
                (!_.isNaN(charToNumber) && _.isNumber(charToNumber)) ||
                char === '.'
            ) {
                numericArray.push(char);
            }
        });

        return numericArray;
    };

    /**
     * receive user's input and format it before rendering
     *
     */
    handleInputKeyUp = (e) => {
        this.setState({ showError: true });

        /**
         * When the key is ENTER, submit answer
         */
        if (e.key === KEY_NAME.ENTER && this.state.isValid) {
            this.submit();
            return;
        }
        const numArray = this.getNumberAsArray(e.target.value);
        const { t, question } = this.props;
        const { min, max, openEndedSettings } = question;
        const {
            thousandSeparator,
            allowDecimal,
            numDecimals,
            showMin,
            showMax
        } = openEndedSettings || {};
        const textInput = document.getElementById('numeric-input');

        let isValid = true,
            validationText = '';
        if (!numArray.length) {
            /**
             * When there is no numeric input, reset to the empty input
             * */
            validationText = getTranslation(
                this.props.t,
                'LABEL.VALIDATE.NUMERIC_INPUT'
            );
            isValid = false;
            textInput['value'] = '';
            this.setState({ formattedAnswer: '', numericAnswer: null });
        } else if (_.last(numArray) !== '.') {
            const numericAnswer = _.toNumber(numArray.join(''));

            /**
             * When showMin is true, validate the answer with question.min
             */
            if (showMin && min > numericAnswer) {
                validationText = getTranslation(t, 'LABEL.VALIDATE.TOO_LOW', {
                    min
                });
                isValid = false;
            }

            /**
             * When showMax is true, validate the answer with question.max
             */
            if (showMax && max < numericAnswer) {
                /** @TODO Show validation */
                validationText = getTranslation(t, 'LABEL.VALIDATE.TOO_HIGH', {
                    max
                });
                isValid = false;
            }
            const decimalPointIndex = numArray.findIndex(
                (char) => char === '.'
            );
            const hasDecimalPoints = decimalPointIndex >= 0;
            const numDeciamlPoints = numArray.filter((char) => char === '.');
            const wholeNumber: string = (
                hasDecimalPoints
                    ? numArray.slice(0, decimalPointIndex)
                    : numArray
            ).join('');
            const fractionalPart: string = (
                hasDecimalPoints ? numArray.slice(decimalPointIndex + 1) : ['']
            ).join('');

            /**
             * When input includes more than one period, show invalid error message
             */
            if (numDeciamlPoints.length > 1) {
                /** @TODO incorrect format */
                validationText = getTranslation(
                    t,
                    'LABEL.VALIDATE.NUMERIC_INPUT',
                    {
                        max
                    }
                );
                isValid = false;
            }

            const numCurrentDecimalPoints = fractionalPart.length;

            /**
             * When decimal point is allowed, validate answer with the allowed decimal number
             */
            if (
                (!allowDecimal && fractionalPart) ||
                (allowDecimal &&
                    numCurrentDecimalPoints &&
                    numCurrentDecimalPoints > numDecimals)
            ) {
                validationText = getTranslation(
                    t,
                    'LABEL.VALIDATE.INVALID_DECIMALS',
                    {
                        numDecimals
                    }
                );
                isValid = false;
            }

            let formattedAnswer = formatThousandSeparator(
                _.toNumber(wholeNumber),
                thousandSeparator
            );

            if (numCurrentDecimalPoints) {
                formattedAnswer = formattedAnswer + '.' + fractionalPart;
            }

            /**
             * When no error message was detected, mark it as valid answer
             */
            if (!validationText) {
                validationText = getTranslation(t, 'LABEL.VALIDATE.SATISFIED', {
                    numDecimals
                });
            }
            textInput['value'] = formattedAnswer;
            this.setState({ formattedAnswer, numericAnswer });
        } else {
            /** @TODO incorrect format */
            validationText = getTranslation(t, 'LABEL.VALIDATE.NUMERIC_INPUT', {
                numDecimals
            });
            isValid = false;
        }

        this.setState({
            isValid,
            validationText
        });
    };

    submit = () => {
        const { isValid, numericAnswer } = this.state;
        const { numDecimals, allowDecimal, thousandSeparator } =
            this.props.question?.openEndedSettings || {};
        if (isValid) {
            // to add decimal points based on numDecimals
            const finalFormattingNumber = formatNumber(numericAnswer, {
                numDecimals,
                allowDecimal,
                thousandSeparator
            });
            this.props.handleAnswer(numericAnswer, finalFormattingNumber);
        }
    };

    render() {
        const { langId, t, question } = this.props;
        const { numericAnswer, isValid } = this.state;
        const {
            showNotApplicableOption,
            notApplicableTexts,
            continueTexts,
            openEndedSettings
        } = question;
        const {
            placeholder,
            thousandSeparator,
            allowDecimal,
            numDecimals,
            leftUnit,
            rightUnit
        } = openEndedSettings || {};
        const notApplicableText = getStringFromMultimediaTexts(
            notApplicableTexts,
            langId,
            getTranslation(this.props.t, 'BUTTON.NOT_APPLICABLE')
        );

        const continueText = getStringFromMultimediaTexts(
            continueTexts,
            langId,
            getTranslation(this.props.t, 'BUTTON.CONTINUE')
        );
        const formattedPlaceholder = formatNumber(placeholder, {
            thousandSeparator,
            allowDecimal,
            numDecimals
        });
        const answerLength = String(numericAnswer)?.length || 0;
        return (
            <Outer>
                <div className="numeric--input--container">
                    {leftUnit ? <span>{leftUnit}</span> : null}
                    <input
                        id="numeric-input"
                        style={{ width: `${answerLength}ch` }}
                        autoFocus
                        placeholder={formattedPlaceholder}
                        onKeyUp={this.handleInputKeyUp}
                    />
                    {rightUnit ? <span>{rightUnit}</span> : null}
                </div>

                <div
                    className={cx('multi-choice-help', {
                        warning: !isValid
                    })}
                >
                    <p>{this.state.validationText}</p>
                </div>

                <div className="bottom-select-links bot-width">
                    {showNotApplicableOption && (
                        <NotApplicableButton
                            onClick={() =>
                                this.props.handleAnswer(
                                    NA_OPTION_VALUE,
                                    notApplicableText
                                )
                            }
                            text={notApplicableText}
                        />
                    )}

                    <ContinueButton
                        onClick={this.submit}
                        disabled={!isValid}
                        text={continueText}
                    />
                </div>
            </Outer>
        );
    }
}
